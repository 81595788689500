import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var cargaAnimacion: any;
declare var pickArribaArriba: any;
declare var pickArribaAbajo: any;
declare var pickAbajoAbajo: any;
declare var pickAbajoArriba: any;
declare var lottie: any;
declare var flexFont: any;


@Component({
  selector: 'app-pick',
  templateUrl: './pick.component.html',
  styleUrls: ['./pick.component.css']
})
export class PickComponent implements OnInit {


  @Input() public data: any;
  @Output() elClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

    document.getElementById('cpick').id = this.data.id + 'cpick';

    let animacion: any;

    switch (this.data.grafico) {
      case 'upup':
        animacion = pickArribaArriba;
        break;
      case 'updown':
        animacion = pickArribaAbajo;
        break;
      case 'downdown':
        animacion = pickAbajoAbajo;
        break;
      case 'downup':
        animacion = pickAbajoArriba;
        break;
      default:
    }


    animacion.op = this.data.principal + 1;

    animacion.layers[18].t.d.k[0].s.t = this.data.verde;
    animacion.layers[17].t.d.k[0].s.t = this.data.amarillo;
    animacion.layers[16].t.d.k[0].s.t = this.data.rojo;
    animacion.layers[15].t.d.k[0].s.t = this.data.morado;
    animacion.layers[8].t.d.k[0].s.t = this.data.circuloIzqValor;
    animacion.layers[9].t.d.k[0].s.t = this.data.circuloDerValor;
    animacion.layers[7].t.d.k[0].s.t = this.data.ovaloIzqValor;
    animacion.layers[6].t.d.k[0].s.t = this.data.ovaloCenValor;
    animacion.layers[5].t.d.k[0].s.t = this.data.ovaloDerValor;
    animacion.layers[2].t.d.k[0].s.t = this.data.textAmarillo;
    animacion.layers[1].t.d.k[0].s.t = this.data.textRojo;
    animacion.layers[0].t.d.k[0].s.t = this.data.textMorado;


    const params = {
      container: document.getElementById(this.data.id + 'cpick'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animacion
    };

    let anim;
    anim = lottie.loadAnimation(params);

    flexFont();
  }

  llamadaClick(e) {

    this.elClick.emit(e);

  }

}
