import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var cargaAnimacion: any;
declare var ingresoVerdeX4: any;
declare var ingresoAmarilloX4: any;
declare var ingresoRojoX4: any;
declare var lottie: any;
declare var flexFont: any;

@Component({
  selector: 'app-ingresada-x4',
  templateUrl: './ingresada-x4.component.html',
  styleUrls: ['./ingresada-x4.component.css']
})

export class IngresadaX4Component implements OnInit {

  @Input() public data: any;
  @Output() elClick = new EventEmitter();

  constructor() { }

  ngOnInit() {

    document.getElementById('contenedorIngreso').id = this.data.id + 'contenedorIngreso';

    let animacion: any;

    switch (this.data.grafico) {
      case 'ingresoVerde':
      animacion = ingresoVerdeX4 ;
          break;
      case 'ingresoRojo':
      animacion = ingresoRojoX4 ;
          break;
      case 'ingresoAmarillo':
      animacion = ingresoAmarilloX4 ;
       break;
      default:
    }

    animacion.op = 101;
    animacion.layers[9].ef[0].ef[0].v.k[0].e[0] = this.data.principal;
    animacion.layers[0].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor;
    animacion.layers[2].ef[0].ef[0].v.k[0].e[0] = this.data.circulo2valor;
    animacion.layers[4].ef[0].ef[0].v.k[0].e[0] = this.data.circulo3valor;
    animacion.layers[6].ef[0].ef[0].v.k[0].e[0] = this.data.circulo4valor;
    animacion.layers[10].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor + this.data.circulo2valor + this.data.circulo3valor +  this.data.circulo4valor;
    animacion.layers[8].t.d.k[0].s.t = this.data.tope;

    var params = {
        container: document.getElementById(this.data.id + 'contenedorIngreso'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animacion
    };

    var anim;
    anim = lottie.loadAnimation(params);


  }

  llamadaClick( funAllamar ) {

    this.elClick.emit({ dato: funAllamar});

  }

  emitterevent(e) {
    this.elClick.emit(e);
  }

}
