import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class LoginService {

  // 'http://10.107.101.106:8342/vev/login/ingresar';
  // 'http://10.107.101.106:8342/vev/login/agregarUsuario';
  // 'http://10.107.101.106:8342/vev/login/modificarUsuario';

  constructor(private _http: HttpClient) {

  }


  login(user: string, pass: string) {
    return this._http.post(environment.dominioServicios + '/vev/Login/ingresar',
      JSON.stringify({
        user: user, pass: pass
      })
      , {headers: new HttpHeaders({'Content-Type': 'application/json'})});

  }

  agregarUsuario(tipoUsuario: string, nombre: string, nombreUsuario: string, passUsuario: string, rutUsuario: string,
                 emailUsuario: string, codProveedor: string, rolUsuario: string) {
    return this._http.post(environment.dominioServicios + '/vev/Login/agregarUsuario',
      JSON.stringify({
        tipoUsuario: tipoUsuario,
        nombre: nombre,
        nombreUsuario: nombreUsuario,
        passUsuario: passUsuario,
        rutUsuario: rutUsuario,
        emailUsuario: emailUsuario,
        codProveedor: codProveedor,
        rolUsuario: rolUsuario
      })
      , {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  modificarUsuario(tipoUsuario: string, nombre: string, nombreUsuario: string, passUsuario: string, rutUsuario: string,
                   emailUsuario: string, codProveedor: string, rolUsuario: string) {
    return this._http.post(environment.dominioServicios + '/vev/Login/modificarUsuario',
      JSON.stringify({
        tipoUsuario: tipoUsuario,
        nombre: nombre,
        nombreUsuario: nombreUsuario,
        passUsuario: passUsuario,
        rutUsuario: rutUsuario,
        emailUsuario: emailUsuario,
        codProveedor: codProveedor,
        rolUsuario: rolUsuario
      })
      , {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
