import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Router} from '@angular/router';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ResponseDetalleIngresadasX5, ResponseIngresadasX5} from '../../../../comun/vo/responseIngresadasX5';
import {ResponseDetallePiramide, ResponsePiramideDespacho} from '../../../../comun/vo/responsePiramideDespacho';
import {ResponseDetalleObtieneEntregados, ResponseObtieneEntregados} from '../../../../comun/vo/responseObtieneEntregados';
import {ResonseVelocimetroModal, ResponseDetalleVelocimetro} from '../../../../comun/vo/resonseVelocimetroModal';
import {ResponseDetalleIngresadasX4, ResponseIngresadasX4} from '../../../../comun/vo/responseIngresadasX4';
import {ResponseDetalleTransporte, ResponsePiramideTransporte} from '../../../../comun/vo/responsePiramideTransporte';
import {ResponseDetalleObtieneEnviados, ResponseObtieneEnviados} from '../../../../comun/vo/ResponseObtieneEnviados';
import {ResponseDetalleIngresadas, ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {ResponseDetallePiramideX3, ResponsePiramideX3} from '../../../../comun/vo/responsePiramideX3';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseSelectBodega} from '../../../../comun/vo/responseSelectBodega';
import {ResponseSelectLocales} from '../../../../comun/vo/responseSelectLocales';

declare var ingresoAmarillo: any;
declare var ingresoAmarilloX5: any;
declare var flexFont: any;
declare var ingresoAmarilloX4: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-dashboard-ventas-administrador',
  templateUrl: './dashboard-ventas-administrador.component.html',
  styleUrls: ['./dashboard-ventas-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player.css', '../../../../../assets/css/player2.css']
})
export class DashboardVentasAdministradorComponent implements OnInit {
  @ViewChild('modalDetallePiramideVentas') modalDetallePiramideVentas;
  modalRefDetallePiramideVentas: BsModalRef;
  @ViewChild('modalDetalleVelocimetroVentas') modalDetalleVelocimetroVentas;
  modalRefDetalleVelocimetroVentas: BsModalRef;
  @ViewChild('modalDetallePiramideEmitir') modalDetallePiramideEmitir;
  modalRefDetallePiramideEmitir: BsModalRef;
  @ViewChild('modalDetalleVelocimetroEmitir') modalDetalleVelocimetroEmitir;
  modalRefDetalleVelocimetroEmitir: BsModalRef;
  @ViewChild('modalDetallePiramideRecepcionar') modalDetallePiramideRecepcionar;
  modalRefDetallePiramideRecepcionar: BsModalRef;
  @ViewChild('modalDetalleVelocimetroRecepcionar') modalDetalleVelocimetroRecepcionar;
  modalRefDetalleVelocimetroRecepcionar: BsModalRef;
  responseIngresadasX5: ResponseIngresadasX5;
  responseDetalleIngresadasX5: ResponseDetalleIngresadasX5;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  messageAlert: MensajeAlert;
  ing5: any;
  tipoDVH: any;
  tipoDDC: any;
  tipoDAD: any;
  tipoDADDVH: any;
  tipoSTS: any;
  tipoTodos: any;
  page = 1;
  pageSize = 5;
  pageSize2 = 5;
  selectPrincipalValue: number;
  tipoBoton: string;
  tipoPiramide: string;
  codTienda: string;
  codBodega: string;
  responsePiramide: ResponsePiramideDespacho;
  responseDetallePiramide: ResponseDetallePiramide[];
  responsePiramideExcel: ResponsePiramideDespacho;
  responseDetallePiramideExcel: ResponseDetallePiramide[];
  respondeObtieneEntregados: ResponseObtieneEntregados;
  responseDetalleEntregados: ResponseDetalleObtieneEntregados;
  porEmitir: any;
  responseVelocimetroModal: ResonseVelocimetroModal;
  responseDetalleVelocimetro: ResponseDetalleVelocimetro[];
  responseVelocimetroModalExcel: ResonseVelocimetroModal;
  responseDetalleVelocimetroExcel: ResponseDetalleVelocimetro[];
  responseIngresadasX4: ResponseIngresadasX4;
  responseDetalleIngresadasX4: ResponseDetalleIngresadasX4;
  responseIngresadasX6: ResponseIngresadasX4;
  responseDetalleIngresadasX6: ResponseDetalleIngresadasX4;
  tipoVelocimetro: string;
  porRecepcionar: any;
  porEntregar: any;
  porEntregars: any;
  enPicking: any;
  ing4: any;
  ing6: any;
  responseTransporte: ResponsePiramideTransporte;
  responseDetalleTransporte: ResponseDetalleTransporte[];
  responseTransporteExcel: ResponsePiramideTransporte;
  responseDetalleTransporteExcel: ResponseDetalleTransporte[];
  responseEnviados: ResponseObtieneEnviados;
  responseDetalleEnviados: ResponseDetalleObtieneEnviados;
  responseRecepcionados: ResponseObtieneEnviados;
  responseDetalleRecepcion: ResponseDetalleObtieneEnviados;
  responseIngresadasVO: ResponseIngresadas;
  responseDetalleIngresadas: ResponseDetalleIngresadas;
  ingA: any;
  responsePiramideEmitirX3: ResponsePiramideX3;
  responseDetalleEmitirX3: ResponseDetallePiramideX3;
  responsePiramideRecepcionX3: ResponsePiramideX3;
  responseDetalleRecepcionX3: ResponseDetallePiramideX3;
  numBoletaSubEstado: any;
  numOrdenSubEstado: any;
  fechaDesdeSubEstado: any;
  fechaHastaSubEstado: any;
  numBoleta: any;
  numOrden: any;
  fechaDesde: any;
  fechaHasta: any;
  codigoTiendaInicial: any;
  codigoBodegaInicial: any;
  fechaVentaInicial: any;
  fechaPickingInicial: any;
  fechaShippingInicial: any;
  fechaCompromisoInicial: any;
  public bodegas: any[];
  responseSelectBodega: ResponseSelectBodega;
  responseSelectLocales: ResponseSelectLocales;
  selectBodegaFiltroPrincipal: any;
  fechaVentaFiltroPrincipal: any;
  tipoPiramideVentas: string;
  tipoPiramideEmitir: string;
  tipoPiramideRecepcionar: string;
  tipoVelocimetroEmitir: string;
  tipoVelocimetroRecepcionar: string;
  numeroRutaInicial: string;
  empresaTransporteInicial: string;
  comunaInicial: string;
  selectLocalFiltroPrincipal: any;
  tipoOrdenInicial: any;

  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _excelService: ExcelService) { }

  ngOnInit() {
    this.cargaPiramideVentas('ingresocC', ingresoAmarilloX5, 'ventas', '', '');
    this.cargaVelocimetroVentas('pickB', 'En Despacho', '', '');
    this.cargaPiramideEmitir('ingresoA', ingresoAmarillo, 'emitir', '', '');
    this.cargaVelocimetroPorEmitir('pickD', 'VEV Sin Emitir Orden', 'emitir', '', '');
    this.cargaPiramideRecepcionar('ingA', ingresoAmarilloX4, 'recepcionar', '', '');
    this.cargaVelocimetroRecepcionar('pickC', 'VEV Por Recepcionar', 'recepcionar', '', '');
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
    this.codigoTiendaInicial = '';
    this.codigoBodegaInicial = '';
    this.fechaVentaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.fechaCompromisoInicial = '';
    this.obtenerBodegas();
    this.obtenerLocales();
    this.selectBodegaFiltroPrincipal = 'n';
    this.selectLocalFiltroPrincipal = 'n';
    this.fechaVentaFiltroPrincipal = '';
    this.tipoPiramideVentas = 'ventas';
    this.tipoPiramideEmitir = 'emitir';
    this.tipoPiramideRecepcionar = 'recepcionar';
    this.tipoVelocimetroEmitir = 'emitir';
    this.tipoVelocimetroRecepcionar = 'recepcionar';
    this.numeroRutaInicial = '';
    this.empresaTransporteInicial = '';
    this.comunaInicial = '';
    this.tipoOrdenInicial = '';
  }

  obtenerBodegas() {
    this._estadoDashboard.getBodegasSelect()
      .subscribe((data: HttpResponse<any>) => {
      this.responseSelectBodega = data.body;
      console.log('Resultado select bodega: ' + this.responseSelectBodega.codigoBodega);
    });
  }

  obtenerLocales() {
    this._estadoDashboard.getLocalesVenta()
      .subscribe((data: HttpResponse<any>) => {
        this.responseSelectLocales = data.body;
        console.log('Resultado select bodega: ' + this.responseSelectLocales.codigoLocal);
      });
  }

  filtroPrincipal() {

    if (this.selectLocalFiltroPrincipal === 'n' && this.fechaVentaFiltroPrincipal === '') {
      this.abrirModalError('Debe seleccionar al menos 1 filtro!', 'Error.');
    } else {

      this.responseDetalleIngresadasX5 = null;
      this.responseDetalleEntregados = null;
      this.responseDetalleEmitirX3 = null;
      this.responseDetalleEnviados = null;
      this.responseDetalleRecepcionX3 = null;
      this.responseDetalleRecepcion = null;

        this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
        this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;
        this.cargaPiramideVentas('ingresocC', ingresoAmarilloX5, this.tipoPiramideVentas, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
        this.cargaVelocimetroVentas('pickB', 'En Despacho', this.validaFecha(this.fechaVentaInicial), this.validaSelect(this.codigoTiendaInicial));
        this.cargaPiramideEmitir('ingresoA', ingresoAmarillo, this.tipoPiramideEmitir, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
        this.cargaVelocimetroPorEmitir('pickD', 'VEV Sin Emitir Orden', this.tipoVelocimetroEmitir, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
        this.cargaPiramideRecepcionar('ingA', ingresoAmarilloX4, this.tipoPiramideRecepcionar, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
        this.cargaVelocimetroRecepcionar('pickC', 'VEV Por Recepcionar', this.tipoVelocimetroRecepcionar, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
    }
  }

  limpiarPrincipal() {

    this.responseDetalleIngresadasX5 = null;
    this.responseDetalleEntregados = null;
    this.responseDetalleEmitirX3 = null;
    this.responseDetalleEnviados = null;
    this.responseDetalleRecepcionX3 = null;
    this.responseDetalleRecepcion = null;

    this.tipoPiramideVentas = 'ventas';
    this.tipoPiramideEmitir = 'emitir';
    this.tipoPiramideRecepcionar = 'recepcionar';
    this.tipoVelocimetroEmitir = 'emitir';
    this.tipoVelocimetroRecepcionar = 'recepcionar';
    this.codigoBodegaInicial = '';
    this.fechaVentaInicial = '';
    this.codigoTiendaInicial = '';
    this.selectBodegaFiltroPrincipal = 'n';
    this.fechaVentaFiltroPrincipal = '';
    this.selectLocalFiltroPrincipal = 'n';

    this.cargaPiramideVentas('ingresocC', ingresoAmarilloX5, this.tipoPiramideVentas, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
    this.cargaVelocimetroVentas('pickB', 'En Despacho', this.validaFecha(this.fechaVentaInicial), this.validaSelect(this.codigoTiendaInicial));
    this.cargaPiramideEmitir('ingresoA', ingresoAmarillo, this.tipoPiramideEmitir, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
    this.cargaVelocimetroPorEmitir('pickD', 'VEV Sin Emitir Orden', this.tipoVelocimetroEmitir, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
    this.cargaPiramideRecepcionar('ingA', ingresoAmarilloX4, this.tipoPiramideRecepcionar, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
    this.cargaVelocimetroRecepcionar('pickC', 'VEV Por Recepcionar', this.tipoVelocimetroRecepcionar, this.validaSelect(this.codigoTiendaInicial), this.validaFecha(this.fechaVentaInicial));
  }

  // piramide ventas
  cargaPiramideVentas(id: string, color: any, tipoPiramide: any, codTienda: any, fecVenta: any) {

    this.codigoBodegaInicial = '';
    this.fechaCompromisoInicial = '';
    this.numeroRutaInicial = '';
    this.empresaTransporteInicial = '';

    this._estadoDashboard.getIngresadasX5(tipoPiramide, codTienda, this.codigoBodegaInicial, fecVenta, this.fechaCompromisoInicial, this.numeroRutaInicial, this.empresaTransporteInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.responseIngresadasX5 = data.body;
        this.responseDetalleIngresadasX5 = this.responseIngresadasX5.responsePiramideEnDespachoVO;
        console.log('RESULTADO INGRESADAS: ');
        console.log(this.responseDetalleIngresadasX5);
        this.ing5 = {
          'grafico': 'ingresoAmarillo',
          'id': id,
          'tope':  '1000000',
          'principal': this.responseDetalleIngresadasX5.hoy,
          'circulo1valor': Number(this.responseDetalleIngresadasX5.dvh),
          'circulo1text': 'DVH: ',
          'circulo1url': 'linkDVH',
          'circulo2valor': Number(this.responseDetalleIngresadasX5.ddc),
          'circulo2text': 'DDC: ',
          'circulo2url': 'linkDDC',
          'circulo3valor': Number(this.responseDetalleIngresadasX5.dadDvh),
          'circulo3text': 'DAD-DVH: ',
          'circulo3url': 'linkTO',
          'circulo4valor': Number(this.responseDetalleIngresadasX5.dad),
          'circulo4text': 'DAD: ',
          'circulo4url': 'urlLink4',
          'circulo5valor': Number(this.responseDetalleIngresadasX5.sts),
          'circulo5text': 'STS: ',
          'circulo5url': 'urlLink5',
          'imgDerecha': 'assets/img/icon_ingresadas.svg',
          'imgText': 'Ventas',
          'imgUrl': 'rojoLink'
        };
        console.log('PRUEBA PRUEBA: ');
        console.log(this.ing5);
        // cargaIngresada(this.ing5, ingresoAmarilloX5);
      });
  }
  // velocimetro ventas
  cargaVelocimetroVentas(id: string, tipo: string, fecVenta: any, codTienda: any) {

    this.codigoBodegaInicial = '';
    this.fechaCompromisoInicial = '';
    this.numeroRutaInicial = '';
    this.empresaTransporteInicial = '';

    this._estadoDashboard.getObtenerEntregados(this.codigoBodegaInicial, fecVenta, this.fechaCompromisoInicial, this.numeroRutaInicial, this.empresaTransporteInicial, codTienda)
      .subscribe((data: HttpResponse<any>) => {
        this.respondeObtieneEntregados = data.body;
        this.responseDetalleEntregados = this.respondeObtieneEntregados.responsePorEntregarVO;
        console.log('RESULTADO OBTIENE ENTREGADOS: ');
        console.log(this.responseDetalleEntregados);
        this.porEntregars = {
          'id': 'pickB',
          'grafico': 'updown',
          'tipo': 'Ventas',
          'principal': Number(this.responseDetalleEntregados.porcetajePrincipal),
          'verde': this.responseDetalleEntregados.numVerde,
          'amarillo': this.responseDetalleEntregados.numAmarillo,
          'rojo': this.responseDetalleEntregados.numRojo,
          'morado': this.responseDetalleEntregados.numMorado,
          'textAmarillo': '1-7 dias',
          'textRojo': this.responseDetalleEntregados.txtRojo + ' dias',
          'textMorado': this.responseDetalleEntregados.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_ingresadas.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleEntregados.pendientes,
          'circuloDerValor': this.responseDetalleEntregados.completadas,
          'ovaloIzqValor': this.responseDetalleEntregados.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEntregados.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEntregados.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Entregar',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
        console.log('VALOR VENTAS: ' + this.responseDetalleEntregados.pendientes);
      });

  }
  // llamada modal piramide ventas
  llamadaClickPiramideVentas(sno) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    switch (sno) {
      case 'linkDVH':
        console.log('aca va DVH');
        this._estadoDashboard.getDetallePiramide5('DVH', 'ventas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), '',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial),
          '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalPiramideVentas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DVH';
            this.tipoPiramide = 'ventas';
          });
        break;
      case 'linkDDC':
        console.log('aca va DDC');
        this._estadoDashboard.getDetallePiramide5('DDC','ventas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial),'',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial),
        '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalPiramideVentas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DDC';
            this.tipoPiramide = 'ventas';
          });
        break;
      case 'linkTO':
        console.log('aca va DAD-DVH');
        this._estadoDashboard.getDetallePiramide5('DADDVH','ventas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), '',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial),
          '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalPiramideVentas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DADDVH';
            this.tipoPiramide = 'ventas';
          });
        break;
      case 'urlLink4':
        console.log('aca va DAD');
        this._estadoDashboard.getDetallePiramide5('DAD','ventas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), '',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial),
          '', '','')
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalPiramideVentas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DAD';
            this.tipoPiramide = 'ventas';
          });
        break;
      case 'urlLink5':
        console.log('aca va STS');
        this._estadoDashboard.getDetallePiramide5('STS', 'ventas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), '',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial),
          '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalPiramideVentas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'STS';
            this.tipoPiramide = 'ventas';
          });
        break;
      default:
    }
  }
  // llamada modal velocimetro ventas
  llamadaClickVelocimetroVentas(sno2) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    switch (sno2) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnVerde', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnAmarillo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnRojo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnMorado', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEntregarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'porcentajeprincipal', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEntregarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'nuevas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEntregarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'total', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroVentas();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }
  // modal piramide ventas
  abrirModalPiramideVentas() {
    this.modalRefDetallePiramideVentas = this.modalService.show(this.modalDetallePiramideVentas, {class: 'modal-lg'});
  }
  // modal velocimetro ventas
  abrirModalVelocimetroVentas() {
    this.modalRefDetalleVelocimetroVentas = this.modalService.show(this.modalDetalleVelocimetroVentas, {class: 'modal-lg'});
  }

  // piramide emitir
  cargaPiramideEmitir(id: string, color: any, tipoPiramide: any, codTienda: any, fecVenta: any) {

    this.codigoBodegaInicial = '';

    this._estadoDashboard.getIngresadasX3Emitir(tipoPiramide, codTienda, this.codigoBodegaInicial, fecVenta)
      .subscribe((data: HttpResponse<any>) => {
        this.responsePiramideEmitirX3 = data.body;
        this.responseDetalleEmitirX3 = this.responsePiramideEmitirX3.responsePiramideEmitirVO;
        console.log('RESULTADO INGRESADAS EMITIR XE: ');
        console.log(this.responseDetalleEmitirX3);
        this.ingA = {
          'grafico': 'ingresoAmarillo',
          'id': id,
          'tope':  '1000000',// this.responseDetalleIngresadas.topeMaximo,
          'principal': Number(this.responseDetalleEmitirX3.hoy),
          'circulo1valor': Number(this.responseDetalleEmitirX3.dvh), // this.responseDetalleIngresadas.valorDvh,
          'circulo1text': 'DVH: ',
          'circulo1url': 'linkDvhEmitir',
          'circulo2valor': Number(this.responseDetalleEmitirX3.ddc), // this.responseDetalleIngresadas.valorDdc,
          'circulo2text': 'DDC: ',
          'circulo2url': 'linkDdcEmitir',
          'circulo3valor': Number(this.responseDetalleEmitirX3.dadDvh), // this.responseDetalleIngresadas.valorVto,
          'circulo3text': 'DAD-DVH: ',
          'circulo3url': 'linkDadDvhEmitir',
          'imgDerecha': 'assets/img/icon_emitir_orden.svg',
          'imgText': 'VEV Sin Orden Compra',
          'imgUrl': 'rojoLink'
        };
        // cargaIngresada(this.ingresada, ingresoAmarillo);
      });
  }
  // velocimetro emitir
  cargaVelocimetroPorEmitir(id: string, tipo: string, tipoVelocimetro: any, codTienda: any, fecVenta: any) {

    this.codigoBodegaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.fechaCompromisoInicial = '';
    this.numeroRutaInicial = '';
    this.comunaInicial = '';
    this.tipoOrdenInicial = '';

    this._estadoDashboard.getObtenerEnviados(tipoVelocimetro, codTienda, this.codigoBodegaInicial, fecVenta,
      this.fechaPickingInicial, this.fechaShippingInicial, this.fechaCompromisoInicial, this.numeroRutaInicial, this.comunaInicial, this.tipoOrdenInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.responseEnviados = data.body;
        this.responseDetalleEnviados = this.responseEnviados.responsePorEnviarVO;
        console.log('RESULTADO OBTIENE EMITIR: ');
        console.log(this.responseDetalleEnviados);
        this.porEmitir = {
          'id': 'pickD',
          'grafico': 'updown',
          'tipo': 'Por Emitir Orden',
          'principal': Number(this.responseDetalleEnviados.porcetajePrincipal),
          'verde': this.responseDetalleEnviados.numVerde,
          'amarillo': this.responseDetalleEnviados.numAmarillo,
          'rojo': this.responseDetalleEnviados.numRojo,
          'morado': this.responseDetalleEnviados.numMorado,
          'textAmarillo': '1-7 dias',
          'textRojo': this.responseDetalleEnviados.txtRojo + ' dias',
          'textMorado': this.responseDetalleEnviados.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_emitir_orden.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleEnviados.pendientes,
          'circuloDerValor': this.responseDetalleEnviados.completadas,
          'ovaloIzqValor': this.responseDetalleEnviados.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEnviados.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEnviados.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Pickear',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
      });
  }
  // llamada modal piramide emitir
  llamadaClickPiramideEmitir(sno1) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;
    switch (sno1) {
      case 'linkDvhEmitir':
        console.log('aca va DVH EMITIR');
        this._estadoDashboard.getDetallePiramide3('dvh', 'emitir', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideEmitir();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'dvh';
            this.tipoPiramide = 'emitir';
          });
        break;
      case 'linkDdcEmitir':
        console.log('aca va DDC EMITIR');
        this._estadoDashboard.getDetallePiramide3('ddc', 'emitir', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideEmitir();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'ddc';
            this.tipoPiramide = 'emitir';
          });
        break;
      case 'linkDadDvhEmitir':
        console.log('aca va DAD DVH EMITIR');
        this._estadoDashboard.getDetallePiramide3('daddvh','emitir', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideEmitir();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'daddvh';
            this.tipoPiramide = 'emitir';
          });
        break;
      default:
    }
  }
  // llamada modal velocimetro emitir
  llamadaClickVelocimetroPorEmitir(sno2) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    switch (sno2) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'btnVerde', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'btnAmarillo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'btnRojo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'btnMorado', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEntregarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'porcentajeprincipal', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEntregarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'nuevas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEntregarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('emitir', 'total', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroEmitir();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'emitir';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }
  // modal piramide emitir
  abrirModalPiramideEmitir() {
    this.modalRefDetallePiramideEmitir = this.modalService.show(this.modalDetallePiramideEmitir, {class: 'modal-lg'});
  }
  // modal velocimetro emitir
  abrirModalVelocimetroEmitir() {
    this.modalRefDetalleVelocimetroEmitir = this.modalService.show(this.modalDetalleVelocimetroEmitir, {class: 'modal-lg'});
  }


  // piramide recepcionar
  cargaPiramideRecepcionar(id: string, color: any, tipoPiramide: any, codTienda: any, fecVenta: any) {

    this.codigoBodegaInicial = '';

    this._estadoDashboard.getIngresadasX3(tipoPiramide, codTienda, this.codigoBodegaInicial, fecVenta)
      .subscribe((data: HttpResponse<any>) => {
        this.responsePiramideRecepcionX3 = data.body;
        this.responseDetalleRecepcionX3 = this.responsePiramideRecepcionX3.responsePiramideEmitirVO;
        console.log('RESULTADO INGRESADAS RECEPCIONAR X3: ');
        console.log(this.responseDetalleRecepcionX3);
        this.ing6 = {
          'grafico': 'ingresoAmarillo',
          'id': id,
          'tope':  '1000000',
          // 'principal': this.responseDetalleIngresadasX4.hoy,
          'principal': Number(this.responseDetalleRecepcionX3.hoy),
          'circulo1valor': Number(this.responseDetalleRecepcionX3.dvh),
          'circulo1text': 'DVH: ',
          'circulo1url': 'linkDvhRecepcionar',
          'circulo2valor': Number(this.responseDetalleRecepcionX3.ddc),
          'circulo2text': 'DDC: ',
          'circulo2url': 'linkDdcRecepcionar',
          'circulo3valor': Number(this.responseDetalleRecepcionX3.dadDvh),
          'circulo3text': 'DAD-DVH: ',
          'circulo3url': 'linkDadDvhRecepcionar',
          'imgDerecha': 'assets/img/icon_recepcionar.svg',
          'imgText': 'VEV Por Recepcionar',
          'imgUrl': 'rojoLink'
        };
        // cargaIngresada(this.ing5, ingresoAmarilloX5);
      });
  }
  // velocimetro recepcionar
  cargaVelocimetroRecepcionar(id: string, tipo: string, tipoVelocimetro: any, codTienda: any, fecVenta: any) {

    this.codigoBodegaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.fechaCompromisoInicial = '';
    this.numeroRutaInicial = '';
    this.comunaInicial = '';
    this.tipoOrdenInicial = '';
    this.tipoOrdenInicial = '';

    this._estadoDashboard.getObtenerEnviados(tipoVelocimetro, codTienda, this.codigoBodegaInicial, fecVenta,
      this.fechaPickingInicial, this.fechaShippingInicial, this.fechaCompromisoInicial, this.numeroRutaInicial, this.comunaInicial, this.tipoOrdenInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.responseRecepcionados = data.body;
        this.responseDetalleRecepcion = this.responseRecepcionados.responsePorEnviarVO;
        console.log('RESULTADO OBTIENE ENTREGADOS: ');
        console.log(this.responseDetalleRecepcion);
        this.porRecepcionar = {
          'id': 'pickC',
          'grafico': 'updown',
          'tipo': 'Por Recepcionar',
          'principal': Number(this.responseDetalleRecepcion.porcetajePrincipal),
          'verde': this.responseDetalleRecepcion.numVerde,
          'amarillo': this.responseDetalleRecepcion.numAmarillo,
          'rojo': this.responseDetalleRecepcion.numRojo,
          'morado': this.responseDetalleRecepcion.numMorado,
          'textAmarillo': '1-7 dias',
          'textRojo': this.responseDetalleRecepcion.txtRojo + ' dias',
          'textMorado': this.responseDetalleRecepcion.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_recepcionar.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleRecepcion.pendientes,
          'circuloDerValor': this.responseDetalleRecepcion.completadas,
          'ovaloIzqValor': this.responseDetalleRecepcion.ovaloIzq,
          'ovaloCenValor': this.responseDetalleRecepcion.ovaloCentro,
          'ovaloDerValor': this.responseDetalleRecepcion.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Pickear',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
      });
  }
  // llamada modal piramide recepcionar
  llamadaClickPiramideRecepcionar(sno) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;
    switch (sno) {
      case 'linkDvhRecepcionar':
        console.log('aca va DVH RECEPCIONAR: ');
        this._estadoDashboard.getDetallePiramide3('dvh', 'recepcionar', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'dvh';
            this.tipoPiramide = 'recepcionar';
          });
        break;
      case 'linkDdcRecepcionar':
        console.log('aca va DDC RECEPCIONAR: ');
        this._estadoDashboard.getDetallePiramide3('ddc', 'recepcionar', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'ddc';
            this.tipoPiramide = 'recepcionar';
          });
        break;
      case 'linkDadDvhRecepcionar':
        console.log('aca va DAD DVH RECEPCIONAR: ');
        this._estadoDashboard.getDetallePiramide3('daddvh','recepcionar', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalPiramideRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'daddvh';
            this.tipoPiramide = 'recepcionar';
          });
        break;
      default:
    }
  }
  // llamada modal velocimetro recepcionar
  llamadaClickVelocimetroRecepcionar(sno2) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    switch (sno2) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'btnVerde', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'btnAmarillo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'btnRojo', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'btnMorado', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEntregarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'porcentajeprincipal', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEntregarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'nuevas', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEntregarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'pendientes', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('recepcionar', 'total', this.page, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalVelocimetroRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'recepcionar';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }
  // modal piramide recepcionar
  abrirModalPiramideRecepcionar() {
    this.modalRefDetallePiramideRecepcionar = this.modalService.show(this.modalDetallePiramideRecepcionar, {class: 'modal-lg'});
  }
  // modal velocimetro recepcionar
  abrirModalVelocimetroRecepcionar() {
    this.modalRefDetalleVelocimetroRecepcionar = this.modalService.show(this.modalDetalleVelocimetroRecepcionar, {class: 'modal-lg'});
  }


  // cierre modal todos
  cerrarModal(modal: string) {
    this + modal;
    this.page = 1;
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
  }
  // valida fecha
  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return anio + '' + mes + '' + dia;
    }
  }
  // exportar Excel Piramide
  exportarExcelPiramide() {
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    this.spinnerService.show();
    if (this.tipoPiramide === 'ventas') {
      this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, this.page, 9999999, this.validaSelect(this.codigoTiendaInicial), '',
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
        '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responsePiramideExcel = data.body;
          this.responseDetallePiramideExcel = this.responsePiramideExcel.dataPage;
          this.selectPrincipalValue = 5;
          const nuevoArray: Array<any> = [];
          this.responseDetallePiramideExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Número de Orden: ': item.numOrden,
                  'Nombre: ': item.nombre,
                  'Apellido: ': item.apellido,
                  'Rut: ': item.rut,
                  'Total: ': item.totalCompra,
                  'Número Boleta: ': item.numBoleta,
                  'Cantidad de Productos: ': item.cantidadProductos,
                  'Fecha Compra: ': item.fechaCompra,
                  'Fecha Compromiso: ': item.fechaEntrega,
                  'Tipo Despacho: ': item.tipoDespacho,
                  'Estado WMS: ': item.descripEstado,
                  // Nuevos
                  'Local de Venta: ': item.localVenta,
                  'Bodega de Despacho: ': item.bodegaDespacho,
                  'Fecha Entrega Real: ': item.fechaEntregaReal,
                  'Empresa Transporte: ': item.nombreTransportista,
                  'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                  'Tipo Orden: ': item.tipoVenta,
                  'Retiro Bodega: ': item.retiroBodega,
                  'Fecha Ingreso CD: ': item.fechaIngresoCD,
                  'Fecha Troncal: ': item.fechaTroncal,
                  'Comuna: ': item.comuna,
                  'Ciudad: ': item.ciudad
                });
            });
            this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_General');
        });
    } else if (this.tipoPiramide === 'emitir' || this.tipoPiramide === 'recepcionar') {
      this._estadoDashboard.getDetallePiramide3(this.tipoBoton, this.tipoPiramide, this.page, 9999999, this.validaSelect(this.codigoTiendaInicial),
        this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseTransporteExcel = data.body;
          this.responseDetalleTransporteExcel = this.responseTransporteExcel.dataPage;
          this.selectPrincipalValue = 5;
          const nuevoArray: Array<any> = [];
          this.responseDetalleTransporteExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Número de Orden: ': item.numOrden,
                  'Nombre: ': item.nombre,
                  'Apellido: ': item.apellido,
                  'Rut: ': item.rut,
                  'Total: ': item.totalCompra,
                  'Número Boleta: ': item.numBoleta,
                  'Cantidad de Productos: ': item.cantidadProductos,
                  'Fecha Compra: ': item.fechaCompra,
                  'Fecha Entrega: ': item.fechaEntrega,
                  'Tipo Despacho: ': item.tipoDespacho,
                  'Estado WMS: ': item.descripEstado,
                  // Nuevos
                  'Local de Venta: ': item.localVenta,
                  'Bodega de Despacho: ': item.bodegaDespacho,
                  'Fecha Entrega Real: ': item.fechaEntregaReal,
                  'Empresa Transporte: ': item.nombreTransportista,
                  'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                  'Tipo Orden: ': item.tipoVenta,
                  'Retiro Bodega: ': item.retiroBodega,
                  'Fecha Ingreso CD: ': item.fechaIngresoCD,
                  'Fecha Troncal: ': item.fechaTroncal,
                  'Comuna: ': item.comuna,
                  'Ciudad: ': item.ciudad
                });
            });
          if (this.tipoPiramide === 'emitir') {
            this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_SinEmitirOrden');
          } else if (this.tipoPiramide === 'recepcionar') {
            this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_PorRecepcionar');
          }
        });
    }
  }
  // exportar Excel Velocimetro
  exportarExcelVelocimetro() {
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    this.spinnerService.show();
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, 9999999, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', '', '', '', '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModalExcel = data.body;
        this.responseDetalleVelocimetroExcel = this.responseVelocimetroModalExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetalleVelocimetroExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Entrega: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': '',
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        if (this.tipoVelocimetro === 'entregados') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_General');
        } else if (this.tipoVelocimetro === 'emitir') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_SinEmitirOrden');
        } else if (this.tipoVelocimetro === 'recepcionar') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_PorRecepcionar');
        }
        // this._excelService.exportAsExcelFile(nuevoArray, 'Reporte_Excel');
      });
  }
  // filtro buscar piramide
  buscarPiramide() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoPiramide === 'ventas') {
      if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
        this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
        this.spinnerService.hide();
      } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
        this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
        this.spinnerService.hide();
      } else {
        console.log('ENTRO AL CONSOLE LOG');
        this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, 1, this.pageSize,
          this.validaSelect(this.codigoTiendaInicial), '', this.numBoleta, this.numOrden,
          this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responsePiramide = data.body;
          this.responseDetallePiramide = this.responsePiramide.dataPage;
          this.selectPrincipalValue = 5;
        });
      }
    } else if (this.tipoPiramide === 'emitir' || this.tipoPiramide === 'recepcionar') {
      if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
        this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
        this.spinnerService.hide();
      } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
        this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
        this.spinnerService.hide();
      } else {
        this._estadoDashboard.getDetallePiramide3(this.tipoBoton, this.tipoPiramide, 1, this.pageSize,
          this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.selectPrincipalValue = 5;
          });
      }
    }
  }
  // filtro limpiar piramide
  limpiarPiramide() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoPiramide === 'ventas') {
      this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, 1, this.pageSize,
        this.validaSelect(this.codigoTiendaInicial), '', this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta),
        this.validaFecha(this.fechaVentaInicial), '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responsePiramide = data.body;
          this.responseDetallePiramide = this.responsePiramide.dataPage;
          this.selectPrincipalValue = 5;
          this.numBoletaSubEstado = '';
          this.numOrdenSubEstado = '';
          this.fechaDesdeSubEstado = '';
          this.fechaHastaSubEstado = '';
        });
    } else if (this.tipoPiramide === 'emitir' || this.tipoPiramide === 'recepcionar') {
      this._estadoDashboard.getDetallePiramide3(this.tipoBoton, this.tipoPiramide, 1, this.pageSize,
        this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseTransporte = data.body;
          this.responseDetalleTransporte = this.responseTransporte.dataPage;
          this.selectPrincipalValue = 5;
          this.numBoletaSubEstado = '';
          this.numOrdenSubEstado = '';
          this.fechaDesdeSubEstado = '';
          this.fechaHastaSubEstado = '';
        });
    }
  }
  // filtro buscar velocimetro
  buscarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoVelocimetro === 'entregados' || this.tipoVelocimetro === 'emitir' || this.tipoVelocimetro === 'recepcionar') {
      if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
        this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
        this.spinnerService.hide();
      } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
        this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
        this.spinnerService.hide();
      } else {
        this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.selectPrincipalValue = 5;
          });
      }
    }
  }
  // filtro limpiar piramide
  limpiarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoVelocimetro === 'entregados' || this.tipoVelocimetro === 'emitir' || this.tipoVelocimetro === 'recepcionar') {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
        this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '',
        '', '', '', '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
          this.selectPrincipalValue = 5;
          this.numBoletaSubEstado = '';
          this.numOrdenSubEstado = '';
          this.fechaDesdeSubEstado = '';
          this.fechaHastaSubEstado = '';
        });
    }
  }
  // cambio select piramide
  cambioSelectPiramide(registros: number) {
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    console.log('Valor evento: ' + registros);

    if (this.tipoPiramide === 'ventas') {
      this.page = 1;
      if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
        || this.tipoSTS !== null || this.tipoTodos !== null) {
        this.selectPrincipalValue = registros;
        this.pageSize2 = this.selectPrincipalValue;
      }
      this._estadoDashboard.getDetallePiramide5(this.tipoBoton, 'ventas', this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial), '',
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
        '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio select: ' + this.page);
          console.log('this.pageSize2 cambio select: ' + this.pageSize2);
          this.responsePiramide = data.body;
          this.responseDetallePiramide = this.responsePiramide.dataPage;
        });
    } else if (this.tipoPiramide === 'emitir' || this.tipoPiramide === 'recepcionar') {
      this.page = 1;
      if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
        this.selectPrincipalValue = registros;
        this.pageSize2 = this.selectPrincipalValue;
      }
      this._estadoDashboard.getDetallePiramide3(this.tipoBoton, this.tipoPiramide, this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial),
        this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio select: ' + this.page);
          console.log('this.pageSize2 cambio select: ' + this.pageSize2);
          this.responseTransporte = data.body;
          this.responseDetalleTransporte = this.responseTransporte.dataPage;
        });
    }
  }
  // cambio pagina piramide
  cambiarPaginaPiramide(pagina: number) {
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    this.page = pagina;
    if (this.tipoPiramide === 'ventas') {
      console.log('VENTAS');
      if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
        || this.tipoSTS !== null || this.tipoTodos !== null) {
        this._estadoDashboard.getDetallePiramide5(this.tipoBoton, 'ventas', this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial), '',
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            console.log('this.page cambio pagina: ' + this.page);
            console.log('this.pageSize cambio pagina: ' + this.pageSize2);
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
          });
      }
    } else if (this.tipoPiramide === 'emitir' || this.tipoPiramide === 'recepcionar') {
      console.log('EMITIR O RECEPCIONAR');
       if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
        this._estadoDashboard.getDetallePiramide3(this.tipoBoton, this.tipoPiramide, this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial), this.codBodega, this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial))
          .subscribe((data: HttpResponse<any>) => {
            console.log('this.page cambio pagina: ' + this.page);
            console.log('this.pageSize cambio pagina: ' + this.pageSize2);
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
          });
      }
    }
  }
  // cambio select velocimetro
  cambioSelectVelocimetro(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    this.selectPrincipalValue = registros;
    this.pageSize2 = this.selectPrincipalValue;
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoVelocimetro === 'entregados' || this.tipoVelocimetro === 'emitir' || this.tipoVelocimetro === 'recepcionar') {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
        this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio select: ' + this.page);
          console.log('this.pageSize2 cambio select: ' + this.pageSize2);
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        });
    }
    // else if (this.tipoVelocimetro === 'operaciones') {
    //   this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize, this.codTienda, this.codBodega,
    //     this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta)
    //     .subscribe((data: HttpResponse<any>) => {
    //       this.responseVelocimetroModal = data.body;
    //       this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
    //     });
    // }
  }
  // cambio pagina velocimetro
  cambiarPaginaVelocimetro(pagina: number) {
    this.page = pagina;
    this.codigoTiendaInicial = this.selectLocalFiltroPrincipal;
    this.fechaVentaInicial = this.fechaVentaFiltroPrincipal;

    if (this.tipoVelocimetro === 'entregados' || this.tipoVelocimetro === 'emitir' || this.tipoVelocimetro === 'recepcionar') {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.validaSelect(this.codigoTiendaInicial), this.codBodega,
        this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '', '', '', '', '', '', '')
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio select: ' + this.page);
          console.log('this.pageSize2 cambio select: ' + this.pageSize2);
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        });
    }
  }
  // Modal Error
  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral, {class: 'modal-plazo3'});
  }
  // valida select
  validaSelect(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }


}
