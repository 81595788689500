import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class AgendaCalendario {

  // private url = 'http://10.107.101.106:8344/vev/agenda';
  private url = environment.dominioServicios + '/vev/Calendario/agenda';

  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getCalendario(fechaConsulta: string) {
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getCalendarioProveedor(fechaConsulta: string, codProveedor: string) {
    if(codProveedor === undefined || codProveedor === 'undefined') {
      codProveedor = '';
    }
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codProveedor=' + codProveedor,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

}
