import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class CambiarEstadoOrdenes {

  // private url = 'http://10.107.101.106:8400/vev/cambiarEstadoOrdenes';
  // private url2 = 'http://10.107.101.106:8400/vev/cambiarEstadoProducto';
  // private url3 = 'http://10.107.101.106:8400/vev/agendarProveedor';
  // private url4 = 'http://10.107.101.106:8400/vev/agendarAdministrador';
  // private url5 = 'http://10.107.101.106:8400/vev/guardarHora';

  private url = environment.dominioServicios + '/vev/CambiarEstadoOrdenes/cambiarEstadoOrdenes';
  private url2 = environment.dominioServicios + '/vev/CambiarEstadoOrdenes/cambiarEstadoProducto';
  private url3 = environment.dominioServicios + '/vev/CambiarEstadoOrdenes/agendarProveedor';
  private url4 = environment.dominioServicios + '/vev/CambiarEstadoOrdenes/agendarAdministrador';
  private url5 = environment.dominioServicios + '/vev/CambiarEstadoOrdenes/guardarHora';


  constructor(private _http: HttpClient) {
  }

  getCambioEstadoOrden(codOC: any, estado: any) {
    return this._http.get(this.url + '?codOC=' + codOC + '&estado=' + estado,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getCambioEstadoOrdenProducto(codOC: any, sku: any, estado: any, newFecha: any) {
    return this._http.get(this.url2 + '?codOC=' + codOC + '&sku=' + sku + '&estado=' + estado + '&fecha=' + newFecha,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getAgendaProveedor(codOC: any, hora: any, fechaAgenda: any) {
    return this._http.get(this.url3 + '?codOC=' + codOC + '&hora=' + hora + '&fechaAgenda=' + fechaAgenda,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  // getAgendaAdministrador(codOC: any, motivoRechazo: any, accion: any) {
  //   return this._http.get(this.url4 + '?codOC=' + codOC + '&motivoRechazo=' + motivoRechazo + '&accion=' + accion,
  //     {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  // }

  getAgendaAdministrador(accion: any, motivoRechazo: any, codOcs: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url4, {
        accion: accion , motivoRechazo: motivoRechazo , codOcs: codOcs},
      {headers : headers});
  }

  getGuardaHora(codOC: any, hora: any, fechaAgenda: any) {
    return this._http.get(this.url5 + '?codOC=' + codOC + '&hora=' + hora + '&fechaAgenda=' + fechaAgenda,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
