import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import swal from 'sweetalert2';
import {HttpResponse} from '@angular/common/http';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ObtenerLiquidacionTransportista} from '../../../../comun/services/ServicioObtenerLiquidacionTransportista/obtener.liquidacion.transportista';
import {DetalleLiquidacion, ResponseLiquidacion, ResponseLiquidacionElementos} from '../../../../comun/vo/responseLiquidacion';
import {ResponseCamiones, ResponseDetallesCamiones} from '../../../../comun/vo/responseCamiones';
import {ResponseBoletas, ResponseDetalleBoletas} from '../../../../comun/vo/responseBoletas';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {TokenService} from '../../../../comun/services/token/token.service';
import {CambiarEstadoLiquidacionService} from '../../../../comun/services/ServicioCambiarEstadoLiquidacion/cambiar.estado.liquidacion.service';
import {ResponseEstadoList, ResponseEstadoVO} from '../../../../comun/vo/responseEstadoVO';
import {ResponseCambioEstadoLiq, ResponseResultadoCambioLiq} from '../../../../comun/vo/responseCambioEstadoLiq';
import {ResponseError} from '../../../../comun/vo/error';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-viajes-punto-a-punto',
  templateUrl: './detalle-liquidacion-admin.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
})
export class DetalleLiquidacionAdminComponent implements OnInit {
  public pagina: string;
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  @ViewChild('modalDetalleTransportistaCamion') modalDetalleTransportistaCamion;
  modalRefDetalleTransportistaCamion: BsModalRef;
  @ViewChild('modalModificarTransportistaCamion') modalModificarTransportistaCamion;
  modalRefModificarTransportistaCamion: BsModalRef;
  @ViewChild('modalConfirmaAprobar') modalConfirmaAprobar;
  modalRefConfirmaAprobar: BsModalRef;
  @ViewChild('modalConfirmaRechazar') modalConfirmaRechazar;
  modalRefConfirmaRechazar: BsModalRef;
  @ViewChild('modalDetalleTransportistaBoleta') modalDetalleTransportistaBoleta;
  modalRefDetalleTransportistaBoleta: BsModalRef;
  @ViewChild('modalModificarTransportistaBoleta') modalModificarTransportistaBoleta;
  modalRefModificarTransportistaBoleta: BsModalRef;
  listaCheck: Array<any> = [];
  isChecked = false;
  public responseliquidacion: ResponseLiquidacion;
  public responseElementosLiq: ResponseLiquidacionElementos;
  public detalleLiquidacion: DetalleLiquidacion[];
  public responseliquidacionExcel: ResponseLiquidacion;
  public responseElementosLiqExcel: ResponseLiquidacionElementos;
  public detalleLiquidacionExcel: DetalleLiquidacion[];
  public reponseCamiones: ResponseCamiones;
  public detalleCamiones: ResponseDetallesCamiones[];
  public reponseCamionesExcel: ResponseCamiones;
  public detalleCamionesExcel: ResponseDetallesCamiones[];
  public responseBoletas: ResponseBoletas;
  public detalleBoletas: ResponseDetalleBoletas[];
  public responseBoletasExcel: ResponseBoletas;
  public detalleBoletasExcel: ResponseDetalleBoletas[];
  page = 1;
  pageSize = 5;
  isTabPrincipal: boolean;
  filtroActivo: boolean;
  messageAlert: MensajeAlert;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  private codLiquid;
  public nombreTrans: string;
  public valorLiqui: number;
  public motivoRechazo: string = '';
  public camionCompleto: any;
  public boletaCompleta: any;
  public chequeadosAgenda: boolean = false;
  private _responseCambioEstado: ResponseCambioEstadoLiq;
  private _responseResultado: ResponseResultadoCambioLiq;
  public licCrrs: Array<number> = [];
  public textPrueba: boolean;
  public codLiquidBoleta: number;
  public codLiquidCamion: number;
  public estados: any[];
  responseEstadoVo: ResponseEstadoVO;
  responseEstadoList: ResponseEstadoList[];
  estadoLiq: any;
  tipoViajeLiq: any;
  nomTransportistaLiq: any;
  rutTransportistaLiq: any;
  fechaDesdeLiq: any;
  fechaHastaLiq: any;
  codTranspostistaLiq: any;
  numBoletaLiq: any;
  numPatenteLiq: any;
  filtroCamionBoleta: boolean;
  codLiqModal: any;
  validaGratificacion: any;
  valGratificacionSelect: any;

  constructor(private _router: Router,
              private modalService: BsModalService,
              private _excelService: ExcelService,
              private _obtenerLiquidacion: ObtenerLiquidacionTransportista,
              private spinnerService: Ng4LoadingSpinnerService,
              private _cambiarestadoLiq: CambiarEstadoLiquidacionService,
              private _token: TokenService) { }

  ngOnInit() {
    this.pagina = localStorage.getItem('pagina');
    this.obtenerDatosLiquidacion();
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.isTabPrincipal = true;
    this.obtenerEstados();
    this.valGratificacionSelect = 0;
  }

  abrirModalTodos(detalle: DetalleLiquidacion) {
    this.nombreTrans = detalle.transportista;
    this.valorLiqui = detalle.total;
    if (detalle.tipoViaje === 'Viaje Punto A Punto') {
        this.abrirModalCamion(detalle.crrLiquidacion);
        this.filtroCamionBoleta = true;
    } else {
        this.abrirModalBoleta(detalle.crrLiquidacion);
        this.filtroCamionBoleta = false;
    }
  }

  abrirModalCamion(codLiq: number) {
    this.obtenerDatosCamion(codLiq);
    this.modalRefDetalleTransportistaCamion = this.modalService.show(this.modalDetalleTransportistaCamion, {class: 'modal-lg'});
  }

  // abrirModalCamionPrueba() {
  //   this.modalRefDetalleTransportistaCamion = this.modalService.show(this.modalDetalleTransportistaCamion, {class: 'modal-lg'});
  // }

  abrirModalModificarCamion(camion: ResponseCamiones) {
    this.camionCompleto = camion;
    this.modalRefModificarTransportistaCamion = this.modalService.show(this.modalModificarTransportistaCamion, {class: 'modal-do3'});
  }

  abrirModalAprobar() {
    if (this.isChecked = false) {
      console.log('Entro en if checked');
      this.abrirModalError('No ha seleccionado ningun check', 'Error.');
    } else {
      this.modalRefConfirmaAprobar = this.modalService.show(this.modalConfirmaAprobar, {class: 'modal-do3'});
    }
  }

  abrirModalRechazar() {
    this.modalRefConfirmaRechazar = this.modalService.show(this.modalConfirmaRechazar, {class: 'modal-do3'});
  }

  abrirModalBoleta(codLiq: number) {
    this.obtenerDatosBoletas(codLiq);
    this.modalRefDetalleTransportistaBoleta = this.modalService.show(this.modalDetalleTransportistaBoleta, {class: 'modal-lg'});
  }

  abrirModalModificarBoleta(boleta: ResponseBoletas) {
    this.boletaCompleta = boleta
    this.modalRefModificarTransportistaBoleta = this.modalService.show(this.modalModificarTransportistaBoleta, {class: 'modal-do3'});
  }

  obtenerDatosLiquidacion() {
    this.spinnerService.show();
    this.estadoLiq = '';
    this.tipoViajeLiq = '';
    this.nomTransportistaLiq = '';
    this.rutTransportistaLiq = '';
    this.fechaDesdeLiq = '';
    this.fechaHastaLiq = '';
    this.codTranspostistaLiq = '';
    this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.estadoLiq, this.tipoViajeLiq, this.nomTransportistaLiq, this.rutTransportistaLiq,
      this.fechaDesdeLiq, this.fechaHastaLiq, this.codTranspostistaLiq)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseliquidacion = data.body;
        this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
        this.detalleLiquidacion = this.responseElementosLiq.dataPage;
        console.log('resultado liquidaciones: ');
        console.log(this.detalleLiquidacion);
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosCamion(codLiq: number) {
    this.codLiquidCamion = codLiq;
    this.codLiqModal = codLiq;
    this.numPatenteLiq = '';
    this.spinnerService.show();
    this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.reponseCamiones = data.body;
        this.detalleCamiones = this.reponseCamiones.camionVOList;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosBoletas(codLiq: number) {
    this.codLiquidCamion = codLiq;
    this.codLiquidBoleta = codLiq;
    this.codLiqModal = codLiq;
    this.numBoletaLiq = '';
    this.spinnerService.show();
    this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseBoletas = data.body;
        this.detalleBoletas = this.responseBoletas.boletaVO;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  cambioSelect(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    this.selectPrincipalValue = registros;
    this.pageSize = this.selectPrincipalValue;

    if (this.filtroActivo) {
      this.filtro();
    } else {
      this.obtenerDatosLiquidacion();
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
      if (this.filtroActivo) {
        this.filtro();
        console.log('esta activo');
      } else {
        this.obtenerDatosLiquidacion();
        console.log('no esta activo');
      }
  }

  filtro() {
    this.spinnerService.show();
    this.filtroActivo = true;
    if (this.filtroActivo) {
    } else {
      this.page = 1;
    }
    // Variables para mandar al servicio, se deben validar
    const estado = $('#estado').val();
    console.log('estado');
    console.log(estado);
    const tipoViaje = $('#tipoViaje').val();
    console.log('tipoViaje');
    console.log(tipoViaje);
    const nombreTransportista = $('#nombreTransportista').val();
    console.log('nombreTransportista');
    console.log(nombreTransportista);
    const rutTransportista = $('#rutTransportista').val();
    console.log('rutTransportista');
    console.log(rutTransportista);
    const fechaDesde = $('#date-desde').val();
    console.log('fechaDesde');
    console.log(fechaDesde);
    const fechaHasta = $('#date-hasta').val();
    console.log('fechaHasta');
    console.log(fechaHasta);
    this.estadoLiq = estado;
    this.tipoViajeLiq = tipoViaje;
    this.nomTransportistaLiq = nombreTransportista;
    this.rutTransportistaLiq = rutTransportista;
    this.fechaDesdeLiq = fechaDesde;
    this.fechaHastaLiq = fechaHasta;

    if (estado === '0' && tipoViaje === '0' && nombreTransportista  === '' && rutTransportista === '' &&
      fechaDesde === '' && fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (fechaDesde !== '' && fechaHasta !== '') {
      if (fechaDesde > fechaHasta) {
        this.abrirModalError('Fecha Desde no puede ser superior a Fecha Hasta!', 'Error.');
        this.spinnerService.hide();
      }
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
      this.spinnerService.hide();
    } else {
      this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
        this.nomTransportistaLiq, this.rutTransportistaLiq, this.validaFecha(this.fechaDesdeLiq), this.validaFecha(this.fechaHastaLiq), this.codTranspostistaLiq)
        .subscribe((data: HttpResponse<ResponseLiquidacion>) => {
          this.spinnerService.hide();
          this.responseliquidacion = data.body;
          this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
          this.detalleLiquidacion = this.responseElementosLiq.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  filtroModal() {
    this.spinnerService.show();
    const patente = $('#patente').val();
    this.numPatenteLiq = patente;
    const boleta = $('#boleta').val();
    this.numBoletaLiq = boleta;
    if (this.filtroCamionBoleta) {
      console.log('entró camion');
      this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.reponseCamiones = data.body;
          this.detalleCamiones = this.reponseCamiones.camionVOList;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    } else {
      console.log('entró boleta');
      this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseBoletas = data.body;
          this.detalleBoletas = this.responseBoletas.boletaVO;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  seleccionarTodo() {
    const checkeadoTodos = $('#selectAll').prop('checked');
    const checkeadoInfo = $('#selectAllInfo').prop('checked');

    if (checkeadoTodos || checkeadoInfo) {
      $('input[name="check[]"]').each(function () {
        this.checked = true;
      });
    } else {
      $('input[name="check[]"]').each(function () {
        this.checked = false;
      });
    }
  }

  change(e, type, crrLiq) {
    // console.log(e.target.checked);
    // console.log(type);
    if (e.target.checked === true) {
      this.listaCheck.push(type);
      this.licCrrs.push(crrLiq);
      this.isChecked = true;
      console.log('CHEQUEADO if ' + this.isChecked.toString());
      console.log('e: ');
      console.log(e);
      console.log('type: ' + type);
      console.log('crrLiq: ' + crrLiq);
    } else {
      const index = this.listaCheck.indexOf(type);
      const indexLiq = this.licCrrs.indexOf(crrLiq);
      this.listaCheck.splice(index, 1);
      this.licCrrs.splice(indexLiq, 1);
      this.isChecked = false;
      console.log('CHEQUEADO else ' + this.isChecked.toString());
      console.log('e: ');
      console.log(e);
      console.log('type: ' + index);
      console.log('crrLiq: ' + indexLiq);
    }
  }

  aceptarRechazar() {
    if (this.motivoRechazo === '') {
      swal({
        title: 'Error!',
        text: 'Debes ingresar un motivo',
        type: 'error',
        confirmButtonText: 'OK'
      });
    } else {
      swal({
        title: 'Correcto!',
        text: 'Acción realizada correctamente',
        type: 'success',
        confirmButtonText: 'OK'
      }).then(okay => {
        if (okay) {
          this.modalRefConfirmaRechazar.hide();
          this.motivoRechazo = '';
        }
      });
    }

  }

  cancelarRechazar() {
    this.motivoRechazo = '';
    this.modalRefConfirmaRechazar.hide();
  }

  aceptarprobar() {
    console.log('entra modal chico');
    swal({
      title: 'Correcto!',
      text: 'Acción realizada correctamente',
      type: 'success',
      confirmButtonText: 'OK'
    }).then(ok => {
      if (ok) {
        this.modalRefConfirmaAprobar.hide();
      }
    });
  }

  exportarExcel() {
    this.spinnerService.show();
    // Variables para mandar al servicio, se deben validar
    const estado = $('#estado').val();
    console.log('estado');
    console.log(estado);
    const tipoViaje = $('#tipoViaje').val();
    console.log('tipoViaje');
    console.log(tipoViaje);
    const nombreTransportista = $('#nombreTransportista').val();
    console.log('nombreTransportista');
    console.log(nombreTransportista);
    const rutTransportista = $('#rutTransportista').val();
    console.log('rutTransportista');
    console.log(rutTransportista);
    const fechaDesde = $('#date-desde').val();
    console.log('fechaDesde');
    console.log(fechaDesde);
    const fechaHasta = $('#date-hasta').val();
    console.log('fechaHasta');
    console.log(fechaHasta);
    this.estadoLiq = estado;
    this.tipoViajeLiq = tipoViaje;
    this.nomTransportistaLiq = nombreTransportista;
    this.rutTransportistaLiq = rutTransportista;
    this.fechaDesdeLiq = fechaDesde;
    this.fechaHastaLiq = fechaHasta;

    this._obtenerLiquidacion.getLiquidaciones(this.page, 9999999, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
      this.nomTransportistaLiq, this.rutTransportistaLiq, this.validaFecha(this.fechaDesdeLiq), this.validaFecha(this.fechaHastaLiq), this.codTranspostistaLiq)
      .subscribe((data: HttpResponse<ResponseLiquidacion>) => {
        this.spinnerService.hide();
        this.responseliquidacionExcel = data.body;
        this.responseElementosLiqExcel = this.responseliquidacionExcel.liquidacionPaginador;
        this.detalleLiquidacionExcel = this.responseElementosLiqExcel.dataPage;
        const nuevoArray: Array<any> = [];
        this.detalleLiquidacionExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Nro.': item.registro,
                'Transportista': item.transportista,
                'Total': item.total,
                'Cantidad de Boletas': item.cantidadBoletas,
                'Fecha Desde': item.fechaDesde,
                'Fecha Hasta': item.fechaHasta,
                'Tipo Viaje': item.tipoViaje,
                'Estado': item.nomEstado
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista');
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  exportarExcelModal () {
    this.spinnerService.show();
    if (this.filtroCamionBoleta) {
      this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.reponseCamionesExcel = data.body;
          this.detalleCamionesExcel = this.reponseCamionesExcel.camionVOList;
          const nuevoArray: Array<any> = [];
          this.detalleCamionesExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Nro.': item.patente,
                  'Transportista': item.tara,
                  'Total': item.cantidadBoletas,
                  'Cantidad de Boletas': item.valorViaje,
                  'Fecha Desde': item.fechaViaje
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista_Camion');
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    } else {
      this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseBoletasExcel = data.body;
          this.detalleBoletasExcel = this.responseBoletasExcel.boletaVO;
          const nuevoArray: Array<any> = [];
          this.detalleBoletasExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Código Local: ': item.codigoLocal,
                  'Número Boleta: ': item.numeroBoleta,
                  'Fecha Boleta: ': item.fechaBoleta,
                  'Código Caja: ': item.codigoCaja,
                  'Comuna: ': item.comuna,
                  'Ciudad: ': item.ciudad,
                  'Valor Flete: ': item.valorFlete
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista_Boleta');
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  public aprobarRechazarLiquidacion(accionLiq: any) {
    this.spinnerService.show();
    this.validaGratificacion = this.valGratificacionSelect;
    let accion;
    let motivoRechazo;
    let crrLiquidaciones;
    const textRechazo = $('.ingreseMotivo').val();



    if (accionLiq === 'rechazarAccion') {
      crrLiquidaciones = this.licCrrs;
      motivoRechazo = textRechazo;
      accion = '0';

      if (accionLiq === 'rechazarAccion' && textRechazo === '') {
        this.aceptarRechazar();
        this.spinnerService.hide();
      } else {
        this._cambiarestadoLiq.getCambiarEstadoLiquidacion(accion, motivoRechazo, crrLiquidaciones, '0')
          .subscribe((data3: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.chequeadosAgenda = true;
            this._responseCambioEstado = data3.body;
            this._responseResultado = this._responseCambioEstado.responseVO;
            this.aceptarRechazar();
            this.licCrrs = [];
            this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
              this.nomTransportistaLiq, this.rutTransportistaLiq, this.fechaDesdeLiq, this.fechaHastaLiq, this.codTranspostistaLiq)
              .subscribe((data1: HttpResponse<any>) => {
                this.spinnerService.hide();
                this.responseliquidacion = data1.body;
                this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
                this.detalleLiquidacion = this.responseElementosLiq.dataPage;
                const tokenfull = data1.headers.get('Authorization');
                this._token.actualizaToken(tokenfull);
              }, (error: ResponseError) => {
                if (error.error.code === 401) {
                  localStorage.clear();
                  this._router.navigate(['/']);
                }
              });
          });
      }
    } else if (accionLiq === 'aprobarAccion') {
      crrLiquidaciones = this.licCrrs;
      motivoRechazo = null;
      accion = '1';
      this._cambiarestadoLiq.getCambiarEstadoLiquidacion(accion, motivoRechazo, crrLiquidaciones, this.validaGratificacion)
        .subscribe((data2: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.chequeadosAgenda = true;
          this._responseCambioEstado = data2.body;
          console.log('data2.body');
          console.log(data2.body);
          this._responseResultado = this._responseCambioEstado.responseVO;
          this.aceptarprobar();
          this.licCrrs = [];
          this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
            this.nomTransportistaLiq, this.rutTransportistaLiq, this.fechaDesdeLiq, this.fechaHastaLiq, this.codTranspostistaLiq)
            .subscribe((data4: HttpResponse<any>) => {
              this.spinnerService.hide();
              this.responseliquidacion = data4.body;
              this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
              this.detalleLiquidacion = this.responseElementosLiq.dataPage;
              const tokenfull = data4.headers.get('Authorization');
              this._token.actualizaToken(tokenfull);
            }, (error: ResponseError) => {
              if (error.error.code === 401) {
                localStorage.clear();
                this._router.navigate(['/']);
              }
            });
        });
    }
  }

  modificarCamion(valPatente: any) {
    this.spinnerService.show();
    const patente = $('#patente').val();
    console.log('PATENTE: ' + patente);
    const tara = $('#tara').val();
    console.log('TARA: ' + tara);
    const cantBoleta = $('#cantidadBoletas').val();
    console.log('CANTIDAD BOLETAS: ' + cantBoleta);
    const valorViaje = $('#valorViaje').val();
    console.log('VALOR VIAJE: ' + valorViaje);
    const fechaViaje = $('#fechaViaje').val();
    console.log('FECHA VIAJE:' + fechaViaje);

    this._cambiarestadoLiq.updateCamiones(valPatente, tara, cantBoleta, fechaViaje, valorViaje)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.obtenerDatosCamion(this.codLiquidCamion);
          this.modalRefModificarTransportistaCamion.hide();
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
  }

  modificarBoleta(numBol: any) {
    this.spinnerService.show();
    const local = $('#local').val();
    console.log('LOCAL: ' + local);
    const boleta = $('#boleta').val();
    console.log('BOLETA: ' + boleta);
    const fechaBoleta = $('#fechaBoleta').val();
    console.log('FECHA BOLETA: ' + fechaBoleta);
    const caja = $('#caja').val();
    console.log('CAJA: ' + caja);
    const comuna = $('#comuna').val();
    console.log('COMUNA:' + comuna);
    const ciudad = $('#ciudad').val();
    console.log('CIUDAD:' + ciudad);
    const valorFlete = $('#valorFlete').val();
    console.log('VALOR VIAJE:' + valorFlete);

    this._cambiarestadoLiq.updateBoletas(numBol, comuna, ciudad, valorFlete)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.modalRefModificarTransportistaBoleta.hide();
        this.obtenerDatosBoletas(this.codLiquidBoleta);
          const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  recargar() {
    this.filtroActivo = false;
    this.page = 1;
    this.pageSize = 5;
    this.selectPrincipalValue = 5;
    this.estadoLiq = '';
    this.tipoViajeLiq = '';
    this.nomTransportistaLiq = '';
    this.rutTransportistaLiq = '';
    this.fechaDesdeLiq = '';
    this.fechaHastaLiq = '';
    this.obtenerDatosLiquidacion();
  }

  recargarModal() {
    if (this.filtroCamionBoleta) {
      this.obtenerDatosCamion(this.codLiquidCamion);
    } else {
      this.obtenerDatosBoletas(this.codLiquidBoleta);
    }
  }

  obtenerEstados() {
    this._obtenerLiquidacion.getEstados()
        .subscribe((data: HttpResponse<any>) => {
        console.log('OBTIENE ESTADOS: ');
        this.responseEstadoVo = data.body;
        this.responseEstadoList = this.responseEstadoVo.estadoVO;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  validaSelect(valor: any) {
    if (valor === '' || valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaInput(valor: any) {
    if (valor === '') {
      return '';
    } else {
      return valor;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  cerrarModal(modal: string) {
    this.numBoletaLiq = '';
    this.numPatenteLiq = '';
    this.obtenerDatosCamion(this.codLiqModal);
    this.obtenerDatosBoletas(this.codLiqModal);
  }
}
