export class ResponseTransSelect {
  transportistaSelectVOS: TransportistaSelectVOS[];
  code: any;
  mensaje: any;
}

export class TransportistaSelectVOS {
  nombre: string;
  rut: string;
  direccion: string;
  telefono: string;
  codTransportista: number;
}

