import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ReporteDashboardGeneral {

  // private url1 = 'http://10.107.101.106:8349/vev/obtieneEstadisticasDespacho';
  // private url2 = 'http://10.107.101.106:8349/vev/obtenerEstadisticasDespachosEntregados';

  private url1 = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEstadisticasDespacho';
  private url2 = environment.dominioServicios + '/vev/DashboardGeneral/obtenerEstadisticasDespachosEntregados';

  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getValoresTabla(tipoDespacho: any, fechaConsulta: any) {
    return this._http.get(this.url1 + '?tipoDespacho=' + tipoDespacho + '&fechaConsulta=' + fechaConsulta,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getEntregadas(tipoDespacho: any, fechaConsulta: any) {
    return this._http.get(this.url2 + '?tipoDespacho=' + tipoDespacho + '&fechaConsulta=' + fechaConsulta,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
