import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ObtenerDetalleOrdenes {

  // private url = 'http://10.107.201.40:8400/vev/obtenerDetalleOrdenes';
  private url = environment.dominioServicios + '/vev/obtenerDetalleOrdenes';

  constructor(private _http: HttpClient) {
  }

  getDetalleOrdenCompra(numOC: any) {
    return this._http.get(this.url + '?numOC=' + numOC);
  }

}
