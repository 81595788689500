import { Component, OnInit } from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ResponseError} from '../../../../comun/vo/error';
import {TokenService} from '../../../../comun/services/token/token.service';
import {Router} from '@angular/router';
import {ReporteDashboardGeneral} from '../../../../comun/services/ServicioReporteDashboardGeneral/reporte.dashboard.general.service';
import {ResponseValores} from '../../../../comun/vo/responseValores';
import {ResponseEntregados} from '../../../../comun/vo/responseEntregados';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';

declare var cargaCuatroBarras: any;
declare var cargaAnimacion: any;
declare var animaVelocimetro: any;
declare var cargaVelocimetro: any;
declare var flexFont: any;

@Component({
  selector: 'app-dashboard1-administrador',
  templateUrl: './dashboard1-administrador-tracking.component.html',
  styleUrls: ['./dashboard1-administrador-tracking.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player2.css']
})
export class Dashboard1AdministradorTrackingComponent implements OnInit {
  public responseValores: ResponseValores;
  public responseEntregados: ResponseEntregados;
  private tipo = 'DDC';
  private fecha = '24-10-2018 10:00';

  constructor(private servicioDashboard1: ReporteDashboardGeneral,
              private _router: Router,
              private _token: TokenService,
              private _estadoDashboard: ObtieneEstadosDashboardService) { }

  ngOnInit() {
    // this.obtenerValores(this.tipo, this.fecha);
    // this.obtenerEntregados();

  }
  //
  // obtenerValores(tipoDespacho: any, fechaConsulta: any) {
  //   this.servicioDashboard1.getValoresTabla(tipoDespacho, fechaConsulta)
  //     .subscribe((data: HttpResponse<ResponseValores>) => {
  //       this.responseValores = data.body;
  //       this.valores = this.responseValores.valoresVOList;
  //       console.log('Response 1: ' + JSON.stringify(data));
  //       console.log('Response 2: ' + JSON.stringify(this.valores));
  //       // Cargar cuatro barras
  //       const jsonCuatro = {
  //         'id': 'grafBarra',
  //         'a': 20,
  //         'aText': 'Atext',
  //         'aUrl': 'http://www.aUrl.cl',
  //         'b': 60,
  //         'bText': 'Btext',
  //         'bUrl': 'http://www.bUrl.cl',
  //         'c': 40,
  //         'cText': 'Ctext',
  //         'cUrl': 'http://wwww.cUrl.cl',
  //         'd': 60,
  //         'dText': 'Dtext',
  //         'dUrl': 'http://www.dUrl.cl',
  //         'rojoText': 'Cuadro Rojo',
  //         'verdeText': 'Cuadro Verde',
  //         'rojoLink': 'Cuadro Rojo',
  //         'verdeLink': 'Cuadro Verde'
  //       };
  //
  //       cargaCuatroBarras(jsonCuatro);
  //       flexFont();
  //       //   // console.log('resultado todo:' + JSON.stringify(data));
  //       const tokenfull =  data.headers.get('Authorization');
  //       this._token.actualizaToken(tokenfull);
  //     }, (error: ResponseError) => {
  //       if (error.error.code === 401) {
  //         localStorage.clear();
  //         this._router.navigate(['/']);
  //       }
  //     });
  // }
  //
  // obtenerEntregados() {
  //   this._estadoDashboard.getEstadEnDespacho(1, 2)
  //     .subscribe((data: HttpResponse<ResponseEntregados>) => {
  //       const response: ResponseEntregados = JSON.parse(JSON.stringify(data));
  //       // this.responseEntregados = data.body;
  //       // console.log('NOMBRE A' + JSON.stringify(data));
  //       // console.log('NOMBRE B' + JSON.stringify(this.responseEntregados.enDespacho));
  //       // Cargar animación
  //
  //       const jsonVelo = {
  //         'id': 'grafVelocimentro',
  //         'valor': response.enDespacho,
  //         'titulo': 'En Despacho',
  //         'tituloLink': 'http://kiosolutions.cl/',
  //         'tituloImg': 'assets/img/icon_camion_rapido.svg',
  //         'azulText': 'Entregados',
  //         'azulLink': 'http://www.azul.cl',
  //         'rojoText': 'No entregados',
  //         'rojoLink': 'http://www.rojo.cl'
  //
  //       };
  //       cargaVelocimetro(jsonVelo);
  //       flexFont();
  //
  //       // cargaAnimacion('grafVelocimentro', this.responseEntregados.entregadas, jsonVelo);
  //       //   const tokenfull =  data.headers.get('Authorization');
  //       //   this._token.actualizaToken(tokenfull);
  //       // }, (error: ResponseError) => {
  //       //   if (error.error.code === 401) {
  //       //     localStorage.clear();
  //       //     this._router.navigate(['/']);
  //       //   }
  //     });
  // }
}
