import {Component, OnInit, ViewChild} from '@angular/core';
import {DetalleOrdenCompra, ResponseDetalle} from '../../../../comun/vo/responsedetalle';
import {OrdenCompra, OrdenCompraPaginador, ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ObtenerDetalleOrdenes} from '../../../../comun/services/ServicioObtenerDetalleOrdenes/obtener.detalle.ordenes.service';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {Router} from '@angular/router';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseError} from '../../../../comun/vo/error';
import {TokenService} from '../../../../comun/services/token/token.service';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-busqueda-administrador',
  templateUrl: './busqueda-administrador.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  // styleUrls: ['./busqueda-administrador.component.css']
})
export class BusquedaAdministradorComponent implements OnInit {
  public responseDetalle: ResponseDetalle;
  public ordenVO: DetalleOrdenCompra;
  public ordenes: OrdenCompra[];
  public bodegas: any[];
  public proveedores: any[];
  public departamentos: any[];
  private estTodo: any = '0,1,2,3,4,5,6,7,8,9,10';
  private estPendiente: any = 0;
  private estInformado: any = 1;
  private estActual;
  totalProductos: number;
  responseOrden: ResponseOrdenes;
  pendienteOcCount: number;
  valorSelectActual: number;
  // paginador
  page = 1;
  pageSize = 5;
  // Filtro
  filtroActivo: boolean;
  // tab
  isTabPrincipal: boolean;
  // Select values
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  ordenPaginador: OrdenCompraPaginador;


  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;

  messageAlert: MensajeAlert;

  constructor(private _detalleOrdenService: ObtenerDetalleOrdenes,
              private modalService: BsModalService,
              private _tablaService: ObtenerOrdenesCompra,
              private _router: Router,
              private _excelService: ExcelService,
              private _token: TokenService,
              private spinnerService: Ng4LoadingSpinnerService) {
  }

  ngOnInit() {
    this.validarSesion();
    if (localStorage.getItem('rol') === 'Ejecutivo') {
      this._router.navigate(['/']);
    }
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.obtenerBodegas();
    this.obtenerDepartamentos();
    this.obtenerProveedores();
    this.isTabPrincipal = true;
    this.estActual = this.estTodo;
    this.obtenerDatosProducto(this.estActual);

    const nomUsuario = localStorage.getItem('nomUsuario');

    if (nomUsuario === null) {
      localStorage.setItem('nomUsuario', 'Maria de las Mercedez');
      localStorage.setItem('rolUsuario', 'administrador');
    }

    console.log('Select Principal Value: ' + this.selectPrincipalValue);
  }

  validarSesion() {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this._router.navigate(['/']);
    }
  }

  obtenerDatos(estado: string) {
    this._tablaService.getOrdenesTabla(this.page, this.pageSize, estado)
      .subscribe((data: HttpResponse<any>) => {
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado todo:' + JSON.stringify(data));
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }


  obtenerDatosProducto(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        if (this.totalProductos === undefined) {
          this.totalProductos = data.body.totalElementos;
        }
        this.pendienteOcCount = data.body.totalPendienteOC;
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          this.totalProductos = this.responseOrden.totalElementos;
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDetalleOrdenes(numOC: any) {
    this._detalleOrdenService.getDetalleOrdenCompra(numOC)
      .subscribe((data: HttpResponse<any>) => {
        this.responseDetalle = data.body;
        this.ordenVO = data.body.ordenCompraVO;
        // this.abrirModal();
      },  (error: ResponseError) => {
        console.log('error: ' + error);
      });
  }

  // abrirModal() {
  //   this.modalRefDetalle = this.modalService.show(this.modalDetalle, Object.assign({}, { class: 'gray' }));
  // }

  formateaRut(rut: any) {
    const rutGuion = rut.indexOf('-');
    if (rutGuion > 0) {
      const rutCompleto = rut;
      return rutCompleto;
    } else {
      const rutsin = rut.slice(0, rut.length - 1);
      const dv = rut.slice(rut.length - 1, rut.length);
      const nuevo = rutsin + '-' + dv;
      return nuevo;
    }
  }

  cambioSelect(registros: number) {
    this.page = 1;
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtroProducto();
    } else {
      this.obtenerDatosProducto(this.estActual);
    }
  }

  filtros() {
    this.filtroActivo = true;
    this.page = 1;
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodega').val());

    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    const selectDepartamento = $('#selectDepartamento').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();
    const proveedor = $('#selectProveedor').val();

    if (numBoleta === '' && selectDepartamento === '0'
    && selectBodega === '0' && fechaDesde === '' && fechaHasta === '' && tipoDespacho === '0' && proveedor === '0') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this._tablaService.getObtenerOrdenesFiltro(this.page, this.pageSize, this.estActual, numBoleta, this.validaSelect(selectDepartamento),
        this.validaSelect(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), this.validaSelect(proveedor))
          .subscribe((data: HttpResponse<any>) => {
            console.log(JSON.stringify(data));
            this.ordenPaginador = data.body;
            this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
            this.ordenes = this.responseOrden.dataPage;
            const tokenfull =  data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            console.log(this.ordenes);
          });
    }
  }

  filtroProducto() {
    // if (this.filtroActivo) {
    // } else {
    //   this.page = 1;
    // }
    this.filtroActivo = true;
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodega').val());

    // Variables para mandar al servicio, se deben validar
    const numOc = $('#numOC').val();
    if (numOc !== '') {
      this.page = 1;
    }
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const numBoleta = $('#numBoleta').val();
    if (numBoleta !== '') {
      this.page = 1;
    }

    if (numBoleta === '' && fechaDesde === '' && fechaHasta === '' && numOc === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this.spinnerService.show();
      this._tablaService.getObtenerOrdenesProductoFiltroBusqueda(this.page, this.pageSize, this.estActual,
        this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), numOc, numBoleta)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this.ordenPaginador = data.body;
          this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
          console.log(this.ordenes);
        });
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  filtrar() {
    if (this.isTabPrincipal) {
      this.filtroProducto();
    }
  }

  validaBoolean(valor: boolean): any {
    if (valor) {
      return 'true';
    } else {
      return '';
    }
  }

  validaSelect(valor: any): any {
    if (valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  quitarFiltro() {
    $('#numOC').val('');
    $('#date-desde').val('');
    $('#date-hasta').val('');
    $('#numBoleta').val('');
    this.page = 1;

    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosProducto(this.estActual);
    } else {
      this.obtenerDatos(this.estActual);
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.filtroProducto();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.filtroProducto();
      }
    }
  }

  fixTab(elemento: string) {
    this.page = 1;
    this.isTabPrincipal = false;
    $('#' + elemento).removeAttr('style');
    if (elemento === 'pOc') {
      this.pageSize = this.selectSecundarioValue;
      this.ordenes = [];
      this.estActual = this.estPendiente;
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual);
        }
      }
    }

    validaFecha(fecha: string): any {
      if (fecha === '') {
        return '';
      } else {
        const fechaSplit = fecha.split('-');

        const anio = fechaSplit[0];
        const mes = fechaSplit[1];
        const dia = fechaSplit[2];

        return dia + '/' + mes + '/' + anio;
      }
    }

    tabPrincipal() {
      this.isTabPrincipal = true;
      $('#' + 'tabPrincipal').removeAttr('style');
      this.ordenes = [];
      this.estActual = this.estTodo;
      this.pageSize = this.selectPrincipalValue;
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual);
      }
    }

    recargar() {
      this.filtroActivo = false;
      if (this.isTabPrincipal) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.obtenerDatos(this.estActual);
      }
    }

  exportarExcel() {
    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();

    this._tablaService.getObtenerOrdenesProductoFiltro(this.page, this.pageSize, this.estActual, numBoleta, '',
      this.validaSelect(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), '', '', '', 'false')
      .subscribe((data: HttpResponse<any>) => {
        console.log(JSON.stringify(data));
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        const tokenfull =  data.headers.get('Authorization');
        // this._token.actualizaToken(tokenfull);
      });
    const nuevoArray: Array<any> = [];
    this.responseOrden.dataPage.forEach(
      item => {
        nuevoArray.push(
          {
            'Número Boleta': item.numeroBoleta,
            'Número OC': item.numOc,
            'Número Caja': item.numCaja,
            'Fecha Venta': item.fechaVenta,
            'Local Venta': item.localVenta,
            'Sku': item.sku,
            'Glosa Sku': item.glosaSku,
            'Cantidad': item.cantidad,
            'Costo': item.costoNetoProducto,
            'Fecha Compromiso': item.fechaCompromisoCliente,
            'Fecha Entrega CD': item.fechaCompromisoDespacho,
            'Fecha Despacho Cliente': item.fechaCompromisoCliente,
            'Rut Proveedor': item.rutProveedor,
            'Razon Social': item.proveedor,
            'Sucursal Despacho': item.codSucursalDespacho,
            'Tipo Despacho': item.tipoDespacho,
            'Estado': item.estado
          });
      });
    this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_Encargado_Proveedor');
  }

  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      console.log(JSON.stringify(data.body));
      this.bodegas = data.body.bodegas;
    });
  }

  obtenerDepartamentos() {
    this._tablaService.getDepartamentos().subscribe((data: HttpResponse<any>) => {
      console.log('Departamentos: ' + JSON.stringify(data.body));
      this.departamentos = data.body;
    });
  }

  obtenerProveedores() {
    this._tablaService.getProveedores().subscribe((data: HttpResponse<any>) => {
      console.log('Proveedores lista: ' + JSON.stringify(data.body));
      this.proveedores = data.body;
    });
  }
}
