import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, environment3 } from '../../../../environments/environment';
import { BoletaUrl } from '../../vo/boletaUrl';



@Injectable()
export class ObtieneEstadosDashboardService {

  // private url = 'http://10.107.101.106:8349/vev/obtieneEstDespachoIngresados';
  // private url2 = 'http://10.107.101.106:8349/vev/obtieneEstDespachoAtrasos';
  // private url3 = 'http://10.107.101.106:8349/vev/obtieneEstadEnDespacho';
  // private url4 = 'http://10.107.101.106:8349/vev/obtienePorEntregar';

  private url = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEstDespachoIngresados';
  private url2 = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEstDespachoAtrasos';
  private url3 = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEstadEnDespacho';
  private url4 = environment.dominioServicios + '/vev/DashboardGeneral/obtienePorEntregar';
  private url5 = environment.dominioServicios + '/vev/DashboardGeneral/despachoEntregados';
  private url6 = environment.dominioServicios + '/vev/DashboardGeneral/porEmitirOrden';
  private url7 = environment.dominioServicios + '/vev/DashboardGeneral/despachoProgramDia';
  private url8 = environment.dominioServicios + '/vev/DashboardGeneral/enPicking';
  private url9 = environment.dominioServicios + '/vev/DashboardGeneral/despachoEnPlazo';
  private url10 = environment.dominioServicios + '/vev/DashboardGeneral/dashboardDVH';
  private url11 = environment.dominioServicios + '/vev/DashboardGeneral/porRecepcionar';
  private url12 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetalleVelocimetroColores';
  private url13 = environment.dominioServicios + '/vev/DashboardGeneral/porcentajeCumplimiento';
  private url14 = environment.dominioServicios + '/vev/DashboardGeneral/obtienePiramide5';
  private url15 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetallePiramide5';
  private url16 = environment.dominioServicios + '/vev/DashboardGeneral/detalleEntregaAtrasadas';
  private url17 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetalleEntregadosEnPlazo';
  private url18 = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEntregados';
  private url19 = environment.dominioServicios + '/vev/DashboardGeneral/obtienePiramide4';
  private url20 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetallePiramide4';
  private url21 = environment.dominioServicios + '/vev/DashboardGeneral/obtieneEnviados';
  private url22 = environment.dominioServicios + '/vev/DashboardGeneral/obtienePiramide3';
  private url23 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetallePiramide3';
  private url24 = environment.dominioServicios + '/vev/ObtenerDetalleDashboard/obtenerDetalleSubEstadoBeetrack';
  private url25 = environment.dominioServicios + '/vev/DashboardGeneral/buscadorBoletasOrdenes';
  private url26 = environment.dominioServicios + '/vev/DashboardGeneral/getBodegasDespacho';
  private url27 = environment.dominioServicios + '/vev/DashboardGeneral/getLocalesVenta';
  private url28 = environment.dominioServicios + '/vev/DashboardGeneral/getComunas';
  private url29 = environment.dominioServicios + '/vev/DashboardGeneral/getEmpresaTransportista';
  private url30 = environment.dominioServicios + '/tracking/obtener/atraso';


  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  // D2: Grafico Piramide Ingresadas
  getEstDespachoIngresados(estados1: any) {
    return this._http.get(this.url + '?estados=' + estados1);
  }

  // D2: Barras Horizontal de atrasos
  getEstDespachoAtrasos(estados1: any) {
    return this._http.get(this.url2 + '?estados=' + estados1);
  }

  // D1: Velocimentro en Despacho
  getEstadEnDespacho(estadosEnDes: any, estadosNoDes: any) {
    return this._http.get(this.url3 + '?estadosEnDes=' + estadosEnDes + '&estadosNoDes=' + estadosNoDes);
  }

  // D2: Velocimetro por Entregar
  getPorEntregar(estadosEnDes: any, estadosNoDes: any) {
    return this._http.get(this.url4 + '?estadosEnDes=' + estadosEnDes + '&estadosNoDes=' + estadosNoDes,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // -----------------------------------------------------------------------------------------------------------------------
  // NUEVO-D1: ENTREGADOS
  getEntregados() {
    return this._http.get(this.url5,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: HORIZONTAL
  getAtrasados() {
    return this._http.get(this.url2,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: EMITIR ORDEN
  getEmitirOrden() {
    return this._http.get(this.url6,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: POR ENTREGAR
  getPorEntregarDash() {
    return this._http.get(this.url4,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: INGRESADAS
  getIngresadas() {
    return this._http.get(this.url7,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: EN PICKING
  getPicking() {
    return this._http.get(this.url8,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: EN PICKING
  getEnPlazo() {
    return this._http.get(this.url9,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D1: GRAFICO VERTICAL
  getGraficoVertical() {
    return this._http.get(this.url10,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D2: POR RECEPCIONAR
  getPorRecepcionar() {
    return this._http.get(this.url11,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // DETALLE VELOCIMETROS EN PICKING, POR RECEPCIONAR, POR ENTREGAR, POR EMITIR, ENTREGADOS Y ENVIADOS
  getDetalleVelocimetros(tipoVelocimetro: any, tipoBoton: any, pagina: any, numFilas: any, codTienda: any, codBodega: any,
    numBoleta: any, numOrden: any, fechaDesde: any, fechaHasta: any, fechaVenta: any, fechaPicking: any, fechaShipping: any,
    tipoOrden: any, fechaCompromiso: any, numeroRuta: any, comuna: any, empresaTransporte: any) {
    return this._http.post(this.url12,
      {
        tipoVelocimetro: tipoVelocimetro, tipoBoton: tipoBoton, pagina: pagina, numFilas: numFilas, codTienda: codTienda, codBodega: codBodega,
        numBoleta: numBoleta, numOrden: numOrden, fechaDesde: fechaDesde, fechaHasta: fechaHasta, fechaVenta: fechaVenta, fechaPicking: fechaPicking,
        fechaShipping: fechaShipping, tipoOrden: tipoOrden, fechaCompromiso: fechaCompromiso, numeroRuta: numeroRuta, comuna: comuna, empresaTransporte: empresaTransporte
      },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO- D3: PORCENTAJE DE CUMPLIMIENTO
  getPorcentajeCumplimiento(codTienda: any, codBodega: any, fechaVenta: any, fechaCompromiso: any, numeroRuta: any, empresaTransporte: any) {
    return this._http.get(this.url13 + '?codTienda=' + codTienda + '&codBodega=' + codBodega + '&fechaVenta=' + fechaVenta
      + '&fechaCompromiso=' + fechaCompromiso + '&numeroRuta=' + numeroRuta + '&empresaTransporte=' + empresaTransporte,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D3: INGRESADAS X 5
  getIngresadasX5(tipoPiramide: any, codTienda: any, codBodega: any, fechaVenta: any, fechaCompromiso: any, numeroRuta: any, empresaTransporte: any) {
    return this._http.get(this.url14 + '?tipoPiramide=' + tipoPiramide + '&codTienda=' + codTienda + '&codBodega=' + codBodega
      + '&fechaVenta=' + fechaVenta + '&fechaCompromiso=' + fechaCompromiso + '&numeroRuta=' + numeroRuta + '&empresaTransporte=' + empresaTransporte,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D3: DETALLE DE PIRAMIDE EN DESPACHO
  getDetallePiramide5(tipoBoton: any, tipoPiramide: any, pagina: any, numFilas: any, codTienda: any, codBodega: any,
    numBoleta: any, numOrden: any, fechaDesde: any, fechaHasta: any, fechaVenta: any, fechaCompromiso: any, numeroRuta: any, empresaTransporte: any) {
    return this._http.post(this.url15,
      {
        tipoBoton: tipoBoton, tipoPiramide: tipoPiramide, pagina: pagina, numFilas: numFilas, codTienda: codTienda, codBodega: codBodega,
        numBoleta: numBoleta, numOrden: numOrden, fechaDesde: fechaDesde, fechaHasta: fechaHasta, fechaVenta: fechaVenta, fechaCompromiso: fechaCompromiso,
        numeroRuta: numeroRuta, empresaTransporte: empresaTransporte
      },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D3: GRAFICO TORTA
  getDetalleGraficoTorta(codTienda: any, codBodega: any, fechaVenta: any, fechaCompromiso: any, numeroRuta: any, empresaTransporte: any) {
    return this._http.get(this.url16 + '?codTienda=' + codTienda + '&codBodega=' + codBodega + '&fechaVenta=' + fechaVenta
      + '&fechaCompromiso=' + fechaCompromiso + '&numeroRuta=' + numeroRuta + '&empresaTransporte=' + empresaTransporte,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D3: DETALLE MODAL ENTREGADOS Y EN PLAZO
  getDetalleEntregadosEnPlazo(pagina: any, numFilas: any, tipoSolucion: any, codTienda: any, codBodega: any) {
    return this._http.get(this.url17 + '?pagina=' + pagina + '&numFilas=' + numFilas + '&tipoSolucion=' + tipoSolucion
      + '&codTienda=' + codTienda + '&codBodega=' + codBodega,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D3: POR ENTREGAR
  getObtenerEntregados(codBodega: any, fechaVenta: any, fechaCompromiso: any, numeroRuta: any, empresaTransporte: any, codTienda: any) {
    return this._http.get(this.url18 + '?codBodega=' + codBodega + '&fechaVenta=' + fechaVenta + '&fechaCompromiso=' + fechaCompromiso
      + '&numeroRuta=' + numeroRuta + '&empresaTransporte=' + empresaTransporte + '&codTienda=' + codTienda,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D4: INGRESADAS X 4
  getIngresadasX4(tipoPiramide: any, codTienda: any, codBodega: any, fechaVenta: any, fechaPicking: any, fechaShipping: any, fechaCompromiso: any,
    numeroRuta: any, comuna: any, tipoOrden: any) {
    return this._http.get(this.url19 + '?tipoPiramide=' + tipoPiramide + '&codTienda=' + codTienda + '&codBodega=' + codBodega + '&fechaVenta=' + fechaVenta
      + '&fechaPicking=' + fechaPicking + '&fechaShipping=' + fechaShipping + '&fechaCompromiso=' + fechaCompromiso + '&numeroRuta=' + numeroRuta
      + '&comuna=' + comuna + '&tipoOrden=' + tipoOrden,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D4: DETALLE DE PIRAMIDE TRANSPORTE
  getDetallePiramide4(tipoBoton: any, tipoPiramide: any, pagina: any, numFilas: any, codTienda: any, codBodega: any,
    numBoleta: any, numOrden: any, fechaDesde: any, fechaHasta: any, fechaPicking: any, fechaShipping: any, tipoOrden: any,
    fechaCompromiso: any, numeroRuta: any, comuna: any) {
    return this._http.post(this.url20,
      {
        tipoBoton: tipoBoton, tipoPiramide: tipoPiramide, pagina: pagina, numFilas: numFilas, codTienda: codTienda, codBodega: codBodega,
        numBoleta: numBoleta, numOrden: numOrden, fechaDesde: fechaDesde, fechaHasta: fechaHasta, fechaPicking: fechaPicking, fechaShipping: fechaShipping,
        tipoOrden: tipoOrden, fechaCompromiso: fechaCompromiso, numeroRuta: numeroRuta, comuna: comuna
      },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-D4: POR ENVIAR
  getObtenerEnviados(tipoVelocimetro: any, codTienda: any, codBodega: any, fechaVenta: any, fechaPicking: any, fechaShipping: any, fechaCompromiso: any,
    numeroRuta: any, comuna: any, tipoOrden: any) {
    return this._http.get(this.url21 + '?tipoVelocimetro=' + tipoVelocimetro + '&codTienda=' + codTienda + '&codBodega=' + codBodega
      + '&fechaVenta=' + fechaVenta + '&fechaPicking=' + fechaPicking + '&fechaShipping=' + fechaShipping + '&fechaCompromiso=' + fechaCompromiso
      + '&numeroRuta=' + numeroRuta + '&comuna=' + comuna + '&tipoOrden=' + tipoOrden,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-VENTAS: INGRESADAS X 3
  getIngresadasX3(tipoPiramide: any, codTienda: any, codBodega: any, fechaVenta: any) {
    return this._http.get(this.url22 + '?tipoPiramide=' + tipoPiramide + '&codTienda=' + codTienda + '&codBodega=' + codBodega + '&fechaVenta=' + fechaVenta,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  getIngresadasX3Emitir(tipoPiramide: any, codTienda: any, codBodega: any, fechaVenta: any) {
    return this._http.get(this.url22 + '?tipoPiramide=' + tipoPiramide + '&codTienda=' + codTienda + '&codBodega=' + codBodega + '&fechaVenta=' + fechaVenta,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // NUEVO-VENTAS: DETALLE DE PIRAMIDE EMITIR Y RECEPCIONAR
  getDetallePiramide3(tipoBoton: any, tipoPiramide: any, pagina: any, numFilas: any, codTienda: any, codBodega: any,
    numBoleta: any, numOrden: any, fechaDesde: any, fechaHasta: any, fechaVenta: any) {
    return this._http.post(this.url23,
      {
        tipoBoton: tipoBoton, tipoPiramide: tipoPiramide, pagina: pagina, numFilas: numFilas, codTienda: codTienda, codBodega: codBodega,
        numBoleta: numBoleta, numOrden: numOrden, fechaDesde: fechaDesde, fechaHasta: fechaHasta, fechaVenta: fechaVenta
      },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // DASHBOARD DESPACHO: DETALLE TORTA
  obtenerDetalleSubEstadoBeetrack(pagina: any, numFilas: any, codTienda: any, codBodega: any, numBoleta: any, numOrden: any, fechaDesde: any, fechaHasta: any,
    fechaCompromiso: any, numeroRuta: any, empresaTransporte: any) {
    return this._http.get(this.url24 + '?pagina=' + pagina + '&numFilas=' + numFilas + '&codTienda=' + codTienda
      + '&codBodega=' + codBodega + '&numBoleta=' + numBoleta + '&numOrden=' + numOrden + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta
      + '&fechaCompromiso=' + fechaCompromiso + '&numeroRuta=' + numeroRuta + '&empresaTransporte=' + empresaTransporte,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // DASHBOARD DESPACHO: DETALLE TORTA
  getBuscadorBoletasOrdenes(numBoleta: any, numOrden: any, rutCliente: any) {
    return this._http.get(this.url25 + '?numBoleta=' + numBoleta + '&numOrden=' + numOrden + '&rutCliente=' + rutCliente,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // SELECT DE BODEGAS DE DESPACHO
  getBodegasSelect() {
    return this._http.get(this.url26,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // SELECT DE LOCALES DE VENTA
  getLocalesVenta() {
    return this._http.get(this.url27,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // SELECT DE COMUNAS
  getComunas() {
    return this._http.get(this.url28,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }

  // SELECT DE COMUNAS
  getEmpresasTransporte() {
    return this._http.get(this.url29,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }), observe: 'response' });
  }


  // SELECT DE MAE ATRASOS
  getAtrasosOrdenes(numBoleta: any) {
    return this._http.get(this.url30 + "/" + numBoleta,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' });
  }

  obtenerUrl(boletaUrl: BoletaUrl) {
    return this._http.post(environment3 + "/boleta/ecologica/obtener/archivo", boletaUrl);
  }

  obtenerUrlPdf(boleta: any) {
   
    return this._http.post(environment3 + "/boleta/ecologica/busquedaNroBoleta",
      {
        boleta: boleta,
        datosPorPagina: "10",
        pagina: "1"
      });
  }

}
