import {Component, ElementRef, OnInit, ViewChild, Input, HostListener} from '@angular/core';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ResponseDetalleIngresadas, ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {ResponseVelocimetro} from '../../../../comun/vo/responseVelocimetro';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DetalleAtrasadosVOS, ResponseAtrasados, ResponseHorizontal} from '../../../../comun/vo/responseHorizontal';
import {ResponseDetalleEmitir, ResponseEmitirOrden} from '../../../../comun/vo/responseEmitirOrden';
import {ResponseDetalleEnPicking, ResponseEnPicking} from '../../../../comun/vo/responseEnPicking';
import {ResponseDetallePorEntregar, ResponsePorEntregar} from '../../../../comun/vo/responsePorEntregar';
import {ResponseDetallePorRecepcionar, ResponsePorRecepcionar} from '../../../../comun/vo/responsePorRecepcionar';
import {ResonseVelocimetroModal, ResponseDetalleVelocimetro} from '../../../../comun/vo/resonseVelocimetroModal';


declare var cargaIngresada: any;
declare var cargaPick: any;
declare var cargaBarraHorizontal3X3: any;
declare var ingresoAmarillo: any;
declare var ingresoVerde: any;
declare var pickArribaArriba: any;
declare var pickAbajoAbajo: any;
declare var pickAbajoArriba: any;
declare var pickArribaAbajo: any;
declare var flexFont: any;
declare var ingresoVerde: any;
declare var ingresoAmarillo: any;
declare var ingresoRojo: any;
declare var lottie: any;


class Response {
  totalPaginas: number;
  totalElementos: number;
  pagina: number;
  cantidadFilas: Array<number>;
  totalPendienteOC: number;
  dataPage: Array<DataPage>;
  code: number;
}

class DataPage {
  numOrden: number;
  nombre: string;
  apellido: string;
  rut: string;
  totalCompra: string;
  numBoleta: string;
  cantidadProductos: number;
  fechaCompra: string;
  fechaEntrega: string;
  tipoDespacho: string;
  estado: number;
}

@Component({
  selector: 'app-dashboard2-administrador',
  templateUrl: './dashboard2-administrador.component.html',
  styleUrls: ['./dashboard2-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player2.css']
})
export class Dashboard2AdministradorComponent implements OnInit {
  @ViewChild('modalDetalleDashboardIngresadas') modalDetalleDashboardIngresadas;
  modalRefDetalleDashboardIngresadas: BsModalRef;
  @ViewChild('modalDetalleDashboardPicking') modalDetalleDashboardPicking;
  modalRefDetalleDashboardPicking: BsModalRef;
  @ViewChild('modalDetalleDashboardPorEntregar') modalDetalleDashboardPorEntregar;
  modalRefDetalleDashboardPorEntregar: BsModalRef;
  @ViewChild('modalDetalleDashboardEmitirOrden') modalDetalleDashboardEmitirOrden;
  modalRefDetalleDashboardEmitirOrden: BsModalRef;
  @ViewChild('modalDetalleDashboardRecepcionar') modalDetalleDashboardRecepcionar;
  modalRefDetalleDashboardRecepcionar: BsModalRef;
  public responseIngresadas: ResponseIngresadas;
  responsePorEntregar: ResponseVelocimetro;
  public ingA;

  porRecepcionar: any;
  porEmitir: any;
  porEntregar: any;
  porEntregarTipo: number;
  numOrdenPorEntregar: string;
  rutClientePorEntregar: string;
  paginaPorEntregar: number;
  filasPorEntregar: number;
  diaPorEntregar: number;
  responsePorEntregarDetalle: Response;

  ingresada: any;
  ingresadaTipo: number;
  estadoIngresada: string;
  numOrdenIngresada: string;
  rutClienteIngresada: string;
  paginaIngresada: number;
  filasIngresada: number;
  responseIngresadaDetalle: Response;
  enPicking: any;
  // VO Atrasados
  responseHorizontal: ResponseHorizontal;
  responseAtrasados: ResponseAtrasados;
  detalleAtrasados: DetalleAtrasadosVOS[];
  responseEmitirOrden: ResponseEmitirOrden;
  responseDetalleEmitir: ResponseDetalleEmitir;
  responseEnPicking: ResponseEnPicking;
  responseDetalleEnPicking: ResponseDetalleEnPicking;
  responseIngresadasVO: ResponseIngresadas;
  responseDetalleIngresadas: ResponseDetalleIngresadas;
  _responsePorEntregar: ResponsePorEntregar;
  responseDetallePorEntregar: ResponseDetallePorEntregar;
  responsePorRecepcionar: ResponsePorRecepcionar;
  responseDetallePorRecepcionar: ResponseDetallePorRecepcionar;
  responseVelocimetroModal: ResonseVelocimetroModal;
  responseDetalleVelocimetro: ResponseDetalleVelocimetro[];
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  page = 1;
  pageSize = 5;
  pageSize2 = 5;
  isTabPrincipal: boolean;
  filtroActivo: boolean;
  registrosSelect: number;
  tipoVelocimetro: string;
  tipoBoton: string;
  numBoleta: any;
  numOrden: any;
  fechaDesde: any;
  fechaHasta: any;


  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private spinnerService: Ng4LoadingSpinnerService
              ) {}


  ngOnInit() {
    this.porEntregarTipo = 0;
    this.paginaPorEntregar = 1;
    this.filasPorEntregar = 5;
    this.diaPorEntregar = 0;
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.registrosSelect = 5;
    this.isTabPrincipal = true;
    // Cargar ingresadas
    this.cargaIngresadas('ingA', ingresoAmarillo);
    /* Cargar velocimetros */
    this.cargaEnPicking('pickA', 'En Picking');
    this.cargaPorEntregar('pickB', 'Por Entregar');
    this.cargaPorRecepcionar('pickC', 'Por Recepcionar');
    this.cargaEmitirOrden('pickD', 'Emitir Orden');
    /* Cargar barra horizontal */
    this.cargaHorizontal('grafHorizontal');
    flexFont();
  }

  cargaIngresadas(id: string, color: any) {
    this._estadoDashboard.getIngresadas()
      .subscribe((data: HttpResponse<any>) => {
        this.responseIngresadasVO = data.body;
        this.responseDetalleIngresadas = this.responseIngresadasVO.responseEnDespachoVO;
        console.log('RESULTADO INGRESADAS: ');
        console.log(this.responseDetalleIngresadas);
        this.ingresada = {
        'grafico': 'ingresoVerde',
        'id': id,
        'tope':  '1000',//this.responseDetalleIngresadas.topeMaximo,
        'principal': this.responseDetalleIngresadas.despachosPrograDia,
        'circulo1valor': 1, //this.responseDetalleIngresadas.valorDvh,
        'circulo1text': 'DVH: ',
        'circulo1url': 'linkDVH',
        'circulo2valor': 2, //this.responseDetalleIngresadas.valorDdc,
        'circulo2text': 'DDC: ',
        'circulo2url': 'linkDDC',
        'circulo3valor': 3, //this.responseDetalleIngresadas.valorVto,
        'circulo3text': 'STS: ',
        'circulo3url': 'linkTO',
        'imgDerecha': 'assets/img/icon_ingresadas.svg',
        'imgText': 'Ingresada',
        'imgUrl': 'rojoLink'
      };
        // cargaIngresada(this.ingresada, ingresoAmarillo);
        flexFont();
    });
  }

  cargaEnPicking(id: string, tipo: string) {
    this._estadoDashboard.getPicking()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEnPicking = data.body;
        this.responseDetalleEnPicking = this.responseEnPicking.responsePorEntregarVO;
        console.log('RESULTADO EN PICKING: ');
        console.log(this.responseDetalleEnPicking);
        this.enPicking = {
          'id': 'pickA',
          'grafico': 'updown',
          'tipo': 'enPicking',
          'principal': Number(this.responseDetalleEnPicking.porcetajePrincipal),
          'verde': this.responseDetalleEnPicking.numVerde,
          'amarillo': this.responseDetalleEnPicking.numAmarillo,
          'rojo': this.responseDetalleEnPicking.numRojo,
          'morado': this.responseDetalleEnPicking.numMorado,
          'textAmarillo': this.responseDetalleEnPicking.txtAmarillo + ' dias',
          'textRojo': this.responseDetalleEnPicking.txtRojo + ' dias',
          'textMorado': this.responseDetalleEnPicking.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_por_entregar.svg',
          'tituloColor': 'fucsia',
          /*azul,fucsia, amarrillo, rojo*/
          'circuloIzqValor': this.responseDetalleEnPicking.pendientes,
          'circuloDerValor': this.responseDetalleEnPicking.completadas,
          'ovaloIzqValor': this.responseDetalleEnPicking.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEnPicking.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEnPicking.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas ordenes',
          'ovaloCenLabel': 'En Picking',
          'ovaloDerLabel': 'de',
          // 'link1': 'link1.cl',
          'link1': 'enPickingLink1',
          'link2': 'enPickingLink2',
          'link3': 'enPickingLink3',
          'link4': 'enPickingLink4',
          'link5': 'enPickingLink5',
          'link6': 'enPickingLink6',
          'link7': 'enPickingLink7',
          'link8': 'enPickingLink8',
          'link9': 'enPickingLink9'
        };
        // this.enPicking = jsonPickA;
        // cargaPick(this.enPicking, pickAbajoArriba);
        flexFont();
      });
  }

  cargaPorEntregar(id: string, tipo: string) {
    this._estadoDashboard.getPorEntregarDash()
      .subscribe((data: HttpResponse<any>) => {
        this._responsePorEntregar = data.body;
        this.responseDetallePorEntregar = this._responsePorEntregar.responsePorEntregarVO;
        console.log('RESULTADO POR ENTREGAR: ');
        console.log(this.responseDetallePorEntregar);
        this.porEntregar = {
          'id': 'pickB',
          'grafico': 'updown',
          'tipo': 'porEntregar',
          'principal': Number(this.responseDetallePorEntregar.porcetajePrincipal),
          'verde': this.responseDetallePorEntregar.numVerde,
          'amarillo': this.responseDetallePorEntregar.numAmarillo,
          'rojo': this.responseDetallePorEntregar.numRojo,
          'morado': this.responseDetallePorEntregar.numMorado,
          'textAmarillo': this.responseDetallePorEntregar.txtAmarillo + ' dias',
          'textRojo': this.responseDetallePorEntregar.txtRojo + ' dias',
          'textMorado': this.responseDetallePorEntregar.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetallePorEntregar.pendientes,
          'circuloDerValor': this.responseDetallePorEntregar.completadas,
          'ovaloIzqValor': this.responseDetallePorEntregar.ovaloIzq,
          'ovaloCenValor': this.responseDetallePorEntregar.ovaloCentro,
          'ovaloDerValor': this.responseDetallePorEntregar.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Entregar',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
        flexFont();
      });
  }

  cargaPorRecepcionar(id: string, tipo: string) {
    this._estadoDashboard.getPorRecepcionar()
      .subscribe((data: HttpResponse<any>) => {
        this.responsePorRecepcionar = data.body;
        this.responseDetallePorRecepcionar = this.responsePorRecepcionar.responsePorEntregarVO;
        console.log('RESULTADO POR RECEPCIONAR: ');
        console.log(this.responseDetallePorRecepcionar);
        this.porRecepcionar = {
          'id': 'pickC',
          'grafico': 'updown',
          'tipo': 'porRecepcionar',
          'principal': Number(this.responseDetallePorRecepcionar.porcetajePrincipal),
          'verde': this.responseDetallePorRecepcionar.numVerde,
          'amarillo': this.responseDetallePorRecepcionar.numAmarillo,
          'rojo': this.responseDetallePorRecepcionar.numRojo,
          'morado': this.responseDetallePorRecepcionar.numMorado,
          'textAmarillo': this.responseDetallePorRecepcionar.txtAmarillo + ' dias',
          'textRojo': this.responseDetallePorRecepcionar.txtRojo + ' dias',
          'textMorado': this.responseDetallePorRecepcionar.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_recepcionar.svg',
          'tituloColor': 'amarillo',
          /*azul,fucsia, amarrillo, rojo*/
          'circuloIzqValor': this.responseDetallePorRecepcionar.pendientes,
          'circuloDerValor': this.responseDetallePorRecepcionar.completadas,
          'ovaloIzqValor': this.responseDetallePorRecepcionar.ovaloIzq,
          'ovaloCenValor': this.responseDetallePorRecepcionar.ovaloCentro,
          'ovaloDerValor': this.responseDetallePorRecepcionar.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas ordenes',
          'ovaloCenLabel': 'Por Recepcionar',
          'ovaloDerLabel': 'de',
          'link1': 'porRecepcionarLink1',
          'link2': 'porRecepcionarLink2',
          'link3': 'porRecepcionarLink3',
          'link4': 'porRecepcionarLink4',
          'link5': 'porRecepcionarLink5',
          'link6': 'porRecepcionarLink6',
          'link7': 'porRecepcionarLink7',
          'link8': 'porRecepcionarLink8',
          'link9': 'porRecepcionarLink9'
        };
        // this.porRecepcionar = jsonPickC;
        // cargaPick(this.porRecepcionar, pickAbajoArriba);
        flexFont();
      });
  }

  cargaEmitirOrden(id: string, tipo: string) {
    this._estadoDashboard.getEmitirOrden()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEmitirOrden = data.body;
        this.responseDetalleEmitir = this.responseEmitirOrden.responsePorEntregarVO;
        console.log('RESULTADO EMITIR ORDEN: ');
        console.log(this.responseDetalleEmitir);
        this.porEmitir = {
          'id': 'pickD',
          'grafico': 'updown',
          'tipo': 'porEmitir',
          'principal': Number(this.responseDetalleEmitir.porcetajePrincipal),
          'verde': this.responseDetalleEmitir.numVerde,
          'amarillo': this.responseDetalleEmitir.numAmarillo,
          'rojo': this.responseDetalleEmitir.numRojo,
          'morado': this.responseDetalleEmitir.numMorado,
          'textAmarillo': this.responseDetalleEmitir.txtAmarillo + ' dias',
          'textRojo': this.responseDetalleEmitir.txtRojo + ' dias',
          'textMorado': this.responseDetalleEmitir.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_emitir_orden.svg',
          'tituloColor': 'rojo',
          /*azul,fucsia, amarrillo, rojo*/
          'circuloIzqValor': this.responseDetalleEmitir.pendientes,
          'circuloDerValor': this.responseDetalleEmitir.completadas,
          'ovaloIzqValor': this.responseDetalleEmitir.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEmitir.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEmitir.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas ordenes',
          'ovaloCenLabel': 'Por Emitir',
          'ovaloDerLabel': 'de',
          'link1': 'porEmitirLink1',
          'link2': 'porEmitirLink2',
          'link3': 'porEmitirLink3',
          'link4': 'porEmitirLink4',
          'link5': 'porEmitirLink5',
          'link6': 'porEmitirLink6',
          'link7': 'porEmitirLink7',
          'link8': 'porEmitirLink8',
          'link9': 'porEmitirLink9'
        };
        // this.porEmitir = jsonPickD;
        // cargaPick(this.porEmitir, pickAbajoArriba);
        flexFont();
      });
  }

  cargaHorizontal(id: string) {
    this._estadoDashboard.getAtrasados()
      .subscribe((data: HttpResponse<any>) => {
        // const response: ResponseHorizontal = JSON.parse(JSON.stringify(data));
        // console.log('RESULTADO INFORMACION: ' + JSON.stringify(response));
        this.responseHorizontal = data.body;
        this.responseAtrasados = this.responseHorizontal.responseAtrasadosVO;
        this.detalleAtrasados = this.responseAtrasados.atrasadosVOS;
        console.log('RESULTADO ATRASADOS: ');
        console.log(this.detalleAtrasados);
        const jsonBarraHorizontal3X3 = {
          'id': 'grafHorizontal',
          'grupo1': this.detalleAtrasados[0].dia,
          'grupo1red': Number(this.detalleAtrasados[0].atrasoMayor),
          'grupo1yellow': Number(this.detalleAtrasados[0].atrasoUno),
          'grupo1green': Number(this.detalleAtrasados[0].enPlazo),
          'grupo2': this.detalleAtrasados[1].dia,
          'grupo2red': Number(this.detalleAtrasados[1].atrasoMayor),
          'grupo2yellow': Number(this.detalleAtrasados[1].atrasoUno),
          'grupo2green': Number(this.detalleAtrasados[1].enPlazo),
          'grupo3': this.detalleAtrasados[2].dia,
          'grupo3red': Number(this.detalleAtrasados[2].atrasoMayor),
          'grupo3yellow': Number(this.detalleAtrasados[2].atrasoUno),
          'grupo3green': Number(this.detalleAtrasados[2].enPlazo),
          'rojoText': 'Más de 1 dia de atraso',
          'verdeText': 'Procesado Hoy',
          'amarilloText': 'Un dia de atraso',
          'rojoLink': 'Cuadro Rojo',
          'verdeLink': 'Cuadro Verde',
          'amarilloLink': 'Cuadro Amarillo',
        };
        cargaBarraHorizontal3X3(jsonBarraHorizontal3X3);
        flexFont();
      });
  }

  cambioSelect(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, '', '',
      this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
      '', '', '', '', '', '')
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
      });
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
        this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, '','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            console.log('this.page cambio pagina: ' + this.page);
            console.log('this.pageSize cambio pagina: ' + this.pageSize2);
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
          });
    }
  }

  // MODALS DE INGRESADAS
  abrirModalIngresadasDVH() {
    console.log('INGRESADAS: ' + this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }
  abrirModalIngresadasDDC() {
    console.log('INGRESADAS: ' + this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }
  abrirModalIngresadasOnline() {
    console.log('INGRESADAS: ' + this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }
  // MODALS DE PICKING
  abrirModalPicking() {
    this.modalRefDetalleDashboardPicking = this.modalService.show(this.modalDetalleDashboardPicking, {class: 'modal-lg'});
  }
  // MODALS DE POR ENTREGAR
  abrirModalPorEntregar() {
    this.modalRefDetalleDashboardPorEntregar = this.modalService.show(this.modalDetalleDashboardPorEntregar, {class: 'modal-lg'});
  }
  // MODALS DE EMITIR ORDEN
  abrirModalEmitirOrden() {
    this.modalRefDetalleDashboardEmitirOrden = this.modalService.show(this.modalDetalleDashboardEmitirOrden, {class: 'modal-lg'});
  }
  abrirModalRecepcionar() {
    this.modalRefDetalleDashboardRecepcionar = this.modalService.show(this.modalDetalleDashboardRecepcionar, {class: 'modal-lg'});
  }
  cerrarModal(modal: string) {
    this + modal;
    this.page = 1;
    this.pageSize = 5;
    this.pageSize2 = 5;
  }

  llamadaClickIngresadas(sno) {
    switch (sno) {
      case 'linkDVH':
        console.log('aca va DVH');
        this.abrirModalIngresadasDVH();
        break;
      case 'linkDDC':
        console.log('aca va DDC');
        this.abrirModalIngresadasDDC();
        break;
      case 'linkTO':
        console.log('aca va Online');
        this.abrirModalIngresadasOnline();
        break;
      default:
    }
  }

  llamadaClickPorEntregar(sno2) {
    switch (sno2) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'btnVerde', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'btnAmarillo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'btnRojo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'btnMorado', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEntregarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'porcentajeprincipal', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEntregarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'nuevas', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEntregarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('porentregar', 'total', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porentregar';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
    // switch (sno) {
    //   case 'porEntregarLink1':
    //     // dia 0
    //     this.porEntregarTipo = 1;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink2':
    //     this.porEntregarTipo = 2;
    //     // dia -1
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink3':
    //     // dia 8
    //     this.porEntregarTipo = 3;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink4':
    //     // dia 9+
    //     this.porEntregarTipo = 4;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink5':
    //     // pendiente
    //     this.porEntregarTipo = 5;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink6':
    //     // avance
    //     this.porEntregarTipo = 6;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink7':
    //     // nuevas ordenes
    //     this.porEntregarTipo = 7;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink8':
    //     // por entregar
    //     this.porEntregarTipo = 8;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink9':
    //     // todo
    //     this.porEntregarTipo = 9;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   default:
    // }
  }

  llamadaClickEnPicking(sno3) {
    switch (sno3) {
      case 'enPickingLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'btnVerde', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'enPickingLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'btnAmarillo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'enPickingLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'btnRojo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'enPickingLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'btnMorado', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'enPickingLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'enPickingLink6':
        console.log('aca va completadas: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'porcentajeprincipal', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'enPickingLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'nuevas', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'enPickingLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'enPickingLink9':
        console.log('aca va total');
        this._estadoDashboard.getDetalleVelocimetros('enpicking', 'total', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPicking();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enpicking';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
    // switch (sno) {
    //   case 'porEntregarLink1':
    //     // dia 0
    //     this.porEntregarTipo = 1;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink2':
    //     this.porEntregarTipo = 2;
    //     // dia -1
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink3':
    //     // dia 8
    //     this.porEntregarTipo = 3;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink4':
    //     // dia 9+
    //     this.porEntregarTipo = 4;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink5':
    //     // pendiente
    //     this.porEntregarTipo = 5;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink6':
    //     // avance
    //     this.porEntregarTipo = 6;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink7':
    //     // nuevas ordenes
    //     this.porEntregarTipo = 7;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink8':
    //     // por entregar
    //     this.porEntregarTipo = 8;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   case 'porEntregarLink9':
    //     // todo
    //     this.porEntregarTipo = 9;
    //     this.paginaPorEntregar = 1;
    //     this.filasPorEntregar = 5;
    //     this.numOrdenPorEntregar = '';
    //     this.rutClientePorEntregar = '';
    //     this.inicioCargarModalPorEntregar();
    //     break;
    //   default:
    // }
  }

  llamadaClickPorEmitir(sno4) {
    switch (sno4) {
      case 'porEmitirLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'btnVerde', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEmitirLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'btnAmarillo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEmitirLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'btnRojo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEmitirLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'btnMorado', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEmitirLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEmitirLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'porcentajeprincipal', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEmitirLink7':
        console.log('aca va nuvas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'nuevas', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEmitirLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEmitirLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('poremitirorden', 'total', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalEmitirOrden();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'poremitirorden';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }

  llamadaClickPorRecepcionar(sno5) {
    switch (sno5) {
      case 'porRecepcionarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'btnVerde', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porRecepcionarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'btnAmarillo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porRecepcionarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'btnRojo', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porRecepcionarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'btnMorado', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porRecepcionarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porRecepcionarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'porcentajeprincipal', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porRecepcionarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'nuevas', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porRecepcionarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'pendientes', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porRecepcionarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('porrecepcionar', 'total', this.page, this.pageSize,'','',
          this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta,'', '',
          '', '', '', '', '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalRecepcionar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'porrecepcionar';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }

  llamadaClick( sno ) {

    switch (sno) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde');
        this.abrirModalPorEntregar();
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo');
        this.abrirModalPorEntregar();
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo');
        this.abrirModalPorEntregar();
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado');
        this.abrirModalPorEntregar();
        break;
      default:
    }
  }

  inicioCargarModalPorEntregar() {
    console.log('a: ' + this.rutClientePorEntregar + ' b: ' + this.numOrdenPorEntregar);
    this.spinnerService.show();
    if (this.porEntregarTipo === 1 || this.porEntregarTipo === 2 || this.porEntregarTipo === 3 || this.porEntregarTipo === 4) {
      this.dashboardBodegaService.getVelocimetroPorEntregar_estado_dia(this.paginaPorEntregar, this.filasPorEntregar,
        this.tipoEstadoPorEntregar(this.porEntregarTipo),  this.diaPorEntregar, this.existePorEntregar(this.numOrdenPorEntregar),
        this.existePorEntregar(this.rutClientePorEntregar))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responsePorEntregarDetalle = data.body;
          console.log('RESPONSE DETALLE');
          console.log(this.responsePorEntregarDetalle);
          // this.abrirModalPorEntregar();
      }, error2 => {
          this.spinnerService.hide();
        });

    } else {
      if (this.porEntregarTipo === 5 || this.porEntregarTipo === 6 || this.porEntregarTipo === 7 || this.porEntregarTipo === 8) {
        this.dashboardBodegaService.getVelocimetroPorEntregar_estado(this.paginaPorEntregar, this.filasPorEntregar,
          this.tipoEstadoPorEntregar(this.porEntregarTipo), this.existePorEntregar(this.numOrdenPorEntregar),
          this.existePorEntregar(this.rutClientePorEntregar))
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.responsePorEntregarDetalle = data.body;
            console.log('RESPONSE DETALLE');
            console.log(this.responsePorEntregarDetalle);
            // this.abrirModalPorEntregar();
          }, error2 => {
            this.spinnerService.hide();
          });
      } else {
        this.dashboardBodegaService.getVelocimetroPorEntregar_todo(this.paginaPorEntregar, this.filasPorEntregar,
          this.existePorEntregar(this.numOrdenPorEntregar), this.existePorEntregar(this.rutClientePorEntregar))
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.responsePorEntregarDetalle = data.body;
            console.log('RESPONSE DETALLE');
            console.log(this.responsePorEntregarDetalle);
            // this.abrirModalPorEntregar();
          }, error2 => {
            this.spinnerService.hide();
          });
      }
    }
  }

  actualizaCargarModalPorEntregar() {
    console.log(this.rutClientePorEntregar + ' ' + this.numOrdenPorEntregar);
    if (this.porEntregarTipo === 1 || this.porEntregarTipo === 2 || this.porEntregarTipo === 3 || this.porEntregarTipo === 4) {
      this.dashboardBodegaService.getVelocimetroPorEntregar_estado_dia(this.paginaPorEntregar, this.filasPorEntregar,
        this.tipoEstadoPorEntregar(this.porEntregarTipo),  this.diaPorEntregar, this.existePorEntregar(this.numOrdenPorEntregar),
        this.existePorEntregar(this.rutClientePorEntregar))
        .subscribe((data: HttpResponse<any>) => {
          this.responsePorEntregarDetalle = data.body;
          console.log('RESPONSE DETALLE');
          console.log(this.responsePorEntregarDetalle);
        }, error2 => {
        });

    } else {
      if (this.porEntregarTipo === 5 || this.porEntregarTipo === 6 || this.porEntregarTipo === 7 || this.porEntregarTipo === 8) {
        this.dashboardBodegaService.getVelocimetroPorEntregar_estado(this.paginaPorEntregar, this.filasPorEntregar,
          this.tipoEstadoPorEntregar(this.porEntregarTipo), this.existePorEntregar(this.numOrdenPorEntregar),
          this.existePorEntregar(this.rutClientePorEntregar))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePorEntregarDetalle = data.body;
            console.log('RESPONSE DETALLE');
            console.log(this.responsePorEntregarDetalle);
          }, error2 => {
          });
      } else {
        this.dashboardBodegaService.getVelocimetroPorEntregar_todo(this.paginaPorEntregar, this.filasPorEntregar,
          this.existePorEntregar(this.numOrdenPorEntregar), this.existePorEntregar(this.rutClientePorEntregar))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePorEntregarDetalle = data.body;
            console.log('RESPONSE DETALLE');
            console.log(this.responsePorEntregarDetalle);
          }, error2 => {
          });
      }
    }
  }

  existePorEntregar(entrada: string): string {
    if (entrada === '' || entrada === undefined) {
      return 'nada';
    } else {
      return entrada;
    }
  }

  tipoEstadoPorEntregar(tipo: number): string {
    if (tipo === 1 || tipo === 2 || tipo === 3 || tipo === 4) {
      return 'estadoActual';
    }
    if (tipo === 5) {
      return 'pendiente';
    }
    if (tipo === 6) {
      return 'avance';
    }
    if (tipo === 7) {
      return 'nuevasOrdenes';
    }
    if (tipo === 8) {
      return 'porEntregar';
    }
  }

  cambiarPaginaPorEntregar(pagina: number) {
    this.paginaPorEntregar = pagina;
    this.actualizaCargarModalPorEntregar();
  }

  cambioFilasPorEntregar(e) {
    this.filasPorEntregar = e;
    console.log(e);
    this.actualizaCargarModalPorEntregar();
  }

  filtrarPorEntregar() {
    this.actualizaCargarModalPorEntregar();
  }

  quitarFiltroPorEntregar() {
    this.numOrdenPorEntregar = '';
    this.rutClientePorEntregar = '';
    this.actualizaCargarModalPorEntregar();
  }

  inicioCargarModalIngresada() {

  }

}

