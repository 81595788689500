import { Component, OnInit } from '@angular/core';
import { HttpResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
import { environment2 } from '../../../../../environments/environment';
@Component({
  selector: 'app-carga-atrasos',
  templateUrl: './carga-atrasos.component.html',
  styleUrls: ['./carga-atrasos.component.css']
})
export class CargaAtrasosComponent implements OnInit {
  uploadForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private _http: HttpClient) { }

  ngOnInit() {

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }



  public handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);
    }
  }

  public cargarAtrasos() {
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    //this._http.post(environment.dominioServicios + '/tracking/carga/atraso', formData,
    this._http.post(environment2.dominioCarga + '/tracking/carga/atraso', formData,

      {
        headers: new HttpHeaders(),
        observe: 'response'
      })
      .subscribe((data: HttpResponse<any>) => {
        console.log(data);

      }, (error) => {
        console.log('ERROR');
        console.log(error);
        swal({
          title: 'Carga Atraso',
          text: 'Problemas para cargar atrasos',
          type: 'error',
          confirmButtonText: 'OK'
        });

      });


    swal({
      title: 'Carga Atraso',
      text: 'Procesando carga de ordenes atraso',
      type: 'success',
      confirmButtonText: 'OK'
    });

  }



}
