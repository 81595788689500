import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
// Venta en Verde
import { LoginComponent } from './comun/login/login.component';
import { InicioAdministradorComponent } from './ventaEnVerde/componentes/administrador/inicio/inicio.component';
import { VentasAdministradorComponent } from './ventaEnVerde/componentes/administrador/ventas-administrador/ventas-administrador.component';
import { MainAdministradorComponent } from './ventaEnVerde/componentes/main-administrador/main-administrador.component';
import { MainProveedorComponent } from './ventaEnVerde/componentes/main-proveedor/main-proveedor.component';
import { SeguimientoAdministradorComponent } from './ventaEnVerde/componentes/administrador/seguimiento-administrador/seguimiento-administrador.component';
import { ReportesAdministradorComponent } from './ventaEnVerde/componentes/administrador/reportes-administrador/reportes-administrador.component';
import { InicioProveedorComponent } from './ventaEnVerde/componentes/proveedor/inicio/inicio.component';
import { OrdenesVentasProveedorComponent } from './ventaEnVerde/componentes/proveedor/ordenes-ventas-proveedor/ordenes-ventas-proveedor.component';
import { SeguimientoProveedorComponent } from './ventaEnVerde/componentes/proveedor/seguimiento-proveedor/seguimiento-proveedor.component';
import { AgendaProveedorComponent } from './ventaEnVerde/componentes/proveedor/agenda-proveedor/agenda-proveedor.component';
import { Dashboard2AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard2-administrador/dashboard2-administrador.component';
import { AgendaAdministradorComponent } from './ventaEnVerde/componentes/administrador/agenda-administrador/agenda-administrador.component';
import { ConfiguracionesAdministradorComponent } from './ventaEnVerde/componentes/administrador/configuraciones-administrador/configuraciones-administrador.component';
import { BusquedaAdministradorComponent } from './ventaEnVerde/componentes/administrador/busqueda-administrador/busqueda-administrador.component';
import { Dashboard1AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard1-administrador/dashboard1-administrador.component';
// Tracking de Boleta
import { MainAdministradorTrackingComponent } from './trackingBoletas/componentes/main-administrador/main-administrador-tracking.component';
import { InicioAdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/inicio/inicio.tracking.component';
import { Dashboard2AdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/dashboard2-administrador/dashboard2-administrador-tracking.component';
import { Dashboard1AdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/dashboard1-administrador/dashboard1-administrador-tracking.component';
import { CargaAtrasosComponent } from './trackingBoletas/componentes/administrador/carga-atrasos/carga-atrasos.component';
// Liquidacion de Transportista
import { MainAdministradorTransportistaComponent } from './liquidacionTransportistas/componentes/main-administrador/main-administrador-transportista.component';
import { MainTransportistaComponent } from './liquidacionTransportistas/componentes/main-transportista/main-transportista.component';
import { InicioAdministradorTransportistaComponent } from './liquidacionTransportistas/componentes/administrador/inicio/inicio.administrador.transportista.component';
import { InicioTransportistaComponent } from './liquidacionTransportistas/componentes/transportista/inicio/inicio.transportista.component';
import { DetalleLiquidacionAdminComponent } from './liquidacionTransportistas/componentes/administrador/detalle-liquidacion-admin/detalle-liquidacion-admin.component';
import { ConfiguracionTransportistaComponent } from './liquidacionTransportistas/componentes/administrador/configuracion-transportista/configuracion-transportista.component';
import { DetalleLiquidacionComponent } from './liquidacionTransportistas/componentes/transportista/detalle-liquidacion/detalle-liquidacion.component';
import { Dashboard3AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard3-administrador/dashboard3-administrador.component';
import { DetalleDespachoAdministradorComponent } from './ventaEnVerde/componentes/administrador/detalle-despacho-administrador/detalle-despacho-administrador.component';
import { DetallePlazoAdministradorComponent } from './ventaEnVerde/componentes/administrador/detalle-plazo-administrador/detalle-plazo-administrador.component';
import { DashboardTransporteAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-transporte-administrador/dashboard-transporte-administrador.component';
import { DashboardOperacionesAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-operaciones-administrador/dashboard-operaciones-administrador.component';
import { DashboardVentasAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-ventas-administrador/dashboard-ventas-administrador.component';
import { BuscadorBoletasComponent } from './ventaEnVerde/componentes/administrador/buscador-boletas/buscador-boletas.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'vev/administrador', component: MainAdministradorComponent,
    children: [
      { path: 'inicio', component: InicioAdministradorComponent },
      { path: 'ventas', component: VentasAdministradorComponent },
      { path: 'seguimiento', component: SeguimientoAdministradorComponent },
      { path: 'agenda', component: AgendaAdministradorComponent },
      { path: 'reportes', component: ReportesAdministradorComponent },
      { path: 'configuraciones', component: ConfiguracionesAdministradorComponent },
      { path: 'busqueda', component: BusquedaAdministradorComponent },
      { path: 'dashboard1', component: Dashboard1AdministradorComponent },
      { path: 'dashboard2', component: Dashboard2AdministradorComponent },
      { path: 'dashboard3', component: Dashboard3AdministradorComponent },
      { path: 'dashboardTransporte', component: DashboardTransporteAdministradorComponent },
      { path: 'dashboardOperaciones', component: DashboardOperacionesAdministradorComponent },
      { path: 'dashboardVentas', component: DashboardVentasAdministradorComponent },
      { path: 'detalleDespacho', component: DetalleDespachoAdministradorComponent },
      { path: 'detallePlazo', component: DetallePlazoAdministradorComponent },
      { path: 'buscadorBoletas', component: BuscadorBoletasComponent }
    ]
  },
  {
    path: 'vev/proveedor', component: MainProveedorComponent,
    children: [
      { path: 'inicio', component: InicioProveedorComponent },
      { path: 'ventas', component: OrdenesVentasProveedorComponent },
      { path: 'seguimiento', component: SeguimientoProveedorComponent },
      { path: 'agenda', component: AgendaProveedorComponent }
    ]
  },
  {
    path: 'tracking/administrador', component: MainAdministradorTrackingComponent,
    children: [
      { path: 'inicio', component: InicioAdministradorTrackingComponent },
      { path: 'dashboard1', component: Dashboard1AdministradorTrackingComponent },
      { path: 'dashboard2', component: Dashboard2AdministradorTrackingComponent },
      { path: 'dashboard3', component: Dashboard3AdministradorComponent },
      { path: 'dashboardTransporte', component: DashboardTransporteAdministradorComponent },
      { path: 'dashboardOperaciones', component: DashboardOperacionesAdministradorComponent },
      { path: 'dashboardVentas', component: DashboardVentasAdministradorComponent },
      { path: 'buscadorBoletas', component: BuscadorBoletasComponent },
      { path: 'cargaAtrasos', component: CargaAtrasosComponent }
    ]
  },
  {
    path: 'liquidTransportista/administrador', component: MainAdministradorTransportistaComponent,
    children: [
      { path: 'inicio', component: InicioAdministradorTransportistaComponent },
      { path: 'detalleliquidacion', component: DetalleLiquidacionAdminComponent },
      { path: 'configuracionLiquidacion', component: ConfiguracionTransportistaComponent },
      { path: 'dashboard3', component: Dashboard3AdministradorComponent },
      { path: 'dashboardTransporte', component: DashboardTransporteAdministradorComponent },
      { path: 'dashboardOperaciones', component: DashboardOperacionesAdministradorComponent },
      { path: 'dashboardVentas', component: DashboardVentasAdministradorComponent },
    ]
  },
  {
    path: 'liquidTransportista/transportista', component: MainTransportistaComponent,
    children: [
      { path: 'inicio', component: InicioTransportistaComponent },
      { path: 'detalleliquidacion', component: DetalleLiquidacionComponent }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}

