import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {DetalleAtrasadosVOS, ResponseAtrasados, ResponseHorizontal} from '../../../../comun/vo/responseHorizontal';
import {ResponseDetalleIngresadas, ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {ResponseDetalleEmitir, ResponseEmitirOrden} from '../../../../comun/vo/responseEmitirOrden';
import {ResponseEntregados, ResponseEntregadosDetalle} from '../../../../comun/vo/responseEntregados';


import {ChartType, ChartOptions, ChartDataSets} from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ResponsePorcentajeCumplimiento, ResponsePorcentajeLogrado} from '../../../../comun/vo/responsePorcentajeCumplimiento';
import {ResponseDetalleIngresadasX5, ResponseIngresadasX5} from '../../../../comun/vo/responseIngresadasX5';
import {ResponseDetallePiramide, ResponsePiramideDespacho} from '../../../../comun/vo/responsePiramideDespacho';
import {ResponseDetalleEnPlazo, ResponseEnPlazo} from '../../../../comun/vo/resposeEnPlazo';
import {ResponseDetalleAtrasados, ResponseEntregadasAtrasadas} from '../../../../comun/vo/responseEntregadasAtrasadas';
import {ResponseDetalleEntregadosEnPlazo, ResponseEntregadosEnPlazo} from '../../../../comun/vo/responseEntregadosEnPlazo';
import {ResponseDetallePorEntregar, ResponsePorEntregar} from '../../../../comun/vo/responsePorEntregar';
import {ResonseVelocimetroModal, ResponseDetalleVelocimetro} from '../../../../comun/vo/resonseVelocimetroModal';
import {ResponseDetalleObtieneEntregados, ResponseObtieneEntregados} from '../../../../comun/vo/responseObtieneEntregados';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseSelectBodega} from '../../../../comun/vo/responseSelectBodega';
import {ResponseEmpresasTrans, ResponseListEmpresasTrans} from '../../../../comun/vo/ResponseEmpresasTrans';


declare var flexFont: any;
declare var ingresoAmarilloX5: any;
declare var ingresoAmarillo: any;
declare var cargaIngresada: any;
declare var cargaIngresadaX5: any;
declare var cargaPick: any;
declare var pickAbajoArriba: any;
declare var cargaVelocimetro: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}


@Component({
  selector: 'app-dashboard3-administrador',
  templateUrl: './dashboard3-administrador.component.html',
  styleUrls: ['./dashboard3-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player.css', '../../../../../assets/css/player2.css']
})
export class Dashboard3AdministradorComponent implements OnInit {
  @ViewChild('modalDetalleDashboardIngresadas') modalDetalleDashboardIngresadas;
  modalRefDetalleDashboardIngresadas: BsModalRef;
  @ViewChild('modalDetalleEnDespacho') modalDetalleEnDespacho;
  modalRefDetalleEnDespacho: BsModalRef;
  @ViewChild('modalDetalleEnPlazo') modalDetalleEnPlazo;
  modalRefDetalleEnPlazo: BsModalRef;
  @ViewChild('modalDetalleSubEstadosBeetrack') modalDetalleSubEstadosBeetrack;
  modalRefDetalleSubEstadosBeetrack: BsModalRef;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  messageAlert: MensajeAlert;
  ing5: any;
  ingresada: any;
  ingresadaX5: any;
  responseHorizontal: ResponseHorizontal;
  responseAtrasados: ResponseAtrasados;
  detalleAtrasados: DetalleAtrasadosVOS[];
  responseEmitirOrden: ResponseEmitirOrden;
  responseDetalleEmitir: ResponseDetalleEmitir;
  responseIngresadasVO: ResponseIngresadas;
  responseDetalleIngresadas: ResponseDetalleIngresadas;
  responseEntregados: ResponseEntregados;
  responseEntregadosDetalle: ResponseEntregadosDetalle;
  responsePorcentajeCumplimiento: ResponsePorcentajeCumplimiento;
  responsePorcentajeLogrado: ResponsePorcentajeLogrado[];
  responseIngresadasX5: ResponseIngresadasX5;
  responseDetalleIngresadasX5: ResponseDetalleIngresadasX5;
  responseEnPlazo: ResponseEnPlazo;
  responseDetalleEnPlazo: ResponseDetalleEnPlazo;
  page = 1;
  pageSize = 5;
  pageSize2 = 5;
  public selectPrincipalValue: number;
  tipoDVH: any;
  tipoDDC: any;
  tipoDAD: any;
  tipoDADDVH: any;
  tipoSTS: any;
  tipoTodos: any;
  tipoBoton: string;
  tipoPiramide: string;
  responsePiramide: ResponsePiramideDespacho;
  responseDetallePiramide: ResponseDetallePiramide[];
  responsePiramideExcel: ResponsePiramideDespacho;
  responseDetallePiramideExcel: ResponseDetallePiramide[];
  jsonVelo: any;
  jsonVeloPlazo: any;
  responseEntregadas: ResponseEntregadasAtrasadas;
  responseDetalleAtrasados: ResponseDetalleAtrasados[];
  tipoEntregados: any;
  tipoPendientes: any;
  tipoEnPlazo: any;
  tipoPendientesEnPlazo: any;
  tipoSolucion: string;
  responseEntregadosEnPlazo: ResponseEntregadosEnPlazo;
  responseDetalleEntregadosPlazo: ResponseDetalleEntregadosEnPlazo[];
  porEntregar: any;
  _responsePorEntregar: ResponsePorEntregar;
  responseDetallePorEntregar: ResponseDetallePorEntregar;
  responseVelocimetroModal: ResonseVelocimetroModal;
  responseDetalleVelocimetro: ResponseDetalleVelocimetro[];
  responseVelocimetroModalExcel: ResonseVelocimetroModal;
  responseDetalleVelocimetroExcel: ResponseDetalleVelocimetro[];
  @ViewChild('modalDetalleDashboardPorEntregar') modalDetalleDashboardPorEntregar;
  modalRefDetalleDashboardPorEntregar: BsModalRef;
  tipoVelocimetro: string;
  porRecepcionar: any;
  porEmitir: any;
  enPicking: any;
  respondeObtieneEntregados: ResponseObtieneEntregados;
  responseDetalleEntregados: ResponseDetalleObtieneEntregados;
  codTienda: any;
  codBodega: any;
  numBoleta: any;
  numOrden: any;
  fechaDesde: any;
  fechaHasta: any;
  numBoletaSubEstado: any;
  numOrdenSubEstado: any;
  fechaDesdeSubEstado: any;
  fechaHastaSubEstado: any;
  codigoTiendaInicial: any;
  codigoBodegaInicial: any;
  fechaVentaInicial: any;
  fechaCompromisoInicial: any;
  responseSelectBodega: ResponseSelectBodega;
  selectBodegaFiltroPrincipal: any;
  fechaCompromisoFiltroPrincipal: any;
  tipoPiramideTransporte: any;
  numeroRutaInicial: any;
  empresaTransporteInicial: any;
  responseEmpresa: ResponseEmpresasTrans;
  responseEmpresaTransporte: ResponseListEmpresasTrans[];
  transporteFiltroPrincipal: any;
  rutaFiltroPrincipal: any;

  // Grafico de Barra
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  // Grafico de torta
  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  // Grafico de barra
  public barChartLabels: Label[];
  public barChartType: ChartType;
  public barChartLegend: boolean;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[];
// Grafico de torta
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType;
  public pieChartLegend: boolean;
  public pieChartPlugins = [pluginDataLabels];

  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _excelService: ExcelService) { }

  ngOnInit() {
    // Cargar ingresadas
    this.cargaIngresadas5('ingresocC', ingresoAmarilloX5, 'despacho', '', '', '', '');
    // this.cargaIngresadas('ingA', ingresoAmarillo);
    /* Cargar velocimetros */
    // this.cargaPorEntregar('pickB', 'Por Entregar');
    this.graficoBarra2('', '', '', '');
    this.graficoBarra('', '');
    this.graficoTortas2('', '', '', '');
    this.graficoTortas('', '');
    this.cargaPorEntregar('pickB', 'Por Entregar', '', '', '', '');
    // this.cargaPorEntregar('pickB', 'Entregadas');
    this.selectPrincipalValue = 5;
    this.codTienda = '';
    this.codBodega = '';
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
    this.codigoTiendaInicial = '';
    this.codigoBodegaInicial = '';
    this.fechaVentaInicial = '';
    this.fechaCompromisoInicial = '';
    this.selectBodegaFiltroPrincipal = 'n';
    this.fechaCompromisoFiltroPrincipal = '';
    this.obtenerBodegas();
    this.tipoPiramideTransporte = 'despacho';
    this.numeroRutaInicial = '';
    this.empresaTransporteInicial = '';
    this.obtenerEmpresasTransporte();
    this.transporteFiltroPrincipal = 'n';
    this.rutaFiltroPrincipal = '';
  }

  // Grafico de torta
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  obtenerBodegas() {
    this._estadoDashboard.getBodegasSelect()
      .subscribe((data: HttpResponse<any>) => {
        this.responseSelectBodega = data.body;
        console.log('Resultado select bodega: ' + this.responseSelectBodega.codigoBodega);
      });
  }

  obtenerEmpresasTransporte() {
    this._estadoDashboard.getEmpresasTransporte()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEmpresa = data.body;
        this.responseEmpresaTransporte = this.responseEmpresa.responseListTransportistas;
        console.log('Resultado select empresa transporte: ' + this.responseEmpresaTransporte );
      });
  }


  filtroPrincipal() {

    if (this.selectBodegaFiltroPrincipal === 'n' && this.fechaCompromisoFiltroPrincipal === '' && this.rutaFiltroPrincipal === '' && this.transporteFiltroPrincipal === 'n') {
      this.abrirModalError('Debe seleccionar al menos 1 filtro!', 'Error.');
    } else {
      this.responseDetalleIngresadasX5 = null;
      this.responseDetalleEntregados = null;
      this.responseDetalleAtrasados = null;
      this.responsePorcentajeLogrado = null;

      this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
      this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
      this.numeroRutaInicial = this.rutaFiltroPrincipal;
      this.empresaTransporteInicial = this.transporteFiltroPrincipal;
      this.cargaIngresadas5('ingresocC', ingresoAmarilloX5, this.tipoPiramideTransporte , this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
      this.cargaPorEntregar('pickB', 'Entregadas', this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
      this.graficoTortas2(this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
      this.graficoBarra2(this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
    }
  }

  limpiarPrincipal() {

    this.responseDetalleIngresadasX5 = null;
    this.responseDetalleEntregados = null;
    this.responseDetalleAtrasados = null;
    this.responsePorcentajeLogrado = null;

    this.codigoBodegaInicial = '';
    this.fechaCompromisoInicial = '';
    this.selectBodegaFiltroPrincipal = 'n';
    this.fechaCompromisoFiltroPrincipal = '';
    this.numeroRutaInicial = '';
    this.empresaTransporteInicial = '';
    this.rutaFiltroPrincipal = '';
    this.transporteFiltroPrincipal = 'n';

    this.cargaIngresadas5('ingresocC', ingresoAmarilloX5, this.tipoPiramideTransporte , this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
    this.cargaPorEntregar('pickB', 'Entregadas', this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
    this.graficoTortas2(this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
    this.graficoBarra2(this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial));
  }

  ///////// grafico 1: Piramide
  // piramide despacho
  cargaIngresadas5(id: string, color: any, tipoPiramide: any, codigoBodega: any, fecCompromiso: any, ruta: any, empresaTransporte: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';

    this._estadoDashboard.getIngresadasX5(tipoPiramide, this.codigoTiendaInicial, codigoBodega, this.fechaVentaInicial, fecCompromiso,
      ruta, empresaTransporte)
      .subscribe((data: HttpResponse<any>) => {
        this.responseIngresadasX5 = data.body;
        this.responseDetalleIngresadasX5 = this.responseIngresadasX5.responsePiramideEnDespachoVO;
        console.log('RESULTADO INGRESADAS: ');
        console.log(this.responseDetalleIngresadasX5);
        this.ing5 = {
          'grafico': 'ingresoAmarillo',
          'id': id,
          'tope':  '2000000',
          'principal': this.responseDetalleIngresadasX5.hoy,
          'circulo1valor': Number(this.responseDetalleIngresadasX5.dvh),
          'circulo1text': 'Pendiente: ',
          'circulo1url': 'linkDVH',
          'circulo2valor': Number(this.responseDetalleIngresadasX5.ddc),
          'circulo2text': 'No Entregado: ',
          'circulo2url': 'linkDDC',
          'circulo3valor': Number(this.responseDetalleIngresadasX5.dadDvh),
          'circulo3text': 'Entregado (Cliente Recibe Conforme): ',
          'circulo3url': 'linkTO',
          'circulo4valor': Number(this.responseDetalleIngresadasX5.dad),
          'circulo4text': 'Entregado (Retiro en Bodega): ',
          'circulo4url': 'urlLink4',
          'circulo5valor': Number(this.responseDetalleIngresadasX5.sts),
          'circulo5text': 'Entregado (Despachado Operador Logistico): ',
          'circulo5url': 'urlLink5',
          'imgDerecha': 'assets/img/icon_camion_rapido.svg',
          'imgText': 'Transporte',
          'imgUrl': 'rojoLink'
        };
        console.log('PRUEBA PRUEBA: ');
        console.log(this.ing5);
        // cargaIngresada(this.ing5, ingresoAmarilloX5);
      });
  }
  // piramide cambio select
  cambioSelect(registros: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
      || this.tipoSTS !== null || this.tipoTodos !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetallePiramide5(this.tipoBoton, 'despacho', this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
      this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responsePiramide = data.body;
        this.responseDetallePiramide = this.responsePiramide.dataPage;
      });
  }
  // piramide cambio pagina
  cambiarPagina(pagina: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    this.page = pagina;
    if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
      || this.tipoSTS !== null || this.tipoTodos !== null) {
      this._estadoDashboard.getDetallePiramide5(this.tipoBoton, 'despacho', this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
        this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio pagina: ' + this.page);
          console.log('this.pageSize cambio pagina: ' + this.pageSize2);
          this.responsePiramide = data.body;
          this.responseDetallePiramide = this.responsePiramide.dataPage;
        });
    }
  }
  // piramide detalle
  llamadaClickIngresadas(sno) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    switch (sno) {
      case 'linkDVH':
        console.log('aca va DVH');
        this._estadoDashboard.getDetallePiramide5('DVH', 'despacho', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DVH';
            this.tipoPiramide = 'despacho';
          });
        break;
      case 'linkDDC':
        console.log('aca va DDC');
        this._estadoDashboard.getDetallePiramide5('DDC', 'despacho', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DDC';
            this.tipoPiramide = 'despacho';
          });
        break;
      case 'linkTO':
        console.log('aca va DAD-DVH');
        this._estadoDashboard.getDetallePiramide5('DADDVH', 'despacho', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DADDVH';
            this.tipoPiramide = 'despacho';
          });
        break;
      case 'urlLink4':
        console.log('aca va DAD');
        this._estadoDashboard.getDetallePiramide5('DAD', 'despacho', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'DAD';
            this.tipoPiramide = 'despacho';
          });
        break;
      case 'urlLink5':
        console.log('aca va STS');
        this._estadoDashboard.getDetallePiramide5('STS', 'despacho', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
          this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responsePiramide = data.body;
            this.responseDetallePiramide = this.responsePiramide.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'STS';
            this.tipoPiramide = 'despacho';
          });
        break;
      default:
    }
  }
  // modal piramide
  abrirModalIngresadas() {
    console.log('INGRESADAS: ');
    console.log(this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }
  // Excel Piramide
  exportarExcelPiramide() {
    this.spinnerService.show();
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, this.page, 9999999, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
      this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responsePiramideExcel = data.body;
        this.responseDetallePiramideExcel = this.responsePiramideExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetallePiramideExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Compromiso: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': item.nombreTransportista,
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Transporte');
      });
  }
  // buscar piramide
  buscarPiramide() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
      this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
        this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responsePiramide = data.body;
          this.responseDetallePiramide = this.responsePiramide.dataPage;
          this.selectPrincipalValue = 5;
        });
    }
  }
  // limpiar piramide
  limpiarPiramide() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    this._estadoDashboard.getDetallePiramide5(this.tipoBoton, this.tipoPiramide, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial),
      this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responsePiramide = data.body;
        this.responseDetallePiramide = this.responsePiramide.dataPage;
        this.selectPrincipalValue = 5;
        this.numBoletaSubEstado = '';
        this.numOrdenSubEstado = '';
        this.fechaDesdeSubEstado = '';
        this.fechaHastaSubEstado = '';
      });
  }



  ///////// grafico 2: Velocimetro
  // velocimetro despacho
  cargaPorEntregar(id: string, tipo: string, codigoBodega: any, fecCompromiso: any, ruta: any, empresaTransporte: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';

    this._estadoDashboard.getObtenerEntregados(codigoBodega, this.fechaVentaInicial, fecCompromiso,
      ruta, empresaTransporte, this.codigoTiendaInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.respondeObtieneEntregados = data.body;
        this.responseDetalleEntregados = this.respondeObtieneEntregados.responsePorEntregarVO;
        console.log('RESULTADO OBTIENE ENTREGADOS: ');
        console.log(this.responseDetalleEntregados);
        this.porEntregar = {
          'id': 'pickB',
          'grafico': 'updown',
          'tipo': 'porEntregar',
          'principal': Number(this.responseDetalleEntregados.porcetajePrincipal),
          'verde': this.responseDetalleEntregados.numVerde,
          'amarillo': this.responseDetalleEntregados.numAmarillo,
          'rojo': this.responseDetalleEntregados.numRojo,
          'morado': this.responseDetalleEntregados.numMorado,
          'textAmarillo': '1-7 dias',
          'textRojo': this.responseDetalleEntregados.txtRojo + ' dias',
          'textMorado': this.responseDetalleEntregados.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleEntregados.pendientes,
          'circuloDerValor': this.responseDetalleEntregados.completadas,
          'ovaloIzqValor': this.responseDetalleEntregados.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEntregados.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEntregados.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Entregar',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
      });
  }
  // velocimetro cambio select
  cambioSelectEnDespacho(registros: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';
    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
      });
  }
  // velocimetro cambio pagina
  cambiarPaginaEnDespacho(pagina: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';
    this.page = pagina;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
        '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio pagina: ' + this.page);
          console.log('this.pageSize cambio pagina: ' + this.pageSize2);
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        });
    }
  }
  // velocimetro detalle
  llamadaClickPorEntregar(sno2) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';
    switch (sno2) {
      case 'porEntregarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnVerde', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEntregarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnAmarillo', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEntregarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnRojo', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEntregarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'btnMorado', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEntregarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'pendientes', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'porcentajeprincipal', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEntregarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'nuevas', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEntregarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'pendientes', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEntregarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('entregados', 'total', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEntregar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'entregados';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }
  // modal velocimetro
  abrirModalPorEntregar() {
    this.modalRefDetalleDashboardPorEntregar = this.modalService.show(this.modalDetalleDashboardPorEntregar, {class: 'modal-lg'});
  }
  // Excel Velocimetro
  exportarExcelVelocimetro() {
    this.spinnerService.show();
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, 9999999, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModalExcel = data.body;
        this.responseDetalleVelocimetroExcel = this.responseVelocimetroModalExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetalleVelocimetroExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Compromiso: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': item.nombreTransportista,
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Transporte');
      });
  }
  // buscar piramide
  buscarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';

    if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
      this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
        '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
          this.selectPrincipalValue = 5;
        });
    }
  }
  // limpiar piramide
  limpiarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this.fechaVentaInicial = '';

    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, '', this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        this.selectPrincipalValue = 5;
        this.numBoletaSubEstado = '';
        this.numOrdenSubEstado = '';
        this.fechaDesdeSubEstado = '';
        this.fechaHastaSubEstado = '';
      });
  }



  ///////// grafico  3: Torta
  graficoTortas2(codigoBodega: any, fecCompromiso: any, ruta: any, empresaTransporte: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';

    this._estadoDashboard.getDetalleGraficoTorta(this.codigoTiendaInicial, codigoBodega, this.fechaVentaInicial, fecCompromiso,
      ruta, empresaTransporte)
      .subscribe((data: HttpResponse<any>) => {
        this.responseEntregadas = data.body;
        console.log('RESPONSE EBNTRAGADAS: ');
        console.log(data.body);
        this.responseDetalleAtrasados = this.responseEntregadas.responseEntregaAtrasadas;
        console.log('RESPONSE DETALLE ENTREGADOS ATRASADOS: ');
        console.log(this.responseDetalleAtrasados);

        const estado = new Array<Label>();
        const cantidades = new Array<number>();

        this.responseDetalleAtrasados.forEach(item => {
          estado.push(item.estado);
          cantidades.push(item.cantidad + 1);
        });

        console.log('ESTADOS: ');
        console.log(estado);
        console.log('CANTIDADES: ');
        console.log(cantidades);

        this.graficoTortas(estado, cantidades);

        /*
                this.pieChartLabels = estado;
                this.pieChartData = cantidades;
                this.pieChartType = 'pie';
                this.pieChartLegend = false;
                this.pieChartPlugins = [pluginDataLabels];*/
      });
  }

  graficoTortas(estados: any, cnt: any) {
    this.pieChartLabels = estados;
    this.pieChartData = cnt;
    this.pieChartType = 'pie';
    this.pieChartLegend = false;
    this.pieChartPlugins = [pluginDataLabels];
  }
  // ABRIR MODAL SUB ESTADOS BEETRACK (TORTA)
  abrirModalSubEstadoBeetrack() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    // this.modalRefDetalleSubEstadosBeetrack = this.modalService.show(this.modalDetalleSubEstadosBeetrack, {class: 'modal-lg'});

    this._estadoDashboard.obtenerDetalleSubEstadoBeetrack(this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaCompromisoInicial)
      , this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.abrirModalTorta();
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
      });
  }

  abrirModalTorta() {
    this.modalRefDetalleSubEstadosBeetrack = this.modalService.show(this.modalDetalleSubEstadosBeetrack, {class: 'modal-lg'});
  }
  // exportar excel sub estado
  exportarExcelSubEstado() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;
    this._estadoDashboard.obtenerDetalleSubEstadoBeetrack(this.page, 9999999, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaCompromisoInicial),
      this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModalExcel = data.body;
        this.responseDetalleVelocimetroExcel = this.responseVelocimetroModalExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetalleVelocimetroExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Entrega: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': item.nombreTransportista,
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Transporte');
      });
  }
  // filtro buscar sub estado
  buscar() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

    if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde > this.fechaHasta) {
      this.abrirModalError('Fecha Desde no puede ser superior a Fecha Hasta!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
      this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._estadoDashboard.obtenerDetalleSubEstadoBeetrack(1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.validaInput(this.numBoleta), this.validaInput(this.numOrden), this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta),
        this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          console.log('segundo if');
          this.spinnerService.hide();
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        });
    }
  }
  // filtro limpiar sub estado
  limpiar() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.empresaTransporteInicial = this.transporteFiltroPrincipal;

      this._estadoDashboard.obtenerDetalleSubEstadoBeetrack(1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.validaInput(this.numBoleta), this.validaInput(this.numOrden), this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta),
        this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.empresaTransporteInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
          this.numBoletaSubEstado = '';
          this.numOrdenSubEstado = '';
          this.fechaDesdeSubEstado = '';
          this.fechaHastaSubEstado = '';
        });
  }



  ///////// grafico 4: Barra
  graficoBarra2(codigoBodega: any, fecCompromiso: any, ruta: any, empresaTransporte: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';

    this._estadoDashboard.getPorcentajeCumplimiento(this.codigoTiendaInicial, codigoBodega, this.fechaVentaInicial, fecCompromiso, ruta, empresaTransporte)
      .subscribe((data: HttpResponse<any>) => {
        this.responsePorcentajeCumplimiento = data.body;
        console.log('PORCENTAJE CUMPLIMIENTO: ');
        console.log(data.body);
        this.responsePorcentajeLogrado = this.responsePorcentajeCumplimiento.responsePorcentajeLogrado;
        console.log('PORCENTAJE LOGRADO: ');
        console.log(this.responsePorcentajeLogrado);

        const dias = new Array<Label>();
        const cantidades = new Array<any>();
        this.responsePorcentajeLogrado.forEach(item => {
          dias.push(item.dia);
          cantidades.push(item.cantidad);
        });
        console.log('BARTCHARLABERLS');
        console.log(dias);
        console.log('cantidades');
        console.log(cantidades);
        this.graficoBarra(dias, cantidades);
      });
  }

  graficoBarra(dias: any, cantidades: any) {
    this.barChartLabels = dias;
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartPlugins = [pluginDataLabels];
    this.barChartData = [
      // { data: [65, 59, 80, 81, 56, 55, 40, 15, 21, 23, 0, 0, 0, 0], label: 'Cumplimiento',
      { data: cantidades,
        label: 'Cumplimiento',
        backgroundColor: ['rgba(0, 99, 132, 0.6)']},
      // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ];
  }



  ///////// validadores
  // valida input
  validaInput(valor: any) {
    if (valor === '' || valor === 'undefined') {
      return '';
    } else {
      return valor;
    }
  }
  // valida select
  validaSelect(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }
  // validaFecha
  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return anio + '' + mes + '' + dia;
    }
  }
  // Modal Error
  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral, {class: 'modal-plazo3'});
  }

  cerrarModal(modal: string) {
    this + modal;
    this.page = 1;
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



}
