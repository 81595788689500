import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var cargaAnimacion: any;
declare var animaBARRA: any;
declare var flexFont: any;

@Component({
  selector: 'app-cuatro-barras',
  templateUrl: './cuatro-barras.component.html',
  styleUrls: ['./cuatro-barras.component.css']
})
export class CuatroBarrasComponent implements OnInit {

  @Input() public data: any;
  @Output() elClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
    document.getElementById('qbarra1').id = this.data.id + '1';
    document.getElementById('qbarra2').id = this.data.id + '2';
    document.getElementById('qbarra3').id = this.data.id + '3';
    document.getElementById('qbarra4').id = this.data.id + '4';

    cargaAnimacion(this.data.id + '1', this.data.a, animaBARRA);
    cargaAnimacion(this.data.id + '2', this.data.b, animaBARRA);
    cargaAnimacion(this.data.id + '3', this.data.c, animaBARRA);
    cargaAnimacion(this.data.id + '4', this.data.d, animaBARRA);
    flexFont();
  }


  llamadaClick( funAllamar ) {

    this.elClick.emit({ dato: funAllamar});

  }
}
