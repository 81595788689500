import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inicio-proveedor',
  templateUrl: './inicio.component.html',
  styleUrls: ['../../../../../assets/css/koma_inv.css']
 /* styleUrls: ['./inicio.administrador.transportista.component.css']*/
})
export class InicioProveedorComponent implements OnInit {
  rol: string;
  nomUsuario: string;
  fecha: string;
  activacionBoton: boolean;
  intervalBoton: any;

  constructor(private _router: Router) { }

  ngOnInit() {
    this.rol = localStorage.getItem('rol');
    this.nomUsuario = localStorage.getItem('nombre');
    this.fecha = localStorage.getItem('fecha');
    this.activacionBoton = false;
    this.intervalBoton = setInterval(() => {
      this.horaFuncionamiento();
    }, 5000);
  }


  horaFuncionamiento() {
    const horaActual = new Date().getHours();

    if (horaActual >= 6 && horaActual < 17) {
      this.activacionBoton = true;
    } else {
      this.activacionBoton = false;
    }
    console.log(horaActual);
  }

}
