import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inicio-administrador',
  templateUrl: './inicio.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  /*styleUrls: ['./inicio.administrador.transportista.component.css']*/
})
export class InicioAdministradorComponent implements OnInit {
  rol: string;
  nomUsuario: string;
  fecha: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.validarSesion();
    this.rol = localStorage.getItem('rol');
    this.nomUsuario = localStorage.getItem('nombre');
    this.fecha = localStorage.getItem('fecha');

    if (this.rol === 'Ejecutivo') {
      this.router.navigate(['/']);
    }
  }

  validarSesion() {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this.router.navigate(['/']);
    }
  }

}
