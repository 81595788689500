export class ResponseMantTransportistaCompleto {
  transportistaPaginador: ResponseMantTransportista;
  code: any;
  mensaje: any;
}

export class ResponseMantTransportista {
  totalPaginas: number;
  totalElementos: number;
  pagina: number;
  dataPage: DetalleMantTransportista[];
  code: number;
}
export class DetalleMantTransportista {
  registro: number;
  crrTransportista: number;
  nomTransportista: string;
  rutTransportista: string;
  dirTransportista: string;
  telTransportista: string;
  emailTransportista: string;
  codTransportista: string;
}
