import {AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ObtenerDetalleOrdenes} from '../../../../comun/services/ServicioObtenerDetalleOrdenes/obtener.detalle.ordenes.service';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {Router} from '@angular/router';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {TokenService} from '../../../../comun/services/token/token.service';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {CambiarEstadoOrdenes} from '../../../../comun/services/ServicioCambiarEstadoOrdenes/cambiar.estado.ordenes.service';
import {DetalleOrdenCompra, ResponseDetalle} from '../../../../comun/vo/responsedetalle';
import {OrdenCompra, OrdenCompraPaginador, ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {ResponseSelect} from '../../../../comun/vo/responseSelect';
import {ResponseCambioEstado} from '../../../../comun/vo/responseCambioEstado';
import {ResponseError} from '../../../../comun/vo/error';
import {DepartamentoLista} from '../../../../comun/vo/responseDepartamento';
import {ProveedoresLista} from '../../../../comun/vo/responseProveedorSelect';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

class NuevaFecha {
  ordencod: string;
  fecha: string;
  constructor(ordencod: string, fecha: string) {
    this.ordencod = ordencod;
    this.fecha = fecha;
  }
}


@Component({
  selector: 'app-ventas-administrador',
  templateUrl: './ventas-administrador.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  // styleUrls: ['./busqueda-administrador.component.css']
})
export class VentasAdministradorComponent implements OnInit, AfterContentInit {
  public pruebaDate = [];
  public fechaDate: NuevaFecha[] = [];
  public responseDetalle: ResponseDetalle;
  public ordenVO: DetalleOrdenCompra;
  public ordenes: OrdenCompra[];
  public ordenesPendientes: OrdenCompra[];
  public bodegas: any[];
  public proveedores: any[];
  public departamentos: any[];
  private estTodo: any = '1,2,3,4,5,6,9,10,11,12,13,14,15,16,17,18,19,20,21';
  private estPendiente: any = '0';
  private estInformado: any = '1';
  private estActual;
  private estActual2;
  totalProductos: number;
  responseOrden: ResponseOrdenes;
  ordenPaginador: OrdenCompraPaginador;
  pendienteOcCount: number;
  valorSelectActual: number;
  // paginador
  page = 1;
  pageSize = 5;
  // Filtro
  filtroActivo: boolean;
  // tab
  isTabPrincipal: boolean;
  // Select values
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  listaSelect: Array<ResponseSelect> = [];
  private _responseCambioEstado: ResponseCambioEstado;
  rol: string;
  deptoLista: DepartamentoLista;
  proveedorLista: ProveedoresLista;


  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;

  messageAlert: MensajeAlert;


  constructor(private _detalleOrdenService: ObtenerDetalleOrdenes,
              private modalService: BsModalService,
              private _tablaService: ObtenerOrdenesCompra,
              private _router: Router,
              private _excelService: ExcelService,
              private _token: TokenService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _cambiarEstado: CambiarEstadoOrdenes) {
  }

  ngOnInit() {
    if (localStorage.getItem('rol') === 'Ejecutivo') {
      this._router.navigate(['/']);
    }
    this.rol = localStorage.getItem('rol');
    this.validarSesion();
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.obtenerBodegas();
    this.obtenerDepartamentos();
    this.obtenerProveedores();
    this.isTabPrincipal = true;
    this.estActual = this.estTodo;
    this.obtenerDatosProducto(this.estTodo);
    // this.obtenerDatosProductoPendientes('0');
    const nomUsuario = localStorage.getItem('nomUsuario');

    if (nomUsuario === null) {
      localStorage.setItem('nomUsuario', 'Maria de las Mercedez');
      localStorage.setItem('rolUsuario', 'administrador');
    }

    console.log('Select Principal Value: ' + this.selectPrincipalValue);
  }

  ngAfterContentInit() {
    this.spinnerService.show();
  }

  validarSesion() {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this._router.navigate(['/']);
    }
  }
  obtenerDatos(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTabla(this.page, this.pageSize, estado)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado todo:');
        console.log(data);
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }


  obtenerDatosProducto(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, '')
      .subscribe((data: HttpResponse<any>) => {
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        this.spinnerService.hide();
        if (this.totalProductos === undefined) {
          this.totalProductos = this.responseOrden.totalElementos;
        }
        this.pendienteOcCount = this.responseOrden.totalPendienteOC;
        this.ordenPaginador = data.body;
        this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;

        let i;
        for (i = 0; i < this.ordenes.length ; i++) {
          this.pruebaDate.push(false);
          this.fechaDate.push(new NuevaFecha('', ''));
        }

        console.log('resultado');
        console.log(data);
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          this.totalProductos = this.responseOrden.totalElementos;
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosProductoPendientes(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        if (this.totalProductos === undefined) {
          this.totalProductos = data.body.totalElementos;
        }
        this.pendienteOcCount = data.body.totalPendienteOC;
        this.responseOrden = data.body;
        this.ordenesPendientes = this.responseOrden.dataPage;

        let i;
        for (i = 0; i < this.ordenes.length ; i++) {
          this.pruebaDate.push(false);
          this.fechaDate.push(new NuevaFecha('', ''));
        }

        console.log('resultado' + JSON.stringify(data));
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          this.totalProductos = this.responseOrden.totalElementos;
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  formateaRut(rut: any) {
    const rutGuion = rut.indexOf('-');
    if (rutGuion > 0) {
      const rutCompleto = rut;
      return rutCompleto;
    } else {
      const rutsin = rut.slice(0, rut.length - 1);
      const dv = rut.slice(rut.length - 1, rut.length);
      const nuevo = rutsin + '-' + dv;
      return nuevo;
    }
  }

  cambioSelect(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtroProducto();
    } else {
      this.obtenerDatosProducto(this.estActual);
    }
  }

  filtros() {
    this.filtroActivo = true;
    this.page = 1;
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodega').val());

    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    let selectDepartamento = $('#selectDepartamento').val();
    if (selectDepartamento === 'n') {
      selectDepartamento = '';
    }
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();
    const proveedor = $('#selectProveedor').val();

    if (numBoleta === '' && selectDepartamento === 'n'
    && selectBodega === 'n' && fechaDesde === '' && fechaHasta === '' && tipoDespacho === '0' && proveedor === '0') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this._tablaService.getObtenerOrdenesFiltro('1', this.pageSize, this.estActual, numBoleta, this.validaSelectBodegayDepto(selectDepartamento),
        this.validaSelectBodegayDepto(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), this.validaSelect(proveedor))
          .subscribe((data: HttpResponse<any>) => {
            console.log(JSON.stringify(data));
            this.ordenPaginador = data.body;
            this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
            this.ordenes = this.responseOrden.dataPage;
            const tokenfull =  data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            console.log(this.ordenes);
          });
    }
  }

  filtroProducto() {
    this.spinnerService.show();
    this.filtroActivo = true;
    console.log('select bodega: ' + $('#selectBodega').val());
    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    if (numBoleta !== '') {
      this.page = 1;
    }
    let selectDepartamento = $('#selectDepartamento').val();
    if (selectDepartamento === 'n') {
      selectDepartamento = '';
    }
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();
    const proveedor = $('#selectProveedor').val();

    if (numBoleta === '' && selectDepartamento === 'n'
      && selectBodega === 'n' && fechaDesde === '' && fechaHasta === '' && tipoDespacho === '0' && proveedor === '0') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this.spinnerService.show();
      this._tablaService.getObtenerOrdenesProductoFiltro(this.page, this.pageSize, this.estActual, numBoleta, this.validaSelectBodegayDepto(selectDepartamento),
        this.validaSelectBodegayDepto(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), this.validaSelect(proveedor), '', '', 'false')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this.ordenPaginador = data.body;
          this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
          console.log(this.ordenes);
        });
    }
  }


  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  filtrar() {
    if (this.isTabPrincipal) {
      this.filtroProducto();
    } else {
      this.filtroProducto();
    }
  }

  validaBoolean(valor: boolean): any {
    if (valor) {
      return 'true';
    } else {
      return '';
    }
  }

  validaSelect(valor: any): any {
    if (valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaSelectBodegayDepto(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }

  quitarFiltro() {
    this.page = 1;
    this.filtroActivo = false;
    this.obtenerDatosProducto(this.estActual);

    // if (this.isTabPrincipal) {
    // } else {
    //   this.obtenerDatosProducto(this.estActual);
    // }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.filtroProducto();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.filtroProducto();
      }
    }
  }

  fixTab(elemento: string) {
    this.page = 1;
    this.isTabPrincipal = false;
    $('#' + elemento).removeAttr('style');
    if (elemento === 'pOc') {
      this.pageSize = this.selectSecundarioValue;
      this.ordenes = new Array<OrdenCompra>();
      this.estActual = this.estPendiente;
      console.log('THIS.ORDENES: ' + this.ordenes);
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual);
        }
      }
    }

    validaFecha(fecha: string): any {
      if (fecha === '') {
        return '';
      } else {
        const fechaSplit = fecha.split('-');

        const anio = fechaSplit[0];
        const mes = fechaSplit[1];
        const dia = fechaSplit[2];

        return dia + '/' + mes + '/' + anio;
      }
    }

    tabPrincipal() {
      this.isTabPrincipal = true;
      $('#' + 'tabPrincipal').removeAttr('style');
      this.ordenes = [];
      this.estActual = this.estTodo;
      this.pageSize = this.selectPrincipalValue;
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual);
        // this.obtenerDatosProductoPendientes('0');
      }
    }

    recargar() {
      this.filtroActivo = false;
      if (this.isTabPrincipal) {
        this.obtenerDatosProducto(this.estActual);
      } else {
        this.obtenerDatosProducto(this.estActual);
      }
    }

  exportarExcel() {
    this.spinnerService.show();
    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();
    const selectDepartamento = $('#selectDepartamento').val();
    const selectProveedor = $('#selectProveedor').val();



    if (tipoDespacho === '0') {
      this.abrirModalError('Debe seleccionar tipo despacho DDC o DVH.', 'Error.');
      this.spinnerService.hide();
    } else if (tipoDespacho === 'DDC') {
        this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.estActual, numBoleta, this.validaSelectBodegayDepto(selectDepartamento),
          this.validaSelectBodegayDepto(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), 'DDC', this.validaSelect(selectProveedor), '', '', 'false')
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            console.log('Exportar');
            console.log(data);
            this.ordenPaginador = data.body;
            this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
            this.ordenes = this.responseOrden.dataPage;
            const tokenfull = data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            const nuevoArray: Array<any> = [];
            this.responseOrden.dataPage.forEach(
              item => {
                nuevoArray.push(
                  {
                    'Fecha Venta': item.fechaVenta,
                    'Local Venta': item.localVenta,
                    'Glosa Tienda': item.glosaTienda,
                    'N° Boleta': item.numeroBoleta,
                    'N° Caja': item.numCaja,
                    'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                    'Codigo Departamento': item.codigoDepartamento,
                    'Glosa Departamento': item.glosaDepto,
                    'Codigo Corto': item.codigoCorto,
                    'SKU': item.sku,
                    'Codigo de Barra': item.codigoBarra,
                    'Glosa SKU': item.glosaSku,
                    'Cantidad': item.cantidad,
                    'Rut Proveedor': item.rutProveedor,
                    'Razon Social': item.proveedor,
                    'Rut Cliente': item.rutCliente,
                    'Nombre Cliente': item.nombreCliente,
                    'Direccion Cliente': item.direccionCliente,
                    'Ciudad': item.ciudadCliente,
                    'Comuna': item.comuna,
                    'Fono Cliente': item.fonoCliente,
                    'Fono Alternativo': item.fonoAlternativo,
                    'Observacion': item.observacion,
                    'Precio Venta': item.precioVenta,
                    'N° GD': item.numeroGuiaDespacho,
                    'N° OC': item.numOc,
                    'Costo Neto': item.costoNetoProducto,
                    'Fecha Vigencia OC': item.fechaVigenciaOc,
                    'Sucursal Entrega OC': item.codSucursalEntrega,
                    'Tipo Despacho': item.tipoDespacho,
                    'Fecha Agenda': item.fechaAgenda
                  });
              });
            this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_Administrador');
          }, error2 => {
            this.spinnerService.hide();
          });
        $('#selectDespacho').val('0');
      } else if (tipoDespacho === 'DVH') {
        this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.estActual, numBoleta, this.validaSelectBodegayDepto(selectDepartamento),
          this.validaSelectBodegayDepto(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), 'DVH', this.validaSelect(selectProveedor), '', '', 'false')
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            console.log('Exportar');
            console.log(data);
            this.ordenPaginador = data.body;
            this.responseOrden = this.ordenPaginador.ordenCompraPaginador;
            this.ordenes = this.responseOrden.dataPage;
            const tokenfull = data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            const nuevoArray: Array<any> = [];
            this.responseOrden.dataPage.forEach(
              item => {
                nuevoArray.push(
                  {
                    'Fecha Venta': item.fechaVenta,
                    'Local Venta': item.localVenta,
                    'Glosa Tienda': item.glosaTienda,
                    'N° Boleta': item.numeroBoleta,
                    'N° Caja': item.numCaja,
                    'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                    'Fecha Entrega CD': item.fechaEntrega,
                    'Codigo Departamento': item.codigoDepartamento,
                    'Glosa Departamento': item.glosaDepto,
                    'Codigo Corto': item.codigoCorto,
                    'SKU': item.sku,
                    'Codigo de Barra': item.codigoBarra,
                    'Glosa SKU': item.glosaSku,
                    'Cantidad': item.cantidad,
                    'Rut Proveedor': item.rutProveedor,
                    'Razon Social': item.proveedor,
                    'Sucursal Despacho': item.codSucursalDespacho,
                    'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                    'Rut Cliente': item.rutCliente,
                    'Nombre Cliente': item.nombreCliente,
                    'Direccion Cliente': item.direccionCliente,
                    'Ciudad': item.ciudadCliente,
                    'Comuna': item.comuna,
                    'Fono Cliente': item.fonoCliente,
                    'Fono Alternativo': item.fonoAlternativo,
                    'Observacion': item.observacion,
                    'Precio Venta': item.precioVenta,
                    'N° OC': item.numOc,
                    'Costo Neto OC': item.costoNetoOc,
                    'Fecha Vigencia OC': item.fechaVigenciaOc,
                    'Sucursal Entrega OC': item.codSucursalEntrega,
                    'Tipo Despacho': item.tipoDespacho
                  });
              });
            this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DVH_Administrador');
          }, error2 => {
            this.spinnerService.hide();
          });
        $('#selectDespacho').val('0');
      }
  }

  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      console.log(JSON.stringify(data.body));
      this.bodegas = data.body.bodegas;
    });
  }

  obtenerDepartamentos() {
    this._tablaService.getDepartamentos().subscribe((data: HttpResponse<any>) => {
      console.log('Departamentos: ' + JSON.stringify(data.body));
      this.deptoLista = data.body;
      this.departamentos = this.deptoLista.departamentosVOList;
    });
  }

  obtenerProveedores() {
    this._tablaService.getProveedores().subscribe((data: HttpResponse<any>) => {
      console.log('Proveedores lista: ' + JSON.stringify(data.body));
      this.proveedorLista = data.body
      this.proveedores = this.proveedorLista.proveedorVOList;
    });
  }
  changeSelect(estado, orden, registro, cantidadFilas) {
    console.log('CHANGE SELECT: ' + estado + ' ' + orden);
    console.log('FECHA NUEVA: ' + this.fechaDate);

    if (estado !== 0) {
      const responseSelect = new ResponseSelect();
      responseSelect.estado = estado;
      responseSelect.productoSelect = orden;
      responseSelect.registro = registro;
      responseSelect.newFecha = '';
      this.listaSelect.push(responseSelect);
    } else {
      const indexTo = this.listaSelect.indexOf(orden);
      this.listaSelect.splice(indexTo, 1);
    }

    if (estado === '7') {
      this.pruebaDate[Number(registro) - 1] = true;
    } else {
      this.pruebaDate[Number(registro) - 1] = false;
    }
    console.log('DATO: ' + estado);
  }

  public cambiaEstadoProducto() {
    const ordenesCopia = this.ordenes;

    if (this.confirmarEstadoSelect()) {
      this.listaSelect.forEach(item => {

        let newFecha = '';

        this.fechaDate.forEach(nuevoArray => {
          if (Number(nuevoArray.ordencod) === item.productoSelect.codOrden) {
            newFecha = nuevoArray.fecha;
          }
        });

        const estado = item.estado;
        const sku = item.productoSelect.sku;
        const codOc = item.productoSelect.codOrden;
        console.log('valor fecha datepicker: ' + newFecha);

        this._cambiarEstado.getCambioEstadoOrdenProducto(codOc, sku, estado, this.validaFecha(newFecha))
          .subscribe((data: HttpResponse<any>) => {
            this.limpiarSelect();
            console.log(JSON.stringify(data));
            this._responseCambioEstado = data.body;
            console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
            if (this.filtroActivo) {
              this.filtroProducto();
            } else {
              this.obtenerDatosProducto(this.estActual);
            }
            const tokenfull = data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
          });
      });
    }
  }
  public confirmarEstadoSelect () {
    let selectOk: boolean = false;
    // Validación Select
    $('select.selectEstado').each(function(i, sel) {
      console.log('VALOR SELECT: ' + $(sel).val());
      if ($(sel).val() === '0') {
      } else {
        selectOk = true;
      }
    });
    return selectOk;
  }
  limpiarSelect() {
    this.listaSelect = this.listaSelect.splice(0, this.listaSelect.length);
  }

  obtenerFecha(e, orden, fecha) {
    this.fechaDate[e].fecha = fecha;
    this.fechaDate[e].ordencod = orden;
  }
}
