import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ReporteDashboardBodegaService } from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import { ObtieneEstadosDashboardService } from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { ProductosResponse, ResponseBoletasOrdenes, ResponseServiceBuscador } from '../../../../comun/vo/responseServiceBuscador';
import { ResponseServiceBoletasAtrasos } from '../../../../comun/vo/responseServiceBoletaAtrasos';
import { throwError } from 'rxjs';
import { DomSanitizer, SafeResourceUrl, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { BoletaUrl } from 'src/app/comun/vo/boletaUrl';
import { ResponseBoletaUrl } from 'src/app/comun/vo/responseBoletaUrl';
import { ResponseObtenerLista } from 'src/app/comun/vo/responseObtenerLista';


class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-buscador-boletas',
  templateUrl: './buscador-boletas.component.html',
  styleUrls: ['./buscador-boletas.component.css', '../../../../../assets/css/koma.css']
})
export class BuscadorBoletasComponent implements OnInit {
  public messageAlert: MensajeAlert;
  public ocultarDiv: number;
  variable1: string;
  variable2: string;
  variable3: string;
  variable4: string;
  variable5: string;
  public reponseServiceBuscador: ResponseServiceBuscador;
  public responseBoletasOrdenes: ResponseBoletasOrdenes[];
  public responseServiceBoletasAtrasos: ResponseServiceBoletasAtrasos;
  public productosRespuesta: ProductosResponse[];
  public numOrden: any;
  public numBoleta: any;
  public rutCliente: any;
  public numOrdenOpcion: any;
  public numBoletaOpcion: any;
  public rutClienteOpcion: any;
  estadoAS400: any;
  botonDisable: boolean = true;
  botonDisableVerBoleta: boolean = false; //true;
  botonDisableBusRut: boolean = false;  //12-11-2020
  botonDisableBusBol: boolean = false;  //12-11-2020
  botonDisableBusOrd: boolean = false;  //12-11-2020
  srcPdf: any;
  private pdfUrl: SafeResourceUrl;
  public pdfpath: string;




  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;

  @ViewChild('modalAtrasos') modalAtrasos;
  modalRefAtrasos: BsModalRef;

  @ViewChild('modalBoleta') modalBoleta;
  modalRefBoleta: BsModalRef;

  constructor(private modalService: BsModalService,
    private dashboardBodegaService: ObtieneEstadosDashboardService,
    private http: HttpClient,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.ocultarDiv = 0;
    this.variable1 = '1';
    this.variable2 = '2';
    this.variable3 = '3';
    this.variable4 = '4';
    this.variable5 = '5';
    // this.buscadorBoleta();
    this.numBoleta = '';
    this.numOrden = '';
    this.rutCliente = '';
    this.numOrdenOpcion = '';
    this.numBoletaOpcion = '';
    this.rutClienteOpcion = '';
    this.estadoAS400 = '0';
    this.pdfpath = '';
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral, { class: 'modal-buscador' });
  }

  abrirModalAtrasos() {
    this.modalRefAtrasos = this.modalService.show(this.modalAtrasos, { class: 'modal-lg' });
  }

  cambiarVista() {
    if (this.ocultarDiv === 1) {
      this.ocultarDiv = 0;
    } else {
      this.ocultarDiv = 1;
    }
  }

  regresar() {
    this.botonDisableBusBol = false;
    this.botonDisableBusOrd = false;
    this.botonDisableBusRut = false;
    this.numOrdenOpcion = '';
    this.numBoletaOpcion = '';
    if (this.ocultarDiv === 1) {
      this.ocultarDiv = 0;
    } else {
      this.ocultarDiv = 1;
    }
  }

  atrasos() {
    this.abrirModalAtrasos();
  }

  buscadorBoleta() {
    this.numBoleta = this.numBoletaOpcion;
    this.responseServiceBoletasAtrasos = new ResponseServiceBoletasAtrasos;
    if (this.numBoleta === '') {
      this.abrirModalError('Favor enviar un número de boleta a consultar', 'Error.');
    } else {
      this.botonDisableBusBol = true;
      this.dashboardBodegaService.getBuscadorBoletasOrdenes(this.numBoleta, '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.reponseServiceBuscador = data.body;
          if (this.reponseServiceBuscador.code !== 0) {
            this.botonDisableBusBol = false;
            this.abrirModalError(this.reponseServiceBuscador.message, 'Error.');
          } else {
            this.responseBoletasOrdenes = this.reponseServiceBuscador.responseBoletaOrdenVOS;
            console.log("orden:"+this.responseBoletasOrdenes[0].numeroOrden);
            console.log("rut:"+this.responseBoletasOrdenes[0].rutCliente);
            //TODO validar que boleta tenga atraso
            this.dashboardBodegaService.getAtrasosOrdenes(this.responseBoletasOrdenes[0].numeroBoleta)
              .subscribe((data: HttpResponse<any>) => {
                console.log(data.body);
                this.responseServiceBoletasAtrasos = data.body;
                if (Number(this.responseServiceBoletasAtrasos.codigo) !== Number("0")) {
                  this.botonDisable = true;
                } else {
                  this.botonDisable = false;
                }
              });
            this.cambiarVista();

            this.dashboardBodegaService.obtenerUrlPdf(this.responseBoletasOrdenes[0].numeroBoleta)
              .subscribe((responseObtenerLista: ResponseObtenerLista) => {
                console.log("Response urlpdf");
                console.log(responseObtenerLista);

                if (responseObtenerLista.codRespuesta === 0) {
                  this.pdfpath = "";
                  this.pdfpath = responseObtenerLista.listaDocumentoVO[0].urlArchivoPdf;
                  this.botonDisableVerBoleta = false;
                } else {
                  this.pdfpath = "";
                  this.botonDisableVerBoleta = true;
                }
                this.botonDisableBusBol = false;
              });
          }
        });
        

    }
    
  }

  verBoleta() {
    //this.mostrarPdf('https://www.googleapis.com/download/storage/v1/b/hi-boleta-ecologica/o/000106081900100012340000125103%2F000106081900100012340000125103MERCHANDISE_RCP.pdf?generation=1565211487403738&alt=media');
    this.mostrarPdf(this.pdfpath);
  }

  mostrarPdf(pdf: any) {

    const mediaType = 'application/pdf';
    //console.log('PDF URL: ' + pdf);
    const valores = new BoletaUrl();
    valores.url = pdf;

    this.dashboardBodegaService.obtenerUrl(valores)
      .subscribe((responseBoletaUrl: ResponseBoletaUrl) => {

        const pdf1 = responseBoletaUrl.file64;
        const byteArray = new Uint8Array(atob(pdf1).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: mediaType });
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL);
        //document.querySelector("iframe").src = fileURL;
        //window.open(this.srcPdf);
        //this.modalRef = this.modalService.show(template, Object.assign({}, {class: 'gray'}));
        //window.open(fileURL).print();
        //this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
        //this.mostarModal();
      }, err => {
        throwError(err);
      });
  }

  mostarModal() {
    this.modalRefBoleta = this.modalService.show(this.modalBoleta, { class: '' });
  }


  buscadorOrden() {
    this.numOrden = this.numOrdenOpcion;
    if (this.numOrden === '') {
      this.abrirModalError('Favor enviar un número de orden a consultar', 'Error.');
    } else {
      this.botonDisableBusOrd = true;
      this.dashboardBodegaService.getBuscadorBoletasOrdenes('', this.numOrden, '')
        .subscribe((data: HttpResponse<any>) => {
          //agregado lcbh 27-11-2020
          this.reponseServiceBuscador = data.body;
          if (this.reponseServiceBuscador.code !== 0) {
            this.botonDisableBusOrd = false;
            this.abrirModalError(this.reponseServiceBuscador.message, 'Error.');
          } else {
            this.responseBoletasOrdenes = this.reponseServiceBuscador.responseBoletaOrdenVOS;
            this.cambiarVista();
            console.log("boleta:"+this.responseBoletasOrdenes[0].numeroBoleta);
            console.log("rut:"+this.responseBoletasOrdenes[0].rutCliente);
            this.dashboardBodegaService.obtenerUrlPdf(this.responseBoletasOrdenes[0].numeroBoleta)
              .subscribe((responseObtenerLista: ResponseObtenerLista) => {
                console.log("Response urlpdf");
                console.log(responseObtenerLista);

                if (responseObtenerLista.codRespuesta === 0) {
                  this.pdfpath = "";
                  this.pdfpath = responseObtenerLista.listaDocumentoVO[0].urlArchivoPdf;
                  this.botonDisableVerBoleta = false;
                } else {
                  this.pdfpath = "";
                  this.botonDisableVerBoleta = true;
                }
                this.botonDisableBusOrd = false;
              });
          }
          this.botonDisableBusOrd = false;
        });
    }
  }


  buscadorRutCliente() {
    this.rutCliente = this.rutClienteOpcion;
    this.botonDisableBusBol = false;
    if (this.rutCliente === '') {
      this.abrirModalError('Favor enviar rut de cliente a consultar', 'Error.');
    } else {
      this.botonDisableBusRut = true;
      this.dashboardBodegaService.getBuscadorBoletasOrdenes('', '', this.rutCliente)
        .subscribe((data: HttpResponse<any>) => {
          //agregado lcbh 27-11-2020
          this.reponseServiceBuscador = data.body;
          if (this.reponseServiceBuscador.code !== 0) {
            this.abrirModalError(this.reponseServiceBuscador.message, 'Error.');
          } else {
            this.responseBoletasOrdenes = this.reponseServiceBuscador.responseBoletaOrdenVOS;
            this.cambiarVista();
            console.log("boleta:"+this.responseBoletasOrdenes[0].numeroBoleta);
            console.log("orden:"+this.responseBoletasOrdenes[0].numeroOrden);
            console.log("rut:"+this.responseBoletasOrdenes[0].rutCliente);
            this.dashboardBodegaService.obtenerUrlPdf(this.responseBoletasOrdenes[0].numeroBoleta)
              .subscribe((responseObtenerLista: ResponseObtenerLista) => {
                console.log("Response urlpdf");
                console.log(responseObtenerLista);

                if (responseObtenerLista.codRespuesta === 0) {
                  this.pdfpath = "";
                  this.pdfpath = responseObtenerLista.listaDocumentoVO[0].urlArchivoPdf;
                  this.botonDisableVerBoleta = false;
                } else {
                  this.pdfpath = "";
                  this.botonDisableVerBoleta = true;
                }
                this.botonDisableBusRut = false;
              });
          }
          this.botonDisableBusRut = false;
        });
       // this.botonDisableBuscador = false;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  formateaRut(rut) {

    // console.log(rut);

    if (rut.length > 1 && rut.length < 13) {
      let rutPuntos = '';
      const actual = rut.replace(/^0+/, '');
      if (actual !== '' && actual.length > 1) {
        const sinPuntos = actual.replace(/\./g, '');

        // console.log('sin puntos' + sinPuntos);

        const actualLimpio = sinPuntos.replace(/-/g, '');

        // console.log('limpio=' + actualLimpio);

        const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        let i = 0;
        let j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
          const letra = inicio.charAt(i);
          rutPuntos = letra + rutPuntos;
          if (j % 3 === 0 && j <= inicio.length - 1) {
            rutPuntos = '.' + rutPuntos;
          }
          j++;
        }
        const dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + '-' + dv;
        // console.log('rutPuntos=' + rutPuntos);
      }

      this.rutCliente = rutPuntos;
      return rutPuntos;
    } else {
      return rut;
    }
  }

  valrut(objeto) {
    let i, s, f, bueno;
    f = '32765432';
    const r = objeto.value;
    const largo = r.length - 2;
    bueno = false;
    s = 0;
    for (i = 0; i < largo; i++) {
      s = s + (Number(r.charAt(i)) * Number(f.charAt(i)));
    }
    const dv = 11 - (s % 11);
    if (dv === 10 && (r.charAt(9) === 'K' || r.charAt(9) === 'k')) {
      bueno = true;
    } else {
      if (dv === 11 && r.charAt(9) === '0') {
        bueno = true;
      } else {
        if (dv === Number(r.charAt(9))) {
          bueno = true;
        } else {
          alert('RUT Incorrecto...');
          objeto.focus();
          bueno = false;
        }
      }
    }
    return bueno;
  }

}
