import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';



@Injectable()
export class ObtenerOrdenesCompra {

  // private url = 'http://10.107.201.40:8400/vev/obtenerOrdenesCompra';
  // private url2 = 'http://10.107.101.106:8400/vev/obtenerOrdenesCompraProductos';
  // private urlSelects = 'http://10.107.101.106:8400/vev/';
  // private urlEstado = 'http://10.107.101.106:8341/vev/';

  private url = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/obtenerOrdenesCompra';
  private url2 = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/obtenerOrdenesCompraProductos';
  private urlSelects = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/';
  private urlEstado = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/';


  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getOrdenesTabla(pagina: any, numFilas: any, estado: string) {
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas + '&estados=' + estado + '&todo=' + 'true',
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getObtenerOrdenesFiltro(pagina: any, numFilas: any, estados: any, numBoleta: any, codDepartamento: any, codBodega: any
    , fechaDesde: any, fechaHasta: any, tipoDespacho: any, proveedor: any) {
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas +
    '&estados=' + estados + '&numBoleta=' + numBoleta + '&codDepartamento=' + codDepartamento + '&codBodega=' + codBodega +
    '&fechaCompraDesde=' + fechaDesde + '&fechaCompraHasta=' + fechaHasta + '&tipoDespacho=' + tipoDespacho + '&proveedor=' + proveedor,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getOrdenesTablaProducto(pagina: any, numFilas: any, estado: string, codProveedor: string) {
    return this._http.get(this.url2 + '?pagina=' + pagina + '&numFilas=' + numFilas + '&estados=' + estado +
      '&todo=' + 'true' + '&codProveedor=' + codProveedor + '&rol=' + localStorage.getItem('rol'),
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getObtenerOrdenesProductoFiltro(pagina: any, numFilas: any, estados: any, numBoleta: any, codDepartamento: any, codBodega: any
    , fechaDesde: any, fechaHasta: any, tipoDespacho: any, proveedor: any, codProducto: any, numOc: any, todo: any) {
    return this._http.get(this.url2 + '?pagina=' + pagina + '&numFilas=' + numFilas +
      '&estados=' + estados + '&numBoleta=' + numBoleta + '&codDepartamento=' + codDepartamento + '&codBodega=' + codBodega +
      '&fechaCompraDesde=' + fechaDesde + '&fechaCompraHasta=' + fechaHasta + '&tipoDespacho=' + tipoDespacho + '&codProveedor=' +
      proveedor + '&codProducto=' + codProducto + '&numOC=' + numOc + '&rol=' + localStorage.getItem('rol') + '&todo=' + todo,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getObtenerOrdenesProductoFiltroBusqueda(pagina: any, numFilas: any, estados: any, fechaDesde: any, fechaHasta: any, numOc: any, numBoleta: any) {
    if (numOc === undefined) {
      numOc = '';
    }
    if (numBoleta === undefined) {
      numBoleta = '';
    }
    return this._http.get(this.url2 + '?pagina=' + pagina + '&numFilas=' + numFilas +
      '&estados=' + estados + '&numBoleta=' + numBoleta + '&fechaCompraDesde=' +
      fechaDesde + '&fechaCompraHasta=' + fechaHasta + '&numOC=' + numOc + '&codProveedor=' + '' + '&rol=' + localStorage.getItem('rol'),
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getBodegas() {
    return this._http.get(this.urlSelects + 'obtenerBodegas'
      , {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getDepartamentos() {
    return this._http.get(this.urlSelects + 'obtenerDepartamentos',
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getProveedores() {
    return this._http.get(this.urlSelects + 'obtenerProveedores',
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
      , observe: 'response'});
  }

  getEstados(estado: any) {
    return this._http.get(this.urlEstado + 'getEstadosVEV' + '?estados=' + estado,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }
}
