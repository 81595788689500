import { Component, OnInit } from '@angular/core';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap';
import {HttpResponse} from '@angular/common/http';
import {DetalleAtrasadosVOS, ResponseAtrasados, ResponseHorizontal} from '../../../../comun/vo/responseHorizontal';
import {ResponseDetalleIngresadas, ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {ResponseEntregados, ResponseEntregadosDetalle} from '../../../../comun/vo/responseEntregados';
import {ResponseDetalleEmitir, ResponseEmitirOrden} from '../../../../comun/vo/responseEmitirOrden';

import {ChartType, ChartOptions, ChartDataSets} from 'chart.js';
import { SingleDataSet, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

declare var flexFont: any;
declare var ingresoAmarillo: any;
declare var cargaIngresada: any;
declare var cargaPick: any;
declare var pickAbajoArriba: any;
declare var cargaVelocimetro: any;

@Component({
  selector: 'app-detalle-despacho-administrador',
  templateUrl: './detalle-despacho-administrador.component.html',
  styleUrls: ['./detalle-despacho-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player2.css']
})
export class DetalleDespachoAdministradorComponent implements OnInit {

  ingresada: any;
  responseHorizontal: ResponseHorizontal;
  responseAtrasados: ResponseAtrasados;
  detalleAtrasados: DetalleAtrasadosVOS[];
  responseEmitirOrden: ResponseEmitirOrden;
  responseDetalleEmitir: ResponseDetalleEmitir;
  responseIngresadasVO: ResponseIngresadas;
  responseDetalleIngresadas: ResponseDetalleIngresadas;
  responseEntregados: ResponseEntregados;
  responseEntregadosDetalle: ResponseEntregadosDetalle;

  // Grafico de torta
  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  // Grafico de Barra
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  // Grafico de torta
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType;
  public pieChartLegend: boolean;
  public pieChartPlugins = [pluginDataLabels];
  // Grafico de barra
  public barChartLabels: Label[];
  public barChartType: ChartType;
  public barChartLegend: boolean;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[];

  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    // Cargar ingresadas
    this.cargaIngresadas('ingA', ingresoAmarillo);
    /* Cargar velocimetros */
    //this.cargaPorEntregar('pickB', 'Por Entregar');
    this.obtenerEntregados();
    this.obtenerEnPlazo();
    this.graficoTortas();
    this.graficoBarra();
    flexFont();
  }

  // Grafico de torta
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  graficoTortas() {
  this.pieChartLabels = ['Domicilio Cerrado', 'Falta de Dato', 'Anula Compra'];
  this.pieChartData = [10, 20, 30];
  this.pieChartType = 'pie';
  this.pieChartLegend = true;
  this.pieChartPlugins = [pluginDataLabels];
  }

  graficoBarra() {
  this.barChartLabels = ['02-mar', '04-mar', '06-mar', '08-mar', '10-mar', '12-mar', '14-mar', '16-mar', '18-mar', '20-mar', '22-mar', '24-mar', '26-mar', '28-mar', '30-mar'];
  this.barChartType = 'bar';
  this.barChartLegend = true;
  this.barChartPlugins = [pluginDataLabels];
  this.barChartData = [
      { data: [65, 59, 80, 81, 56, 55, 40, 15, 21, 23, 0, 0, 0, 0], label: 'Cumplimiento',
      backgroundColor: ['rgba(0, 99, 132, 0.6)']} //,
      //{ data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ];
  }

  cargaIngresadas(id: string, color: any) {
    this._estadoDashboard.getIngresadas()
      .subscribe((data: HttpResponse<any>) => {
        this.responseIngresadasVO = data.body;
        this.responseDetalleIngresadas = this.responseIngresadasVO.responseEnDespachoVO;
        console.log('RESULTADO INGRESADAS: ');
        console.log(this.responseDetalleIngresadas);
        this.ingresada = {
          'grafico': 'ingresoVerde',
          'id': id,
          'tope':  '1000',//this.responseDetalleIngresadas.topeMaximo,
          'principal': this.responseDetalleIngresadas.despachosPrograDia,
          'circulo1valor': 1, //this.responseDetalleIngresadas.valorDvh,
          'circulo1text': 'DVH : Venta en Verde',
          'circulo1url': 'linkDVH',
          'circulo2valor': 2, //this.responseDetalleIngresadas.valorDdc,
          'circulo2text': 'DDC TEXTO',
          'circulo2url': 'linkDDC',
          'circulo3valor': 3, //this.responseDetalleIngresadas.valorVto,
          'circulo3text': 'Venta en Tienda / Online',
          'circulo3url': 'linkTO',
          'imgDerecha': 'assets/img/icon_camion_rapido.svg',
          'imgText': 'Despacho',
          'imgUrl': 'rojoLink'
        };
        cargaIngresada(this.ingresada, ingresoAmarillo);
        flexFont();
      });
  }

  cargaPorEntregar(id: string, tipo: string) {
    this._estadoDashboard.getPorEntregarDash()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEmitirOrden = data.body;
        this.responseDetalleEmitir = this.responseEmitirOrden.responsePorEntregarVO;
        console.log('RESULTADO POR ENTREGAR: ');
        console.log(this.responseDetalleEmitir);
        const porEntregar = {
          'id': 'pickB',
          'grafico': 'updown',
          'tipo': 'porEntregar',
          'principal': Number(this.responseDetalleEmitir.porcetajePrincipal),
          'verde': this.responseDetalleEmitir.numVerde,
          'amarillo': this.responseDetalleEmitir.numAmarillo,
          'rojo': this.responseDetalleEmitir.numRojo,
          'morado': this.responseDetalleEmitir.numMorado,
          'textAmarillo': this.responseDetalleEmitir.txtAmarillo + ' dias',
          'textRojo': this.responseDetalleEmitir.txtRojo + ' dias',
          'textMorado': this.responseDetalleEmitir.txtMorado + ' dias',
          'titulo': 'Por Entregar',
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleEmitir.pendientes,
          'circuloDerValor': this.responseDetalleEmitir.completadas,
          'ovaloIzqValor': this.responseDetalleEmitir.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEmitir.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEmitir.ovaloDer,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Entregar',
          'ovaloDerLabel': 'de',
          'link1': 'porEntregarLink1',
          'link2': 'porEntregarLink2',
          'link3': 'porEntregarLink3',
          'link4': 'porEntregarLink4',
          'link5': 'porEntregarLink5',
          'link6': 'porEntregarLink6',
          'link7': 'porEntregarLink7',
          'link8': 'porEntregarLink8',
          'link9': 'porEntregarLink9'
        };
        // this.porEntregar = porEntregar;
        cargaPick(porEntregar, pickAbajoArriba);
        flexFont();
      });
  }

  obtenerEntregados() {
    this._estadoDashboard.getEntregados()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEntregados = data.body;
        this.responseEntregadosDetalle = this.responseEntregados.responseEnDespachoVO;
        const response = this.responseEntregadosDetalle;
        console.log('RESULTADO ENTREGADOS: ');
        console.log(response);
        const jsonVelo = {
          'id': 'grafVelocimentro',
          'valor': Number(response.porcentajeEntregadas),
          'titulo': 'En Despacho',
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'azulText': 'Despachados',
          'azulLink': 'http://www.azul.cl',
          'rojoText': 'Por Despachar',
          'rojoLink': 'http://www.rojo.cl'
        };
        cargaVelocimetro(jsonVelo);
        flexFont();
      });
  }

  obtenerEnPlazo() {
    this._estadoDashboard.getEnPlazo()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEntregados = data.body;
        this.responseEntregadosDetalle = this.responseEntregados.responseEnDespachoVO;
        const response = this.responseEntregadosDetalle;
        console.log('RESULTADO EN PLAZO: ');
        console.log(response);
        const jsonVelo = {
          'id': 'grafVelocimentro2',
          'valor': Number(response.porcentajeEnPlazo),
          'titulo': 'En Plazo',
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'azulText': 'En plazo',
          'azulLink': 'http://www.azul.cl',
          'rojoText': 'Atrasados',
          'rojoLink': 'http://www.rojo.cl'
        };
        cargaVelocimetro(jsonVelo);
        flexFont();
      });
  }

}
