import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-proveedor',
  templateUrl: './menu-proveedor.component.html',
  styleUrls: ['../../../assets/css/koma_inv.css']
 /* styleUrls: ['./menu-proveedor.component.css']*/
})
export class MenuProveedorComponent implements OnInit {
  rol: string;
  nomUsuario: string;
  activacionBoton: boolean;
  intervalBoton: any;

  constructor() {
    const nomUsuario = localStorage.getItem('nomUsuario');
    if (nomUsuario === null) {
      localStorage.setItem('nomUsuario', 'Maria de las Mercedez');
      localStorage.setItem('rolUsuario', 'administrador');
    }
    if (this.rol === undefined && this.nomUsuario === undefined) {
      this.rol = localStorage.getItem('rolUsuario');
      this.nomUsuario = localStorage.getItem('nomUsuario');

    }
  }
  ngOnInit() {
    this.activacionBoton = false;
    this.intervalBoton = setInterval(() => {
      this.horaFuncionamiento();
    }, 5000);
  }

  horaFuncionamiento() {
    const horaActual = new Date().getHours();

    if (horaActual >= 6 && horaActual < 17) {
      this.activacionBoton = true;
    } else {
      this.activacionBoton = false;
    }
    console.log(horaActual);
  }

}
