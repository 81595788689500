import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ReporteDashboardBodegaService {

  // private url = 'http://10.107.101.106:8348/vev/';
  // private url2 = 'http://localhost:8341/vev';

  private url = environment.dominioServicios + '/vev/DashboardBodega/';

  private url2 = environment.dominioServicios + '/vev/DashboardBodega';

  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getVelocimetro(tipo: string) {
    return this._http.get(this.url + 'getVelocimetro?tipo=' + tipo);
  }

  getHorizontal() {
    return this._http.get(this.url + 'getHorizontal');
  }

  getIngresadas() {
    return this._http.get(this.url + 'getIngresadas');
  }

  getVelocimetroPorEntregar_estado_dia(pagina: number, filas: number, estado: string, dia: number, orden: string, rut: string) {
    return this._http.get(this.url2 + '/obtenerDetalleVelocimetroPorEntregar/' + pagina + '/' + filas + '/estado/' + estado + '/dia/' + dia + '/filtro/' + orden + '/' + rut,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getVelocimetroPorEntregar_estado(pagina: number, filas: number, estado: string, orden: string, rut: string) {
    return this._http.get(this.url2 + '/obtenerDetalleVelocimetroPorEntregar/' + pagina + '/' + filas + '/estado/' + estado + '/filtro/' + orden + '/' + rut,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getVelocimetroPorEntregar_todo(pagina: number, filas: number, orden: string, rut: string) {
    return this._http.get(this.url2 + '/obtenerDetalleVelocimetroPorEntregar/' + pagina + '/' + filas + '/filtro/' + orden + '/' + rut,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }
}
