import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var cargaAnimacion: any;
declare var ingresoVerdeX3 : any;
declare var ingresoAmarilloX3 : any;
declare var ingresoRojoX3: any;
declare var lottie: any;
declare var flexFont: any;

@Component({
  selector: 'app-ingresada-x3',
  templateUrl: './ingresada-x3.component.html',
  styleUrls: ['./ingresada-x3.component.css']
})
export class IngresadaX3Component implements OnInit {

  @Input() public data: any;
  @Output() elClick = new EventEmitter();

  constructor() { }

  ngOnInit() {

    console.log('DATA KOMA: ' + this.data);


    document.getElementById('contenedorIngreso').id = this.data.id + 'contenedorIngreso';

    let animacion: any;

    switch (this.data.grafico) {
      case 'ingresoVerde':
        animacion = ingresoVerdeX3 ;
        break;
      case 'ingresoRojo':
        animacion = ingresoRojoX3 ;
        break;
      case 'ingresoAmarillo':
        animacion = ingresoAmarilloX3 ;
        break;
      default:
    }

    animacion.op = 101;
    animacion.layers[7].ef[0].ef[0].v.k[0].e[0] = this.data.principal;
    animacion.layers[2].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor;
    animacion.layers[1].ef[0].ef[0].v.k[0].e[0] = this.data.circulo2valor;
    animacion.layers[0].ef[0].ef[0].v.k[0].e[0] = this.data.circulo3valor;
    animacion.layers[8].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor + this.data.circulo2valor + this.data.circulo3valor;
    animacion.layers[3].t.d.k[0].s.t = this.data.tope;

    const params = {
      container: document.getElementById(this.data.id + 'contenedorIngreso'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animacion
    };

    let anim;
    anim = lottie.loadAnimation(params);


  }

  emitterevent(e) {
    this.elClick.emit(e);
  }

}
