import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ResponseDetalleEnPlazo, ResponseEnPlazo} from '../../../../comun/vo/resposeEnPlazo';
import {ResponseDetalleEntregadosEnPlazo, ResponseEntregadosEnPlazo} from '../../../../comun/vo/responseEntregadosEnPlazo';
import {ResponseDetallePorEntregar, ResponsePorEntregar} from '../../../../comun/vo/responsePorEntregar';
import {ResponsePorcentajeCumplimiento, ResponsePorcentajeLogrado} from '../../../../comun/vo/responsePorcentajeCumplimiento';
import {ResonseVelocimetroModal, ResponseDetalleVelocimetro} from '../../../../comun/vo/resonseVelocimetroModal';
import {ResponseDetalleIngresadas, ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {ResponseDetalleObtieneEntregados, ResponseObtieneEntregados} from '../../../../comun/vo/responseObtieneEntregados';
import {ResponseDetallePiramide, ResponsePiramideDespacho} from '../../../../comun/vo/responsePiramideDespacho';
import {ResponseDetalleEmitir, ResponseEmitirOrden} from '../../../../comun/vo/responseEmitirOrden';
import {ResponseEntregados, ResponseEntregadosDetalle} from '../../../../comun/vo/responseEntregados';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {DetalleAtrasadosVOS, ResponseAtrasados, ResponseHorizontal} from '../../../../comun/vo/responseHorizontal';
import {ResponseDetalleAtrasados, ResponseEntregadasAtrasadas} from '../../../../comun/vo/responseEntregadasAtrasadas';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Label, SingleDataSet} from 'ng2-charts';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Router} from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {ResponseDetalleIngresadasX4, ResponseIngresadasX4} from '../../../../comun/vo/responseIngresadasX4';
import {ResponseDetalleTransporte, ResponsePiramideTransporte} from '../../../../comun/vo/responsePiramideTransporte';
import {ResponseDetalleObtieneEnviados, ResponseObtieneEnviados} from '../../../../comun/vo/ResponseObtieneEnviados';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseSelectBodega} from '../../../../comun/vo/responseSelectBodega';
import {ResponseListComunas, ResponseSelectComuna} from '../../../../comun/vo/ResponseSelectComuna';

declare var flexFont: any;
declare var ingresoAmarilloX5: any;
declare var ingresoAmarilloX4: any;
declare var ingresoAmarillo: any;
declare var cargaIngresada: any;
declare var cargaIngresadaX5: any;
declare var cargaPick: any;
declare var pickAbajoArriba: any;
declare var cargaVelocimetro: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-dashboard-transporte-administrador',
  templateUrl: './dashboard-transporte-administrador.component.html',
  styleUrls: ['./dashboard-transporte-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player.css', '../../../../../assets/css/player2.css']
})
export class DashboardTransporteAdministradorComponent implements OnInit {

  @ViewChild('modalDetalleDashboardIngresadas') modalDetalleDashboardIngresadas;
  modalRefDetalleDashboardIngresadas: BsModalRef;
  @ViewChild('modalDetalleEnDespacho') modalDetalleEnDespacho;
  modalRefDetalleEnDespacho: BsModalRef;
  @ViewChild('modalDetalleEnPlazo') modalDetalleEnPlazo;
  modalRefDetalleEnPlazo: BsModalRef;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  messageAlert: MensajeAlert;
  ing4: any;
  ingA: any;
  ingresada: any;
  ingresadaX5: any;
  responseHorizontal: ResponseHorizontal;
  responseAtrasados: ResponseAtrasados;
  detalleAtrasados: DetalleAtrasadosVOS[];
  responseEmitirOrden: ResponseEmitirOrden;
  responseDetalleEmitir: ResponseDetalleEmitir;
  responseIngresadasVO: ResponseIngresadas;
  responseDetalleIngresadas: ResponseDetalleIngresadas;
  responseEntregados: ResponseEntregados;
  responseEntregadosDetalle: ResponseEntregadosDetalle;
  responsePorcentajeCumplimiento: ResponsePorcentajeCumplimiento;
  responsePorcentajeLogrado: ResponsePorcentajeLogrado[];
  responseIngresadasX4: ResponseIngresadasX4;
  responseDetalleIngresadasX4: ResponseDetalleIngresadasX4;
  responseEnPlazo: ResponseEnPlazo;
  responseDetalleEnPlazo: ResponseDetalleEnPlazo;
  page = 1;
  pageSize = 5;
  pageSize2 = 5;
  public selectPrincipalValue: number;
  tipoDVH: any;
  tipoDDC: any;
  tipoDAD: any;
  tipoDADDVH: any;
  tipoSTS: any;
  tipoTodos: any;
  tipoBoton: string;
  tipoPiramide: string;
  responsePiramide: ResponsePiramideDespacho;
  responseDetallePiramide: ResponseDetallePiramide[];
  jsonVelo: any;
  jsonVeloPlazo: any;
  responseEntregadas: ResponseEntregadasAtrasadas;
  responseDetalleAtrasados: ResponseDetalleAtrasados[];
  tipoEntregados: any;
  tipoPendientes: any;
  tipoEnPlazo: any;
  tipoPendientesEnPlazo: any;
  tipoSolucion: string;
  responseEntregadosEnPlazo: ResponseEntregadosEnPlazo;
  responseDetalleEntregadosPlazo: ResponseDetalleEntregadosEnPlazo[];
  porEntregar: any;
  porEnviar: any;
  _responsePorEntregar: ResponsePorEntregar;
  responseDetallePorEntregar: ResponseDetallePorEntregar;
  responseVelocimetroModal: ResonseVelocimetroModal;
  responseDetalleVelocimetro: ResponseDetalleVelocimetro[];
  responseVelocimetroModalExcel: ResonseVelocimetroModal;
  responseDetalleVelocimetroExcel: ResponseDetalleVelocimetro[];
  @ViewChild('modalDetalleDashboardPorEnviar') modalDetalleDashboardPorEnviar;
  modalRefDetalleDashboardPorEnviar: BsModalRef;
  tipoVelocimetro: string;
  porRecepcionar: any;
  porEmitir: any;
  enPicking: any;
  respondeObtieneEntregados: ResponseObtieneEntregados;
  responseDetalleEntregados: ResponseDetalleObtieneEntregados;
  responseTransporte: ResponsePiramideTransporte;
  responseDetalleTransporte: ResponseDetalleTransporte[];
  responseTransporteExcel: ResponsePiramideTransporte;
  responseDetalleTransporteExcel: ResponseDetalleTransporte[];
  responseEnviados: ResponseObtieneEnviados;
  responseDetalleEnviados: ResponseDetalleObtieneEnviados;
  codTienda: any;
  codBodega: any;
  numBoletaSubEstado: any;
  numOrdenSubEstado: any;
  fechaDesdeSubEstado: any;
  fechaHastaSubEstado: any;
  numBoleta: any;
  numOrden: any;
  fechaDesde: any;
  fechaHasta: any;
  codigoTiendaInicial: any;
  codigoBodegaInicial: any;
  fechaVentaInicial: any;
  fechaPickingInicial: any;
  fechaShippingInicial: any;
  fechaCompromisoInicial: any;
  responseSelectBodega: ResponseSelectBodega;
  responseSelectComunas: ResponseSelectComuna;
  responseListComunas: ResponseListComunas[];
  selectBodegaFiltroPrincipal: any;
  fechaCompromisoFiltroPrincipal: any;
  tipoPiramideDespacho: any;
  tipoVelocimetroDespacho: any;
  numeroRutaInicial: any;
  comunaInicial: any;
  empresaTransportistaInicial: any;
  tipoOrdenInicial: any;
  selectComunaFiltroPrincipal: any;
  rutaFiltroPrincipal: any;

  // Grafico de Barra
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  // Grafico de torta
  public pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  // Grafico de barra
  public barChartLabels: Label[];
  public barChartType: ChartType;
  public barChartLegend: boolean;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[];
// Grafico de torta
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType;
  public pieChartLegend: boolean;
  public pieChartPlugins = [pluginDataLabels];

  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _excelService: ExcelService) { }

  ngOnInit() {
    // Cargar ingresadas
    this.cargaIngresadas4('ingresoB', ingresoAmarilloX4, 'transporte', '', '', '', '');
    // this.cargaIngresadas('ingA', ingresoAmarillo);
    /* Cargar velocimetros */
    // this.cargaPorEntregar('pickB', 'Por Entregar');
    this.cargaPorEnviar('pickB', 'Enviadas', 'transporte', '', '', '', '');
    // this.cargaPorEnviar('pickB', 'Enviadas');
    this.selectPrincipalValue = 5;
    this.codTienda = '';
    this.codBodega = '';
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
    this.codigoTiendaInicial = '';
    this.codigoBodegaInicial = '';
    this.fechaVentaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.fechaCompromisoInicial = '';
    this.selectBodegaFiltroPrincipal = 'n';
    this.obtenerBodegas();
    this.fechaCompromisoFiltroPrincipal = '';
    this.tipoPiramideDespacho = 'transporte';
    this.tipoVelocimetroDespacho = 'transporte';
    this.numeroRutaInicial = '';
    this.comunaInicial = '';
    this.empresaTransportistaInicial = '';
    this.tipoOrdenInicial = '';
    this.obtenerComunas();
    this.selectComunaFiltroPrincipal = 'n';
    this.rutaFiltroPrincipal = '';
  }

  // Grafico de torta
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  obtenerBodegas() {
    this._estadoDashboard.getBodegasSelect()
      .subscribe((data: HttpResponse<any>) => {
        this.responseSelectBodega = data.body;
        console.log('Resultado select bodega: ' + this.responseSelectBodega.codigoBodega);
      });
  }

  obtenerComunas() {
    this._estadoDashboard.getComunas()
      .subscribe((data: HttpResponse<any>) => {
        this.responseSelectComunas = data.body;
        this.responseListComunas = this.responseSelectComunas.responseComunas;
        console.log('Resultado select comunas: ' + this.responseListComunas);
      });
  }

  filtroPrincipal() {

    if (this.selectBodegaFiltroPrincipal === 'n' && this.fechaCompromisoFiltroPrincipal === '' && this.rutaFiltroPrincipal === '' && this.selectComunaFiltroPrincipal === 'n') {
      this.abrirModalError('Debe seleccionar al menos 1 filtro!', 'Error.');
    } else {
      this.responseDetalleIngresadasX4 = null;
      this.responseDetalleEnviados = null;

      this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
      this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
      this.numeroRutaInicial = this.rutaFiltroPrincipal;
      this.comunaInicial = this.selectComunaFiltroPrincipal;
      this.cargaIngresadas4('ingresoB', ingresoAmarilloX4, this.tipoPiramideDespacho, this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial));
      this.cargaPorEnviar('pickB', 'Enviadas', this.tipoVelocimetroDespacho, this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial));
    }
  }

  limpiarPrincipal() {
    this.responseDetalleIngresadasX4 = null;
    this.responseDetalleEnviados = null;
    this.codigoBodegaInicial = '';
    this.fechaCompromisoInicial = '';
    this.numeroRutaInicial = '';
    this.selectBodegaFiltroPrincipal = 'n';
    this.fechaCompromisoFiltroPrincipal = '';
    this.tipoPiramideDespacho = 'transporte';
    this.tipoVelocimetroDespacho = 'transporte';
    this.selectComunaFiltroPrincipal = 'n';
    this.comunaInicial = '';
    this.rutaFiltroPrincipal = '';

    this.cargaIngresadas4('ingresoB', ingresoAmarilloX4, this.tipoPiramideDespacho, this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial));
    this.cargaPorEnviar('pickB', 'Enviadas', this.tipoVelocimetroDespacho, this.validaSelect(this.codigoBodegaInicial), this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial));
  }



  // piramide transporte
  cargaIngresadas4(id: string, color: any, tipoPiramide: any, codBodega: any, fecCompromiso: any, ruta: any, comuna: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.tipoOrdenInicial = '';

    this._estadoDashboard.getIngresadasX4(tipoPiramide, this.codigoTiendaInicial, codBodega, this.fechaVentaInicial,
      this.fechaPickingInicial, this.fechaShippingInicial, fecCompromiso, ruta, comuna, this.tipoOrdenInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.responseIngresadasX4 = data.body;
        this.responseDetalleIngresadasX4 = this.responseIngresadasX4.responsePiramideTransporteVO;
        console.log('RESULTADO INGRESADAS: ');
        console.log(this.responseDetalleIngresadasX4);
        this.ingA = {
          'grafico': 'ingresoAmarillo',
          'id': id,
          'tope':  '1000000',
          'principal': Number(this.responseDetalleIngresadasX4.hoy),
          'circulo1valor': Number(this.responseDetalleIngresadasX4.empacado),
          'circulo1text': 'Empacado: ',
          'circulo1url': 'linkEmpacado',
          'circulo2valor': Number(this.responseDetalleIngresadasX4.cargado),
          'circulo2text': 'Cargado: ',
          'circulo2url': 'linkCargado',
          'circulo3valor': Number(this.responseDetalleIngresadasX4.enviado),
          'circulo3text': 'Enviado: ',
          'circulo3url': 'linkEnviado',
          'circulo4valor': Number(this.responseDetalleIngresadasX4.hoy),
          'circulo4text': 'Detalle día: ',
          'circulo4url': 'linkEnBeetrack',
          'imgDerecha': 'assets/img/icon_por_entregar.svg',
          'imgText': 'En Despacho',
          'imgUrl': 'rojoLink'
        };
      });
  }
  // cambio select piramide
  cambioSelect(registros: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
      || this.tipoSTS !== null || this.tipoTodos !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetallePiramide4(this.tipoBoton, 'transporte', this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responseTransporte = data.body;
        this.responseDetalleTransporte = this.responseTransporte.dataPage;
      });
  }
  // cambio pagina piramide
  cambiarPagina(pagina: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    this.page = pagina;
    if (this.tipoDVH !== null || this.tipoDDC !== null || this.tipoDAD !== null || this.tipoDADDVH !== null
      || this.tipoSTS !== null || this.tipoTodos !== null) {
      this._estadoDashboard.getDetallePiramide4(this.tipoBoton, 'transporte', this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio pagina: ' + this.page);
          console.log('this.pageSize cambio pagina: ' + this.pageSize2);
          this.responseTransporte = data.body;
          this.responseDetalleTransporte = this.responseTransporte.dataPage;
        });
    }
  }
  // piramide detalle
  llamadaClickIngresadas(sno) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    switch (sno) {
      case 'linkEmpaque':
        console.log('aca va EMPAQUE');
        this._estadoDashboard.getDetallePiramide4('empaque', 'transporte', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'empaque';
            this.tipoPiramide = 'transporte';
          });
        break;
      case 'linkEmpacado':
        console.log('aca va EMPACADO');
        this._estadoDashboard.getDetallePiramide4('empacado', 'transporte', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'empacado';
            this.tipoPiramide = 'transporte';
          });
        break;
      case 'linkCargado':
        console.log('aca va CARGADO');
        this._estadoDashboard.getDetallePiramide4('cargado', 'transporte', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'cargado';
            this.tipoPiramide = 'transporte';
          });
        break;
      case 'linkEnBeetrack':
        console.log('aca va EN BEETRACK');
        this._estadoDashboard.getDetallePiramide4('hoy', 'transporte', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '', '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
          .subscribe((data: HttpResponse<any>) => {
            this.responseTransporte = data.body;
            this.responseDetalleTransporte = this.responseTransporte.dataPage;
            this.abrirModalIngresadas();
            this.selectPrincipalValue = 5;
            this.tipoBoton = 'hoy';
            this.tipoPiramide = 'transporte';
          });
        break;
      default:
    }
  }
  // modal ingresadas
  abrirModalIngresadas() {
    console.log('INGRESADAS: ');
    console.log(this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }



  // velocimetro transporte
  cargaPorEnviar(id: string, tipo: string, tipoVelocimetro: any, codBodega: any, fecCompromiso: any, ruta: any, comuna: any) {
    this.codigoTiendaInicial = '';
    this.fechaVentaInicial = '';
    this.fechaPickingInicial = '';
    this.fechaShippingInicial = '';
    this.tipoOrdenInicial = '';

    this._estadoDashboard.getObtenerEnviados(tipoVelocimetro, this.codigoTiendaInicial, codBodega, this.fechaVentaInicial,
      this.fechaPickingInicial, this.fechaShippingInicial, fecCompromiso, ruta, comuna, this.tipoOrdenInicial)
      .subscribe((data: HttpResponse<any>) => {
        this.responseEnviados = data.body;
        this.responseDetalleEnviados = this.responseEnviados.responsePorEnviarVO;
        console.log('RESULTADO OBTIENE ENVIADOS: ');
        console.log(this.responseDetalleEnviados);
        this.porEnviar = {
          'id': 'pickB',
          'grafico': 'updown',
          'tipo': 'porEntregar',
          'principal': Number(this.responseDetalleEnviados.porcetajePrincipal),
          'verde': this.responseDetalleEnviados.numVerde,
          'amarillo': this.responseDetalleEnviados.numAmarillo,
          'rojo': this.responseDetalleEnviados.numRojo,
          'morado': this.responseDetalleEnviados.numMorado,
          'textAmarillo': '1-7 dias',
          'textRojo': this.responseDetalleEnviados.txtRojo + ' dias',
          'textMorado': this.responseDetalleEnviados.txtMorado + ' dias',
          'titulo': tipo,
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_por_entregar.svg',
          'tituloColor': 'azul',
          /*azul,fucsia, amarillo, rojo*/
          'circuloIzqValor': this.responseDetalleEnviados.pendientes,
          'circuloDerValor': this.responseDetalleEnviados.completadas,
          'ovaloIzqValor': this.responseDetalleEnviados.ovaloIzq,
          'ovaloCenValor': this.responseDetalleEnviados.ovaloCentro,
          'ovaloDerValor': this.responseDetalleEnviados.ovaloDer2,
          'circuloIzqLabel': 'Pendientes',
          'circuloDerLabel': 'Avance',
          'ovaloIzqLabel': 'Nuevas Ordenes',
          'ovaloCenLabel': 'Por Enviar',
          'ovaloDerLabel': 'de',
          'link1': 'porEnviarLink1',
          'link2': 'porEnviarLink2',
          'link3': 'porEnviarLink3',
          'link4': 'porEnviarLink4',
          'link5': 'porEnviarLink5',
          'link6': 'porEnviarLink6',
          'link7': 'porEnviarLink7',
          'link8': 'porEnviarLink8',
          'link9': 'porEnviarLink9'
        };
        // this.porEntregar = porEntregar;
        // cargaPick(this.porEntregar, pickAbajoArriba);
      });
  }
  // cambio select velocimetro
  cambioSelectEnDespacho(registros: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    this.fechaVentaInicial = '';

    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.fechaVentaInicial, '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
      });
  }
  // cambio pagina velocimetro
  cambiarPaginaEnDespacho(pagina: number) {
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;
    this.fechaVentaInicial = '';
    this.page = pagina;
    if (this.porEmitir !== null || this.porRecepcionar !== null || this.enPicking !== null || this.porEntregar !== null) {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, this.pageSize2, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.fechaDesde, this.fechaHasta, this.validaFecha(this.fechaVentaInicial), '',
        '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio pagina: ' + this.page);
          console.log('this.pageSize cambio pagina: ' + this.pageSize2);
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        });
    }
  }
  // velocimetro detalle
  llamadaClickPorEnviar(sno2) {
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;
    this.fechaVentaInicial = '';
    switch (sno2) {
      case 'porEnviarLink1':
        console.log('aca va rectangulo verde: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'btnVerde', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'btnVerde';
          });
        break;
      case 'porEnviarLink2':
        console.log('aca va rectangulo amarillo: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'btnAmarillo', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'btnAmarillo';
          });
        break;
      case 'porEnviarLink3':
        console.log('aca va rectangulo rojo: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'btnRojo', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'btnRojo';
          });
        break;
      case 'porEnviarLink4':
        console.log('aca va rectangulo morado: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'btnMorado', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'btnMorado';
          });
        break;
      case 'porEnviarLink5':
        console.log('aca va pendientes: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'pendientes', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEnviarLink6':
        console.log('aca va completados: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'porcentajeprincipal', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'porcentajeprincipal';
          });
        break;
      case 'porEnviarLink7':
        console.log('aca va nuevas ordenes: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'nuevas', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'nuevas';
          });
        break;
      case 'porEnviarLink8':
        console.log('aca va pendientes rectangulo: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'pendientes', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'pendientes';
          });
        break;
      case 'porEnviarLink9':
        console.log('aca va total: ');
        this._estadoDashboard.getDetalleVelocimetros('enviados', 'totalreal', this.page, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
          this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
          '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
          .subscribe((data: HttpResponse<any>) => {
            this.responseVelocimetroModal = data.body;
            this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
            this.abrirModalPorEnviar();
            this.selectPrincipalValue = 5;
            this.tipoVelocimetro = 'enviados';
            this.tipoBoton = 'total';
          });
        break;
      default:
    }
  }
  // modal velocimetro
  abrirModalPorEnviar() {
    this.modalRefDetalleDashboardPorEnviar = this.modalService.show(this.modalDetalleDashboardPorEnviar, {class: 'modal-lg'});
  }



  // excel piramide
  exportarExcelPiramide() {
    this.spinnerService.show();
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    this._estadoDashboard.getDetallePiramide4(this.tipoBoton, this.tipoPiramide, this.page, 9999999, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseTransporteExcel = data.body;
        this.responseDetalleTransporteExcel = this.responseTransporteExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetalleTransporteExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Compromiso: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': item.nombreTransportista,
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Despacho');
      });
  }
  // excel velocimetro
  exportarExcelVelocimetro() {
    this.spinnerService.show();
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;
    this.fechaVentaInicial = '';
    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, this.page, 9999999, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModalExcel = data.body;
        this.responseDetalleVelocimetroExcel = this.responseVelocimetroModalExcel.dataPage;
        this.selectPrincipalValue = 5;
        const nuevoArray: Array<any> = [];
        this.responseDetalleVelocimetroExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Número de Orden: ': item.numOrden,
                'Nombre: ': item.nombre,
                'Apellido: ': item.apellido,
                'Rut: ': item.rut,
                'Total: ': item.totalCompra,
                'Número Boleta: ': item.numBoleta,
                'Cantidad de Productos: ': item.cantidadProductos,
                'Fecha Compra: ': item.fechaCompra,
                'Fecha Compromiso: ': item.fechaEntrega,
                'Tipo Despacho: ': item.tipoDespacho,
                'Estado WMS: ': item.descripEstado,
                // Nuevos
                'Local de Venta: ': item.localVenta,
                'Bodega de Despacho: ': item.bodegaDespacho,
                'Fecha Entrega Real: ': item.fechaEntregaReal,
                'Empresa Transporte: ': item.nombreTransportista,
                'Sub Estado Beetrack: ': item.subEstadoBeetrack,
                'Tipo Orden: ': item.tipoVenta,
                'Retiro Bodega: ': item.retiroBodega,
                'Fecha Ingreso CD: ': item.fechaIngresoCD,
                'Fecha Troncal: ': item.fechaTroncal,
                'Comuna: ': item.comuna,
                'Ciudad: ': item.ciudad
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Despacho');
      });
  }
  // cerrar todos
  cerrarModal(modal: string) {
    this + modal;
    this.page = 1;
    this.pageSize = 5;
    this.pageSize2 = 5;
    this.numBoletaSubEstado = '';
    this.numOrdenSubEstado = '';
    this.fechaDesdeSubEstado = '';
    this.fechaHastaSubEstado = '';
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
  }
  // valida fecha
  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return anio + '' + mes + '' + dia;
    }
  }
  // valida select
  validaSelect(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }
  // buscar piramide
  buscarPiramide() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;


    if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
      this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._estadoDashboard.getDetallePiramide4(this.tipoBoton, this.tipoPiramide, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '',
        '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseTransporte = data.body;
          this.responseDetalleTransporte = this.responseTransporte.dataPage;
          this.selectPrincipalValue = 5;
        });
    }
  }
  // limpiar piramide
  limpiarPiramide() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    this._estadoDashboard.getDetallePiramide4(this.tipoBoton, this.tipoPiramide, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseTransporte = data.body;
        this.responseDetalleTransporte = this.responseTransporte.dataPage;
        this.numBoletaSubEstado = '';
        this.numOrdenSubEstado = '';
        this.fechaDesdeSubEstado = '';
        this.fechaHastaSubEstado = '';
      });
  }
  // buscar velocimetro
  buscarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = this.numBoletaSubEstado;
    this.numOrden = this.numOrdenSubEstado;
    this.fechaDesde = this.fechaDesdeSubEstado;
    this.fechaHasta = this.fechaHastaSubEstado;
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;

    this.fechaVentaInicial = '';

    if (this.numBoleta === '' && this.numOrden === '' && this.fechaDesde === '' && this.fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (this.fechaDesde === '' && this.fechaHasta !== '') {
      this.abrirModalError('Debe enviar ambas fechas a consultar!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.codTienda,  this.validaSelect(this.codigoBodegaInicial),
        this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
        '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseVelocimetroModal = data.body;
          this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
          this.selectPrincipalValue = 5;
        });
    }
  }
  // limpiar velocimetro
  limpiarVelocimetro() {
    this.spinnerService.show();
    this.numBoleta = '';
    this.numOrden = '';
    this.fechaDesde = '';
    this.fechaHasta = '';
    this.codigoBodegaInicial = this.selectBodegaFiltroPrincipal;
    this.fechaCompromisoInicial = this.fechaCompromisoFiltroPrincipal;
    this.numeroRutaInicial = this.rutaFiltroPrincipal;
    this.comunaInicial = this.selectComunaFiltroPrincipal;
    this.fechaVentaInicial = '';

    this._estadoDashboard.getDetalleVelocimetros(this.tipoVelocimetro, this.tipoBoton, 1, this.pageSize, this.codTienda, this.validaSelect(this.codigoBodegaInicial),
      this.numBoleta, this.numOrden, this.validaFecha(this.fechaDesde), this.validaFecha(this.fechaHasta), this.validaFecha(this.fechaVentaInicial), '',
      '', '', this.validaFecha(this.fechaCompromisoInicial), this.numeroRutaInicial, this.validaSelect(this.comunaInicial), '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseVelocimetroModal = data.body;
        this.responseDetalleVelocimetro = this.responseVelocimetroModal.dataPage;
        this.numBoletaSubEstado = '';
        this.numOrdenSubEstado = '';
        this.fechaDesdeSubEstado = '';
        this.fechaHastaSubEstado = '';
      });
  }
// Modal Error
  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral, {class: 'modal-plazo3'});
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

}
