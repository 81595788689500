import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Router} from '@angular/router';
import {DetalleLiquidacion, ResponseLiquidacion, ResponseLiquidacionElementos} from '../../../../comun/vo/responseLiquidacion';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ObtenerLiquidacionTransportista} from '../../../../comun/services/ServicioObtenerLiquidacionTransportista/obtener.liquidacion.transportista';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseCamiones, ResponseDetallesCamiones} from '../../../../comun/vo/responseCamiones';
import {ResponseBoletas, ResponseDetalleBoletas} from '../../../../comun/vo/responseBoletas';
import {ResponseEstadoList, ResponseEstadoVO} from '../../../../comun/vo/responseEstadoVO';
import {ResponseError} from '../../../../comun/vo/error';
import {TokenService} from '../../../../comun/services/token/token.service';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-detalle-liquidacion',
  templateUrl: './detalle-liquidacion.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
})
export class DetalleLiquidacionComponent implements OnInit {
  public pagina: string;
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  @ViewChild('modalModificarTransportista') modalModificarTransportista;
  modalRefModificarTransportista: BsModalRef;
  public responseliquidacion: ResponseLiquidacion;
  public responseElementosLiq: ResponseLiquidacionElementos;
  public detalleLiquidacion: DetalleLiquidacion[];
  page = 1;
  pageSize = 5;
  isTabPrincipal: boolean;
  filtroActivo: boolean;
  messageAlert: MensajeAlert;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  public codLiquidCamion: number;
  public codLiquidBoleta: number;
  public reponseCamiones: ResponseCamiones;
  public detalleCamiones: ResponseDetallesCamiones[];
  @ViewChild('modalDetalleTransportistaCamion') modalDetalleTransportistaCamion;
  modalRefDetalleTransportistaCamion: BsModalRef;
  @ViewChild('modalDetalleTransportistaBoleta') modalDetalleTransportistaBoleta;
  modalRefDetalleTransportistaBoleta: BsModalRef;
  public responseBoletas: ResponseBoletas;
  public detalleBoletas: ResponseDetalleBoletas[];
  public nombreTrans: string;
  public valorLiqui: number;
  public numeroLiquidacion: number;
  public responseliquidacionExcel: ResponseLiquidacion;
  public responseElementosLiqExcel: ResponseLiquidacionElementos;
  public detalleLiquidacionExcel: DetalleLiquidacion[];
  estadoLiq: any;
  tipoViajeLiq: any;
  nomTransportistaLiq: any;
  rutTransportistaLiq: any;
  fechaDesdeLiq: any;
  fechaHastaLiq: any;
  codTranspostistaLiq: any;
  numBoletaLiq: any;
  numPatenteLiq: any;
  filtroCamionBoleta: boolean;
  codLiqModal: any;
  responseEstadoVo: ResponseEstadoVO;
  responseEstadoList: ResponseEstadoList[];
  public reponseCamionesExcel: ResponseCamiones;
  public detalleCamionesExcel: ResponseDetallesCamiones[];
  public responseBoletasExcel: ResponseBoletas;
  public detalleBoletasExcel: ResponseDetalleBoletas[];

  constructor(private router: Router,
              private modalService: BsModalService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _obtenerLiquidacion: ObtenerLiquidacionTransportista,
              private _excelService: ExcelService,
              private _token: TokenService,
              private _router: Router) { }

  ngOnInit() {
    this.selectPrincipalValue = 5;
    this.pagina = localStorage.getItem('pagina');
    this.codTranspostistaLiq = localStorage.getItem('codProveedor');
    this.obtenerDatosLiquidacion();
    this.obtenerEstados();
  }

  abrirModalModificar() {
    this.modalRefModificarTransportista = this.modalService.show(this.modalModificarTransportista, {class: 'modal-do3'});
  }

  obtenerDatosLiquidacion() {
    console.log('obtenerDatosLiquidacion()');
    this.spinnerService.show();
    this.estadoLiq = '';
    this.tipoViajeLiq = '';
    this.nomTransportistaLiq = '';
    this.rutTransportistaLiq = '';
    this.fechaDesdeLiq = '';
    this.fechaHastaLiq = '';
    this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.estadoLiq, this.tipoViajeLiq, this.nomTransportistaLiq, this.rutTransportistaLiq,
      this.fechaDesdeLiq, this.fechaHastaLiq, this.codTranspostistaLiq)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseliquidacion = data.body;
        this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
        this.detalleLiquidacion = this.responseElementosLiq.dataPage;
        console.log('resultado liquidaciones: ');
        console.log(this.detalleLiquidacion);
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  cambioSelect(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    this.selectPrincipalValue = registros;
    this.pageSize = this.selectPrincipalValue;

    if (this.filtroActivo) {
      this.filtro();
    } else {
      this.obtenerDatosLiquidacion();
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    if (this.filtroActivo) {
      this.filtro();
      console.log('esta activo');
    } else {
      this.obtenerDatosLiquidacion();
      console.log('no esta activo');
    }
  }

  filtro() {
    this.spinnerService.show();
    this.filtroActivo = true;
    if (this.filtroActivo) {
    } else {
      this.page = 1;
    }
    // Variables para mandar al servicio, se deben validar
    const estado = $('#estado').val();
    console.log('estado');
    console.log(estado);
    const tipoViaje = $('#tipoViaje').val();
    console.log('tipoViaje');
    console.log(tipoViaje);
    const nombreTransportista = $('#nombreTransportista').val();
    console.log('nombreTransportista');
    console.log(nombreTransportista);
    const rutTransportista = $('#rutTransportista').val();
    console.log('rutTransportista');
    console.log(rutTransportista);
    const fechaDesde = $('#date-desde').val();
    console.log('fechaDesde');
    console.log(fechaDesde);
    const fechaHasta = $('#date-hasta').val();
    console.log('fechaHasta');
    console.log(fechaHasta);
    this.estadoLiq = estado;
    this.tipoViajeLiq = tipoViaje;
    this.nomTransportistaLiq = nombreTransportista;
    this.rutTransportistaLiq = rutTransportista;
    this.fechaDesdeLiq = fechaDesde;
    this.fechaHastaLiq = fechaHasta;

    if (estado === '0' && tipoViaje === '0' && nombreTransportista  === '' && rutTransportista === '' &&
      fechaDesde === '' && fechaHasta === '') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
      this.spinnerService.hide();
    } else if (fechaDesde !== '' && fechaHasta !== '') {
      if (fechaDesde > fechaHasta) {
        this.abrirModalError('Fecha Desde no puede ser superior a Fecha Hasta!', 'Error.');
        this.spinnerService.hide();
      }
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
      this.spinnerService.hide();
    } else {
      this._obtenerLiquidacion.getLiquidaciones(this.page, this.pageSize, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
        '', '', this.validaFecha(this.fechaDesdeLiq), this.validaFecha(this.fechaHastaLiq), this.codTranspostistaLiq)
        .subscribe((data: HttpResponse<ResponseLiquidacion>) => {
          this.spinnerService.hide();
          this.responseliquidacion = data.body;
          this.responseElementosLiq = this.responseliquidacion.liquidacionPaginador;
          this.detalleLiquidacion = this.responseElementosLiq.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  recargar() {
    this.obtenerDatosLiquidacion();
  }

  recargarCamion() {
    this.obtenerDatosCamion(this.codLiquidCamion);
  }

  recargarBoleta() {
    this.obtenerDatosBoletas(this.codLiquidBoleta);
  }

  exportarExcel() {
    this.spinnerService.show();
    // Variables para mandar al servicio, se deben validar
    const estado = $('#estado').val();
    console.log('estado');
    console.log(estado);
    const tipoViaje = $('#tipoViaje').val();
    console.log('tipoViaje');
    console.log(tipoViaje);
    const nombreTransportista = $('#nombreTransportista').val();
    console.log('nombreTransportista');
    console.log(nombreTransportista);
    const rutTransportista = $('#rutTransportista').val();
    console.log('rutTransportista');
    console.log(rutTransportista);
    const fechaDesde = $('#date-desde').val();
    console.log('fechaDesde');
    console.log(fechaDesde);
    const fechaHasta = $('#date-hasta').val();
    console.log('fechaHasta');
    console.log(fechaHasta);
    this.estadoLiq = estado;
    this.tipoViajeLiq = tipoViaje;
    this.nomTransportistaLiq = nombreTransportista;
    this.rutTransportistaLiq = rutTransportista;
    this.fechaDesdeLiq = fechaDesde;
    this.fechaHastaLiq = fechaHasta;

    this._obtenerLiquidacion.getLiquidaciones(this.page, 9999999, this.validaSelect(this.estadoLiq), this.validaSelect(this.tipoViajeLiq),
      this.nomTransportistaLiq, this.rutTransportistaLiq, this.validaFecha(this.fechaDesdeLiq), this.validaFecha(this.fechaHastaLiq), this.codTranspostistaLiq)
      .subscribe((data: HttpResponse<ResponseLiquidacion>) => {
        this.spinnerService.hide();
        this.responseliquidacionExcel = data.body;
        this.responseElementosLiqExcel = this.responseliquidacionExcel.liquidacionPaginador;
        this.detalleLiquidacionExcel = this.responseElementosLiqExcel.dataPage;
        const nuevoArray: Array<any> = [];
        this.detalleLiquidacionExcel.forEach(
          item => {
            nuevoArray.push(
              {
                'Nro.': item.registro,
                'Transportista': item.transportista,
                'Total': item.total,
                'Cantidad de Boletas': item.cantidadBoletas,
                'Fecha Desde': item.fechaDesde,
                'Fecha Hasta': item.fechaHasta,
                'Tipo Viaje': item.tipoViaje,
                'Estado': item.nomEstado
              });
          });
        this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista');
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  abrirModalCamion(codLiq: number) {
    this.obtenerDatosCamion(codLiq);
    this.modalRefDetalleTransportistaCamion = this.modalService.show(this.modalDetalleTransportistaCamion, {class: 'modal-lg'});
  }

  abrirModalBoleta(codLiq: number) {
    this.obtenerDatosBoletas(codLiq);
    this.modalRefDetalleTransportistaBoleta = this.modalService.show(this.modalDetalleTransportistaBoleta, {class: 'modal-lg'});
  }

  abrirModalTodos(detalle: DetalleLiquidacion) {
    this.numeroLiquidacion = detalle.crrLiquidacion;
    this.nombreTrans = detalle.transportista;
    this.valorLiqui = detalle.total;
    if (detalle.tipoViaje === 'Viaje Punto A Punto') {
      this.abrirModalCamion(detalle.crrLiquidacion);
      this.filtroCamionBoleta = true;
    } else {
      this.abrirModalBoleta(detalle.crrLiquidacion);
      this.filtroCamionBoleta = false;
    }
  }

  obtenerDatosCamion(codLiq: number) {
    this.codLiquidCamion = codLiq;
    this.codLiqModal = codLiq;
    this.numPatenteLiq = '';
    this.spinnerService.show();
    this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.reponseCamiones = data.body;
        this.detalleCamiones = this.reponseCamiones.camionVOList;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosBoletas(codLiq: number) {
    this.codLiquidCamion = codLiq;
    this.codLiquidBoleta = codLiq;
    this.codLiqModal = codLiq;
    this.numBoletaLiq = '';
    this.spinnerService.show();
    this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseBoletas = data.body;
        this.detalleBoletas = this.responseBoletas.boletaVO;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  validaSelect(valor: any) {
    if (valor === '' || valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaInput(valor: any) {
    if (valor === '') {
      return '';
    } else {
      return valor;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  cerrarModal(modal: string) {
    this.numBoletaLiq = '';
    this.numPatenteLiq = '';
    this.obtenerDatosCamion(this.codLiqModal);
    this.obtenerDatosBoletas(this.codLiqModal);
  }

  filtroModal() {
    const patente = $('#patente').val();
    this.numPatenteLiq = patente;
    const boleta = $('#boleta').val();
    this.numBoletaLiq = boleta;
    if (this.filtroCamionBoleta) {
      console.log('entró camion');
      this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.reponseCamiones = data.body;
          this.detalleCamiones = this.reponseCamiones.camionVOList;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    } else {
      console.log('entró boleta');
      this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseBoletas = data.body;
          this.detalleBoletas = this.responseBoletas.boletaVO;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  recargarModal() {
    if (this.filtroCamionBoleta) {
      this.obtenerDatosCamion(this.codLiquidCamion);
    } else {
      this.obtenerDatosBoletas(this.codLiquidBoleta);
    }
  }

  exportarExcelModal () {
    this.spinnerService.show();
    if (this.filtroCamionBoleta) {
      this._obtenerLiquidacion.getCamiones(this.codLiqModal, this.validaInput(this.numPatenteLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.reponseCamionesExcel = data.body;
          this.detalleCamionesExcel = this.reponseCamionesExcel.camionVOList;
          const nuevoArray: Array<any> = [];
          this.detalleCamionesExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Nro.': item.patente,
                  'Transportista': item.tara,
                  'Total': item.cantidadBoletas,
                  'Cantidad de Boletas': item.valorViaje,
                  'Fecha Desde': item.fechaViaje
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista_Camion');
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    } else {
      this._obtenerLiquidacion.getBoletas(this.codLiqModal, this.validaInput(this.numBoletaLiq))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseBoletasExcel = data.body;
          this.detalleBoletasExcel = this.responseBoletasExcel.boletaVO;
          const nuevoArray: Array<any> = [];
          this.detalleBoletasExcel.forEach(
            item => {
              nuevoArray.push(
                {
                  'Código Local: ': item.codigoLocal,
                  'Número Boleta: ': item.numeroBoleta,
                  'Fecha Boleta: ': item.fechaBoleta,
                  'Código Caja: ': item.codigoCaja,
                  'Comuna: ': item.comuna,
                  'Ciudad: ': item.ciudad,
                  'Valor Flete: ': item.valorFlete
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Liquidación_Transportista_Boleta');
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  imprimirLiquidacion() {
    this.spinnerService.show();
  }

  obtenerEstados() {
    this._obtenerLiquidacion.getEstados()
      .subscribe((data: HttpResponse<any>) => {
        console.log('OBTIENE ESTADOS: ');
        this.responseEstadoVo = data.body;
        this.responseEstadoList = this.responseEstadoVo.estadoVO;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }


}
