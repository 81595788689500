import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';


declare var cargaAnimacion: any;
declare var ingresoVerde : any;
declare var ingresoAmarillo : any;
declare var ingresoRojo: any;
declare var lottie: any;
declare var flexFont: any;

@Component({
  selector: 'app-ingresada',
  templateUrl: './ingresada.component.html',
  styleUrls: ['./ingresada.component.css']
})
export class IngresadaComponent implements OnInit {

  @Input() public data: any;
  @Output() elClick = new EventEmitter();

  constructor() { }

  ngOnInit() {

    console.log('DATA KOMA: ' + this.data);


    document.getElementById('contenedorIngreso').id = this.data.id + 'contenedorIngreso';

    let animacion: any;

    switch (this.data.grafico) {
      case 'ingresoVerde':
      animacion = ingresoVerde ;
          break;
      case 'ingresoRojo':
      animacion = ingresoRojo ;
          break;
      case 'ingresoAmarillo':
      animacion = ingresoAmarillo ;
       break;
      default:
    }

    animacion.op = 101;
    animacion.layers[7].ef[0].ef[0].v.k[0].e[0] = this.data.principal;
    animacion.layers[2].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor;
    animacion.layers[1].ef[0].ef[0].v.k[0].e[0] = this.data.circulo2valor;
    animacion.layers[0].ef[0].ef[0].v.k[0].e[0] = this.data.circulo3valor;
    animacion.layers[8].ef[0].ef[0].v.k[0].e[0] = this.data.circulo1valor + this.data.circulo2valor + this.data.circulo3valor;
    animacion.layers[3].t.d.k[0].s.t = this.data.tope;

    const params = {
        container: document.getElementById(this.data.id + 'contenedorIngreso'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animacion
    };

    let anim;
    anim = lottie.loadAnimation(params);


  }

  emitterevent(e) {
    this.elClick.emit(e);
  }

}
