import {Injectable} from '@angular/core';

@Injectable()
export class TokenService {

  constructor() { }

  actualizaToken(tokenFull: string) {
    const token = tokenFull.split(' ');
    localStorage.setItem('token', token[1]);
  }

}
