import {Component, OnInit, ViewChild} from '@angular/core';
import {ResponseAgenda, ResponseCalendarioVO} from '../../../../comun/vo/responseAgenda';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DatePipe} from '@angular/common';
import {AgendaCalendario} from '../../../../comun/services/ServicioAgendaCalendario/agenda.calendario.service';
import {HttpResponse} from '@angular/common/http';
import {ResponseCabecera, ResponseDetalleAgenda, ResponseDetalleDia} from '../../../../comun/vo/responseDetalleAgenda';
import {ResponseError} from '../../../../comun/vo/error';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {DetalleDiaAgenda} from '../../../../comun/services/ServicioDetalleDiaAgenda/detalle.dia.agenda.service';
import {TokenService} from '../../../../comun/services/token/token.service';
import {Router} from '@angular/router';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseHoraDisponible} from '../../../../comun/vo/responseHoraDisponible';
import {ResponseCambioEstado} from '../../../../comun/vo/responseCambioEstado';
import {CambiarEstadoOrdenes} from '../../../../comun/services/ServicioCambiarEstadoOrdenes/cambiar.estado.ordenes.service';
import {OrdenCompra, ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-agenda-proveedor',
  templateUrl: './agenda-proveedor.component.html',
  styleUrls: ['../../../../../assets/css/koma.css', '../../../../../assets/css/principal.css', '../../../../../assets/css/calendario.css']
  /*styleUrls: ['./agenda-proveedor.component.css']*/
})
export class AgendaProveedorComponent implements OnInit {
  responseAgendaLoad: boolean;
  responseAgendaProveedor: ResponseAgenda;
  public fechaActual: string;
  @ViewChild('modalDetalleOrdenes') modalDetalleOrdenes;
  modalRefDetalleOrden: BsModalRef;
  messageAlert: MensajeAlert;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  public bodegas: any[];
  listaSelectHora: Array<any> = [];
  listaCheckAgenda: Array<any> = [];
  isChecked = false;
  responseOrden: ResponseOrdenes;
  public responseHoraDisponible: ResponseHoraDisponible[];
  @ViewChild('modalConfirmaAgenda') modalConfirmaAgenda;
  modalRefConfirmaAgenda: BsModalRef;
  private _responseCambioEstado: ResponseCambioEstado;
  public ordenes: OrdenCompra[];
  private estActual;
  private estTodo: any = '3,10,13,14,15';
  page = 1;
  pageSize = 5;
  totalProductos: number;
  public mesActualProve: string;
  public fechaActualProve: string;
  private codProveedor: any;
  _responseCalendario: ResponseCalendarioVO;
  _responseCabecera: ResponseCabecera;
  public responseDetalleDia: ResponseDetalleDia;
  responseDetalleAgenda: ResponseDetalleAgenda[];


  constructor(private spinnerService: Ng4LoadingSpinnerService,
              private datePipe: DatePipe,
              private _agendaCalendario: AgendaCalendario,
              private modalService: BsModalService,
              private _detalleDiaAgenda: DetalleDiaAgenda,
              private _token: TokenService,
              private _router: Router,
              private _tablaService: ObtenerOrdenesCompra,
              private _excelService: ExcelService,
              private _cambiarEstado: CambiarEstadoOrdenes,
              private _horaDisponible: DetalleDiaAgenda) { }

  ngOnInit() {
    this.obtenerAgendaCalendario();
    this.obtenerBodegas();
    this.horasDisponiblesCall();
    this.codProveedor = localStorage.getItem('codProveedor');
  }

  abrirModalDetalleOrden(fecha: string) {
    console.log('Fecha: ' + fecha);
    this.fechaActual = fecha;
    this.modalRefDetalleOrden = this.modalService.show(this.modalDetalleOrdenes, {class: 'modal-do2'});
    this._detalleDiaAgenda.getDetalleDiaProveedor(fecha, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseCabecera = data.body;
        this.responseDetalleDia = this._responseCabecera.responseDetalleCabecera;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        this.horasDisponiblesCall();
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  public obtenerAgendaCalendario() {
    this.spinnerService.show();
    const fecha = new Date();
    const fechaFormateada = this.datePipe.transform(fecha, 'yyyy-MM-dd');
    console.log('FECHA: ' + fecha);
    this._agendaCalendario.getCalendarioProveedor(fechaFormateada, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.mesActualProve = data.body.desMesActual;
        this.spinnerService.hide();
        console.log('Response Agenda = ' + JSON.stringify(data));
        console.log('FECHA FORMATEADA: ' + fechaFormateada);
        console.log('Mes actual: ' + data.body.desMesActual);
        this._responseCalendario = data.body;
        this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
        this.responseAgendaLoad = true;
      });
    this.fechaActualProve = fechaFormateada;
  }

  public obtenerAgendaCalendarioProveedor(date, mes) {
    this.spinnerService.show();
    let fecha = date;
    try {
      fecha = fecha.replace(' 00:00:00', '');
      fecha = fecha.replace('.0', '');
      fecha = fecha.replace('/', '-');
    } catch (e) {

    }
    console.log('FECHA: ' + fecha);
    if (mes === this.mesActualProve){
      this._agendaCalendario.getCalendarioProveedor(this.fechaActualProve, this.codProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response Agenda = ' + JSON.stringify(data));
          console.log('Mes actual: ' + data.body.desMesActual);
          this._responseCalendario = data.body;
          this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
          this.responseAgendaLoad = true;
        });
    } else {
      this._agendaCalendario.getCalendarioProveedor(fecha, this.codProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response Agenda = ' + JSON.stringify(data));
          console.log('Mes actual: ' + data.body.desMesActual);
          this._responseCalendario = data.body;
          this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
          this.responseAgendaLoad = true;
        });
    }

  }
  filtroAgenda() {
    this.spinnerService.show();
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodegaAgenda').val());

    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();

    if (selectBodega === 'Seleccionar') {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (selectBodega !== 'Seleccionar') {
      this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor, selectBodega)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCabecera = data.body;
          this.responseDetalleDia = this._responseCabecera.responseDetalleCabecera;
          this.responseDetalleAgenda = this.responseDetalleDia.detalles;
          const tokenfull =  data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        });
    }
  }
  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }
  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      this.bodegas = data.body.bodegas;
    });
  }
  /*exportarExcelAgenda() {
    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();

    // if ($('#selectBodegaAgenda').val() === '0') {
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<ResponseDetalleDia>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this.responseDetalleDia = data.body;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
    const nuevoArray: Array<any> = [];
    this.responseDetalleDia.detalles.forEach(
      item => {
        nuevoArray.push(
          {
            'Número Boleta': item.numBoleta,
            'Número OC': item.numOC,
            'Costo OC': item.costoOC,
            'Estado': item.estado,
            'Fecha Entrega CD': item.fechaEntregaCD,
            'Bodega de Entrega': item.codBodega
          });
      });
    this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Proveedor');
  }*/

  exportarExcelAgenda() {
    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();
    this._tablaService.getObtenerOrdenesProductoFiltro(1, 99999, '3,10,13,14,15', '', '',
      '', '', '', '', this.codProveedor, '', '', this.validaFiltro(selectBodega))
      .subscribe((data: HttpResponse<any>) => {
        let ordenesDVH: any[];
        console.log('DATA EXCEL AGENDA PROVEEDOR:' + data);
        // ordenesDVH = data.body.dataPage;
        // this._responseCabezera = data.body;
        // this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
        ordenesDVH = data.body.ordenCompraPaginador.dataPage;
        // this.ordenes = data.dataPage;
        const nuevoArray: Array<any> = [];
        ordenesDVH.forEach(
          item => {
            nuevoArray.push(
              {
                'Fecha Venta': item.fechaVenta,
                'Local Venta': item.localVenta,
                'Glosa Tienda': item.glosaTienda,
                'N° Caja': item.numCaja,
                'Glosa Estado': item.estado,
                'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                'Codigo Departamento': item.codigoDepartamento,
                'Glosa Departamento': item.glosaDepto,
                'Codigo Corto': item.codigoCorto,
                'SKU': item.sku,
                'Codigo de Barra': item.codigoBarra,
                'Glosa SKU': item.glosaSku,
                'Cantidad': item.cantidad,
                'Rut Proveedor': item.rutProveedor,
                'Razon Social': item.proveedor,
                'Sucursal Despacho': item.codSucursalDespacho,
                'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                'Rut Cliente': item.rutCliente,
                'Nombre Cliente': item.nombreCliente,
                'Direccion Cliente': item.direccionCliente,
                'Ciudad': item.ciudadCliente,
                'Comuna': item.comuna,
                'Fono Cliente': item.fonoCliente,
                'Fono Alternativo': item.fonoAlternativo,
                'Observacion': item.observacion,
                'Precio Venta': item.precioVenta,
                'N° OC': item.numOc,
                'Costo Neto OC': item.costoNetoOC,
                'Fecha Vigencia OC': item.fechaVigenciaOc,
                'Sucursal Entrega OC': item.codSucursalEntrega,
                'Fecha Entrega CD': item.fechaEntregaCD,
                'Fecha Entrega Cliente': item.fechaEntregaCliente,
                'Fecha Agenda': item.fechaAgenda
              });
          });
        this.spinnerService.hide();
        this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Administrador');
      }, error2 => {
        this.spinnerService.hide();
      })
    // this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Proveedor');
  }

  validaFiltro(bodega: string): string {
    if (bodega === 'Seleccionar') {
      return 'true';
    } else {
      return 'false';
    }
  }

  recargarAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabecera = data.body;
        this.responseDetalleDia = this._responseCabecera.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }
  quitarFiltroAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabecera = data.body;
        this.responseDetalleDia = this._responseCabecera.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }
  seleccionarTodoAgenda() {
    const checkeadoTodos = $('#selectAllAgenda').prop('checked');
    if (checkeadoTodos) {
      this.responseDetalleDia.detalles.forEach(item => {
          this.listaCheckAgenda.push(item.registro);
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = true;
      });
    } else {
      this.responseDetalleDia.detalles.forEach(item => {
          const posicion = this.listaCheckAgenda.indexOf(item.registro);
          this.listaCheckAgenda.splice(posicion, 1);
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = false;
      });
    }
  }
  changeSelectHora(hora, registro) {
    console.log('CHANGE SELECT: ' + hora, registro);
    if (hora !== 0) {
      console.log('aaaaa ' + hora + ' - ' + registro);
      const responseSelect = {registro: registro, hora: hora};
      this.listaSelectHora.push(responseSelect);
    } else {
      const indexTo = this.listaSelectHora.indexOf(registro);
      this.listaSelectHora.splice(indexTo, 1);
    }
  }
  changeAgenda(e, type) {
    // console.log(e.target.checked);
    // console.log(type);
    if (e.target.checked === true) {
      this.listaCheckAgenda.push(type);
      this.isChecked = true;
      console.log('CHEQUEADO if ' + this.isChecked.toString());
    } else {
      const index = this.listaCheckAgenda.indexOf(type);
      this.listaCheckAgenda.splice(index, 1);
      console.log('CHEQUEADO else ' + this.isChecked.toString());
    }
  }

  public confirmarProceso() {
    let correcto: boolean = false;
    console.log('lista' + this.listaCheckAgenda.length);
    let checkAgenda: any[] = [];
    const selectAgenda: any[] = [];
    // const selectAgenda = $('#selectAgenda').val();
    // const horaSelect = this.responseHoraDisponible.length;

    if (this.listaCheckAgenda.length === 0 || this.listaCheckAgenda.length === undefined) {
      checkAgenda = [];
    } else {
      $('input[name="checkAgenda[]"]').each( function () {
        checkAgenda.push($(this).prop('checked'));
      });
    }

    // select.selectHora
    $('select[name="selectAgenda[]"]').each(function(i, sel) {
      console.log('valor select: ' + $(sel).val());
      if ($(sel).val() === '0') {
        selectAgenda.push('0');
      } else {
        selectAgenda.push($(sel).val());
      }
    });

    console.log('selectAgenda ' + selectAgenda.length);

    if (this.listaCheckAgenda.length === 0 || this.listaCheckAgenda.length === undefined) {
      console.log('error 1 modal');
      this.abrirModalError('Debe seleccionar al menos una orden y su hora de llegada', 'Error.');
    } else {
      console.log('checkAgenda ' + checkAgenda.length);
      console.log(checkAgenda);
      checkAgenda.forEach( (itemAgenda, i) => {
        console.log('FOR ');
        const checkActual = checkAgenda[i + 1];
        const selectActual = selectAgenda[i];
        if (checkActual && selectActual !== '0') {
          correcto = true;
          console.log('TRUE');
        } /*else {
          if (correcto) {
            if (selectActual !== '0') {
              if (!checkActual) {
                correcto = false;
                console.log('FALSE CORRECTO');
              }
            }
          } else {
            correcto = false;
            console.log('FALSE');
          }
        }*/
      });
      if (correcto) {
        this.abrirModalConfirmaAgenda();
      } else {
        console.log('error 2 modal');
        this.abrirModalError('Debe seleccionar al menos una orden y su hora de llegada', 'Error.');
      }
    }
  }
  abrirModalConfirmaAgenda() {
    this.modalRefConfirmaAgenda = this.modalService.show(this.modalConfirmaAgenda, {class: 'modal-ca'});
  }
  public agendarOrden(fecha) {
    this.spinnerService.show();
    let codOC = '';
    let hora = this.listaSelectHora[0].hora;
    console.log('Array: ' + this.listaCheckAgenda.length);
    let numero = 1;
    this.listaCheckAgenda.forEach((item, index) => {
      console.log('numero: ' + numero);
      this.responseDetalleDia.detalles.forEach(orden => {
        if (orden.registro === item) {
          if (numero < this.listaCheckAgenda.length) {
            numero = numero + 1;
            codOC = codOC +  orden.crrOrden + ';';
          } else {
            numero = numero + 1;
            codOC = codOC + orden.crrOrden;
          }
        }
      });
    });
    console.log('DRAMA: ' + codOC);
    console.log('HORA: ' + hora);
    this._cambiarEstado.getAgendaProveedor(codOC, hora, this.fechaActual)
      .subscribe((data2: HttpResponse<any>) => {
        console.log(JSON.stringify(data2));
        this._responseCambioEstado = data2.body;
        console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
        this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
          .subscribe((data1: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.obtenerAgendaCalendario();
            // this.responseDetalleDia = data1.body;
            this._responseCabecera = data1.body;
            this.responseDetalleDia = this._responseCabecera.responseDetalleCabecera;
            this.responseDetalleAgenda = this.responseDetalleDia.detalles;
            const tokenfull = data1.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            this.obtenerDatosProducto(this.estActual);
            this.modalRefConfirmaAgenda.hide();
          });
      });



    this.listaCheckAgenda.forEach((item, index) => {
    });
  }
  obtenerDatosProducto(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, '')
      .subscribe((data: HttpResponse<ResponseOrdenes>) => {
        this.spinnerService.hide();
        this.responseOrden = data.body;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          // this.totalProductos = data.totalElementos;
        }

      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }
  public horasDisponiblesCall() {
    this._horaDisponible.getHoraDisponible(this.fechaActual)
      .subscribe((data: HttpResponse<ResponseHoraDisponible[]>) => {
        this.responseHoraDisponible = data.body;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        console.log('resultado hora disponible' + JSON.stringify(data));
      });
  }

}
