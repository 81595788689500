import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ObtenerGuiaDespachoService {

  // private url = 'http://10.107.101.106:8400/vev/obtenerGuiaDespacho';

  private url = environment.dominioServicios + '/vev/ObtenerDocumentosTributarios/obtenerGuiaDespacho';


  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getGuiaDespacho(folio: any) {
    return this._http.get(this.url + '?folioDocumeto=' + folio, {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}),
      observe: 'response'
    });
  }
}
