import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {DetalleOrdenCompra, ResponseDetalle} from '../../../../comun/vo/responsedetalle';
import {EstadosDisponibles, OrdenCompra, OrdenCompraPaginador, ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ResponseCambioEstado} from '../../../../comun/vo/responseCambioEstado';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {Router} from '@angular/router';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {Angular2Txt} from 'angular2-txt/Angular2-txt';
import {CambiarEstadoOrdenes} from '../../../../comun/services/ServicioCambiarEstadoOrdenes/cambiar.estado.ordenes.service';
import {ResponseError} from '../../../../comun/vo/error';
import {TokenService} from '../../../../comun/services/token/token.service';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {ResponseCabecera, ResponseDetalleAgenda, ResponseDetalleDia} from '../../../../comun/vo/responseDetalleAgenda';
import {DetalleDiaAgenda} from '../../../../comun/services/ServicioDetalleDiaAgenda/detalle.dia.agenda.service';
import {ResponseHoraDisponible} from '../../../../comun/vo/responseHoraDisponible';
import {ResponseAgenda, ResponseCalendarioVO} from '../../../../comun/vo/responseAgenda';
import {AgendaCalendario} from '../../../../comun/services/ServicioAgendaCalendario/agenda.calendario.service';
import {ResponseSelect} from '../../../../comun/vo/responseSelect';
import index from '@angular/cli/lib/cli';
import {DatePipe} from '@angular/common';


declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

class NuevaFecha {
  ordencod: string;
  fecha: string;
  constructor(ordencod: string, fecha: string) {
    this.ordencod = ordencod;
    this.fecha = fecha;
  }
}

@Component({
  selector: 'app-ordenes-ventas-proveedor',
  templateUrl: './ordenes-ventas-proveedor.component.html',
  styleUrls: ['../../../../../assets/css/koma.css', '../../../../../assets/css/principal.css', '../../../../../assets/css/calendario.css']
  /*styleUrls: ['./ordenes-ventas-proveedor.component.css']*/
})
export class OrdenesVentasProveedorComponent implements OnInit {
  // public pruebaDate: boolean[] = [false, false, false, false, false];
  public todayDate;
  public pruebaDate = [];
  public fechaDate: NuevaFecha[] = [];
  public fechaEntredaCD: string;
  myThenBlock: TemplateRef<any> = null;
  myElseBlock: TemplateRef<any> = null;
  public fechaActual: string;
  public responseDetalle: ResponseDetalle;
  public responseDetalleDia: ResponseDetalleDia;
  public responseHoraDisponible: ResponseHoraDisponible[];
  public responseDetalleAgenda: ResponseDetalleAgenda[];
  public ordenVO: DetalleOrdenCompra;
  public ordenes: OrdenCompra[];
  public bodegas: any[];
  public bodegaAgenda: any[];
  private estTodo: any = '1,2,3,4,5,6,9,10,11,13,14,15,16,17,18,19,20';
  private codProveedor: any;
  private estInformado: any = 1;
  private estNoInformado: any = '0';
  private estActual;
  totalProductos: number;
  responseOrden: ResponseOrdenes;
  responseOrden2: ResponseOrdenes;
  pendienteOcCount: number;
  // paginador
  page = 1;
  pageSize = 5;
  // Filtro
  filtroActivo: boolean;
  // tab
  isTabPrincipal: boolean;
  listaCheck: Array<any> = [];
  listaSelect: Array<ResponseSelect> = [];
  listaSelectHora: Array<any> = [];
  listaCheckAgenda: Array<any> = [];
  isChecked = false;
  nBoleta: any;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  @ViewChild('modalAgenda') modalAgenda;
  modalRefAgenda: BsModalRef;
  @ViewChild('modalDetalleOrdenes') modalDetalleOrdenes;
  modalRefDetalleOrden: BsModalRef;
  @ViewChild('modalConfirmaAgenda') modalConfirmaAgenda;
  @ViewChild('modalEstados') modalEstados;
  modalRefEstados: BsModalRef;
  modalRefConfirmaAgenda: BsModalRef;
  @ViewChild('firstThenBlock')
  firstThenBlock: TemplateRef<any> = null;
  @ViewChild('secondThenBlock')
  secondThenBlock: TemplateRef<any> = null;

  @ViewChild('firstElseBlock')
  firstElseBlock: TemplateRef<any> = null;
  @ViewChild('secondElseBlock')
  secondElseBlock: TemplateRef<any> = null;

  private _responseCambioEstado: ResponseCambioEstado;
  private ordenesDDC: any[];
  private ordenesDVH: any[];
  public selectHora: any;

  // Selects cantidad de registros
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;

  messageAlert: MensajeAlert;
  // Objeto para llenar el calendario
  responseAgendaProveedor: ResponseAgenda;

  responseAgendaLoad: boolean;
  public mesActualProve: string;
  public fechaActualProve: string;
  public codProveedorLogin: string;
  _responseCalendario: ResponseCalendarioVO;
  _ordenCompraPaginador: OrdenCompraPaginador;
  _responseCabezeraAgenda: ResponseCabecera;
  public pageProducto;
  activacionBoton: boolean;
  intervalBoton: any;


  constructor(private modalService: BsModalService,
              private _tablaService: ObtenerOrdenesCompra,
              private _router: Router,
              private _excelService: ExcelService,
              private _cambiarEstado: CambiarEstadoOrdenes,
              private _token: TokenService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _detalleDiaAgenda: DetalleDiaAgenda,
              private _agendarProveedor: CambiarEstadoOrdenes,
              private _horaDisponible: DetalleDiaAgenda,
              private _agendaCalendario: AgendaCalendario,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.responseAgendaLoad = false;
    // this.obtenerAgendaCalendario('141', fecha);
    if (localStorage.getItem('rol') === 'Administrador') {
      this._router.navigate(['/']);
    }
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.obtenerBodegas();
    this.isTabPrincipal = true;
    this.estActual = this.estTodo;
    this.codProveedorLogin = localStorage.getItem('codProveedor');
    this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    this.spinnerService.show();
    this.horasDisponiblesCall();
    this.myThenBlock = this.firstThenBlock;
    this.myThenBlock = this.secondThenBlock;
    this.myElseBlock = this.firstElseBlock;
    this.myThenBlock = this.secondElseBlock;
    this.codProveedor = localStorage.getItem('codProveedor');
    this.activacionBoton = false;
    this.intervalBoton = setInterval(() => {
      this.horaFuncionamiento();
    }, 5000);
  }

  desSeleccionarTodo(){
    this.listaCheck = new Array<any>();
    $('input[name="check[]"]').each(function () {
      this.checked = false;
    });
  }

  seleccionarTodo() {
    const checkeadoTodos = $('#selectAll').prop('checked');
    const checkeadoInfo = $('#selectAllInfo').prop('checked');
    let numero = 0;
    if (checkeadoTodos || checkeadoInfo) {
      this.ordenes.forEach(item => {
        numero = numero + 1;
        if  (item.estadoProducto === 1) {
          this.listaCheck.push(numero);
        }
      });
      this.isChecked = true;
      $('input[name="check[]"]').each(function () {
        this.checked = true;
      });
    } else {
      this.ordenes.forEach(item => {
        numero = numero + 1;
        if (item.estadoProducto === 1) {
          const posicion = this.listaCheck.indexOf(numero);
          this.listaCheck.splice(posicion, 1);
        }
      });
      $('input[name="check[]"]').each(function () {
        this.checked = false;
      });
    }
  }

  horaFuncionamiento() {
    const horaActual = new Date().getHours();

    if (horaActual >= 9 && horaActual < 17) {
      this.activacionBoton = true;
    } else {
      this.activacionBoton = false;
    }
    console.log(horaActual + ' + activacionBoton ' + this.activacionBoton);
  }


  seleccionarTodoAgenda() {
    const checkeadoTodos = $('#selectAllAgenda').prop('checked');
    if (checkeadoTodos) {
      this.responseDetalleDia.detalles.forEach(item => {
        this.listaCheckAgenda.push(item.registro);
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = true;
      });
    } else {
      this.responseDetalleDia.detalles.forEach(item => {
        const posicion = this.listaCheckAgenda.indexOf(item.registro);
        this.listaCheckAgenda.splice(posicion, 1);
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = false;
      });
    }
  }

  obtenerDatos(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTabla(this.page, this.pageSize, estado)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._ordenCompraPaginador = data.body;
        this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado todo:' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (estado === this.estNoInformado && this.pendienteOcCount === undefined) {
          // this.pendienteOcCount = data.totalElementos;
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosProducto(estado: string, codProveedor: string) {
    this.spinnerService.show();
    this.fechaDate = new Array<NuevaFecha>();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._ordenCompraPaginador = data.body;
        this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
        this.ordenes = this.responseOrden.dataPage;
        let i;
        for (i = 0; i < this.ordenes.length ; i++) {
          this.pruebaDate.push(false);
          this.fechaDate.push(new NuevaFecha(this.ordenes[i].codOrden.toString(), ''));
        }
        console.log('FechaDate abajo');
        console.log(this.fechaDate);
        console.log('resultado');
        console.log(data);
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          // this.totalProductos = data.totalElementos;
        }

      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  formateaRut(rut: any) {
    const rutGuion = rut.indexOf('-');
    if (rutGuion > 0) {
      const rutCompleto = rut;
      return rutCompleto;
    } else {
      const rutsin = rut.slice(0, rut.length - 1);
      const dv = rut.slice(rut.length - 1, rut.length);
      const nuevo = rutsin + '-' + dv;
      return nuevo;
    }
  }

  cambioSelect(registros: number) {
    this.page = 1;
    this.desSeleccionarTodo();
    console.log('Registros: ' + registros);
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtroProducto();
    } else {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    }
  }

  filtros(paginas: boolean) {
    this.spinnerService.show();
    this.filtroActivo = true;
    this.page = 1;
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodega').val());
    this.fechaDate = new Array<NuevaFecha>();

    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();

    if (numBoleta === '' && selectBodega === '0' && fechaDesde === '' && fechaHasta === '' && tipoDespacho === '0' && !paginas) {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.spinnerService.hide();
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this._tablaService.getObtenerOrdenesFiltro(this.page, this.pageSize, this.estActual, numBoleta, '',
        this.validaSelectBodega(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), this.codProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._ordenCompraPaginador = data.body;
          this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
          console.log(this.ordenes);
        });
    }
  }

  filtroProducto() {
    this.spinnerService.show();
    this.filtroActivo = true;
    this.desSeleccionarTodo();
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodega').val());
    this.fechaDate = new Array<NuevaFecha>();

    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    if (numBoleta !== '') {
      this.page = 1;
    }
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();

    if (numBoleta === '' && selectBodega === 'n' && fechaDesde === '' && fechaHasta === '' && tipoDespacho === '0') {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (fechaDesde === '' && fechaHasta !== '') {
      this.spinnerService.hide();
      this.abrirModalError('Debe ingresar la fecha desde para el filtro entre fechas', 'Error.');
    } else {
      this._tablaService.getObtenerOrdenesProductoFiltro(this.page, this.pageSize, this.estActual, numBoleta, '',
        this.validaSelectBodega(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), this.codProveedor, '', '', 'false')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._ordenCompraPaginador = data.body;
          this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;

          for (let i = 0; i < this.ordenes.length ; i++) {
            this.pruebaDate.push(false);
            this.fechaDate.push(new NuevaFecha(this.ordenes[i].codOrden.toString(), ''));
          }
          console.log('FechaDate abajo');
          console.log(this.fechaDate);
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
          console.log(this.ordenes);
        });
    }
  }

  filtroAgenda() {
    this.spinnerService.show();
    // console.log('filtro 48: ' + $('#check48').is(':checked'));
    console.log('select bodega: ' + $('#selectBodegaAgenda').val());

    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();

    if (selectBodega === 'Seleccionar') {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else {
      this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor, selectBodega)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCabezeraAgenda = data.body;
          this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
          this.responseDetalleAgenda = this.responseDetalleDia.detalles;
          const tokenfull =  data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        });
    }
  }

  filtrar() {
    if (this.isTabPrincipal) {
      this.filtroProducto();
    } else {
      this.filtroProducto();
    }
  }

  validaBoolean(valor: boolean): any {
    if (valor) {
      return 'true';
    } else {
      return '';
    }
  }

  validaSelect(valor: any): any {
    if (valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaSelectBodega(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }

  quitarFiltro() {
    this.desSeleccionarTodo();
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    } else {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    }
  }

  quitarFiltroAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabezeraAgenda = data.body;
        this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    this.fechaDate = new Array<NuevaFecha>();
    this.desSeleccionarTodo();
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
      } else {
        this.filtroProducto();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
      } else {
        this.filtroProducto();
      }
    }
  }

  fixTab(elemento: string) {
    this.page = 1;
    this.isTabPrincipal = false;
    this.ordenes = [];
    $('#' + elemento).removeAttr('style');
    if (!this.isTabPrincipal) {
      this.pageSize = this.selectSecundarioValue;
      this.estActual = this.estNoInformado;
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
      }
    } else {
      this.pageSize = this.selectPrincipalValue;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  tabPrincipal() {
    this.isTabPrincipal = true;
    $('#' + 'tabPrincipal').removeAttr('style');
    this.ordenes = [];
    this.estActual = this.estTodo;
    this.pageSize = this.selectPrincipalValue;
    if (this.filtroActivo) {
      this.filtroProducto();
    } else {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    }
  }

  recargar() {
    this.desSeleccionarTodo();
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    } else {
      this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
    }
  }

  recargarAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabezeraAgenda = data.body;
        this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  change(e, type) {
    console.log('E: ' + e);
    console.log('type: ' + type);
    if (e.target.checked === true) {
      this.listaCheck.push(type);
      this.isChecked = true;
      console.log('CHEQUEADO if ' + this.isChecked.toString());
    } else {
      const index = this.listaCheck.indexOf(type);
      this.listaCheck.splice(index, 1);
      console.log('CHEQUEADO else ' + this.isChecked.toString());

    }
    // console.log(this.listaCheck.toString());
    // console.log(this.listaCheck.length);
  }

  changeSelect(estado, orden, registro, cantidadFilas) {
    console.log('CHANGE SELECT: ' + estado + ' ' + orden);
    console.log('FECHA NUEVA: ' + this.fechaDate);

    if (estado !== 0) {
      const responseSelect = new ResponseSelect();
      responseSelect.estado = estado;
      responseSelect.productoSelect = orden;
      responseSelect.registro = registro;
      responseSelect.newFecha = '';
      this.listaSelect.push(responseSelect);
    } else {
      const indexTo = this.listaSelect.indexOf(orden);
      this.listaSelect.splice(indexTo, 1);
    }

    if (estado === '8') {
      this.pruebaDate[Number(registro) - 1] = true;
    } else {
      this.pruebaDate[Number(registro) - 1] = false;
    }
    console.log('DATO: ' + estado);
  }

  changeSelectHora(hora, registro) {
    console.log('CHANGE SELECT: ' + hora, registro);
    if (hora !== 0) {
      const responseSelect = {registro: registro, hora: hora};
      this.listaSelectHora.push(responseSelect);
    } else {
      const indexTo = this.listaSelectHora.indexOf(registro);
      this.listaSelectHora.splice(indexTo, 1);
    }
  }

  exportarExcel() {
    this.spinnerService.show();
    // Variables para mandar al servicio, se deben validar
    const numBoleta = $('#ordenId').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();
    const tipoDespacho = $('#selectDespacho').val();

    const listaCodProducto: Array<string> = [];
    const listaTipoDespacho: Array<string> = [];


    if ($('#selectDespacho').val() === '0') {
      this.abrirModalError('Debe seleccionar tipo despacho DDC o DVH.', 'Error.');
      this.spinnerService.hide();
    } else if ($('#selectDespacho').val() === 'DDC') {
      this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.estActual, numBoleta, '',
        this.validaSelectBodega(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho),
        this.codProveedor, '', '', 'false')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._ordenCompraPaginador = data.body;
          this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;
          this.ordenesDDC = this.ordenes;
          const nuevoArray: Array<any> = [];
          this.ordenesDDC.forEach(
            item => {
              nuevoArray.push(
                {
                  'Fecha Venta': item.fechaVenta,
                  'Local Venta': item.localVenta,
                  'Glosa Tienda': item.glosaTienda,
                  'N° Boleta': item.numeroBoleta,
                  'N° Caja': item.numCaja,
                  'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                  'Codigo Departamento': item.codigoDepartamento,
                  'Glosa Departamento': item.glosaDepto,
                  'Codigo Corto': item.codigoCorto,
                  'SKU': item.sku,
                  'Codigo de Barra': item.codigoBarra,
                  'Glosa SKU': item.glosaSku,
                  'Cantidad': item.cantidad,
                  'Rut Proveedor': item.rutProveedor,
                  'Razon Social': item.proveedor,
                  'Rut Cliente': item.rutCliente,
                  'Nombre Cliente': item.nombreCliente,
                  'Direccion Cliente': item.direccionCliente,
                  'Ciudad': item.ciudadCliente,
                  'Comuna': item.comuna,
                  'Fono Cliente': item.fonoCliente,
                  'Fono Alternativo': item.fonoAlternativo,
                  'Observacion': item.observacion,
                  'Precio Venta': item.precioVenta,
                  'N° GD': item.numeroGuiaDespacho,
                  'N° OC': item.numOc,
                  'Costo Neto': item.costoNetoProducto,
                  'Fecha Vigencia OC': item.fechaVigenciaOc,
                  'Sucursal Entrega OC': item.codSucursalEntrega,
                  'Tipo Despacho': item.tipoDespacho,
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_Proveedor');
          $('#selectDespacho').val('0');
        }, error2 => {
          this.spinnerService.hide();
        });

    } else if ($('#selectDespacho').val() === 'DVH') {
      console.log('Export DVH');
      this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.estActual, numBoleta, '',
        this.validaSelect(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho),
        this.codProveedor, '', '', 'false')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._ordenCompraPaginador = data.body;
          this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
          this.ordenes = this.responseOrden.dataPage;
          this.ordenesDVH = this.ordenes;
          // this.ordenes = data.dataPage;
          const nuevoArray: Array<any> = [];
          this.ordenesDVH.forEach(
            item => {
              nuevoArray.push(
                {
                  'Fecha Venta': item.fechaVenta,
                  'Local Venta': item.localVenta,
                  'Glosa Tienda': item.glosaTienda,
                  'N° Boleta': item.numeroBoleta,
                  'N° Caja': item.numCaja,
                  'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                  'Fecha Entrega CD': item.fechaEntrega,
                  'Codigo Departamento': item.codigoDepartamento,
                  'Glosa Departamento': item.glosaDepto,
                  'Codigo Corto': item.codigoCorto,
                  'SKU': item.sku,
                  'Codigo de Barra': item.codigoBarra,
                  'Glosa SKU': item.glosaSku,
                  'Cantidad': item.cantidad,
                  'Rut Proveedor': item.rutProveedor,
                  'Razon Social': item.proveedor,
                  'Sucursal Despacho': item.codSucursalDespacho,
                  'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                  'Rut Cliente': item.rutCliente,
                  'Nombre Cliente': item.nombreCliente,
                  'Direccion Cliente': item.direccionCliente,
                  'Ciudad': item.ciudadCliente,
                  'Comuna': item.comuna,
                  'Fono Cliente': item.fonoCliente,
                  'Fono Alternativo': item.fonoAlternativo,
                  'Observacion': item.observacion,
                  'Precio Venta': item.precioVenta,
                  'N° OC': item.numOc,
                  'Costo Neto OC': item.costoNetoOc,
                  'Fecha Vigencia OC': item.fechaVigenciaOc,
                  'Sucursal Entrega OC': item.codSucursalEntrega,
                  'Tipo Despacho': item.tipoDespacho
                });
            });
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DVH_Proveedor');
          $('#selectDespacho').val('0');
        }, error2 => {
          this.spinnerService.hide();
        });
    }
  }

  exportarExcelAgenda() {
    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();

    // if ($('#selectBodegaAgenda').val() === '0') {
    this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabezeraAgenda = data.body;
        this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
    const nuevoArray: Array<any> = [];
    this.responseDetalleDia.detalles.forEach(
      item => {
        nuevoArray.push(
          {
            'Número Boleta': item.numBoleta,
            'Número OC': item.numOC,
            'Costo OC': item.costoOC,
            'Estado': item.estado,
            'Fecha Entrega CD': item.fechaEntregaCD,
            'Bodega de Entrega': item.codBodega
          });
      });
    this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Proveedor');
  }

  // }

  dataText() {
    const nuevoArray: Array<any> = [];
    this.responseOrden = this._ordenCompraPaginador.ordenCompraPaginador;
    this.ordenes = this.responseOrden.dataPage;
    this.ordenes.forEach(
      item => {
        nuevoArray.push(
          {
            // '': 'Número Boleta;' + 'Numero OC;' + 'Numero Caja;' + 'Fecha Venta;' + 'Local Venta;' + 'Sku;' + 'Cantidad;' + 'Costo;'
            // + 'Fecha Compromiso;' + 'Fecha Entrega CD;' + 'Fecha Despacho Cliente;' + 'Rut Proveedor;' + 'Razon Social;'
            // + 'Sucursal Despacho;' + 'Tipo Despacho;' + 'Estado;',
            ' ': +item.numeroBoleta + '|' + item.numOc + '|' + item.numCaja + '|'
            + item.fechaVenta + '|' + item.localVenta + '|' + item.sku + '|' + item.glosaSku + '|'
            + item.cantidad + '|' + item.costoNetoOc + '|' + item.fechaCompromisoCliente + '|' + item.fechaCompromisoDespacho + '|'
            + item.fechaCompromisoCliente + '|' + item.rutProveedor + '|' + item.proveedor + '|' + item.codSucursalDespacho + '|'
            + item.tipoDespacho + '|' + item.estado
          });
      });
    return nuevoArray;
  }

  exportarTxt() {
    const options = {
      fieldSeparator: '\r\n',
      quoteStrings: '',
      decimalseparator: '.',
      showTitle: false,
      useBom: true
    };
    const dato = new Angular2Txt(this.dataText(), 'Ventas_Proveedor', options);
  }
  public cambiaEstadoProducto() {
    const ordenesCopia = this.ordenes;
    console.log('cambiaEstadoProducto');
    console.log('FechaDate abajo');
    console.log(this.fechaDate);

    if (this.listaCheck.length === 0 && !this.confirmarEstadoSelect()) {
      this.abrirModalError('Debe seleccionar al menos una orden.', 'Error.');
    } else if (this.listaCheck.length > 0) {
      this.listaCheck.forEach(item => {
        let sku;
        let codOc;
        let estado;
        let newFecha = '';
        let registro;
        console.log('Ordenes abajo' + item);
        console.log(this.ordenes);
        console.log('Orden Buscada: ' + this.ordenes[item - 1].codOrden);
        this.fechaDate.forEach(nuevoArray => {
          if (Number(nuevoArray.ordencod) === this.ordenes[item - 1].codOrden) {
            console.log('Orden Encontrada: ' + this.ordenes[item - 1].codOrden)
            newFecha = nuevoArray.fecha;
            registro = this.ordenes[item - 1].registro;
          }
        });

        this.ordenes.forEach(orden => {
          if (orden.registro === Number(registro)) {
            sku = orden.sku;
            estado = this.obtenerCodEstado(orden.estado);
            codOc = orden.codOrden;
          }
        });

          this._cambiarEstado.getCambioEstadoOrdenProducto(codOc, sku, estado,  this.validaFecha(newFecha))
            .subscribe((data: HttpResponse<ResponseCambioEstado>) => {
            console.log(JSON.stringify(data));
            this.limpiarCheck();
            this._responseCambioEstado = data.body;
            console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
            if (this.filtroActivo) {
              this.filtroProducto();
            } else {
              this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
            }
            this.limpiarCheck();
            const tokenfull = data.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
          });
      });
    } else if (this.confirmarEstadoSelect()) {
          this.listaSelect.forEach(item => {

            let newFecha = '';

            this.fechaDate.forEach(nuevoArray => {
              if (Number(nuevoArray.ordencod) === item.productoSelect.codOrden) {
                newFecha = nuevoArray.fecha;
              }
            });

            const estado = item.estado;
            const sku = item.productoSelect.sku;
            const codOc = item.productoSelect.codOrden;

            this._cambiarEstado.getCambioEstadoOrdenProducto(codOc, sku, estado, this.validaFecha(newFecha))
              .subscribe((data: HttpResponse<ResponseCambioEstado>) => {
                this.limpiarSelect();
              console.log(JSON.stringify(data));
              this._responseCambioEstado = data.body;
              console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
              if (this.filtroActivo) {
                this.filtroProducto();
              } else {
                this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
              }
              this.limpiarCheck();
              const tokenfull = data.headers.get('Authorization');
              this._token.actualizaToken(tokenfull);
            });
          });
    }
  }

  public cambiaEstadoSiguiente(codOc, estadoSiguiente) {
    this._cambiarEstado.getCambioEstadoOrdenProducto(codOc, '', estadoSiguiente, '').subscribe((data: HttpResponse<ResponseCambioEstado>) => {
      console.log(JSON.stringify(data));
      this._responseCambioEstado = data.body;
      console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
      if (this.filtroActivo) {
        this.filtroProducto();
      } else {
        this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
      }
      this.limpiarCheck();
      const tokenfull = data.headers.get('Authorization');
      this._token.actualizaToken(tokenfull);
    });
  }


  public confirmarEstadoSelect () {
    let selectOk: boolean = false;
    // Validación Select
    $('select.selectEstado').each(function(i, sel) {
      console.log('I SELECT: ' + $(sel).val());
      console.log('SEL SELECT: ' + $(sel).val());
      console.log('VALOR SELECT: ' + $(sel).val());
      if ($(sel).val() === '0') {
      } else {
        selectOk = true;
      }
    });
    return selectOk;
  }

  checkedEstados() {
    if (this.listaCheck.length === 0) {
      this.abrirModalError('Debe seleccionar al menos una orden.', 'Error.');
    } else {
      this.listaCheck.forEach(item => {
        let estado;
        this.ordenes.forEach(orden => {
          if (orden.registro === item) {
            estado = orden.estadoProducto;
          }
        });
        if (estado === 1) {
          this.cambiaEstadoProducto();
        } else if (estado > 1) {
          this.abrirModalEstados();
        }
        this.limpiarCheck();
      });
    }
  }

  limpiarCheck() {
    this.listaCheck = [];
  }

  limpiarSelect() {
    this.listaSelect = [];
  }

  public obtenerCodEstado(estadoActual: string) {
    let codigoEstado = null;

    switch (estadoActual) {

      case 'Pendiente OC':
        codigoEstado = 0;
        break;

      case 'Informada':
        codigoEstado = 1;
        break;

      case 'En Proceso':
        codigoEstado = 2;
        break;

      case 'Por Agendar':
        codigoEstado = 3;
        break;

      case 'Agendada':
        codigoEstado = 4;
        break;

      case 'Por Despacho':
        codigoEstado = 5;
        break;

      case 'En Despacho':
        codigoEstado = 6;
        break;

      case 'Entregado CD':
        codigoEstado = 7;
        break;

      case 'Entregado Cliente':
        codigoEstado = 8;
        break;
    }
    if (codigoEstado !== 8) {
      return codigoEstado + 1;
    } else {
      return null;
    }
  }

  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      this.bodegas = data.body.bodegas;
    });
  }

  abrirModalAgenda(fecha) {
    this.obtenerAgendaCalendario(fecha);
    this.modalRefAgenda = this.modalService.show(this.modalAgenda, {class: 'modal-lg'});
  }

  abrirModalEstados() {
    this.modalRefEstados = this.modalService.show(this.modalEstados, {class: 'modal-ca'});
  }

  abrirModalDetalleOrden(fecha) {
    console.log('FechaRequest: ' + fecha);
    this.fechaActual = fecha;
    this.modalRefDetalleOrden = this.modalService.show(this.modalDetalleOrdenes, {class: 'modal-do'});
    this._detalleDiaAgenda.getDetalleDiaProveedor(fecha, this.codProveedor, '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseCabezeraAgenda = data.body;
        this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);

        this.horasDisponiblesCall();
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  abrirModalConfirmaAgenda() {
    this.modalRefConfirmaAgenda = this.modalService.show(this.modalConfirmaAgenda, {class: 'modal-ca'});
  }

  changeAgenda(e, type) {
    // console.log(e.target.checked);
    // console.log(type);
    if (e.target.checked === true) {
      this.listaCheckAgenda.push(type);
      this.isChecked = true;
      console.log('CHEQUEADO if ' + this.isChecked.toString());
    } else {
      const index = this.listaCheckAgenda.indexOf(type);
      this.listaCheckAgenda.splice(index, 1);
      console.log('CHEQUEADO else ' + this.isChecked.toString());
    }
  }


  public confirmarProceso() {
    let correcto: boolean = false;
    console.log('' + this.listaCheckAgenda.length);
    let checkAgenda: any[] = [];
    const selectAgenda: any[] = [];
    // const selectAgenda = $('#selectAgenda').val();
    // const horaSelect = this.responseHoraDisponible.length;

    if (this.listaCheckAgenda.length === 0 || this.listaCheckAgenda.length === undefined) {
      checkAgenda = [];
    } else {
      $('input[name="checkAgenda[]"]').each( function () {
        checkAgenda.push($(this).prop('checked'));
      });
    }

    $('select.selectHora').each(function(i, sel) {
      console.log('valor select: ' + $(sel).val());
      if ($(sel).val() === '0') {
        selectAgenda.push('0');
      } else {
        selectAgenda.push($(sel).val());
      }
    });

    if (this.listaCheckAgenda.length === 0 || this.listaCheckAgenda.length === undefined) {
      this.abrirModalError('Debe seleccionar al menos una orden y su hora de llegada', 'Error.');
    } else {
      checkAgenda.forEach( (itemAgenda, i) => {
        const checkActual = checkAgenda[i + 1];
        const selectActual = selectAgenda[i];
        if (checkActual && selectActual !== '0') {
          correcto = true;
        } /*else {
          if (correcto) {
            if (selectActual !== '0') {
              if (!checkActual) {
                correcto = false;
              }
            }
          } else {
            correcto = false;
          }
        }*/
      });
      if (correcto) {
        this.abrirModalConfirmaAgenda();
      } else {
        this.abrirModalError('Debe seleccionar al menos una orden y su hora de llegada', 'Error.');
      }
    }
  }

  public agendarOrden(fecha) {
    this.spinnerService.show();
    let codOC = '';
    let hora = this.listaSelectHora[0].hora;
    console.log('Array: ' + this.listaCheckAgenda.length);
    let numero = 1;
    this.listaCheckAgenda.forEach((item, index) => {
      console.log('numero: ' + numero);
      this.responseDetalleDia.detalles.forEach(orden => {
        if (orden.registro === item) {
          if (numero < this.listaCheckAgenda.length) {
            numero = numero + 1;
            codOC = codOC + orden.crrOrden + ';';
          } else {
            numero = numero + 1;
            codOC = codOC + orden.crrOrden;
          }
        }
      });
    });
    console.log('DRAMA: ' + codOC);
    console.log('HORA: ' + hora);
    this._cambiarEstado.getAgendaProveedor(codOC, hora, this.fechaActual)
      .subscribe((data2: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data2));
        this._responseCambioEstado = data2.body;
        console.log('Mensaje Cambio Estado: ' + this._responseCambioEstado.message);
        this._detalleDiaAgenda.getDetalleDiaProveedor(this.fechaActual, this.codProveedor)
          .subscribe((data1: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.obtenerAgendaCalendario(this.fechaActualProve);
            this._responseCabezeraAgenda = data1.body;
            this.responseDetalleDia = this._responseCabezeraAgenda.responseDetalleCabecera;
            this.responseDetalleAgenda = this.responseDetalleDia.detalles;
            const tokenfull = data1.headers.get('Authorization');
            this._token.actualizaToken(tokenfull);
            this.obtenerDatosProducto(this.estActual, this.codProveedorLogin);
            this.modalRefConfirmaAgenda.hide();
          });
      });
  }


  public horasDisponiblesCall() {
    this._horaDisponible.getHoraDisponible(this.fechaActual)
      .subscribe((data: HttpResponse<ResponseHoraDisponible[]>) => {
      this.responseHoraDisponible = data.body;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        console.log('resultado hora disponible' + JSON.stringify(data));
      });
  }

  public obtenerAgendaCalendario(date) {
    this.spinnerService.show();
    let fecha = date;
    try {
      fecha = fecha.replace(' 00:00:00', '');
      fecha = fecha.replace('.0', '');
      fecha = fecha.replace('/', '-');
    } catch (e) {

    }
    console.log('FECHA: ' + fecha);
    this._agendaCalendario.getCalendarioProveedor(fecha, this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.mesActualProve = data.body.desMesActual;
        this.spinnerService.hide();
        console.log('Response Agenda = ' + JSON.stringify(data));
      console.log('Mes actual: ' + data.body.desMesActual);
      this._responseCalendario = data.body;
      this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
      this.responseAgendaLoad = true;
    });
    this.fechaActualProve = fecha;
  }

  public obtenerAgendaCalendarioProveedor(date, mes) {
    this.spinnerService.show();
    let fecha = date;
    try {
      fecha = fecha.replace(' 00:00:00', '');
      fecha = fecha.replace('.0', '');
      fecha = fecha.replace('/', '-');
    } catch (e) {

    }
    console.log('FECHA: ' + fecha);
    if (mes === this.mesActualProve) {
      this._agendaCalendario.getCalendarioProveedor(this.fechaActualProve, this.codProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response Agenda = ' + JSON.stringify(data));
          console.log('Mes actual: ' + data.body.desMesActual);
          this._responseCalendario = data.body;
          this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
          this.responseAgendaLoad = true;
        });
    } else {
      this._agendaCalendario.getCalendarioProveedor(fecha, this.codProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response Agenda = ' + JSON.stringify(data));
          console.log('Mes actual: ' + data.body.desMesActual);
          this._responseCalendario = data.body;
          this.responseAgendaProveedor = this._responseCalendario.responseCalendaroVO;
          this.responseAgendaLoad = true;
        });
    }
  }

  obtenerFecha(e, orden, fecha) {
    this.fechaDate[e].fecha = fecha;
    this.fechaDate[e].ordencod = orden;
  }
}

