import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SeguimientoOrdenesCompra {

  // private url = 'http://10.107.101.106:8400/vev/seguimientoOrdenesCompra';
  // private urlSelects = 'http://10.107.101.106:8400/vev/';
  // // private urlEstado = 'http://10.107.101.106:8400/vev/getEstadosVEV';
  // private urlEstado = 'http://localhost:8341/vev/getEstadosVEV';

  private url = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/seguimientoOrdenesCompra';
  private urlSelects = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/';
  // private urlEstado = 'http://10.107.101.106:8400/vev/getEstadosVEV';
  private urlEstado = environment.dominioServicios + '/vev/ObtenerOrdenesCompra/getEstadosVEV';


  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getSeguimientoOrdenes(pagina: any, numFilas: any, todo: any, codProveedor: any) {
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas + '&todo=' + 'true' + '&codProveedor=' + codProveedor + '&estado=' + '',
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getSeguimientoOrdenesFiltros(pagina: any, numFilas: any, bodega: any, departamento: any, tipoDespacho: any, tipoAlerta: any, codProveedor: string, estado: string) {
    if (codProveedor === undefined) {
      codProveedor = '';
    }
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas + '&bodega=' + bodega +
      '&departamento=' + departamento + '&tipoDespacho=' + tipoDespacho + '&tipoAlerta=' + tipoAlerta + '&codProveedor=' + codProveedor + '&estado=' + estado,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getEstadoVEV() {
    return this._http.get(this.urlEstado,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
