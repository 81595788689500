import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class CambiarEstadoLiquidacionService {

  // private url = 'http://10.107.101.106:8354/lqt/cambiarEstadoLiq';
  // private url2 = 'http://10.107.101.106:8354/lqt/updateBoleta';
  // private url3 = 'http://10.107.101.106:8354/lqt/updateCamion';

  private url = environment.dominioServicios + '/lqt/CambioEstadoLiquidaciones/cambiarEstadoLiq';
  private url2 = environment.dominioServicios + '/lqt/CambioEstadoLiquidaciones/updateBoleta';
  private url3 = environment.dominioServicios + '/lqt/CambioEstadoLiquidaciones/updateCamion';

  constructor(private _http: HttpClient) {
  }

  getCambiarEstadoLiquidacion(accion: any, motivoRechazo: any, crrLiquidaciones: any, valGratificacion: any) {
    return this._http.post(this.url, {accion: accion , motivoRechazo: motivoRechazo, crrLiquidaciones: crrLiquidaciones, valGratificacion: valGratificacion},
      {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'response'});
  }

  updateBoletas(numBoleta: any, comuna: any, ciudad: any, valorFlete: any ) {
    return this._http.post(this.url2, {numBoleta: numBoleta , comuna: comuna, ciudad: ciudad, valorFlete: valorFlete},
      {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'response'});
  }

  updateCamiones(patente: any, tara: any, cantBoleta: any, fechaViaje: any, valorViaje: any) {
    return this._http.post(this.url3, {patente: patente , tara: tara, cantBoleta: cantBoleta, fechaViaje: fechaViaje, valorViaje: valorViaje},
      {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'response'});
  }
}
