import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['../../../assets/css/koma.css']
  /*styleUrls: ['./menu-admin.component.css']*/
})
export class MenuAdminComponent implements OnInit {
  rol: string;
  nomUsuario: string;

  constructor() {
    const nomUsuario = localStorage.getItem('nomUsuario');
    if (nomUsuario === null) {
      localStorage.setItem('nomUsuario', 'Maria de las Mercedez');
      localStorage.setItem('rolUsuario', 'administrador');
    }
    if (this.rol === undefined && this.nomUsuario === undefined) {
      this.rol = localStorage.getItem('rolUsuario');
      this.nomUsuario = localStorage.getItem('nomUsuario');

    }
  }
  ngOnInit() {
  }

}
