export class ProveedoresConfig {
  responseProveedorConfigs: ResponseProveedorConfig[];
  code: number;
  mensaje: string;
}

export class ResponseProveedorConfig {
  rut: number;
  nombre: string;
  direccion: string;
  email: string;
  telefono: string;
}
