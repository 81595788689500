// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // dominioServicios: 'http://localhost:8349' // LOCAL
  // dominioServicios: 'http://192.168.100.175:8400' // DESA
  // dominioServicios: 'http://10.107.101.106:8400' // TEST
   dominioServicios: 'https://ventaenverde.servicioshites.cl' // PROD
};

export const environment2 = {
  // dominioCarga: 'http://10.107.101.106:8355' // TEST
   dominioCarga: 'https://apiprod.servicioshites.cl' // PROD
}

export const environment3 = {
  //ecoboletaUrl: 'http://10.110.70.14:8104', //TEST
  //ecoboletaPdf: 'http://10.110.70.14:8108' //TEST

  ecoboletaUrl: ' http://be-services.hites.global', //PROD
  ecoboletaPdf:  'http://be-services.hites.global' //PROD
}
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
