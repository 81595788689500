import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inicio-administrador',
  templateUrl: './inicio.administrador.transportista.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  /*styleUrls: ['./inicio.administrador.transportista.component.css']*/
})
export class InicioAdministradorTransportistaComponent implements OnInit {
  rol: string;
  nomUsuario: string;
  fecha: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.rol = localStorage.getItem('rol');
    this.nomUsuario = localStorage.getItem('nombre');
    this.fecha = localStorage.getItem('fecha');
  }

}
