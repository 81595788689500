import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ReporteDashboardBodegaService} from '../../../../comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import {ResponseIngresadas} from '../../../../comun/vo/responseIngresadas';
import {ResponseVelocimetro} from '../../../../comun/vo/responseVelocimetro';
import {ResponseHorizontal} from '../../../../comun/vo/responseHorizontal';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';

declare var cargaIngresada: any;
declare var cargaPick: any;
declare var cargaBarraHorizontal3X3: any;
declare var ingresoAmarillo: any;
declare var ingresoVerde: any;
declare var pickArribaArriba: any;
declare var pickAbajoAbajo: any;
declare var pickAbajoArriba: any;
declare var pickArribaAbajo: any;
declare var flexFont: any;

@Component({
  selector: 'app-dashboard2-administrador',
  templateUrl: './dashboard2-administrador-tracking.component.html',
  styleUrls: ['./dashboard2-administrador-tracking.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player2.css']
})
export class Dashboard2AdministradorTrackingComponent implements OnInit {
  @ViewChild('modalDetalleDashboardIngresadas') modalDetalleDashboardIngresadas;
  modalRefDetalleDashboardIngresadas: BsModalRef;
  @ViewChild('modalDetalleDashboardPicking') modalDetalleDashboardPicking;
  modalRefDetalleDashboardPicking: BsModalRef;
  @ViewChild('modalDetalleDashboardPorEntregar') modalDetalleDashboardPorEntregar;
  modalRefDetalleDashboardPorEntregar: BsModalRef;
  @ViewChild('modalDetalleDashboardEmitirOrden') modalDetalleDashboardEmitirOrden;
  modalRefDetalleDashboardEmitirOrden: BsModalRef;
  @ViewChild('modalDetalleDashboardRecepcionar') modalDetalleDashboardRecepcionar;
  modalRefDetalleDashboardRecepcionar: BsModalRef;

  constructor(private dashboardBodegaService: ReporteDashboardBodegaService,
              private router: Router,
              private modalService: BsModalService,
              private _estadoDashboard: ObtieneEstadosDashboardService) {}

  ngOnInit() {
    // Cargar ingresadas
    // this.cargaIngresadas('ingresoA', ingresoAmarillo);

    /* Cargar velocimetros */
    // this.cargaVelocimetro('pickA', 'En Picking');
    // this.cargaVelocimetroB('pickB', 'Por Entregar');
    // this.cargaVelocimetroC('pickC', 'Por Recepcionar');
    // this.cargaVelocimetroD('pickD', 'Emitir Orden');

    /* Cargar barra horizontal */
    // this.cargaHorizontal('grafHorizontal');
  }

//   cargaIngresadas(id: string, color: any) {
//     this._estadoDashboard.getEstDespachoIngresados(1)
//       .subscribe( (data: HttpResponse<ResponseIngresadas>) => {
//         console.log(JSON.stringify(data));
//         const response: ResponseIngresadas = JSON.parse(JSON.stringify(data));
//         const jsonIngA = {
//           'id': 'ingresoA',
//           'tope': response.topeMaximo,
//           'principal': response.valorDia,
//           'circulo1valor': response.valorDvh,
//           'circulo1text': 'DVH : Venta en Verde',
//           'circulo1url': 'www.koma.cl',
//           'circulo2valor': response.valorDdc,
//           'circulo2text': 'DDC TEXTO',
//           'circulo2url': 'www.koma.cl',
//           'circulo3valor': response.valorVto,
//           'circulo3text': 'Venta en Tienda / Online',
//           'circulo3url': 'www.koma.cl',
//           'imgDerecha': 'assets/img/icon_ingresadas.svg',
//           'imgText': 'Ingresada',
//           'imgUrl': 'www.koma.cl'
//         };
//         cargaIngresada(jsonIngA, ingresoVerde);
//         flexFont();
//
//       });
//   }
//
//   cargaVelocimetro(id: string, tipo: string) {
//
//     const jsonPickA = {
//       'id': id,
//       'tipo': 'emitirOrden',
//       'principal': 70,
//       'verde': '111',
//       'amarillo': '222',
//       'rojo': '57',
//       'morado': '58',
//       'textAmarillo': '30 dias',
//       'textRojo': '40 dias',
//       'textMorado': '20 dias',
//       'titulo': tipo,
//       'tituloLink': 'http://kiosolutions.cl/',
//       'tituloImg': 'assets/img/icon_por_entregar.svg',
//       'tituloColor': 'fucsia',
//       /*azul,fucsia, amarrillo, rojo*/
//       'circuloIzqValor': '53',
//       'circuloDerValor': '54',
//       'ovaloIzqValor': '32',
//       'ovaloCenValor': '51',
//       'ovaloDerValor': '800',
//       'circuloIzqLabel': 'circulo Izquierdo',
//       'circuloDerLabel': 'circulo Derecho',
//       'ovaloIzqLabel': 'Ovalo Izquierda',
//       'ovaloCenLabel': 'Ovalo Centro',
//       'ovaloDerLabel': 'de',
//       'link1': 'link1.cl',
//       'link2': 'link2.cl',
//       'link3': 'link3.cl',
//       'link4': 'link4.cl',
//       'link5': 'link5.cl',
//       'link6': 'link6.cl',
//       'link7': 'link7.cl',
//       'link8': 'link8.cl',
//       'link9': 'link9.cl'
//     };
//
//     cargaPick(jsonPickA, pickAbajoArriba);
//     flexFont();
//
// //    this.dashboardBodegaService.getVelocimetro(tipo).subscribe( (data: ResponseVelocimetro) => {
// //      const response: ResponseVelocimetro = JSON.parse(JSON.stringify(data));
// //      console.log('Response OBJ: ' + JSON.stringify(response));
// //    });
//   }
//
//   cargaVelocimetroB(id: string, tipo: string) {
//     this._estadoDashboard.getPorEntregar(1, 2)
//       .subscribe((data: ResponseVelocimetro) => {
//         const response: ResponseVelocimetro = JSON.parse(JSON.stringify(data));
//         console.log('Response OBJ: ' + JSON.stringify(response));
//         console.log('Response NUM VERDE: ' + response.numVerde);
//         const jsonPickB = {
//           'id': 'pickB',
//           'tipo': 'porEntregar',
//           'principal': response.porcetajePrincipal,
//           'verde': response.numVerde,
//           'amarillo': response.numAmarillo,
//           'rojo': response.numRojo,
//           'morado': response.numMorado,
//           'textAmarillo': response.txtAmarillo,
//           'textRojo': response.txtRojo,
//           'textMorado': response.txtMorado,
//           'titulo': 'Por Entregar',
//           'tituloLink': 'http://kiosolutions.cl/',
//           'tituloImg': 'assets/img/icon_camion_rapido.svg',
//           'tituloColor': 'azul',
//           /*azul,fucsia, amarillo, rojo*/
//           'circuloIzqValor': response.pendientes,
//           'circuloDerValor': response.completadas,
//           'ovaloIzqValor': response.ovaloIzq,
//           'ovaloCenValor': response.ovaloCentro,
//           'ovaloDerValor': response.ovaloDer,
//           'circuloIzqLabel': 'Pendientes',
//           'circuloDerLabel': 'Avance',
//           'ovaloIzqLabel': 'Nuevas Ordenes',
//           'ovaloCenLabel': 'Por Entregar',
//           'ovaloDerLabel': 'de',
//           'link1': 'link1.cl',
//           'link2': 'link2.cl',
//           'link3': 'link3.cl',
//           'link4': 'link4.cl',
//           'link5': 'link5.cl',
//           'link6': 'link6.cl',
//           'link7': 'link7.cl',
//           'link8': 'link8.cl',
//           'link9': 'link9.cl'
//         };
//         cargaPick(jsonPickB, pickAbajoArriba);
//         flexFont();
//       });
//   }
//
//   cargaVelocimetroC(id: string, tipo: string) {
//
//     const jsonPickC = {
//       'id': id,
//       'tipo': 'recepcionar',
//       'principal': 50,
//       'verde': '555',
//       'amarillo': '556',
//       'rojo': '557',
//       'morado': '558',
//       'textAmarillo': '30 dias',
//       'textRojo': '40 dias',
//       'textMorado': '20 dias',
//       'titulo': tipo,
//       'tituloLink': 'http://kiosolutions.cl/',
//       'tituloImg': 'assets/img/icon_recepcionar.svg',
//       'tituloColor': 'amarillo',
//       /*azul,fucsia, amarrillo, rojo*/
//       'circuloIzqValor': '53',
//       'circuloDerValor': '54',
//       'ovaloIzqValor': '32',
//       'ovaloCenValor': '51',
//       'ovaloDerValor': '800',
//       'circuloIzqLabel': 'circulo  Izquierdo',
//       'circuloDerLabel': 'circulo  Derecho',
//       'ovaloIzqLabel': 'Ovalo Izquierda',
//       'ovaloCenLabel': 'Ovalo Centro',
//       'ovaloDerLabel': 'de',
//       'link1': 'link1.cl',
//       'link2': 'link2.cl',
//       'link3': 'link3.cl',
//       'link4': 'link4.cl',
//       'link5': 'link5.cl',
//       'link6': 'link6.cl',
//       'link7': 'link7.cl',
//       'link8': 'link8.cl',
//       'link9': 'link9.cl'
//     };
//
//     cargaPick(jsonPickC, pickAbajoArriba);
//     flexFont();
//
// //    this.dashboardBodegaService.getVelocimetro(tipo).subscribe( (data: ResponseVelocimetro) => {
// //      const response: ResponseVelocimetro = JSON.parse(JSON.stringify(data));
// //      console.log('Response OBJ: ' + JSON.stringify(response));
// //    });
//   }
//
//   cargaVelocimetroD(id: string, tipo: string) {
//
//     const jsonPickD = {
//       'id': id,
//       'tipo': 'picking',
//       'principal': 60,
//       'verde': '555',
//       'amarillo': '556',
//       'rojo': '557',
//       'morado': '558',
//       'textAmarillo': '30 dias',
//       'textRojo': '40 dias',
//       'textMorado': '20 dias',
//       'titulo': tipo,
//       'tituloLink': 'http://kiosolutions.cl/',
//       'tituloImg': 'assets/img/icon_emitir_orden.svg',
//       'tituloColor': 'rojo',
//       /*azul,fucsia, amarrillo, rojo*/
//       'circuloIzqValor': '53',
//       'circuloDerValor': '54',
//       'ovaloIzqValor': '32',
//       'ovaloCenValor': '51',
//       'ovaloDerValor': '800',
//       'circuloIzqLabel': 'circulo  Izquierdo',
//       'circuloDerLabel': 'circulo  Derecho',
//       'ovaloIzqLabel': 'Ovalo Izquierda',
//       'ovaloCenLabel': 'Ovalo Centro',
//       'ovaloDerLabel': 'de',
//       'link1': 'link1.cl',
//       'link2': 'link2.cl',
//       'link3': 'link3.cl',
//       'link4': 'link4.cl',
//       'link5': 'link5.cl',
//       'link6': 'link6.cl',
//       'link7': 'link7.cl',
//       'link8': 'link8.cl',
//       'link9': 'link9.cl'
//     };
//
//     cargaPick(jsonPickD, pickAbajoArriba);
//     flexFont();
//
// //    this.dashboardBodegaService.getVelocimetro(tipo).subscribe( (data: ResponseVelocimetro) => {
// //      const response: ResponseVelocimetro = JSON.parse(JSON.stringify(data));
// //      console.log('Response OBJ: ' + JSON.stringify(response));
// //    });
//   }
//
//   cargaHorizontal(id: string) {
//     this._estadoDashboard.getEstDespachoAtrasos(1)
//       .subscribe((data: HttpResponse<ResponseHorizontal>) => {
//         const response: ResponseHorizontal = JSON.parse(JSON.stringify(data));
//         console.log('RESULTADO INFORMACION: ' + JSON.stringify(response));
//         const jsonBarraHorizontal3X3 = {
//           'id': id,
//           'grupo1': response.atrasadosVOS[0].dia,
//           'grupo1red': Number(response.atrasadosVOS[0].atrasoMayor),
//           'grupo1yellow': Number(response.atrasadosVOS[0].atrasoUno),
//           'grupo1green': Number(response.atrasadosVOS[0].enPlazo),
//           'grupo2': response.atrasadosVOS[1].dia,
//           'grupo2red': Number(response.atrasadosVOS[1].atrasoMayor),
//           'grupo2yellow': Number(response.atrasadosVOS[1].atrasoUno),
//           'grupo2green': Number(response.atrasadosVOS[1].enPlazo),
//           'grupo3': response.atrasadosVOS[2].dia,
//           'grupo3red': Number(response.atrasadosVOS[2].atrasoMayor),
//           'grupo3yellow': Number(response.atrasadosVOS[2].atrasoUno),
//           'grupo3green': Number(response.atrasadosVOS[2].enPlazo),
//           'rojoText': 'Más de 1 dia de atraso',
//           'verdeText': 'Procesado Hoy',
//           'amarilloText': 'Un dia de atraso',
//           'rojoLink': 'Cuadro Rojo',
//           'verdeLink': 'Cuadro Verde',
//           'amarilloLink': 'Cuadro Amarillo',
//         };
//         cargaBarraHorizontal3X3(jsonBarraHorizontal3X3);
//         flexFont();
//       });
//   }

  abrirModalIngresadas() {
    console.log('INGRESADAS: ' + this.modalDetalleDashboardIngresadas);
    this.modalRefDetalleDashboardIngresadas = this.modalService.show(this.modalDetalleDashboardIngresadas, {class: 'modal-lg'});
  }

  abrirModalPicking() {
    this.modalRefDetalleDashboardPicking = this.modalService.show(this.modalDetalleDashboardPicking, {class: 'modal-lg'});
  }

  abrirModalPorEntregar() {
    this.modalRefDetalleDashboardPorEntregar = this.modalService.show(this.modalDetalleDashboardPorEntregar, {class: 'modal-lg'});
  }

  abrirModalEmitirOrden() {
    this.modalRefDetalleDashboardEmitirOrden = this.modalService.show(this.modalDetalleDashboardEmitirOrden, {class: 'modal-lg'});
  }

  abrirModalRecepcionar() {
    this.modalRefDetalleDashboardRecepcionar = this.modalService.show(this.modalDetalleDashboardRecepcionar, {class: 'modal-lg'});
  }
}
