import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';



@Injectable()
export class ConfiguracionService {

  // private url1 = 'http://10.107.101.106:8400/vev/getUsuarios';
  // private url2 = 'http://10.107.101.106:8400/vev/getProveedores';
  // private url3 = 'http://10.107.101.106:8400/vev/guardarUsuario';
  // private url4 = 'http://10.107.101.106:8400/vev/guardarProveedor';
  // private url5 = 'http://10.107.101.106:8400/vev/getBodegasConfig';
  // private url6 = 'http://10.107.101.106:8400/vev/getDepartamentosConfig';
  // private url7 = 'http://10.107.101.106:8400/vev/getRolesConfig';
  // private url8 = 'http://10.107.101.106:8400/vev/getAlertas';
  // private url9 = 'http://10.107.101.106:8346/vev/guardarAlertas';
  // private url10 = 'http://10.107.101.106:8346/vev/getProveedoresConfig';

  private url1 = environment.dominioServicios + '/vev/Configuracion/getUsuarios';
  private url2 = environment.dominioServicios + '/vev/Configuracion/getProveedores';
  private url3 = environment.dominioServicios + '/vev/Configuracion/guardarUsuario';
  private url4 = environment.dominioServicios + '/vev/Configuracion/guardarProveedor';
  private url5 = environment.dominioServicios + '/vev/Configuracion/getBodegasConfig';
  private url6 = environment.dominioServicios + '/vev/Configuracion/getDepartamentosConfig';
  private url7 = environment.dominioServicios + '/vev/Configuracion/getRolesConfig';
  private url8 = environment.dominioServicios + '/vev/Configuracion/getAlertas';
  private url9 = environment.dominioServicios + '/vev/Configuracion/guardarAlertas';
  private url10 = environment.dominioServicios + '/vev/Configuracion/getProveedoresConfig';
  private url11 = environment.dominioServicios + '/vev/Configuracion/getProveedoresAll';



  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  getUsuario(pagina: any, nombre: any, app: any) {
    return this._http.get(this.url1 + '?pagina=' + pagina + '&nombre=' + nombre + '&app=' + app,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getProveedor(pagina: any, nombre: any) {
    return this._http.get(this.url2 + '?pagina=' + pagina + '&nombre=' + nombre,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getAgregarUsuario(nombre: string , nombreUsuario: string , password: string, rut: string, email: string, codRol: string, codProveedor: string, codTransportista: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url3, {
        nombre: nombre , nombreUsuario: nombreUsuario , password: password, rut: rut, email: email, codRol: codRol, codProveedor: codProveedor, codTransportista: codTransportista},
      {headers : headers});
  }

  getModificarUsuario(crrUsuario: string, nombre: string , nombreUsuario: string , password: string, rut: string, email: string, codRol: string, codProveedor: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url3, {
        crrUsuario: crrUsuario, nombre: nombre , nombreUsuario: nombreUsuario , password: password, rut: rut, email: email, codRol: codRol, codProveedor: codProveedor},
      {headers : headers});
  }

  getAgregarProveedor(nomProveedor: string , rut: string , direccion: string, email: string, telefono: string, codBodega: string, codDepartamento: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url4, {
        nomProveedor: nomProveedor , rut: rut , direccion: direccion, email: email, telefono: telefono, codBodega: codBodega, codDepartamento: codDepartamento},
      {headers : headers});
  }
  getModificarProveedor(codProveedor: string, nomProveedor: string , rut: string , direccion: string, email: string, telefono: string, codBodega: string, codDepartamento: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url4, {
        codProveedor: codProveedor , nomProveedor: nomProveedor , rut: rut , direccion: direccion, email: email, telefono: telefono, codBodega: codBodega, codDepartamento: codDepartamento},
      {headers : headers});
  }

  getBodegas() {
    return this._http.get(this.url5,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getDepartamentos() {
    return this._http.get(this.url6,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')})
        , observe: 'response'});
  }

  getRoles(app: any) {
    return this._http.get(this.url7 + '?app=' + app ,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getAlertas() {
    return this._http.get(this.url8,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  guardarAlerta(alerta1: any, alerta2: any, alerta3: any) {
    return this._http.get(this.url9 + '?alerta1=' + alerta1 + '&alerta2=' + alerta2 + '&alerta3=' + alerta3,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getProveedoresConfig(nombre: string) {
    return this._http.get(this.url10 + '?nombre=' + nombre,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getProveedoresAll() {
    return this._http.get(this.url11,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

}
