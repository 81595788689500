import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class MantenedorTransportistaService {

  // private url = 'http://10.107.101.106:8356/lqt/getCamionesMan';
  // private url2 = 'http://10.107.101.106:8356/lqt/getTransportistasMan';
  // private url3 = 'http://10.107.101.106:8356/lqt/getRutasMan';
  // private url4 = 'http://10.107.101.106:8356/lqt/guardarCamionMan';
  // private url5 = 'http://10.107.101.106:8356/lqt/updateCamionMan';
  // private url6 = 'http://10.107.101.106:8356/lqt/guardarRutaMan';
  // private url7 = 'http://10.107.101.106:8356/lqt/guardarTransportistaMan';
  // private url8 = 'http://10.107.101.106:8356/lqt/getTransportistasSelect';
  // private url9 = 'http://10.107.101.106:8356/lqt/getBodegasOrigen';
  // private url10 = 'http://10.107.101.106:8356/lqt/getBodegasOrigen';

  private url = environment.dominioServicios + '/lqt/MantenedorTransportista/getCamionesMan';
  private url2 = environment.dominioServicios + '/lqt/MantenedorTransportista/getTransportistasMan';
  private url3 = environment.dominioServicios + '/lqt/MantenedorTransportista/getRutasMan';
  private url4 = environment.dominioServicios + '/lqt/MantenedorTransportista/guardarCamionMan';
  private url5 = environment.dominioServicios + '/lqt/MantenedorTransportista/updateCamionMan';
  private url6 = environment.dominioServicios + '/lqt/MantenedorTransportista/guardarRutaMan';
  private url7 = environment.dominioServicios + '/lqt/MantenedorTransportista/guardarTransportistaMan';
  private url8 = environment.dominioServicios + '/lqt/MantenedorTransportista/getTransportistasSelect';
  private url9 = environment.dominioServicios + '/lqt/MantenedorTransportista/getBodegasOrigen';
  private url10 = environment.dominioServicios + '/lqt/MantenedorTransportista/getBodegasOrigen';

  constructor(private _http: HttpClient) {
  }

  getMantCamiones(pagina: any, numFilas: any, patente: any) {
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas + '&patente=' + patente,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getMantTransportistas(pagina: any, numFilas: any, nomTransportista: any) {
    return this._http.get(this.url2 + '?pagina=' + pagina + '&numFilas=' + numFilas + '&nomTransportista=' + nomTransportista,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getMantRutas(pagina: any, numFilas: any, ciudadOrigen: any) {
    return this._http.get(this.url3 + '?pagina=' + pagina + '&numFilas=' + numFilas + '&ciudadOrigen=' + ciudadOrigen,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getAgregarTransportista(nomTransportista: string , rutTransportista: string , dirTransportista: string, telTransportista: string, emailTransportista: string, codTransportista: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url7, {
        nomTransportista: nomTransportista , rutTransportista: rutTransportista , dirTransportista: dirTransportista,
        telTransportista: telTransportista, emailTransportista: emailTransportista, codTransportista: codTransportista},
      {headers : headers});
  }

  getModificarTransportista(crrTransportista: string, nomTransportista: string , rutTransportista: string, dirTransportista: string, telTransportista: string, emailTransportista: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url7, { crrTransportista: crrTransportista,
        nomTransportista: nomTransportista , rutTransportista: rutTransportista , dirTransportista: dirTransportista,
        telTransportista: telTransportista, emailTransportista: emailTransportista},
      {headers : headers});
  }

  getAgregarCamion(codPatente: string , nomMarca: string , gloModelo: string, valTara: any, codTransportista: any, tipoCamion: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url4, {
        codPatente: codPatente , nomMarca: nomMarca , gloModelo: gloModelo,
        valTara: valTara, codTransportista: codTransportista, tipoCamion: tipoCamion},
      {headers : headers});
  }

  getMofificarCamion(codPatente: string , nomMarca: string , gloModelo: string, valTara: any, codTransportista: any, tipoCamion: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url5, {
        codPatente: codPatente , nomMarca: nomMarca , gloModelo: gloModelo,
        valTara: valTara, codTransportista: codTransportista, tipoCamion: tipoCamion},
      {headers : headers});
  }

  getAgregarRuta(codTiendaOrigen: string , gloTiendaOrigen: string , codTiendaDestino: string, gloTiendaDestino: any, valViajeRampla: any,
                 valViajeCamion: any, valViajeAcoplado: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url6, {
        codTiendaOrigen: codTiendaOrigen , gloTiendaOrigen: gloTiendaOrigen , codTiendaDestino: codTiendaDestino,
        gloTiendaDestino: gloTiendaDestino, valViajeRampla: valViajeRampla, valViajeCamion: valViajeCamion, valViajeAcoplado: valViajeAcoplado},
      {headers : headers});
  }

  getModificarRuta(crrRuta: any, codTiendaOrigen: string , gloTiendaOrigen: string , codTiendaDestino: string, gloTiendaDestino: any, valViajeRampla: any,
                   valViajeCamion: any, valViajeAcoplado: any) {
    const headers = new HttpHeaders({

      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
      observe: 'response'
    });
    return this._http.post(this.url6, {
        crrRuta: crrRuta, codTiendaOrigen: codTiendaOrigen , gloTiendaOrigen: gloTiendaOrigen , codTiendaDestino: codTiendaDestino,
        gloTiendaDestino: gloTiendaDestino, valViajeRampla: valViajeRampla, valViajeCamion: valViajeCamion, valViajeAcoplado: valViajeAcoplado},
      {headers : headers});
  }

  getTrans(nombre: any) {
    return this._http.get(this.url8 + '?nombre=' + nombre, {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getBodegaOrigen() {
    return this._http.get(this.url9, {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

}
