import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ChartsModule } from 'ng2-charts';

import { HttpClientModule } from '@angular/common/http';
// Venta en Verde
import { AppComponent } from './app.component';
import { LoginComponent } from './comun/login/login.component';
import { FooterComponent } from './comun/footer/footer.component';
import { MenuComponent } from './comun/menu/menu.component';
import { AppRoutingModule } from './app.routing';
import { ModalModule, TabsModule } from 'ngx-bootstrap';
import { ObtenerDetalleOrdenes } from './comun/services/ServicioObtenerDetalleOrdenes/obtener.detalle.ordenes.service';
import { ObtenerOrdenesCompra } from './comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ExcelService } from './comun/services/excelExport/excel.service';
import { CambiarEstadoOrdenes } from './comun/services/ServicioCambiarEstadoOrdenes/cambiar.estado.ordenes.service';
import { InicioAdministradorComponent } from './ventaEnVerde/componentes/administrador/inicio/inicio.component';
import { VentasAdministradorComponent } from './ventaEnVerde/componentes/administrador/ventas-administrador/ventas-administrador.component';
import { OrdenesVentasProveedorComponent } from './ventaEnVerde/componentes/proveedor/ordenes-ventas-proveedor/ordenes-ventas-proveedor.component';
import { InicioProveedorComponent } from './ventaEnVerde/componentes/proveedor/inicio/inicio.component';
import { MainAdministradorComponent } from './ventaEnVerde/componentes/main-administrador/main-administrador.component';
import { MainProveedorComponent } from './ventaEnVerde/componentes/main-proveedor/main-proveedor.component';
import { SeguimientoAdministradorComponent } from './ventaEnVerde/componentes/administrador/seguimiento-administrador/seguimiento-administrador.component';
import { ReportesAdministradorComponent } from './ventaEnVerde/componentes/administrador/reportes-administrador/reportes-administrador.component';
import { SeguimientoProveedorComponent } from './ventaEnVerde/componentes/proveedor/seguimiento-proveedor/seguimiento-proveedor.component';
import { AgendaProveedorComponent } from './ventaEnVerde/componentes/proveedor/agenda-proveedor/agenda-proveedor.component';
import { MenuAdminComponent } from './comun/menu-admin/menu-admin.component';
import { MenuProveedorComponent } from './comun/menu-proveedor/menu-proveedor.component';
import { LoginService } from './comun/services/login/login.service';
import { TokenService } from './comun/services/token/token.service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DetalleDiaAgenda } from './comun/services/ServicioDetalleDiaAgenda/detalle.dia.agenda.service';
import { AgendaCalendario } from './comun/services/ServicioAgendaCalendario/agenda.calendario.service';
import { PopoverModule } from 'ngx-bootstrap';
import { SeguimientoOrdenesCompra } from './comun/services/ServicioSeguimientoOrdenesCompra/seguimiento.ordenes.compra.service';
import { ObtenerGuiaDespachoService } from './comun/services/ObtenerGuiaDespacho/obtener.guia.despacho.service';
import { ConfiguracionService } from './comun/services/ServicioConfiguracion/configuracion.service';
import { Ng2Rut } from 'ng2-rut';
import { Dashboard2AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard2-administrador/dashboard2-administrador.component';
import { ReporteDashboardGeneral } from './comun/services/ServicioReporteDashboardGeneral/reporte.dashboard.general.service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ReporteDashboardBodegaService } from './comun/services/ServicioReporteDashboardBodega/reporte.dashboard.bodega.service';
import { DatePipe } from '@angular/common';
import { EnvioCorreoService } from './comun/services/ServicioEnvioCorreo/envioCorreo.service';
import { AgendaAdministradorComponent } from './ventaEnVerde/componentes/administrador/agenda-administrador/agenda-administrador.component';
import { ConfiguracionesAdministradorComponent } from './ventaEnVerde/componentes/administrador/configuraciones-administrador/configuraciones-administrador.component';
import { BusquedaAdministradorComponent } from './ventaEnVerde/componentes/administrador/busqueda-administrador/busqueda-administrador.component';
import { Dashboard1AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard1-administrador/dashboard1-administrador.component';
// Tracking de Boleta
import { MainAdministradorTrackingComponent } from './trackingBoletas/componentes/main-administrador/main-administrador-tracking.component';
import { InicioAdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/inicio/inicio.tracking.component';
import { Dashboard2AdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/dashboard2-administrador/dashboard2-administrador-tracking.component';
import { Dashboard1AdministradorTrackingComponent } from './trackingBoletas/componentes/administrador/dashboard1-administrador/dashboard1-administrador-tracking.component';
// Liquidación de Transportistas
import { MainAdministradorTransportistaComponent } from './liquidacionTransportistas/componentes/main-administrador/main-administrador-transportista.component';
import { MainTransportistaComponent } from './liquidacionTransportistas/componentes/main-transportista/main-transportista.component';
import { InicioAdministradorTransportistaComponent } from './liquidacionTransportistas/componentes/administrador/inicio/inicio.administrador.transportista.component';
import { InicioTransportistaComponent } from './liquidacionTransportistas/componentes/transportista/inicio/inicio.transportista.component';
import { DetalleLiquidacionComponent } from './liquidacionTransportistas/componentes/transportista/detalle-liquidacion/detalle-liquidacion.component';
import { DetalleLiquidacionAdminComponent } from './liquidacionTransportistas/componentes/administrador/detalle-liquidacion-admin/detalle-liquidacion-admin.component';
import { ConfiguracionTransportistaComponent } from './liquidacionTransportistas/componentes/administrador/configuracion-transportista/configuracion-transportista.component';
import { ObtenerLiquidacionTransportista } from './comun/services/ServicioObtenerLiquidacionTransportista/obtener.liquidacion.transportista';
import { CambiarEstadoLiquidacionService } from './comun/services/ServicioCambiarEstadoLiquidacion/cambiar.estado.liquidacion.service';
import { MantenedorTransportistaService } from './comun/services/ServicioMantenedorTransportista/mantenedor.transportista.service';
import { ObtieneEstadosDashboardService } from './comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import { BarrasHorizontalComponent } from './comun/dashboards/barras-horizontal/barras-horizontal.component';
import { CuatroBarrasComponent } from './comun/dashboards/cuatro-barras/cuatro-barras.component';
import { IngresadaComponent } from './comun/dashboards/ingresada/ingresada.component';
import { Dashboard3AdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard3-administrador/dashboard3-administrador.component';
import { PickComponent } from './comun/dashboards/pick/pick.component';
import { DetalleDespachoAdministradorComponent } from './ventaEnVerde/componentes/administrador/detalle-despacho-administrador/detalle-despacho-administrador.component';
import { DetallePlazoAdministradorComponent } from './ventaEnVerde/componentes/administrador/detalle-plazo-administrador/detalle-plazo-administrador.component';
import { IngresadaX5Component } from './comun/dashboards/ingresadaX5/ingresada-x5/ingresada-x5.component';
import { IngresadaX4Component } from './comun/dashboards/ingresadaX4/ingresada-x4/ingresada-x4.component';
import { DashboardTransporteAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-transporte-administrador/dashboard-transporte-administrador.component';
import { DashboardOperacionesAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-operaciones-administrador/dashboard-operaciones-administrador.component'
import { DashboardVentasAdministradorComponent } from './ventaEnVerde/componentes/administrador/dashboard-ventas-administrador/dashboard-ventas-administrador.component'
import { VelocimetroComponent } from './comun/dashboards/velocimetro/velocimetro.component';
import { IngresadaX3Component } from './comun/dashboards/ingresada-x3/ingresada-x3.component';
import { BuscadorBoletasComponent } from './ventaEnVerde/componentes/administrador/buscador-boletas/buscador-boletas.component';
import { MyPieChartComponent } from './my-pie-chart/my-pie-chart.component';
import { CargaAtrasosComponent } from './trackingBoletas/componentes/administrador/carga-atrasos/carga-atrasos.component';
import { TrackingClienteComponent } from './ventaEnVerde/componentes/tracking-cliente/tracking-cliente.component';





@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    InicioAdministradorComponent,
    InicioProveedorComponent,
    MenuComponent,
    VentasAdministradorComponent,
    OrdenesVentasProveedorComponent,
    MainAdministradorComponent,
    MainProveedorComponent,
    SeguimientoAdministradorComponent,
    AgendaAdministradorComponent,
    ReportesAdministradorComponent,
    ConfiguracionesAdministradorComponent,
    SeguimientoProveedorComponent,
    AgendaProveedorComponent,
    MenuAdminComponent,
    MenuProveedorComponent,
    BusquedaAdministradorComponent,
    Dashboard1AdministradorComponent,
    Dashboard2AdministradorComponent,
    Dashboard3AdministradorComponent,
    MainAdministradorTrackingComponent,
    InicioAdministradorTrackingComponent,
    Dashboard2AdministradorTrackingComponent,
    Dashboard1AdministradorTrackingComponent,
    MainAdministradorTransportistaComponent,
    MainTransportistaComponent,
    InicioAdministradorTransportistaComponent,
    InicioTransportistaComponent,
    DetalleLiquidacionComponent,
    DetalleLiquidacionAdminComponent,
    ConfiguracionTransportistaComponent,
    BarrasHorizontalComponent,
    CuatroBarrasComponent,
    IngresadaComponent,
    PickComponent,
    DetalleDespachoAdministradorComponent,
    DetallePlazoAdministradorComponent,
    IngresadaX5Component,
    IngresadaX4Component,
    VelocimetroComponent,
    DashboardTransporteAdministradorComponent,
    DashboardOperacionesAdministradorComponent,
    DashboardVentasAdministradorComponent,
    IngresadaX3Component,
    BuscadorBoletasComponent,
    CargaAtrasosComponent,
    MyPieChartComponent,
    TrackingClienteComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    PopoverModule.forRoot(),
    Ng2Rut,
    SelectDropDownModule,
    ChartsModule
  ],
  providers: [
    ObtenerDetalleOrdenes,
    ObtenerOrdenesCompra,
    ExcelService,
    CambiarEstadoOrdenes,
    LoginService,
    TokenService,
    DetalleDiaAgenda,
    AgendaCalendario,
    SeguimientoOrdenesCompra,
    ObtenerGuiaDespachoService,
    ConfiguracionService,
    FormBuilder,
    ReporteDashboardGeneral,
    ReporteDashboardBodegaService,
    DatePipe,
    EnvioCorreoService,
    ObtenerLiquidacionTransportista,
    CambiarEstadoLiquidacionService,
    MantenedorTransportistaService,
    ObtieneEstadosDashboardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
