import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-administrador',
  templateUrl: './main-administrador.component.html',
  styleUrls: ['./main-administrador.component.css']
})
export class MainAdministradorComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
