import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { FuncionesVO, PermisoVO } from '../vo/permisoVO';
import { UsuarioVO } from '../vo/usuarioVO';
import { Compiler } from '@angular/core';

declare var jQuery: any;
declare var $: any;

class Response {
  code: number;
  token: string;
  permiso: PermisoVO;
  usuario: UsuarioVO;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../../assets/css/login.css', '../../../assets/css/koma.css']

  // styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usuario: string;
  password: string;
  mensaje: number;
  public radiador: boolean;
  public mensajeChecked: string;


  constructor(private router: Router, private _login: LoginService, private _compiler: Compiler) {
    // this._compiler.clearCache();
  }

  ngOnInit() {
    this.mensaje = 0;
  }

  login(form: NgForm) {
    console.log(form.value);

    const username = form.value.user;
    const password = form.value.password;

    console.log(username + ' ' + password);

    this._login.login(form.value.user, form.value.password).subscribe(
      (data: Response) => {
        if (data.code === 0) {
          this.eliminarSesion();
          localStorage.setItem('usuario', form.value.email);
          localStorage.setItem('token', data.token);
          // localStorage.setItem('permiso', data.permiso);
          localStorage.setItem('nombre', data.usuario.name);
          localStorage.setItem('fecha', data.usuario.fecha);
          localStorage.setItem('rol', data.usuario.rol);
          localStorage.setItem('email', data.usuario.mail);
          localStorage.setItem('codProveedor', data.usuario.codProveedor);
          console.log('COD PROVEEDOR LOGIN: ' + data.usuario.codProveedor);
          console.log('COD PROVEEDOR GET ITEM: ' + localStorage.getItem('codProveedor'));

          let pantallas = '';
          data.permiso.pantallas.forEach(item => {
            if (item.codPantalla === 1) {
              const funciones = this.funcionesDePantalla(item.funciones);
              console.log(funciones);
              localStorage.setItem('P1', funciones);
              pantallas += '1,';
            }
            if (item.codPantalla === 2) {
              const funciones = this.funcionesDePantalla(item.funciones);
              localStorage.setItem('P2', funciones);
              pantallas += '2,';
            }
            if (item.codPantalla === 3) {
              const funciones = this.funcionesDePantalla(item.funciones);
              localStorage.setItem('P3', funciones);
              pantallas += '3,';
            }
            if (item.codPantalla === 4) {
              const funciones = this.funcionesDePantalla(item.funciones);
              localStorage.setItem('P4', funciones);
              pantallas += '4,';
            }
            if (item.codPantalla === 5) {
              const funciones = this.funcionesDePantalla(item.funciones);
              localStorage.setItem('P5', funciones);
              pantallas += '5,';
            }
          });
          localStorage.setItem('pantallas', pantallas);
          if (data.usuario.rol === 'Administrador Venta en Verde' ||
            data.usuario.rol === 'Ejecutor VEV' || data.usuario.rol === 'Consulta VEV') { // Administrador VEV
            this.router.navigate(['vev/administrador/inicio']);
          } else if (data.usuario.rol === 'Proveedor Venta en Verde') { // Proveedor VEV
            this.router.navigate(['vev/proveedor/inicio']);
          } else if (data.usuario.rol === 'Liquidación Administrador') { // Administrador LIQ
            this.router.navigate(['liquidTransportista/administrador/inicio']);
          } else if (data.usuario.rol === 'Liquidación Transportista') { // Transportista LIQ
            this.router.navigate(['liquidTransportista/transportista/inicio']);
          } else if (data.usuario.rol === 'Tracking Administrador') { // Administrador TRK
            this.router.navigate(['tracking/administrador/inicio']);
          } else if (data.usuario.rol === 'Traking Interno') { // Administrador TRK
            this.router.navigate(['tracking/administrador/inicio']);
          } else if (data.usuario.rol === 'Administrador Carga') { // Administrador TRK
            this.router.navigate(['tracking/administrador/inicio']);
          }

        } else {
          this.mensaje = 1;
        }

      }, (err) => {
        this.mensaje = 1;
      });
    // }
  }

  funcionesDePantalla(item: Array<FuncionesVO>) {
    let funciones = '';
    let numero = 0;
    item.forEach(funcion => {
      numero = numero + 1;
      if (item.length === numero) {
        funciones += funcion.codigoFuncion;
      } else {
        funciones += funcion.codigoFuncion + ',';
      }
    });
    return funciones;
  }

  eliminarSesion() {
    localStorage.removeItem('usuario');
    localStorage.removeItem('token');
    localStorage.removeItem('nombre');
    localStorage.removeItem('fecha');
    localStorage.removeItem('rol');
    localStorage.removeItem('email');
    localStorage.removeItem('codProveedor');
  }

  radioChecked() {
    this.mensaje = 0;
    this.radiador = true;
    const radioInfo = $('.adminCheck').prop('checked');

    if (radioInfo) {
      $('input[name="radio[]"]').each(function () {
        this.radiador = true;
      });
    } else {
      $('input[name="radio[]"]').each(function () {
        this.radiador = false;
      });
    }
    console.log('PRUEBA CHECK LOGIN:' + this.radiador);
  }

  radioChecked2() {
    this.mensaje = 0;
    this.radiador = false;
    console.log('PRUEBA CHECK LOGIN:' + this.radiador);
  }

  validaUsuarioFicticio(username: string, password: string) {

  }
}

