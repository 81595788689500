export class ResponseServiceBoletasAtrasos {
    codigo: number;
    mensaje: string;
    body: Detalle[];

}

export class Detalle {
    cod_boleta: number;
    est_atraso: string;
    est_actual: string;
    tipo_venta: string;
    sku: number;
    des_producto: string;
    cant_producto
    obs_atraso: string;
    nota_credito: string;
    est_sku: string;
    tiem_solucion: string;
    adic_1: string;
    adic_2: string;
    adic_3: string;
    adic_4: string;
    crr_atrasos: number;
}