import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {TokenService} from '../../../../comun/services/token/token.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {SeguimientoOrdenesCompra} from '../../../../comun/services/ServicioSeguimientoOrdenesCompra/seguimiento.ordenes.compra.service';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseSeguimiento, SeguimientoOrdenCompra, SeguimientoOrdenCompraFirst} from '../../../../comun/vo/responseSeguimiento';
import {ResponseError} from '../../../../comun/vo/error';
import {DepartamentoLista} from '../../../../comun/vo/responseDepartamento';
import {ResponseBodega} from '../../../../comun/vo/responseBodega';
import {ResponseEstadoSeguimiento} from '../../../../comun/vo/responseEstadosSeguimiento';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-seguimiento-administrador',
  templateUrl: './seguimiento-administrador.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  /*styleUrls: ['./seguimiento-administrador.component.css']*/

})
export class SeguimientoAdministradorComponent implements OnInit {
  public ordenes: SeguimientoOrdenCompra[];
  private estActual;
  private estTodo: any = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21';
  page = 1;
  pageSize = 5;
  responseOrden: SeguimientoOrdenCompraFirst;
  totalProductos: number;
  filtroActivo: boolean;
  isTabPrincipal: boolean;
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  messageAlert: MensajeAlert;
  public bodegas: any[];
  public departamentos: any[];
  public tipoAlertas: any[];
  public estados: any[];
  responseSeguimiento: ResponseSeguimiento;
  deptoLista: DepartamentoLista;
  responseBodega: ResponseBodega;
  responseEstados: ResponseEstadoSeguimiento;

  constructor(private _tablaService: ObtenerOrdenesCompra,
              private spinnerService: Ng4LoadingSpinnerService,
              private _token: TokenService,
              private _router: Router,
              private modalService: BsModalService,
              private _seguimiento: SeguimientoOrdenesCompra,
              private _excelService: ExcelService) { }

  ngOnInit() {
    this.validarSesion();
    this.obtenerDatosSeguimiento(this.estActual);
    this.selectPrincipalValue = 5;
    this.obtenerBodegas();
    this.obtenerDepartamentos();
    this.obtenerEstados();
  }

  validarSesion() {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this._router.navigate(['/']);
    }
  }

  obtenerDatosSeguimiento(estado: string) {
    this.spinnerService.show();
    this._seguimiento.getSeguimientoOrdenes(this.page, this.pageSize, '', '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseSeguimiento = data.body;
        this.responseOrden = this.responseSeguimiento.ordenCompraPaginadorSeguimiento;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  filtro() {
    this.filtroActivo = true;
    // Variables para mandar al servicio, se deben validar
    const selectDepartamento = $('#selectDepartamento').val();
    console.log('selectDepartamento: ' + selectDepartamento);
    const selectBodega = $('#selectBodega').val();
    console.log('selectBodega: ' + selectBodega );
    const selectTipoDespacho = $('#selectDespacho').val();
    const selectTipoAlerta = $('#selectTipoAlerta').val();
    const selectEstados = $('#selectEstados').val();

    if (selectDepartamento === 'n' && selectBodega === 'n' && selectTipoAlerta === 'n' && selectEstados === 'n' && selectTipoDespacho === 'n') {
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else {
      this.spinnerService.show();
      this._seguimiento.getSeguimientoOrdenesFiltros(this.page, this.pageSize, this.validaSelect(selectBodega), this.validaSelect(selectDepartamento),
        this.validaSelect(selectTipoDespacho), this.validaSelect(selectTipoAlerta), '', this.validaSelect(selectEstados))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseSeguimiento = data.body;
          this.responseOrden = this.responseSeguimiento.ordenCompraPaginadorSeguimiento;
          this.ordenes = this.responseOrden.dataPage;
          console.log('resultado' + JSON.stringify(data));
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  exportarExcel() {
    // Variables para mandar al servicio, se deben validar
    this.spinnerService.show();
    const tipoDespacho = $('#selectDespacho').val();
    const selectEstados = $('#selectEstados').val();

    const selectDepartamentos = $('#selectDepartamento').val();
    const selectBodega = $('#selectBodega').val();
    const fechaDesde = $('#date-desde').val();
    const fechaHasta = $('#date-hasta').val();

    console.log(tipoDespacho);

      this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.validaSelectEstado(selectEstados), '', this.validaSelect(selectDepartamentos),
      this.validaSelect(selectBodega), this.validaFecha(fechaDesde), this.validaFecha(fechaHasta), this.validaSelect(tipoDespacho), '', '', '', this.validaFiltro(selectDepartamentos, selectBodega, selectEstados, tipoDespacho))
      .subscribe((data: HttpResponse<any>) => {
        let ordenesDVH: any[];
        console.log(data);
        ordenesDVH = data.body.ordenCompraPaginador.dataPage;
        // this.ordenes = data.dataPage;
        const nuevoArray: Array<any> = [];
        ordenesDVH.forEach(
          item => {
            nuevoArray.push(
              {
                'Fecha Venta': item.fechaVenta,
                'Local Venta': item.localVenta,
                'Glosa Tienda': item.glosaTienda,
                'N° Boleta': item.numeroBoleta,
                'N° Caja': item.numCaja,
                'Glosa Estado': item.estado,
                'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                'Codigo Departamento': item.codigoDepartamento,
                'Glosa Departamento': item.glosaDepto,
                'Codigo Corto': item.codigoCorto,
                'SKU': item.sku,
                'Codigo de Barra': item.codigoBarra,
                'Glosa SKU': item.glosaSku,
                'Cantidad': item.cantidad,
                'Rut Proveedor': item.rutProveedor,
                'Razon Social': item.proveedor,
                'Sucursal Despacho': item.codSucursalDespacho,
                'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                'Rut Cliente': item.rutCliente,
                'Nombre Cliente': item.nombreCliente,
                'Direccion Cliente': item.direccionCliente,
                'Ciudad': item.ciudadCliente,
                'Comuna': item.comuna,
                'Fono Cliente': item.fonoCliente,
                'Fono Alternativo': item.fonoAlternativo,
                'Observacion': item.observacion,
                'Precio Venta': item.precioVenta,
                'N° OC': item.numOc,
                'Costo Neto OC': item.costoNetoOC,
                'Fecha Vigencia OC': item.fechaVigenciaOc,
                'Fecha Entrega CD': item.fechaEntregaCD,
                'Fecha Entrega Cliente': item.fechaEntregaCliente,
                'Fecha Agenda': item.fechaAgenda,
                'Estado: ': item.estado
              });
          });

        if ($('#selectDespacho').val() === 'DDC') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_Administrador');
          $('#selectDespacho').val('n');
          this.spinnerService.hide();
        } else if ($('#selectDespacho').val() === 'DVH') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DVH_Administrador');
          $('#selectDespacho').val('n');
          this.spinnerService.hide();
        } else {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_DVH_Administrador');
          this.spinnerService.hide();
        }
      }, error2 => {
        this.spinnerService.hide();
      });
  }

  validaSelectEstado(valor: any): any {
    if (valor === 'n') {
      return this.estTodo;
    } else {
      return valor;
    }
  }

  validaSelect(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  quitarFiltro() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosSeguimiento(this.estActual);
    } else {
      this.obtenerDatosSeguimiento(this.estActual);
    }
  }

  cambioSelect(registros: number) {
    this.page = 1;
    console.log('Registros: ' + registros);
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtro();
    } else {
      this.obtenerDatosSeguimiento(this.estActual);
    }
  }
  validaFiltro(depto: string, bodega: string, estado: string, tipoDespacho: string): string {
    if (depto === 'n' && bodega === 'n' && estado === 'n' && tipoDespacho === 'n') {
      return 'true';
    } else {
      return 'false';
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;

    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosSeguimiento(this.estActual);
      } else {
        this.filtro();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosSeguimiento(this.estActual);
      } else {
        this.filtro();
      }
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      console.log(JSON.stringify(data.body));
      this.responseBodega = data.body;
      this.bodegas = this.responseBodega.bodegas;
    });
  }

  obtenerDepartamentos() {
    this._tablaService.getDepartamentos().subscribe((data: HttpResponse<any>) => {
      console.log('Departamentos: ' + JSON.stringify(data.body));
      this.deptoLista = data.body;
      this.departamentos = this.deptoLista.departamentosVOList;
    });
  }

  obtenerEstados() {
    this._tablaService.getEstados('')
      .subscribe((data: HttpResponse<any>) => {
      console.log('resultado Estados: ' + JSON.stringify(data.body));
      this.responseEstados = data.body;
      this.estados = this.responseEstados.responseSelectList;
    });
  }

}
