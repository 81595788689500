import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var cargaAnimacion: any;
declare var animaVelocimetro: any;
declare var flexFont: any;


@Component({
  selector: 'app-velocimetro',
  templateUrl: './velocimetro.component.html',
  styleUrls: ['./velocimetro.component.css']
})

export class VelocimetroComponent implements OnInit {

  @Input() public data: any;
  @Output() elClick = new EventEmitter();


  constructor() {
  }

  ngOnInit() {
    document.getElementById('grafico').id = 'vlm' + this.data.id;

    cargaAnimacion('vlm' + this.data.id, this.data.valor, animaVelocimetro);
    flexFont();
  }

  llamadaClick(funAllamar) {

    this.elClick.emit({dato: funAllamar});

  }

  emitterevent(e) {
    this.elClick.emit(e);
  }


}

