import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking-cliente',
  templateUrl: './tracking-cliente.component.html',
  styleUrls: ['./tracking-cliente.component.css']
})
export class TrackingClienteComponent implements OnInit {

  @Input('estado') estado: string;
  @Input('tipo') tipo: string;
  @Input('pasos') pasos: number;

  constructor() { }

  ngOnInit() {

    //this.estado = 'ORDEN RECIBIDA';
    //this.estado = 'ORDEN CONFIRMADA';
    //this.estado = 'EN TRANSITO';
    //this.estado = 'BODEGA REGIONAL';
    //this.estado = 'ORDEN LISTA';
    //this.estado = 'ORDEN ENTREGADA';
    //this.estado = 'ORDEN ENTREGADA BODEGA';

    //this.tipo = 'RET';
    //this.tipo = 'DAD';

    //this.pasos = 4;
  }



}
