import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnvioCorreoVO} from '../../vo/envioCorreoVO';
import {environment} from '../../../../environments/environment';

@Injectable()
export class EnvioCorreoService {

  // private url1 = 'http://10.107.101.106:8347/vev/enviarCorreo';

  private url1 = environment.dominioServicios + '/vev/EnviarEmail/enviarCorreo';

  constructor(private _http: HttpClient) {
    console.log('Servicio listo para utilizar!');
  }

  enviarMailAgenda(envio: EnvioCorreoVO) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      observe: 'response'
    });
    return this._http.post(this.url1, {tipoMensaje: envio.tipoMensaje, numOrden: envio.numOrden});
  }
}
