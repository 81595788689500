import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class DetalleDiaAgenda {

  /*private url = 'http://10.107.101.106:8400/vev/detalleDia';
  private url2 = 'http://10.107.101.106:8400/vev/horasDisponibles';*/

  private url = environment.dominioServicios + '/vev/Calendario/detalleDia';
  private url2 = environment.dominioServicios + '/vev/Calendario/horasDisponibles';


  constructor(private _http: HttpClient) {
  }

/*  getDetalleDiaAgenda(fechaConsulta: any) {
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getBodegaAgenda(fechaConsulta: any, codBodega: any) {
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codBodega=' + codBodega,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
  getProveedorAgenda(fechaConsulta: any, codProveedor: any) {
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codProveedor=' + codProveedor,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }*/

  getDetalleDiaAdmin(fechaConsulta: any, codProveedor?: any, codBodega?: any, estado?: any) {
    if (codProveedor === undefined) {
      codProveedor = '';
    }
    if (codBodega === undefined) {
      codBodega = '';
    }
    if (estado === undefined) {
      estado = '';
    }
    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codProveedor=' + codProveedor + '&codBodega=' + codBodega + '&admin=' + 'true' + '&estado=' + estado,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getDetalleDiaProveedor(fechaConsulta: any, codProveedor: any, codBodega?: any) {
    if (codBodega === undefined) {
      codBodega = '';
    }
    // if (codProveedor === 'undefined') {
    //   codProveedor = '';
    // }
   // return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codProveedor=' + codProveedor + '&codBodega=' + codBodega,

    return this._http.get(this.url + '?fechaConsulta=' + fechaConsulta + '&codProveedor=' + codProveedor + '&codBodega=' + codBodega,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getHoraDisponible(dia: any) {
    return this._http.get(this.url2 + '?dia=' + dia + '&bodega=36',
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
