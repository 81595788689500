import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {DetalleDiaAgenda} from '../../../../comun/services/ServicioDetalleDiaAgenda/detalle.dia.agenda.service';
import {CambiarEstadoOrdenes} from '../../../../comun/services/ServicioCambiarEstadoOrdenes/cambiar.estado.ordenes.service';
import {ResponseCabecera, ResponseDetalleAgenda, ResponseDetalleDia} from '../../../../comun/vo/responseDetalleAgenda';
import {ResponseError} from '../../../../comun/vo/error';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {Router} from '@angular/router';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseHoraDisponible} from '../../../../comun/vo/responseHoraDisponible';
import {ResponseCambioEstado} from '../../../../comun/vo/responseCambioEstado';
import {AgendaCalendario} from '../../../../comun/services/ServicioAgendaCalendario/agenda.calendario.service';
import {ResponseAgenda, ResponseCalendarioVO} from '../../../../comun/vo/responseAgenda';
import {TokenService} from '../../../../comun/services/token/token.service';
import {EnvioCorreoService} from '../../../../comun/services/ServicioEnvioCorreo/envioCorreo.service';
import {EnvioEmailVO, ResponseEnviarMail} from '../../../../comun/vo/responseEnviarMail';
import {EnvioCorreoVO} from '../../../../comun/vo/envioCorreoVO';
import {DatePipe} from '@angular/common';
import {ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {ProveedoresLista} from '../../../../comun/vo/responseProveedorSelect';
import {ResponseEstadoSeguimiento} from '../../../../comun/vo/responseEstadosSeguimiento';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-agenda-administrador',
  templateUrl: './agenda-administrador.component.html',
  styleUrls: ['../../../../../assets/css/calendario.css' , '../../../../../assets/css/koma.css' , '../../../../../assets/css/principal.css']
  /*styleUrls: ['./agenda-administrador.component.css']*/
})
export class AgendaAdministradorComponent implements OnInit {
  public fechaActual: string;
  public responseDetalleDia: ResponseDetalleDia;
  filtroActivo: boolean;
  public bodegas: any[];
  public radiador: boolean;
  @ViewChild('modalDetalleOrdenes') modalDetalleOrdenes;
  modalRefDetalleOrden: BsModalRef;
  @ViewChild('modalAccion') modalAccion;
  modalRefAccion: BsModalRef;
  messageAlert: MensajeAlert;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  public proveedor: any[];
  public responseHoraDisponible: ResponseHoraDisponible[];
  private _responseCambioEstado: ResponseCambioEstado;
  public dia: ResponseDetalleAgenda;
  textAreaModal: string;
  responseAgenda: ResponseAgenda;
  responseAgendaLoad: boolean;
  public textValue: string;
  public proveedores: any[];
  public estados: any[];
  private estTodo: any = '4,9,10';
  isTabPrincipal: boolean;
  responseAgendaProveedor: ResponseAgenda;
  listaCheckAgenda: Array<any> = [];
  isChecked = false;
  public numberOcs: Array<number> = [];
  public ocsProcesar: Array<number> = [];
  public chequeadosAgenda: boolean = false;
  public mesActualAdmin: string;
  public fechaActualAdmin: string;
  public numOcCheck: Array<number> = [];
  _responseCalendario: ResponseCalendarioVO;
  _responseCabezera: ResponseCabecera;
  _proveedorLista: ProveedoresLista;
  _responseEstados: ResponseEstadoSeguimiento;
  _envioCorreo: EnvioEmailVO;
  isChequeado: boolean;

  constructor(private _tablaService: ObtenerOrdenesCompra,
              private modalService: BsModalService,
              private _detalleDiaAgenda: DetalleDiaAgenda,
              private spinnerService: Ng4LoadingSpinnerService,
              private _getProveedorAgenda: DetalleDiaAgenda,
              private _router: Router,
              private _excelService: ExcelService,
              private _horaDisponible: DetalleDiaAgenda,
              private _agendaAdmin: CambiarEstadoOrdenes,
              private _agendaCalendario: AgendaCalendario,
              private _token: TokenService,
              private datePipe: DatePipe,
              private _envioCorreoService: EnvioCorreoService) {
  }

  ngOnInit() {
    this.validarSesion();
    this.obtenerBodegas();
    this.obtenerProveedores();
    this.horasDisponiblesCall();
    this.obtenerEstados();
    this.responseAgendaLoad = false;
    this.obtenerCalendario();
  }
  // ngAfterViewInit() {
  //   this.responseAgendaLoad = false;
  //   this.obtenerCalendario();
  // }

  validarSesion() {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
      this._router.navigate(['/']);
    }
  }

  obtenerBodegas() {
    this._tablaService.getBodegas().subscribe((data: HttpResponse<any>) => {
      this.bodegas = data.body.bodegas;
    });
  }

  obtenerEstados() {
    this._tablaService.getEstados('agendaAdmin').subscribe((data: HttpResponse<any>) => {
      // const estadoConsulta = JSON.parse(JSON.stringify(data));
      this._responseEstados = data.body;
      this.estados = this._responseEstados.responseSelectList;
      console.log('ESTOS ESTADOS VEV: ' + JSON.parse(JSON.stringify(data)));
    });
  }

  abrirModalDetalleOrden(fecha: string) {
    this.spinnerService.show();
    console.log('Fecha: ' + fecha);
    this.fechaActual = fecha;
    this.modalRefDetalleOrden = this.modalService.show(this.modalDetalleOrdenes, {class: 'modal-do2'});
    this._detalleDiaAgenda.getDetalleDiaAdmin(fecha)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseCabezera = data.body;
        this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  abrirModalAccion(dia, type) {
    const checkeadoTodos = $('#selectAllAgendaAdmin').prop('checked');

    console.log('this.listaCheckAgenda: ' + this.listaCheckAgenda);
    console.log('this.numberocs: ');
    console.log(this.numberOcs);
    const codOC = this.numberOcs;

      if (codOC.toString() === '') {
        this.abrirModalError('Debe seleccionar al menos una orden de compra.', 'Error.');
      } else {
        this.dia = dia;
        this.modalRefAccion = this.modalService.show(this.modalAccion, {class: 'modal-ca'});
      }
  }



  limpiarModal() {
    this.modalRefAccion.hide();
    this.radiador = false;
  }

  filtroAgenda() {
    this.spinnerService.show();
    this.filtroActivo = true;
    console.log('select bodega: ' + $('#selectBodegaAgenda').val());

    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();
    const selectProveedor = $('#selectProveedorAgenda').val();
    const selectProveedorEstado = $('#selectProveedorEstado').val();


    if (selectBodega === 'Seleccionar' && selectProveedor === '0' && selectProveedorEstado === '0') {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else if (selectBodega !== 'Seleccionar' && selectProveedor === '0') {
      this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual, '', selectBodega, '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCabezera = data.body;
          this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
          const tokenfull =  data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        });
    } else if (selectBodega === 'Seleccionar' && selectProveedor !== '0' && selectProveedorEstado === '0') {
      this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual, selectProveedor, '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCabezera = data.body;
          this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
          const tokenfull =  data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        });
    } else if (selectBodega === 'Seleccionar' && selectProveedor === '0' && selectProveedorEstado !== '0') {
      this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual, '', '', selectProveedorEstado)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCabezera = data.body;
          this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
          const tokenfull =  data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        });
    }
  }

  /*exportarExcelAgenda() {
    // Variables para mandar al servicio, se deben validar
    const selectBodega = $('#selectBodegaAgenda').val();

    // if ($('#selectBodegaAgenda').val() === '0') {
    this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual)
      .subscribe((data: HttpResponse<ResponseDetalleDia>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this.responseDetalleDia = data.body;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
    const nuevoArray: Array<any> = [];
    this.responseDetalleDia.detalles.forEach(
      item => {
        nuevoArray.push(
          {
            'Número Boleta': item.numBoleta,
            'Número OC': item.numOC,
            'Costo OC': item.costoOC,
            'Motivo': item.obsAgenda,
            'Bodega de Entrega': item.codBodega,
            'Fecha Agenda': item.fechaAgenda,
            'Hora Recepcion': item.horaRecepcion,
            'Proveedor': item.glosaProveedor,
            'Estado': item.glosaEstadoOc
          });
      });
    this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Administrador');
  }*/

  exportarExcelAgenda() {
    // Variables para mandar al servicio, se deben validar
    this.spinnerService.show();

    const selectBodega = $('#selectBodegaAgenda').val();
    const selectProveedor = $('#selectProveedorAgenda').val();
    const selectProveedorEstado = $('#selectProveedorEstado').val();

    const listaCodProducto: Array<string> = [];
    const listaTipoDespacho: Array<string> = [];


    this._tablaService.getObtenerOrdenesProductoFiltro(1, 99999, this.validaEstado(selectProveedorEstado), '', '',
      '', '', '', '', this.validaProveedor(selectProveedor), '', '', this.validaFiltro(selectBodega))
      .subscribe((data: HttpResponse<any>) => {
        let ordenesDVH: any[];
        console.log('DATA EXCEL AGENDA ADMIN:' + data);
        // ordenesDVH = data.body.dataPage;
        // this._responseCabezera = data.body;
        // this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
        ordenesDVH = data.body.ordenCompraPaginador.dataPage;
        // this.ordenes = data.dataPage;
        const nuevoArray: Array<any> = [];
        ordenesDVH.forEach(
          item => {
            nuevoArray.push(
              {
                'Fecha Venta': item.fechaVenta,
                'Local Venta': item.localVenta,
                'Glosa Tienda': item.glosaTienda,
                'N° Caja': item.numCaja,
                'Glosa Estado': item.estado,
                'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                'Codigo Departamento': item.codigoDepartamento,
                'Glosa Departamento': item.glosaDepto,
                'Codigo Corto': item.codigoCorto,
                'SKU': item.sku,
                'Codigo de Barra': item.codigoBarra,
                'Glosa SKU': item.glosaSku,
                'Cantidad': item.cantidad,
                'Rut Proveedor': item.rutProveedor,
                'Razon Social': item.proveedor,
                'Sucursal Despacho': item.codSucursalDespacho,
                'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                'Rut Cliente': item.rutCliente,
                'Nombre Cliente': item.nombreCliente,
                'Direccion Cliente': item.direccionCliente,
                'Ciudad': item.ciudadCliente,
                'Comuna': item.comuna,
                'Fono Cliente': item.fonoCliente,
                'Fono Alternativo': item.fonoAlternativo,
                'Observacion': item.observacion,
                'Precio Venta': item.precioVenta,
                'N° OC': item.numOc,
                'Costo Neto OC': item.costoNetoOC,
                'Fecha Vigencia OC': item.fechaVigenciaOc,
                'Sucursal Entrega OC': item.codSucursalEntrega,
                'Fecha Entrega CD': item.fechaEntregaCD,
                'Fecha Entrega Cliente': item.fechaEntregaCliente,
                'Fecha Agenda': item.fechaAgenda
              });
          });
        this.spinnerService.hide();
        this._excelService.exportAsExcelFile(nuevoArray, 'Agenda Administrador');
      }, error2 => {
        this.spinnerService.hide();
      });
  }

  validaFiltro(bodega: string): string {
    if (bodega === 'Seleccionar') {
      return 'true';
    } else {
      return 'false';
    }
  }

  validaProveedor(proveedor: string): string {
    if (proveedor === '0') {
      return '';
    } else {
      return proveedor;
    }
  }

  validaEstado(estado: string): string {
    if (estado === '0') {
      return this.estTodo;
    } else {
      return estado;
    }
  }

  // }
  recargarAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabezera = data.body;
        this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }
  quitarFiltroAgenda() {
    this.spinnerService.show();
    this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log(JSON.stringify(data));
        this._responseCabezera = data.body;
        this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
        const tokenfull =  data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      });
  }

  radioChecked() {
    this.radiador = true;
    const radioInfo = $('.prueba').prop('checked');

    if (radioInfo) {
      $('input[name="radio[]"]').each(function () {
        this.radiador = true;

      });
    } else {
      $('input[name="radio[]"]').each(function () {
        this.radiador = false;
      });
    }
    console.log('RECHAZADO:' + this.radiador);
  }


  radioChecked2() {
    this.radiador = false;
    console.log('APROBADO:' + this.radiador);
  }

  public aprobarRechazarOrden() {
    let codOC;
    let motivoRechazo;
    let accion;
    const radioRechazo = $('.ingreseMotivo').val();

    if (!this.radiador) {
        codOC = this.numberOcs;
        motivoRechazo = null;
        accion = '1';
        console.log('cod oc: ' + codOC);
      this._agendaAdmin.getAgendaAdministrador(accion, motivoRechazo, codOC)
        .subscribe((data: HttpResponse<any>) => {
          this.chequeadosAgenda = true;
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCambioEstado = data.body;
          this.modalRefAccion.hide();
          this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual)
            .subscribe((data1: HttpResponse<any>) => {
              this._responseCabezera = data1.body;
              this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
              const tokenfull =  data.headers.get('Authorization');
              this._token.actualizaToken(tokenfull);
              this.limpiarCheck();
              }
            );
        });
    } else if (this.radiador = true && radioRechazo === '') {
      this.abrirModalError('Debes ingresar motivo de rechazo', 'Error.');
    } else if (this.radiador = true && radioRechazo !== '') {
      codOC = this.numberOcs;
      motivoRechazo = radioRechazo;
      accion = '2';
      this._agendaAdmin.getAgendaAdministrador(accion, motivoRechazo, codOC)
        .subscribe((data: HttpResponse<any>) => {
          this.chequeadosAgenda = true;
          this.spinnerService.hide();
          console.log(JSON.stringify(data));
          this._responseCambioEstado = data.body;
          this.modalRefAccion.hide();
          this._detalleDiaAgenda.getDetalleDiaAdmin(this.fechaActual)
            .subscribe((data1: HttpResponse<any>) => {
              this._responseCabezera = data1.body;
              this.responseDetalleDia = this._responseCabezera.responseDetalleCabecera;
              const tokenfull =  data.headers.get('Authorization');
              this._token.actualizaToken(tokenfull);
              this.limpiarCheck();
              }
            );
        });
    }
  }

  public obtenerCalendario() {
    this.spinnerService.show();
    const fecha = new Date();
    // fecha = fecha.replace('/', '-');
    // fecha = fecha.replace('/', '-');
    const fechaFormateada = this.datePipe.transform(fecha, 'yyyy-MM-dd');
    console.log('SOLO MES ACTUAL: ' + this.datePipe.transform(fecha, 'MM'));
    console.log('Fecha Formateada: ' + fechaFormateada);
    this._agendaCalendario.getCalendario(fechaFormateada)
      .subscribe((data: HttpResponse<any>) => {
        this.mesActualAdmin = data.body.desMesActual;
        this.spinnerService.hide();
        console.log('Response: ' + JSON.stringify(data));
        this._responseCalendario = data.body;
        this.responseAgenda = this._responseCalendario.responseCalendaroVO;
        // console.log('Mes actual: ' + data.desMesActual);
        this.responseAgendaLoad = true;
      });
    this.fechaActualAdmin = fechaFormateada;
  }

  public obtenerAgendaCalendarioAdmin(date, mes) {
    this.spinnerService.show();
    let fecha = date;
    try {
      fecha = fecha.replace(' 00:00:00', '');
      fecha = fecha.replace('.0', '');
      fecha = fecha.replace('/', '-');
    } catch (e) {

    }
    console.log('FECHA NEW DATE: ' + fecha);

    if (mes === this.mesActualAdmin){
      this._agendaCalendario.getCalendario(this.fechaActualAdmin)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response: ' + JSON.stringify(data));
          this._responseCalendario = data.body;
          this.responseAgenda = this._responseCalendario.responseCalendaroVO;
          // console.log('Mes actual: ' + data.desMesActual);
          this.responseAgendaLoad = true;
        });
    } else {
      this._agendaCalendario.getCalendario(fecha)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          console.log('Response: ' + JSON.stringify(data));
          this._responseCalendario = data.body;
          this.responseAgenda = this._responseCalendario.responseCalendaroVO;
          // console.log('Mes actual: ' + data.desMesActual);
          this.responseAgendaLoad = true;
        });
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
    console.log('' + this.messageAlert);
  }

  obtenerProveedores() {
    this._tablaService.getProveedores().subscribe((data: HttpResponse<any>) => {
      console.log('Proveedores lista: ' + JSON.stringify(data.body));
      this._proveedorLista = data.body;
      this.proveedores = this._proveedorLista.proveedorVOList;
    });
  }

  public horasDisponiblesCall() {
    this._horaDisponible.getHoraDisponible(this.fechaActual)
      .subscribe((data: HttpResponse<any>) => {
        this.responseHoraDisponible = data.body;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        console.log('resultado hora disponible' + JSON.stringify(data));
      });
  }

  seleccionarTodoAgendaAdmin() {
    const checkeadoTodos = $('#selectAllAgendaAdmin').prop('checked');

    if (checkeadoTodos) {
      this.responseDetalleDia.detalles.forEach(item => {
        this.listaCheckAgenda.push(item.registro);
        if (item.codEstadoOc === '9') {
          this.numberOcs.push(item.crrOrden);
        }
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = true;
      });
      console.log('THIS.CHECKED TRUE: ' + checkeadoTodos);
    } else {
      this.responseDetalleDia.detalles.forEach(item => {
        const posicion = this.listaCheckAgenda.indexOf(item.registro);
        this.listaCheckAgenda.splice(posicion, 1);
        this.numberOcs.splice(posicion, 1);
      });
      $('input[name="checkAgenda[]"]').each(function () {
        this.checked = false;
      });
      console.log('THIS.CHECKED FALSE: ' + checkeadoTodos);
    }
  }
  changeAgendaAdmin(e, type, crrOC, codProveedor, numOC) {
    // console.log(e.target.checked);
    // console.log(type);
    if (e.target.checked === true) {
      this.listaCheckAgenda.push(type);
      this.isChecked = true;
      console.log('CHEQUEADO if ' + this.isChecked.toString());
      console.log('e: ' + JSON.stringify(e));
      console.log('type: ' + type);
      this.numberOcs.push(crrOC);
      this.ocsProcesar.push(codProveedor);
      this.numOcCheck.push(numOC);
    } else {
      const index = this.listaCheckAgenda.indexOf(type);
      const indexOC = this.numberOcs.indexOf(crrOC);
      const indexProveedor = this.ocsProcesar.indexOf(codProveedor);
      this.listaCheckAgenda.splice(index, 1);
      console.log('CHEQUEADO else ' + this.isChecked.toString());
      console.log('e: ' + JSON.stringify(e));
      console.log('type: ' + type);
      console.log('index: ' + index);
      this.numberOcs.splice(indexOC);
      this.ocsProcesar.splice(indexProveedor);
    }
    console.log('THIS.NUMERBSPC: ' + this.ocsProcesar);
  }

  limpiarCheck() {
    this.numberOcs = this.numberOcs.splice(0, this.numberOcs.length);
    this.listaCheckAgenda = this.listaCheckAgenda.splice(0, this.listaCheckAgenda.length);
  }

  procesarOcs() {

    if (this.chequeadosAgenda){
      console.log('Procesando..');
      const mailReq = new EnvioCorreoVO();
      let codProveedorOCS: string = '';

      this.ocsProcesar.forEach( item =>
        codProveedorOCS = codProveedorOCS + ';' + item
      );
      console.log('COD PROVEEDOR OCS: ' + codProveedorOCS);

      mailReq.tipoMensaje = 'enviarEmailProveedorOrdenAgendadaRechazada' + codProveedorOCS;
      mailReq.numOrden = 1;

      this._envioCorreoService.enviarMailAgenda(mailReq)
        .subscribe( ( data: HttpResponse<any>) => {
          console.log('Response: ' + data.body);
          this._envioCorreo =  data.body;
          const response = this._envioCorreo.responseVO;
          if (response.codigo === 28) {
            this.abrirModalError('Proceso realizado con éxito.', 'Éxito.');
          } else {
            this.abrirModalError('Problemas al enviar correo.', 'Error.');
          }
        });
    } else {
      this.abrirModalError('Aun no se aprueban o rechazar ordenes', 'Error');
    }
  }
}
