import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {HttpResponse} from '@angular/common/http';
import {ObtenerGuiaDespachoService} from '../services/ObtenerGuiaDespacho/obtener.guia.despacho.service';
import {TokenService} from '../services/token/token.service';
import {GuíaDespacho, ResponseGuiaDespacho} from '../vo/responseGuiaDespacho';

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['../../../assets/css/koma.css']
  /*styleUrls: ['./menu.component.css']*/
})
export class MenuComponent implements OnInit {
  rol: string;
  name: string;
  reca: string;
  @ViewChild('modalGuiaDespacho') modalGuiaDespacho;
  modalRefGuiaDespacho: BsModalRef;
  responseGuiaDespacho: ResponseGuiaDespacho;
  mensajeModal: string;
  mensajePPL: string;
  urlPPL: string;
  _guiaDespacho: GuíaDespacho;
  activacionBoton: boolean;
  intervalBoton: any;


  constructor(private router: Router,
              private modalService: BsModalService,
              private _guiaDespachoService: ObtenerGuiaDespachoService,
              private _token: TokenService) {

  }

  ngOnInit() {
    this.rol = localStorage.getItem('rol');
    this.name = localStorage.getItem('nombre');
    this.activacionBoton = false;
    this.intervalBoton = setInterval(() => {
      this.horaFuncionamiento();
    }, 5000);

  }

  salir() {
    this.router.navigate(['/']);
  }

  horaFuncionamiento() {
    const horaActual = new Date().getHours();
    if (horaActual >= 6 && horaActual < 17) {
      this.activacionBoton = true;
    } else {
      this.activacionBoton = false;
    }
  }

  abrirModalGuiaDespacho() {
    this.modalRefGuiaDespacho = this.modalService.show(this.modalGuiaDespacho, {class: 'modal-do3'});
  }

  obtenerGuiaDespacho() {
    this.mensajeModal = null;
    this.urlPPL = null;
    this.mensajePPL = null;
    this._guiaDespachoService.getGuiaDespacho($('#folioBoleta').val())
      .subscribe((data: HttpResponse<any>) => {
        console.log(JSON.stringify(data));
        this._guiaDespacho = data.body;
        this.responseGuiaDespacho = this._guiaDespacho.documentosVO;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        console.log('Return: ' + this.responseGuiaDespacho);

        this.mensajeModal = this.responseGuiaDespacho.mensajeLog;
        if (this.responseGuiaDespacho.urlDocumento != null) {
          this.urlPPL = this.responseGuiaDespacho.urlDocumento;
          this.mensajePPL = null;
        } else {
          this.mensajePPL = this.responseGuiaDespacho.mensajePpl;
        }
      });
  }

  abrirDocumento(url: string) {
    window.open(url);
  }

  reCargar() {
    console.log('reCargar');
    this.reca = window.location.pathname;
    console.log(this.reca);
    if (this.reca === '/tracking/administrador/buscadorBoletas') {
        window.location.reload();
    }
  }
}
