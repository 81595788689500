import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes-administrador',
  templateUrl: './reportes-administrador.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  /*styleUrls: ['./reportes-administrador.component.css']*/
})
export class ReportesAdministradorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
