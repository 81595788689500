import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OrdenCompra, ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {HttpResponse} from '@angular/common/http';
import {ResponseError} from '../../../../comun/vo/error';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {TokenService} from '../../../../comun/services/token/token.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ResponseDetalleDia} from '../../../../comun/vo/responseDetalleAgenda';
import {DetalleDiaAgenda} from '../../../../comun/services/ServicioDetalleDiaAgenda/detalle.dia.agenda.service';
import {ResponseUsuario, ResponseUsuarioCompleto, ResponseUsuarioLista} from '../../../../comun/vo/responseUsuario';
import {ResponseProveedor, ResponseProveedorCompleto, ResponseProveedorLista} from '../../../../comun/vo/responseProveedor';
import {ConfiguracionService} from '../../../../comun/services/ServicioConfiguracion/configuracion.service';
import {FormBuilder, Validators} from '@angular/forms';
import {RutValidator} from 'ng2-rut';
import {ResponseAlerta, ResponseAlertaCompleto} from '../../../../comun/vo/responseAlerta';
import {ProveedoresConfig, ResponseProveedorConfig} from '../../../../comun/vo/responseProveedorConfig';
import {LoginService} from '../../../../comun/services/login/login.service';
import {ResponseAdministrarUsuarioVO, ResponseUsuarioGuardar, UsuarioVO} from '../../../../comun/vo/usuarioVO';
import {ResponseRoles, ResponseRolesCompleto} from '../../../../comun/vo/responseRolesSelect';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

class Rol {
  cod: number;
  nombre: string;
}


@Component({
  selector: 'app-configuraciones-administrador',
  templateUrl: './configuraciones-administrador.component.html',
  styleUrls: ['../../../../../assets/css/koma.css', '../../../../../assets/css/principal.css']
  /*styleUrls: ['./configuraciones-administrador.component.css']*/
})
export class ConfiguracionesAdministradorComponent implements OnInit {
  isTabPrincipal: boolean;
  public ordenes: OrdenCompra[];
  private estActual;
  private estTodo: any = '0,1,2,3,4,5,6,7,8,9,10';
  pageSize = 5;
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  filtroActivo: boolean;
  page = 1;
  pageProveedor = 1;
  responseOrden: ResponseOrdenes;
  totalProductos: number;
  pendienteOcCount: number;
  messageAlert: MensajeAlert;
  @ViewChild('rut') rut;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  private estPendiente: any = 0;
  @ViewChild('modalModificarUsuario') modalModificarUsuario;
  modalRefModificar: BsModalRef;
  @ViewChild('modalAgregarUsuario') modalAgregarUsuario;
  modalRefAgregar: BsModalRef;
  @ViewChild('modalAgregarProveedor') modalAgregarProveedor;
  modalRefAgregarProveedor: BsModalRef;
  @ViewChild('modalModificarProveedor') modalModificarProveedor;
  modalRefModificarProveedor: BsModalRef;
  private _detalleDiaAgenda: DetalleDiaAgenda;
  public responseDetalleDia: ResponseDetalleDia;
  public usuario: ResponseUsuarioLista;
  public responseUsuario: ResponseUsuario[];
  public proveedor: ResponseProveedorLista;
  public responseProveedor: ResponseProveedor[] = [];
  public responseProveedorAll: ResponseProveedor[] = [];
  public selectUsuario: number;
  public usuarioCompleto: any;
  public proveedorCompleto: any;
  public selectRol: any;
  public selectProv: any;
  public rolAux: any;
  public proAux: any;
  public selectRolAux: any;
  private nombreActual;
  private nombreProveedorActual;
  public bodegas: any[];
  public alertas: ResponseAlerta[] = [];
  public departamentos: any[];
  public proveedoresConfig: ResponseProveedorConfig[];
  private nombreProveedorConfig;
  public responseProveedoresConfig: ResponseProveedorConfig = new ResponseProveedorConfig();
  public roles: Rol[];
  public muestraRut: string;
  public reactiveForm: any;
  public userRut: any;
  public responseAlertasDia: ResponseAlerta;
  public emailCorreo: any;
  public nombres: string[] = new Array();
  public config = {displayKey: 'description', search: true, limitTo: 10, placeholder: 'Selecionar'};
  public nombreProveedor: any;
  public usuarioDispoVo: UsuarioVO;
  public responseAdministrarUsuarioVO: ResponseAdministrarUsuarioVO;
  private mantBodega: boolean;
  private mantAnden: boolean;
  _responseUsuarioCompleto: ResponseUsuarioCompleto;
  _responseProveedorCompleto: ResponseProveedorCompleto;
  _responseAlertasCompleto: ResponseAlertaCompleto;
  _responseRolesCompleto: ResponseRolesCompleto;
  responseRolesList: ResponseRoles;
  _guardarUserResponse: ResponseUsuarioGuardar;
  _proveedorConfigResponse: ProveedoresConfig;


  constructor(private spinnerService: Ng4LoadingSpinnerService,
              private _tablaService: ObtenerOrdenesCompra,
              private _token: TokenService,
              private _router: Router,
              private modalService: BsModalService,
              private _tableConfiguracion: ConfiguracionService,
              fb: FormBuilder,
              rutValidator: RutValidator,
              private _loginService: LoginService) {

    this.reactiveForm = fb.group({
      rut: [this.userRut, [Validators.required, rutValidator]]
    });
  }

  ngOnInit() {
    this.emailCorreo = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
    this.responseProveedoresConfig.rut = null;
    this.responseProveedoresConfig.direccion = '';
    this.responseProveedoresConfig.email = '';
    this.responseProveedoresConfig.telefono = '';
    this.isTabPrincipal = true;
    this.estActual = this.estTodo;
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.obtenerDatosProveedor(this.nombreProveedorActual);
    this.selectUsuario = 0;
    this.obtenerBodegas();
    this.obtenerDepartamentos();
    this.obtenerRoles();
    this.obtieneAlertas();
    this.obtenerProveedoresConfig(this.nombreProveedorConfig);
    // this.andenBodegas();
    this.obtenerDatosUsuario(this.nombreActual);
    this.obtenerProveedoresAll();
  }

  tabPrincipal() {
    this.isTabPrincipal = true;
    $('#' + 'tabPrincipal').removeAttr('style');
    this.ordenes = [];
    this.estActual = this.estTodo;
    this.pageSize = this.selectPrincipalValue;
    if (this.filtroActivo) {
      this.filtrar();
    } else {
      this.obtenerDatosProducto(this.estActual);
    }
  }

  cambiarTab(tabActual: string) {
    if (tabActual === 'usuarios') {
      this.obtenerDatosUsuario(this.nombreActual);
    } else if (tabActual === 'proveedores') {
      this.obtenerDatosProveedor(this.nombreProveedorActual);
      $('#' + tabActual).removeAttr('style');
    } else if (tabActual === 'alertas') {
      this.obtieneAlertas();
      $('#' + tabActual).removeAttr('style');
    }
  }

  filtrar() {
    if (!this.filtroActivo) {
      this.page = 1;
    }
    this.filtroActivo = true;
    const nombreUsuario = $('#nombreUsuario').val();

    if (nombreUsuario === '') {
      this.abrirModalError('Debes rellenar filtro nombre usuario!', 'Error.');
    } else {
      this.spinnerService.show();
      this._tableConfiguracion.getUsuario(this.page, nombreUsuario, 'VEV')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this._responseUsuarioCompleto = data.body;
          this.usuario = this._responseUsuarioCompleto.usuariosPaginador;
          this.responseUsuario = this.usuario.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  filtrarProveedor() {
    if (!this.filtroActivo) {
      this.pageProveedor = 1;
    }
    this.filtroActivo = true;
    const nombreProveedor = $('#nombreProveedor').val();

    if (nombreProveedor === '') {
      this.abrirModalError('Debes rellenar filtro nombre usuario!', 'Error.');
    } else {
      this.spinnerService.show();
      this._tableConfiguracion.getProveedor(this.page, nombreProveedor)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this._responseProveedorCompleto = data.body;
          this.proveedor = this._responseProveedorCompleto.proveedorPaginador;
          this.responseProveedor = this.proveedor.dataPage;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  recargar() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosUsuario(this.nombreActual);
    } else {
      this.obtenerDatosUsuario(this.nombreActual);
    }
  }

  recargarProveedor() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosProveedor(this.nombreProveedorActual);
    } else {
      this.obtenerDatosProveedor(this.nombreProveedorActual);
    }
  }

  obtenerDatosProducto(estado: string) {
    this.spinnerService.show();
    this._tablaService.getOrdenesTablaProducto(this.page, this.pageSize, estado, '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        if (this.totalProductos === undefined) {
          this.totalProductos = data.body.totalElementos;
        }
        this.pendienteOcCount = data.body.totalPendienteOC;
        this.responseOrden = data.body;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
        if (this.totalProductos === undefined) {
          this.totalProductos = this.responseOrden.totalElementos;
        }
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  validaSelect(valor: any): any {
    if (valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaFecha(fecha: string): any {
    if (fecha === '') {
      return '';
    } else {
      const fechaSplit = fecha.split('-');

      const anio = fechaSplit[0];
      const mes = fechaSplit[1];
      const dia = fechaSplit[2];

      return dia + '/' + mes + '/' + anio;
    }
  }

  cambioSelect(registros: number) {
    this.page = 1;
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtrar();
    } else {
      this.obtenerDatosProducto(this.estActual);
    }
  }

  formateaRut(rut: any) {
    const rutGuion = rut.indexOf('-');
    if (rutGuion > 0) {
      const rutCompleto = rut;
      return rutCompleto;
    } else {
      const rutsin = rut.slice(0, rut.length - 1);
      const dv = rut.slice(rut.length - 1, rut.length);
      const nuevo = rutsin + '-' + dv;
      return nuevo;
    }
  }

  cambiarPagina(pagina: number) {
    if (this.filtroActivo) {
      this.page = pagina;
      this.filtrar();
    } else {
      this.page = pagina;
      this.obtenerDatosUsuario(this.nombreActual);
    }
  }

  cambiarPaginaProveedor(pagina: number) {
    if (this.filtroActivo) {
      this.pageProveedor = pagina;
      this.filtrarProveedor();
    } else {
      this.pageProveedor = pagina;
      this.obtenerDatosProveedor(this.nombreProveedorActual);
    }
  }

  fixTab(elemento: string) {
    $('#' + elemento).removeAttr('style');
  }

  fixTab2(elemento: string) {
    this.page = 1;
    this.isTabPrincipal = false;
    $('#' + elemento).removeAttr('style');
  }

  abrirModalModificarUsuario(usuario: any) {
    console.log(JSON.stringify(usuario));
    this.usuarioCompleto = usuario;
    this.rolAux = this.usuarioCompleto.rol;
    this.userRut = this.usuarioCompleto.rutUsuario;
    this.modalRefModificar = this.modalService.show(this.modalModificarUsuario, {class: 'modal-do3'});
  }

  modificarUsuario(crrUsuario: any) {
    const nombre = $('#nombre').val();
    console.log('NOMBRE ' + nombre);
    const usuario = $('#usuario').val();
    console.log('USUARIO ' + usuario);
    const pass = $('#pass').val();
    console.log('PASS ' + pass);
    const pass2 = $('#pass2').val();
    console.log('PASS2 ' + pass2);
    const rut = $('#rut').val();
    console.log('RUT ' + rut);
    const email = $('#email').val();
    console.log('email ' + email);
    const selectAgregarUsuario = $('#selectAgregarUsuario').val();
    console.log('SELECT USUARIO' + selectAgregarUsuario);
    const selectProveedor = $('#selectProveedor').val();
    console.log('SELECT PROVEEDOR' + selectProveedor);
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;
    const tipoUsuario = $('#selectAgregarUsuario option:selected').text();

    if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else if (pass !== pass2) {
      this.abrirModalError('Debe introducir las mismas contraseñas!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.getModificarUsuario(crrUsuario, nombre, usuario, pass, rut, email, selectAgregarUsuario, selectProveedor)
        .subscribe((data: HttpResponse<any>) => {

          this._loginService.modificarUsuario(tipoUsuario, nombre, usuario, pass, rut, email, selectProveedor, selectAgregarUsuario)
            .subscribe((data2: HttpResponse<any>) => {
            }, (error: ResponseError) => {
              if (error.error.code === 401) {
                localStorage.clear();
                this._router.navigate(['/']);
              }
            });

          this.spinnerService.hide();
          this.obtenerDatosUsuario(nombre);
          this.modalRefModificar.hide();
          this.selectRol = false;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });


    }
  }

  modificarProveedor(codProveedor: any) {
    this.spinnerService.show();
    const nombre = $('#nombre').val();
    console.log('nombre ' + nombre);
    const rut = $('#rut').val();
    console.log('rut ' + rut);
    const direccion = $('#direccion').val();
    console.log('direccion ' + direccion);
    const email = $('#email').val();
    console.log('email ' + email);
    const telefono = $('#telefono').val();
    console.log('telefono ' + telefono);
    const selectBodega = $('#selectBodega').val();
    console.log('selectBodega ' + selectBodega);
    const selectDepartamento = $('#selectDepartamento').val();
    console.log('selectDepartamento ' + selectDepartamento);
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

    if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.getModificarProveedor(codProveedor, nombre, rut, direccion, email, telefono, '0', '0')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.obtenerDatosProveedor(nombre);
          this.modalRefModificarProveedor.hide();
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  abrirModalAgregarUsuario() {
    this.userRut = '';
    this.modalRefAgregar = this.modalService.show(this.modalAgregarUsuario, {class: 'modal-do3'});
  }

  guardarUsuario() {
    this.spinnerService.show();
    const nombre = $('#nombre').val();
    console.log('NOMBRE ' + nombre);
    const usuario = $('#usuario').val();
    console.log('USUARIO ' + usuario);
    const pass = $('#pass').val();
    console.log('PASS ' + pass);
    const pass2 = $('#pass2').val();
    console.log('PASS2 ' + pass2);
    const rut = $('#rut').val();
    console.log('RUT ' + rut);
    const email = $('#email').val();
    console.log('email ' + email);
    const selectAgregarUsuario = $('#selectAgregarUsuario').val();
    console.log('SELECT USUARIO ' + selectAgregarUsuario);
    let selectProveedor = $('#selectProveedorAdd').val();
    console.log('SELECT PROVEEDOR ' + selectProveedor);
    const tipoUsuario = $('#selectAgregarUsuario option:selected').text();
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

    if (nombre === '' && usuario === ''
      && pass === '' && pass2 === '' && rut === '' && email === '' && selectAgregarUsuario === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (nombre === '' || usuario === ''
      || pass === '' || pass2 === '' || rut === '' || email === '' || selectAgregarUsuario === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (selectAgregarUsuario === '1' && selectProveedor === '0' || selectProveedor === 'undefined') {
      this.abrirModalError('Debes seleccionar el tipo de proveedor!', 'Error.');
      this.spinnerService.hide();
    }  else if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else if (pass !== pass2) {
      this.abrirModalError('Debe introducir las mismas contraseñas!', 'Error.');
      this.spinnerService.hide();
    } else if ((selectAgregarUsuario === '6' || selectAgregarUsuario === '7' || selectAgregarUsuario === '2') && selectProveedor !== '0') {
      this._tableConfiguracion.getAgregarUsuario(nombre, usuario, pass, rut, email, selectAgregarUsuario, selectProveedor, '')
        .subscribe((data: HttpResponse<any>) => {
          this.responseAdministrarUsuarioVO = JSON.parse(JSON.stringify(data));
          if (this.responseAdministrarUsuarioVO.code === 4) {
            this.abrirModalError(this.responseAdministrarUsuarioVO.message, 'Error.');
            this.spinnerService.hide();
          } else {
            if (selectAgregarUsuario !== 1) {
              console.log('Entro al crear usuario');
              if (selectAgregarUsuario === '6' || selectAgregarUsuario === '7') {
                selectProveedor = '0';
              }
// cuando el usuario es rol administrador, no se debne incovar el agregar usuario del login
              this._loginService.agregarUsuario(tipoUsuario, nombre, usuario, pass, rut, email, selectProveedor, selectAgregarUsuario)
                .subscribe((data2: HttpResponse<any>) => {
                }, (error: ResponseError) => {
                  if (error.error.code === 401) {
                    localStorage.clear();
                    this._router.navigate(['/']);
                  }
                });

              this.spinnerService.hide();
              this.obtenerDatosUsuario(nombre);
              this.modalRefAgregar.hide();
              this.selectRol = false;
              const tokenfull = data.headers.get('Authorization');
              this._token.actualizaToken(tokenfull);
            }
          }
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    } else if (selectAgregarUsuario === '1') {
      this._tableConfiguracion.getAgregarUsuario(nombre, usuario, pass, rut, email, selectAgregarUsuario, '', '')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.obtenerDatosUsuario(nombre);
          this.modalRefAgregar.hide();
          this.selectRol = false;
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  guardarProveedor(emailref) {
    this.spinnerService.show();
    const nombre = this.responseProveedoresConfig.nombre;
    console.log('nombre ' + nombre);
    const rut = $('#rut').val();
    console.log('rut ' + rut);
    const direccion = $('#direccion').val();
    console.log('direccion ' + direccion);
    const email = $('#email').val();
    console.log('email ' + email);
    const telefono = $('#telefono').val();
    console.log('telefono ' + telefono);
    const selectBodega = $('#selectBodega').val();
    console.log('selectBodega ' + selectBodega);
    const selectDepartamento = $('#selectDepartamento').val();
    console.log('selectDepartamento ' + selectDepartamento);
    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (nombre === '' && rut === ''
      && direccion === '' && email === '' && telefono === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (nombre === '' || rut === ''
      || direccion === '' || email === '' || telefono === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.getAgregarProveedor(nombre, rut, direccion, email, telefono, '0', '0')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.obtenerDatosProveedor(nombre);
          this.modalRefAgregarProveedor.hide();
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  cierreUsuarioModificar() {
    console.log('SELECT_ROL' + this.selectRol);
    this.usuarioCompleto.rol = this.rolAux;
    this.selectRol = this.selectRolAux;
    this.modalRefModificar.hide();
  }

  cierreUsuarioAgregar() {
    this.selectRol = false;
    this.modalRefAgregar.hide();
  }

  abrirModalAgregarProveedor() {
    this.limpiarModalProveedorConfig();
    this.userRut = '';
    this.modalRefAgregarProveedor = this.modalService.show(this.modalAgregarProveedor, {class: 'modal-do3'});
  }

  abrirModalModificarProveedor(proveedor: string) {
    console.log(JSON.stringify(proveedor));
    this.proveedorCompleto = proveedor;
    this.userRut = this.proveedorCompleto.rut;
    this.modalRefModificarProveedor = this.modalService.show(this.modalModificarProveedor, {class: 'modal-do3'});
  }

  obtenerDatosUsuario(nombre: string) {
    this.spinnerService.show();
    this._tableConfiguracion.getUsuario(this.page, '', 'VEV')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseUsuarioCompleto = data.body;
        this.usuario = this._responseUsuarioCompleto.usuariosPaginador;
        this.responseUsuario = this.usuario.dataPage;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  obtenerDatosProveedor(nombre: string) {
    this.spinnerService.show();
    this._tableConfiguracion.getProveedor(this.pageProveedor, '')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseProveedorCompleto = data.body;
        this.proveedor = this._responseProveedorCompleto.proveedorPaginador;
        this.responseProveedor = this.proveedor.dataPage;
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  selectChecked(selectInfo: string, agregar?: boolean) {
    console.log('Select' + JSON.stringify(this.responseProveedor));
    if (agregar) {
    } else {
      this.selectRolAux = this.selectRol;
      this.rolAux = this.usuarioCompleto.rol;
      this.usuarioCompleto.rol = selectInfo;
    }

    if (selectInfo === '2' || selectInfo === '6' || selectInfo === '7') {
      this.selectRol = true;
      if (selectInfo === '6' || selectInfo === '7') {
        this.selectProv = false;
      } else {
        this.selectProv = true;
      }
    } else {
      this.selectRol = false;
    }
    console.log('PRUEBA:' + this.selectRol + selectInfo);
  }

  obtenerBodegas() {
    this._tableConfiguracion.getBodegas().subscribe((data: HttpResponse<any>) => {
      console.log(JSON.stringify(data.body));
      this.bodegas = data.body;
    });
  }

  obtenerDepartamentos() {
    this._tableConfiguracion.getDepartamentos().subscribe((data: HttpResponse<any>) => {
      console.log('Departamentos: ' + JSON.stringify(data.body));
      this.departamentos = data.body;
    });
  }

  obtenerRoles() {
    this._tableConfiguracion.getRoles('VEV')
      .subscribe((data: HttpResponse<any>) => {
      console.log('Roles: ' + JSON.stringify(data.body));
      this._responseRolesCompleto = data.body;
      this.roles = this._responseRolesCompleto.responseSelectList;
    });
  }

  obtieneAlertas() {
    this.spinnerService.show();
    this._tableConfiguracion.getAlertas()
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        console.log('pruebas alertassss' + JSON.stringify(data.body));
        this._responseAlertasCompleto = data.body;
        this.alertas = this._responseAlertasCompleto.responseAlertas;
      });
  }

  guardarAlertas() {
    this.spinnerService.show();
    const alerta1 = $('#inputAlerta1').val();
    console.log('inputAlerta1 ' + alerta1);
    const alerta2 = $('#inputAlerta2').val();
    console.log('inputAlerta2 ' + alerta2);
    const alerta3 = $('#inputAlerta3').val();
    console.log('inputAlerta3 ' + alerta3);

    if (this.alertas[0].valorParametro === alerta1 && this.alertas[1].valorParametro === alerta2 && this.alertas[2].valorParametro === alerta3) {
      this.abrirModalError('Debe modificar al menos una hora alerta del día', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.guardarAlerta(alerta1, alerta2, alerta3)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.obtieneAlertas();
          this.abrirModalError('Ingreso con éxito', 'Éxito.');
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  obtenerProveedoresConfig(nombre: string) {
    this._tableConfiguracion.getProveedoresConfig('')
      .subscribe((data: HttpResponse<any>) => {
        console.log('Proveedores Config: ' + JSON.stringify(data.body));
        this._proveedorConfigResponse = data.body;
        this.proveedoresConfig = this._proveedorConfigResponse.responseProveedorConfigs;
        this.obtenerNombres();
      });
  }

  selectProveedorConfig() {
    console.log('RUT CONFIG: ' + this.nombreProveedor);
    this.proveedoresConfig.forEach(item => {
      if (item.nombre == this.nombreProveedor) {
        this.responseProveedoresConfig = item;
        console.log('RESPONSE PROVEEDORES: ' + JSON.stringify(this.responseProveedoresConfig));
      }
    });
  }

  limpiarModalProveedorConfig() {
    this.nombreProveedor = '';
    this.responseProveedoresConfig.rut = null;
    this.responseProveedoresConfig.direccion = '';
    this.responseProveedoresConfig.email = '';
    this.responseProveedoresConfig.telefono = '';
  }

  obtenerNombres() {
    this.proveedoresConfig.forEach(item => {
      let re = new RegExp('\"', 'g');
      let re2 = item.nombre.replace(re, '');
      re = new RegExp('\\+', 'g');
      re2 = re2.replace(re, '');
      re = new RegExp('\\*', 'g');
      re2 = re2.replace(re, '');
      this.nombres.push(re2);
      // const nombre = item.nombre.split(' ');
    });
  }

  quitarComillas(nombre: string) {
    this.responseProveedoresConfig.nombre = nombre.replace('\"', '');
  }

  buttonSelect() {
    this.mantBodega = true;
  }

  cambiarTipoPass() {
    const pass = $('#pass').attr('type');
    const pass2 = $('#pass2').attr('type');
    const check = $('#checkPass').prop('checked');
    const check2 = $('#checkPass2').prop('checked');
    console.log('PASS1' + pass);
    console.log('PASS2' + pass2);
    console.log('check' + check);
    console.log('check2' + check2);
    if (check) {
      $('#pass').prop('type', 'text');
    } else {
      $('#pass').prop('type', 'password');
    }
    if (check2) {
      $('#pass2').prop('type', 'text');
    } else {
      $('#pass2').prop('type', 'password');
    }
  }

  // andenBodegas() {
  //   $('#horario').hide();
  //
  //   $('.addBodega').on('click', function() {
  //     const bodega = $(this).attr('data-bodega');
  //     const siguiente = parseInt(bodega) + 1;
  //
  //     const elhtml = '<div class="col-sm-6 colFix" id="contenedorBodega' + bodega + '"> <div class="form-group" > <input data-bodega="' + bodega + '" id="bodega' + bodega + '" class="form-control input-sm inputBodega" type="text" placeholder="Nombre"> <img class="eliminarBodega" data-bodega="' + bodega + '"  src="assets/img/eliminar.png"> </div></div><div class="col-sm-6" id="Bodega' + bodega + 'anden"> </div><div class="form-group  col-sm-6 pull-right" id="otroAndenBodega' + bodega + '">  <button  id="btnBodega' + bodega + '" type="button" class="btn btn-default pull-right addAnden" data-bodega="' + bodega + '" data-anden="1"><span class="icon" style="color:#999999">+</span> Agregar otro anden </button> </div> <div class="col-sm-12 separacionBodega" id="separacionBodega' + bodega + '" > </div>';
  //
  //     $('#contenedorBodegas').append(elhtml);
  //
  //     $(this).attr('data-bodega', siguiente);
  //
  //     $('#contenedorBodega' + bodega).on('click', '.eliminarBodega ', function() {
  //       const bodega = $(this).attr('data-bodega');
  //       $('#contenedorBodega' + bodega).remove();
  //       $('#Bodega' + bodega + 'anden').remove();
  //       $('#separacionBodega' + bodega).remove();
  //       $('#btnBodega' + bodega).remove();
  //       $('#otroAndenBodega' + bodega).remove();
  //       //  alert(bodega + " " + anden);
  //     });
  //
  //     $('#otroAndenBodega' + bodega).on('click', '.addAnden', function() {
  //
  //       const bodega = $(this).attr('data-bodega');
  //       const anden = $(this).attr('data-anden');
  //
  //       const siguiente = parseInt(anden) + 1;
  //
  //       const elhtml = '<div class="form-group" id="contenedorBodega' + bodega + 'anden' + anden + '"  > <input name="Bodega' + bodega + 'anden' + anden + '" data-bodega="' + bodega + '" id="Bodega' + bodega + 'anden' + anden + '" class="form-control input-sm inputAnden" type="text" placeholder="Nombre Anden"> <img class="eliminarBodega" src="assets/img/eliminar.png" data-bodega="' + bodega + '"  data-anden="' + anden + '" > </div> ';
  //
  //       $('#Bodega' + bodega + 'anden').append(elhtml);
  //
  //       $(this).attr('data-anden', siguiente);
  //
  //
  //       $('#contenedorBodega' + bodega + 'anden' + anden).on('click', '.eliminarBodega',
  //         function() {
  //           var bodega = $(this).attr('data-bodega');
  //           var anden = $(this).attr('data-anden');
  //           $('#contenedorBodega' + bodega + 'anden' + anden).remove();
  //           //   alert(bodega + " " + anden);
  //         });
  //
  //       $('.inputAnden').on('focus', function() {
  //         $('.inputAnden').removeClass('andenActivo');
  //         $('.inputAnden').attr('placeholder', 'Nombre Anden');
  //         $(this).addClass('andenActivo');
  //         $(this).attr('placeholder', '');
  //
  //         let bodega = $(this).attr('data-bodega');
  //         $('.inputBodega').removeClass('bodegaActiva');
  //
  //         $('.inputBodega').attr('placeholder', 'Nombre Bodega');
  //
  //         $('#bodega' + bodega).addClass('bodegaActiva');
  //         $('#bodega' + bodega).attr('placeholder', '');
  //
  //         $('#horario').show();
  //
  //       });
  //
  //     });
  //
  //     $('.inputBodega').on('focus', function() {
  //
  //       $('.inputAnden').removeClass('andenActivo');
  //       $('.inputAnden').attr('placeholder', 'Nombre Anden');
  //
  //       $('.inputBodega').removeClass('bodegaActiva');
  //       $('.inputBodega').attr('placeholder', 'Nombre Bodega');
  //
  //       $(this).addClass('bodegaActiva');
  //       $(this).attr('placeholder', '');
  //     });
  //   });
  //
  //   $('#marcarTodo').change(function() {
  //     if ($(this).is(':checked')) {
  //       $('input[type=checkbox]').prop('checked', true);
  //       $('#TextMarcarTodo').html('Desmarcar Todo');
  //     } else {
  //       $('input[type=checkbox]').prop('checked', false);
  //       $('#TextMarcarTodo').html('Marcar Todo');
  //     }
  //   });
  //
  // }

  onClickMeBodega() {
    console.log('Presionó botón Agregar bodeda');
    this.mantBodega = true;
    console.log('Mant Bodega:' + this.mantBodega);
    if (this.mantBodega) {
      $('button[name="pressAnden"]').show();
      $('input[name="bodegaText"]').show();
    } else {
    }
  }

  onClickMeAnden() {
    console.log('Presionó botón Agregar Anden');
    this.mantAnden = true;
    console.log('Mant Anden:' + this.mantAnden);
    if (this.mantAnden) {
      $('input[name="andenText"]').show();
    } else {
    }
  }

  obtenerProveedoresAll() {
    this._tableConfiguracion.getProveedoresAll()
      .subscribe((data: HttpResponse<any>) => {
      console.log(JSON.stringify(data.body));
        this._responseProveedorCompleto = data.body;
        this.proveedor = this._responseProveedorCompleto.proveedorPaginador;
        this.responseProveedorAll = this.proveedor.dataPage;
    });
  }

}
