import {Component, OnInit, ViewChild} from '@angular/core';
import {ReporteDashboardGeneral} from '../../../../comun/services/ServicioReporteDashboardGeneral/reporte.dashboard.general.service';
import {ResponseEntregados, ResponseEntregadosDetalle} from '../../../../comun/vo/responseEntregados';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {TokenService} from '../../../../comun/services/token/token.service';
import {ObtieneEstadosDashboardService} from '../../../../comun/services/ServicioObtieneEstadosDashboard/obtiene.estados.dashboard.service';
import {ResponseDetalleValores, ResponseValores} from '../../../../comun/vo/responseValores';
import {ResponseDetalleEntregadosEnPlazo, ResponseEntregadosEnPlazo} from '../../../../comun/vo/responseEntregadosEnPlazo';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

declare var cargaCuatroBarras: any;
declare var cargaAnimacion: any;
declare var animaVelocimetro: any;
declare var cargaVelocimetro: any;
declare var flexFont: any;


@Component({
  selector: 'app-dashboard1-administrador',
  templateUrl: './dashboard1-administrador.component.html',
  styleUrls: ['./dashboard1-administrador.component.css', '../../../../../assets/css/koma.css', '../../../../../assets/css/player2.css']
})
export class Dashboard1AdministradorComponent implements OnInit {
  public responseValores: ResponseValores;
  public responseDetalleValores: ResponseDetalleValores;
  public responseEntregados: ResponseEntregados;
  public responseEntregadosDetalle: ResponseEntregadosDetalle;
  private tipo = 'DDC';
  private fecha = '24-10-2018 10:00';
  jsonVelo: any;
  tipoEntregados: any;
  tipoPendientes: any;
  tipoEnPlazo: any;
  tipoPendientesEnPlazo: any;
  tipoSolucion: string;
  page = 1;
  pageSize = 5;
  pageSize2 = 5;
  selectPrincipalValue: number;
  responseEntregadosEnPlazo: ResponseEntregadosEnPlazo;
  responseDetalleEntregadosPlazo: ResponseDetalleEntregadosEnPlazo[];
  @ViewChild('modalDetalleEnDespacho') modalDetalleEnDespacho;
  modalRefDetalleEnDespacho: BsModalRef;

  constructor(private servicioDashboard1: ReporteDashboardGeneral,
              private _router: Router,
              private _token: TokenService,
              private _estadoDashboard: ObtieneEstadosDashboardService,
              private modalService: BsModalService) { }

  ngOnInit() {
    // this.obtenerValores();
    this.obtenerEntregados();
    this.obtenerValores();
    this.selectPrincipalValue = 5;

  }

  obtenerValores() {
    this._estadoDashboard.getGraficoVertical()
      .subscribe((data: HttpResponse<any>) => {
        this.responseValores = data.body;
        this.responseDetalleValores = this.responseValores.responsePorEntregarVO;
        console.log('RESPONSE VALORES TABLA: ');
        console.log(this.responseDetalleValores);
        // Cargar cuatro barras
        const jsonCuatro = {
          'id': 'grafBarra',
          'a': this.responseDetalleValores.totalUnitarias,
          'aText': 'Unitarias',
          'aUrl': 'http://www.aUrl.cl',
          'b': this.responseDetalleValores.totalStockDVH,
          'bText': 'Stock + DVH',
          'bUrl': 'http://www.bUrl.cl',
          'c': this.responseDetalleValores.totalTraspasoDVH,
          'cText': 'Traspaso + DVH',
          'cUrl': 'http://wwww.cUrl.cl',
          'd': this.responseDetalleValores.totalStockTraspasoDVH,
          'dText': 'Stock + Traspaso + DVH',
          'dUrl': 'http://www.dUrl.cl',
          'rojoText': 'Cuadro Rojo',
          'verdeText': 'Cuadro Verde',
          'rojoLink': 'Cuadro Rojo',
          'verdeLink': 'Cuadro Verde'
        };

        cargaCuatroBarras(jsonCuatro);
        flexFont();
      //   // console.log('resultado todo:' + JSON.stringify(data));
      //   const tokenfull =  data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerEntregados() {
    this._estadoDashboard.getEntregados()
      .subscribe((data: HttpResponse<any>) => {
        this.responseEntregados = data.body;
        this.responseEntregadosDetalle = this.responseEntregados.responseEnDespachoVO;
        const response = this.responseEntregadosDetalle;
        console.log('RESULTADO ENTREGADOS: ');
        console.log(response);
        this.jsonVelo = {
          'id': 'grafVelocimentro',
          'valor': Number(response.porcentajeEntregadas),
          'titulo': 'En Despacho',
          'tituloLink': 'http://kiosolutions.cl/',
          'tituloImg': 'assets/img/icon_camion_rapido.svg',
          'azulText': 'Entregados',
          'azulLink': 'entregadosLink',
          'rojoText': 'No Entregados',
          'rojoLink': 'porEntregarLink'

        };
        // cargaVelocimetro(this.jsonVelo);
        flexFont();
      });
  }

  cambioSelectEntregadosEnPlazo(registros: number) {
    console.log('Valor evento: ' + registros);
    this.page = 1;
    if (this.tipoEntregados !== null || this.tipoPendientes !== null || this.tipoEnPlazo !== null || this.tipoPendientesEnPlazo !== null) {
      this.selectPrincipalValue = registros;
      this.pageSize2 = this.selectPrincipalValue;
    }
    this._estadoDashboard.getDetalleEntregadosEnPlazo(this.page, this.pageSize, this.tipoSolucion,'','')
      .subscribe((data: HttpResponse<any>) => {
        console.log('this.page cambio select: ' + this.page);
        console.log('this.pageSize2 cambio select: ' + this.pageSize2);
        this.responseEntregadosEnPlazo = data.body;
        this.responseDetalleEntregadosPlazo = this.responseEntregadosEnPlazo.dataPage;
      });
  }

  cambiarPaginaEntregadosEnPlazo(pagina: number) {
    this.page = pagina;
    if (this.tipoEntregados !== null || this.tipoPendientes !== null || this.tipoEnPlazo !== null || this.tipoPendientesEnPlazo !== null) {
      this._estadoDashboard.getDetalleEntregadosEnPlazo(this.page, this.pageSize2, this.tipoSolucion,'','')
        .subscribe((data: HttpResponse<any>) => {
          console.log('this.page cambio select: ' + this.page);
          console.log('this.pageSize2 cambio select: ' + this.pageSize2);
          this.responseEntregadosEnPlazo = data.body;
          this.responseDetalleEntregadosPlazo = this.responseEntregadosEnPlazo.dataPage;
        });
    }
  }

  cerrarModal(modal: string) {
    this + modal;
    this.page = 1;
    this.pageSize = 5;
    this.pageSize2 = 5;
  }

  // MODALS DETALLE DESPACHADOS
  abrirModalDetalleDespachados() {
    console.log('DESPACHADOS: ');
    console.log(this.modalDetalleEnDespacho);
    this.modalRefDetalleEnDespacho = this.modalService.show(this.modalDetalleEnDespacho, {class: 'modal-lg'});
  }

  llamadaClickEnDespacho(sno1) {
    switch (sno1) {
      case 'entregadosLink':
        console.log('aca va despachados');
        this._estadoDashboard.getDetalleEntregadosEnPlazo(this.page, this.pageSize2, 'entregados','','')
          .subscribe((data: HttpResponse<any>) => {
            this.responseEntregadosEnPlazo = data.body;
            this.responseDetalleEntregadosPlazo = this.responseEntregadosEnPlazo.dataPage;
            this.abrirModalDetalleDespachados();
            this.selectPrincipalValue = 5;
            this.tipoSolucion = 'entregados';
          });
        break;
      case 'porEntregarLink':
        console.log('aca va por despachar');
        this._estadoDashboard.getDetalleEntregadosEnPlazo(this.page, this.pageSize2, 'pendientes','','')
          .subscribe((data: HttpResponse<any>) => {
            this.responseEntregadosEnPlazo = data.body;
            this.responseDetalleEntregadosPlazo = this.responseEntregadosEnPlazo.dataPage;
            this.abrirModalDetalleDespachados();
            this.selectPrincipalValue = 5;
            this.tipoSolucion = 'pendientes';
          });
        break;
      default:
    }
  }
}
