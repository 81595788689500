import {Component, OnInit, ViewChild} from '@angular/core';
import {ObtenerOrdenesCompra} from '../../../../comun/services/ServicioObtenerOrdenes/obtener.orden.compra.service';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {TokenService} from '../../../../comun/services/token/token.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {SeguimientoOrdenesCompra} from '../../../../comun/services/ServicioSeguimientoOrdenesCompra/seguimiento.ordenes.compra.service';
import {ExcelService} from '../../../../comun/services/excelExport/excel.service';
import {ResponseSeguimiento, SeguimientoOrdenCompra, SeguimientoOrdenCompraFirst} from '../../../../comun/vo/responseSeguimiento';
import {ResponseError} from '../../../../comun/vo/error';
import {ResponseOrdenes} from '../../../../comun/vo/responseOrdenes';
import {ResponseEstadoSeguimiento} from '../../../../comun/vo/responseEstadosSeguimiento';

declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-seguimiento-proveedor',
  templateUrl: './seguimiento-proveedor.component.html',
  styleUrls: ['../../../../../assets/css/koma.css']
  /*styleUrls: ['./seguimiento-proveedor.component.css']*/

})
export class SeguimientoProveedorComponent implements OnInit {
  public ordenes: SeguimientoOrdenCompra[];
  private estActual;
  public codProveedor: string;
  private estTodo: any = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21';
  page = 1;
  pageSize = 5;
  _responseSeguimiento: ResponseSeguimiento;
  responseOrden: SeguimientoOrdenCompraFirst;
  responseOrdenExport: SeguimientoOrdenCompraFirst;
  totalProductos: number;
  // private estTodo: any = '5';
  filtroActivo: boolean;
  isTabPrincipal: boolean;
  public selectPrincipalValue: number;
  public selectSecundarioValue: number;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  messageAlert: MensajeAlert;
  public estados: any[];
  _responseEstados: ResponseEstadoSeguimiento;
  private ordenesExcel: any[];


  constructor(private _tablaService: ObtenerOrdenesCompra,
              private spinnerService: Ng4LoadingSpinnerService,
              private _token: TokenService,
              private _router: Router,
              private modalService: BsModalService,
              private _seguimiento: SeguimientoOrdenesCompra,
              private _excelService: ExcelService) {
  }

  ngOnInit() {
    this.codProveedor = localStorage.getItem('codProveedor');
    console.log('COD PROVEEDOR TS: ' + localStorage.getItem('codProveedor'));
    this.obtenerDatosSeguimiento(this.estActual);
    this.selectPrincipalValue = 5;
    this.selectSecundarioValue = 5;
    this.obtenerEstados();
    this.spinnerService.show();
  }

  obtenerDatosSeguimiento(estado: string) {
    this.spinnerService.show();
    this._seguimiento.getSeguimientoOrdenes(this.page, this.pageSize, '', this.codProveedor)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this._responseSeguimiento = data.body;
        this.responseOrden = this._responseSeguimiento.ordenCompraPaginadorSeguimiento;
        this.ordenes = this.responseOrden.dataPage;
        console.log('resultado' + JSON.stringify(data));
        const tokenfull = data.headers.get('Authorization');
        this._token.actualizaToken(tokenfull);
      }, (error: ResponseError) => {
        if (error.error.code === 401) {
          localStorage.clear();
          this._router.navigate(['/']);
        }
      });
  }

  filtro() {
    this.filtroActivo = true;
    this.spinnerService.show();
    // Variables para mandar al servicio, se deben validar
    const selectDepartamento = $('#selectDepartamento').val();
    console.log('selectDepartamento: ' + selectDepartamento);
    const selectBodega = $('#selectBodega').val();
    console.log('selectBodega: ' + selectBodega);
    const selectDespacho = $('#selectDespacho').val();
    const selectTipoAlerta = $('#selectTipoAlerta').val();
    let selectEstados = $('#selectEstados').val();


    if (selectDespacho === '0' && selectTipoAlerta === '0' && selectEstados === 'n') {
      this.spinnerService.hide();
      this.abrirModalError('Debes seleccionar al menos 1 filtro!', 'Error.');
    } else {

      this.spinnerService.show();
      this._seguimiento.getSeguimientoOrdenesFiltros(this.page, this.pageSize, '', '',
        this.validaSelect(selectDespacho), this.validaSelect(selectTipoAlerta), this.codProveedor, this.validaSelectEstadoN(selectEstados))
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this._responseSeguimiento = data.body;
          this.responseOrden = this._responseSeguimiento.ordenCompraPaginadorSeguimiento;
          this.ordenes = this.responseOrden.dataPage;
          console.log('resultado' + JSON.stringify(data));
          const tokenfull = data.headers.get('Authorization');
          this._token.actualizaToken(tokenfull);
        }, (error: ResponseError) => {
          if (error.error.code === 401) {
            localStorage.clear();
            this._router.navigate(['/']);
          }
        });
    }
  }

  exportarExcel() {
    // Variables para mandar al servicio, se deben validar
    this.spinnerService.show();
    const tipoDespacho = $('#selectDespacho').val();
    const selectEstados = $('#selectEstados').val();

    const listaCodProducto: Array<string> = [];
    const listaTipoDespacho: Array<string> = [];

    console.log(tipoDespacho);

    this._tablaService.getObtenerOrdenesProductoFiltro(this.page, 99999, this.validaSelectEstado(selectEstados), '', '',
      '', '', '', this.validaSelect(tipoDespacho), this.codProveedor, '', '', this.validaFiltro(selectEstados, tipoDespacho))
      .subscribe((data: HttpResponse<any>) => {
        let ordenesDVH: any[];
        // this._responseSeguimiento = data.body;
        console.log(data.body);
        // this.responseOrden = this._responseSeguimiento.ordenCompraPaginadorSeguimiento;
        // this.ordenes = this.responseOrden.dataPage;
        ordenesDVH = data.body.ordenCompraPaginador.dataPage;
        const nuevoArray: Array<any> = [];
        ordenesDVH.forEach(
          item => {
            nuevoArray.push(
              {
                'Fecha Venta': item.fechaVenta,
                'Local Venta': item.localVenta,
                'Glosa Tienda': item.glosaTienda,
                'N° Boleta': item.numeroBoleta,
                'N° Caja': item.numCaja,
                'Glosa Estado': item.estado,
                'Fecha Despacho Cliente': item.fechaCompromisoCliente,
                'Codigo Departamento': item.codigoDepartamento,
                'Glosa Departamento': item.glosaDepto,
                'Codigo Corto': item.codigoCorto,
                'SKU': item.sku,
                'Codigo de Barra': item.codigoBarra,
                'Glosa SKU': item.glosaSku,
                'Cantidad': item.cantidad,
                'Rut Proveedor': item.rutProveedor,
                'Razon Social': item.proveedor,
                'Sucursal Despacho': item.codSucursalDespacho,
                'Glosa Sucursal Despacho': item.glosaSucursalDespacho,
                'Rut Cliente': item.rutCliente,
                'Nombre Cliente': item.nombreCliente,
                'Direccion Cliente': item.direccionCliente,
                'Ciudad': item.ciudadCliente,
                'Comuna': item.comuna,
                'Fono Cliente': item.fonoCliente,
                'Fono Alternativo': item.fonoAlternativo,
                'Observacion': item.observacion,
                'Precio Venta': item.precioVenta,
                'N° OC': item.numOc,
                'Costo Neto OC': item.costoNetoOC,
                'Fecha Vigencia OC': item.fechaVigenciaOc,
                'Sucursal Entrega OC': item.codSucursalEntrega,
                'Fecha Entrega CD': item.fechaEntregaCD,
                'Fecha Entrega Cliente': item.fechaEntregaCliente,
                'Fecha Agenda': item.fechaAgenda,
                'Estado: ': item.estado
              });
          });

        if ($('#selectDespacho').val() === 'DDC') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_Proveedor');
          $('#selectDespacho').val('0');
          this.spinnerService.hide();
        } else if ($('#selectDespacho').val() === 'DVH') {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DVH_Proveedor');
          $('#selectDespacho').val('0');
          this.spinnerService.hide();
        } else {
          this._excelService.exportAsExcelFile(nuevoArray, 'Ventas_DDC_DVH_Administrador');
          this.spinnerService.hide();
        }
      }, error2 => {
        this.spinnerService.hide();
      });
  }

  validaFiltro(estado: string, tipoDespacho: string): string {
    if (estado === 'n' && tipoDespacho === '0') {
      return 'true';
    } else {
      return 'false';
    }
  }


  validaSelect(valor: any): any {
    if (valor === '0') {
      return '';
    } else {
      return valor;
    }
  }

  validaSelectEstado(valor: any): any {
    if (valor === 'n') {
      return this.estTodo;
    } else {
      return valor;
    }
  }

  validaSelectEstadoN(valor: any): any {
    if (valor === 'n') {
      return '';
    } else {
      return valor;
    }
  }

  quitarFiltro() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosSeguimiento(this.estActual);
    } else {
      this.obtenerDatosSeguimiento(this.estActual);
    }
  }

  cambioSelect(registros: number) {
    this.page = 1;
    console.log('Registros: ' + registros);
    if (this.isTabPrincipal) {
      this.selectPrincipalValue = registros;
      this.pageSize = this.selectPrincipalValue;
    } else {
      this.selectSecundarioValue = registros;
      this.pageSize = this.selectSecundarioValue;
    }
    if (this.filtroActivo) {
      this.filtro();
    } else {
      this.obtenerDatosSeguimiento(this.estActual);
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;

    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosSeguimiento(this.estActual);
      } else {
        // this.filtroProducto();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosSeguimiento(this.estActual);
      } else {
        // this.filtroProducto();
      }
    }
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  obtenerEstados() {
    this._tablaService.getEstados('')
      .subscribe((data: HttpResponse<any>) => {
        console.log('resultado Estados: ' + JSON.stringify(data.body));
        this._responseEstados = data.body;
        this.estados = this._responseEstados.responseSelectList;
      });
  }

}
