import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable()
export class ObtenerLiquidacionTransportista {

  // private url = 'http://10.107.101.106:8353/lqt/getLiquidaciones';
  // private url2 = 'http://10.107.101.106:8353/lqt/getBoletas';
  // private url3 = 'http://10.107.101.106:8353/lqt/getCamiones';
  // private url4 = 'http://10.107.101.106:8353/lqt/getEstadosLiquidaciones';

  private url = environment.dominioServicios + '/lqt/ObtenerLiquidacionesTransportista/getLiquidaciones';
  private url2 = environment.dominioServicios + '/lqt/ObtenerLiquidacionesTransportista/getBoletas';
  private url3 = environment.dominioServicios + '/lqt/ObtenerLiquidacionesTransportista/getCamiones';
  private url4 = environment.dominioServicios + '/lqt/ObtenerLiquidacionesTransportista/getEstadosLiquidaciones';


  constructor(private _http: HttpClient) {
  }


  getBoletas(codLiq: any, numBoleta: any) {
    return this._http.get(this.url2 + '?codLiq=' + codLiq + '&numBoleta=' + numBoleta,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getCamiones(codLiq: any, valPatente: any) {
    return this._http.get(this.url3 + '?codLiq=' + codLiq + '&valPatente=' + valPatente,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getLiquidaciones(pagina: any, numFilas: any, estado: any, tipoViaje: any, nomTransportista: any, rutTransportista: any,
                         fechaDesde: any, fechaHasta: any, codTransportista: any) {
    return this._http.get(this.url + '?pagina=' + pagina + '&numFilas=' + numFilas + '&estado=' + estado + '&tipoViaje=' + tipoViaje + '&nomTransportista=' + nomTransportista
      + '&rutTransportista=' + rutTransportista + '&fechaDesde=' + fechaDesde + '&fechaHasta=' + fechaHasta + '&codTransportista=' + codTransportista,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }

  getEstados() {
    return this._http.get(this.url4,
      {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}), observe: 'response'});
  }
}
