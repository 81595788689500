import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import swal from 'sweetalert2';
import {HttpResponse} from '@angular/common/http';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {MantenedorTransportistaService} from '../../../../comun/services/ServicioMantenedorTransportista/mantenedor.transportista.service';
import {
  DetalleMantTransportista,
  ResponseMantTransportista,
  ResponseMantTransportistaCompleto
} from '../../../../comun/vo/responseMantTransportista';
import {DetalleMantCamiones, ResponseMantCamiones, ResponseMantCamionesCompleto} from '../../../../comun/vo/responseMantCamiones';
import {DetalleMantRuta, ResponseMantRutas, ResponseMantRutasComplento} from '../../../../comun/vo/responseMantRutas';
import {ResponseTransSelect, TransportistaSelectVOS} from '../../../../comun/vo/responseTransSelect';
import {ResponseUsuario, ResponseUsuarioCompleto, ResponseUsuarioLista} from '../../../../comun/vo/responseUsuario';
import {ResponseError} from '../../../../comun/vo/error';
import {ConfiguracionService} from '../../../../comun/services/ServicioConfiguracion/configuracion.service';
import {ResponseProveedor} from '../../../../comun/vo/responseProveedor';
import {ProveedoresConfig, ResponseProveedorConfig} from '../../../../comun/vo/responseProveedorConfig';
import {ResponseAdministrarUsuarioVO} from '../../../../comun/vo/usuarioVO';
import {LoginService} from '../../../../comun/services/login/login.service';
import {Router} from '@angular/router';
import {TokenService} from '../../../../comun/services/token/token.service';
import {ResponseRoles, ResponseRolesCompleto} from '../../../../comun/vo/responseRolesSelect';
import {ResponseBodegasOrigen, ResponseListadoBodega} from '../../../../comun/vo/responseBodegasOrigen';


declare var jQuery: any;
declare var $: any;

class MensajeAlert {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'app-configuracion-transportista',
  templateUrl: './configuracion-transportista.component.html',
  styleUrls: ['../../../../../assets/css/koma.css', '../../../../../assets/css/principal.css']
})
export class ConfiguracionTransportistaComponent implements OnInit {
  @ViewChild('modalAgregarTransportista') modalAgregarTransportista;
  modalRefAgregarTransportista: BsModalRef;
  @ViewChild('modalModificarTransportista') modalModificarTransportista;
  modalRefModificarTransportista: BsModalRef;
  @ViewChild('modalAgregarCamion') modalAgregarCamion;
  modalRefAgregarCamion: BsModalRef;
  @ViewChild('modalModificarCamion') modalModificarCamion;
  modalRefModificarCamion: BsModalRef;
  @ViewChild('modalAgregarRuta') modalAgregarRuta;
  modalRefAgregarRuta: BsModalRef;
  @ViewChild('modalModificarRuta') modalModificarRuta;
  modalRefModificarRuta: BsModalRef;
  messageAlert: MensajeAlert;
  @ViewChild('modalErrorGeneral') modalErrorGeneral;
  modalRefErrorGeneral: BsModalRef;
  @ViewChild('modalConfirma') modalConfirma;
  modalRefConfirma: BsModalRef;
  @ViewChild('modalModificarUsuario') modalModificarUsuario;
  modalRefModificar: BsModalRef;
  @ViewChild('modalAgregarUsuario') modalAgregarUsuario;
  modalRefAgregar: BsModalRef;
  page = 1;
  pageSize = 5;
  public responseMantTransportistaCompleto: ResponseMantTransportistaCompleto;
  public responseTransMan: ResponseMantTransportista;
  public responseMant: ResponseMantTransportistaCompleto;
  public responseTransManConfig: ResponseMantTransportista;
  public detalleTransManConfig: DetalleMantTransportista[];
  public responseUsuarioCompleto: ResponseUsuarioCompleto;
  public usuarioPag: ResponseUsuarioLista;
  public responseUsuario: ResponseUsuario[];
  public detalleTransMan: DetalleMantTransportista[];
  public responseMantCamionesCompleto: ResponseMantCamionesCompleto;
  public responseCamionMan: ResponseMantCamiones;
  public detalleCamionMan: DetalleMantCamiones[];
  public responseMantRutasComplento: ResponseMantRutasComplento;
  public responseRutaMan: ResponseMantRutas;
  public detalleRutaMan: DetalleMantRuta[];
  public responseTransSelect: TransportistaSelectVOS =  new TransportistaSelectVOS();
  public responseTransSelect2: DetalleMantTransportista =  new DetalleMantTransportista();
  isTabPrincipal: boolean;
  filtroActivo: boolean;
  public transCompleto: any;
  public camionCompleto: any;
  public rutaCompleto: any;
  public transportistas: ResponseTransSelect;
  transportistaSelectVOS: TransportistaSelectVOS[];
  public nombreTrans: any;
  public config = {displayKey: 'description', limitTo: 10,  search: true, placeholder: 'Selecionar'};
  public nombres: string[] = new Array();
  public nombresTrans: string[] = new Array();
  public nombreTransportista: any;
  public bodegas: any[];
  public selectTransRol: any;
  public selectTransRolAux: any;
  public patenteTrans: any;
  public ciudadRuta: any;
  private nombreActual;
  public roles: any[];
  public selectRolAux: any;
  public rolAux: any;
  public usuarioCompleto: any;
  public selectRol: any;
  public responseProveedor: ResponseProveedor[] = [];
  public userRut: any;
  public proveedoresConfig: ProveedoresConfig;
  private nombreProveedorConfig;
  public responseAdministrarUsuarioVO: ResponseAdministrarUsuarioVO;
  nombreUsuario: any;
  nombreTransportistaP: any;
  responseRolesCompleto: ResponseRolesCompleto;
  responseRoles: ResponseRoles[];
  responseBodegasOrigen: ResponseBodegasOrigen;
  responseListadoBodega: ResponseListadoBodega[];

  constructor(private modalService: BsModalService,
              private _mantenedorTrans: MantenedorTransportistaService,
              private spinnerService: Ng4LoadingSpinnerService,
              private _tableConfiguracion: ConfiguracionService,
              private _loginService: LoginService,
              private _router: Router,
              private _token: TokenService) { }

  ngOnInit() {
    this.responseTransSelect.rut = '';
    this.responseTransSelect.direccion = '';
    this.responseTransSelect.telefono = '';
    this.responseTransSelect.nombre = '';
    this.responseTransSelect2.rutTransportista = '';
    this.responseTransSelect2.dirTransportista = '';
    this.responseTransSelect2.telTransportista = '';
    this.responseTransSelect2.nomTransportista = '';
    this.obtenerRoles();
    this.obtenerDatosTransportista();
    this.obtenerDatosCamiones();
    this.obtenerDatosRutas();
    this.obtenerTransNuevo();
    this.obtenerTransCamion();
    this.obtenerBodegas();
    this.obtenerDatosUsuario();
    this.obtenerProveedoresConfig(this.nombreProveedorConfig);

  }

  abrirModalAgregarTrans() {
    // this.limpiarModalTransConfig();
    this.config = {displayKey: 'description', limitTo: 10,  search: true, placeholder: 'Selecionar'};
    this.modalRefAgregarTransportista = this.modalService.show(this.modalAgregarTransportista, {class: 'modal-lg2'});
  }

  limpiarModalTransConfig() {
    this.responseTransSelect.rut = '';
    console.log('rut: ');
    console.log(this.responseTransSelect.rut);
    this.responseTransSelect.direccion = '';
    console.log('direccion: ');
    console.log(this.responseTransSelect.direccion);
    this.responseTransSelect.telefono = '';
    console.log('telefono: ');
    console.log(this.responseTransSelect.telefono);
    this.responseTransSelect.nombre = '';
    console.log('nombre: ');
    console.log(this.responseTransSelect.nombre);
  }

  abrirModalModificarTrans(trans: DetalleMantTransportista) {
    this.transCompleto = trans;
    this.modalRefModificarTransportista = this.modalService.show(this.modalModificarTransportista, {class: 'modal-lg2'});
  }

  abrirModalAgregarCamion() {
    this.modalRefAgregarCamion = this.modalService.show(this.modalAgregarCamion, {class: 'modal-lg2'});
  }

  abrirModalModificarCamion(camion: DetalleMantCamiones) {
    this.camionCompleto = camion;
    this.modalRefModificarCamion = this.modalService.show(this.modalModificarCamion, {class: 'modal-lg2'});
  }

  abrirModalAgregarRuta() {
    this.modalRefAgregarRuta = this.modalService.show(this.modalAgregarRuta, {class: 'modal-lg2'});
  }

  abrirModalModificarRuta(ruta: DetalleMantRuta) {
    this.rutaCompleto = ruta;
    this.modalRefModificarRuta = this.modalService.show(this.modalModificarRuta, {class: 'modal-lg2'});
  }

  abrirModalError(mensaje: string, motivo: string) {
    const mensajes = new MensajeAlert();
    mensajes.titulo = motivo.toString();
    mensajes.mensaje = mensaje.toString();
    this.messageAlert = mensajes;
    this.modalRefErrorGeneral = this.modalService.show(this.modalErrorGeneral);
  }

  abrirModalModificarUsuario(usuario: any) {
    console.log(JSON.stringify(usuario));
    this.usuarioCompleto = usuario;
    this.rolAux = this.usuarioCompleto.rol;
    this.userRut = this.usuarioCompleto.rutUsuario;
    this.modalRefModificar = this.modalService.show(this.modalModificarUsuario, {class: 'modal-do3'});
  }

  abrirModalAgregarUsuario() {
    this.userRut = '';
    this.modalRefAgregar = this.modalService.show(this.modalAgregarUsuario, {class: 'modal-do3'});
  }

  abrirModalConfirma() {
    this.modalRefConfirma = this.modalService.show(this.modalConfirma, {class: 'modal-ca'});
  }

  abrilModalOkAccion() {
    swal({
      title: 'Correcto!',
      text: 'Guardado correctamente',
      type: 'success',
      confirmButtonText: 'OK'
    }).then(ok => {
      if (ok) {
        if (this.modalRefConfirma !== undefined) {
          this.modalRefConfirma.hide();
        }
        if (this.modalRefAgregarTransportista !== undefined) {
          this.modalRefAgregarTransportista.hide();
        }
        if (this.modalRefModificarTransportista !== undefined) {
          this.modalRefModificarTransportista.hide();

        }
        if (this.modalRefAgregarCamion !== undefined) {
          this.modalRefAgregarCamion.hide();
        }
        if (this.modalRefModificarCamion !== undefined) {
          this.modalRefModificarCamion.hide();
        }
        if (this.modalRefAgregarRuta !== undefined) {
          this.modalRefAgregarRuta.hide();
        }
        if (this.modalRefModificarRuta !== undefined) {
          this.modalRefModificarRuta.hide();
        }
        if (this.modalRefAgregar !== undefined) {
          this.modalRefAgregar.hide();
        }
        if (this.modalRefModificar !== undefined) {
          this.modalRefModificar.hide();
        }
      }
    });
  }

  // cambiarTab(tabActual: string) {
  //   $('#' + tabActual).removeAttr('style');
  // }

  cambiarTab(tabActual: string) {
    if (tabActual === 'usuarios') {
      // this.obtenerDatosUsuario();
    } else if (tabActual === 'trsnportista') {
      // this.obtenerDatosTransportista();
      $('#' + tabActual).removeAttr('style');
    } else if (tabActual === 'camiones') {
      // this.obtenerDatosCamiones();
      $('#' + tabActual).removeAttr('style');
    } else if (tabActual === 'rutas') {
      // this.obtenerDatosRutas();
      $('#' + tabActual).removeAttr('style');
    }
  }

  cambiarPagina(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosUsuario();
      } else {
        this.filtrarUsuarios();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosUsuario();
      } else {
        this.filtrarUsuarios();
      }
    }
  }

  cambiarPaginaTrans(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosTransportista();
      } else {
        this.filtrarTransportista();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosTransportista();
      } else {
        this.filtrarTransportista();
      }
    }
  }

  cambiarPaginaCamion(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosCamiones();
      } else {
        this.filtrarCamiones();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosCamiones();
      } else {
        this.filtrarCamiones();
      }
    }
  }

  cambiarPaginaRuta(pagina: number) {
    this.page = pagina;
    if (this.isTabPrincipal) {
      if (!this.filtroActivo) {
        this.obtenerDatosRutas();
      } else {
        this.filtrarRutas();
      }
    } else {
      if (!this.filtroActivo) {
        this.obtenerDatosRutas();
      } else {
        this.filtrarRutas();
      }
    }
  }

  obtenerDatosTransportista() {
    this.spinnerService.show();
    this.nombreTransportistaP = '';
    this._mantenedorTrans.getMantTransportistas(this.page, this.pageSize, this.nombreTransportistaP)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseMantTransportistaCompleto = data.body;
        this.responseTransMan = this.responseMantTransportistaCompleto.transportistaPaginador;
        this.detalleTransMan = this.responseTransMan.dataPage;
        console.log('DATOS TRANSPORTISTA PESTAÑA');
        console.log(this.detalleTransMan);
        //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerDatosCamiones() {
    this.patenteTrans = '';
    this.spinnerService.show();
    this._mantenedorTrans.getMantCamiones(this.page, this.pageSize, this.patenteTrans)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseMantCamionesCompleto = data.body;
        this.responseCamionMan = this.responseMantCamionesCompleto.camionesPaginador;
        this.detalleCamionMan = this.responseCamionMan.dataPage;
        console.log('DATOS CAMIONES PESTAÑA: ');
        console.log(this.detalleCamionMan);
        //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerDatosRutas() {
    this.ciudadRuta = '';
    this.spinnerService.show();
    this._mantenedorTrans.getMantRutas(this.page, this.pageSize, this.ciudadRuta)
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseMantRutasComplento = data.body;
        this.responseRutaMan = this.responseMantRutasComplento.rutasPaginador;
        this.detalleRutaMan = this.responseRutaMan.dataPage;
        console.log('DATOS RUTA PESTAÑA: ');
        console.log(this.detalleRutaMan);
        //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerDatosUsuario() {
    this.spinnerService.show();
    this.nombreUsuario = '';
    this._tableConfiguracion.getUsuario(this.page, this.nombreUsuario, 'LIQ')
      .subscribe((data: HttpResponse<any>) => {
        this.spinnerService.hide();
        this.responseUsuarioCompleto = data.body;
        this.usuarioPag = this.responseUsuarioCompleto.usuariosPaginador;
        console.log('Response Usuarios: ' + JSON.stringify(data.body));
        this.responseUsuario = this.usuarioPag.dataPage;
      //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerRoles() {
    this._tableConfiguracion.getRoles('LIQ')
      .subscribe((data: HttpResponse<any>) => {
      this.responseRolesCompleto = data.body;
      this.responseRoles = this.responseRolesCompleto.responseSelectList;
      console.log('********************* RESPONSE ROLES: *****************');
      console.log(this.responseRoles);
      //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  filtro() {

  }

  guardarTransportista() {
    this.spinnerService.show();
    const nombreTrans = this.responseTransSelect.nombre;
    const rutTrans = $('#rutTrans').val();
    console.log('RUT TRANS ' + rutTrans);
    const dirTrans = $('#dirTrans').val();
    console.log('DIR TRANS ' + dirTrans);
    const telTrans = $('#telTrans').val();
    console.log('TEL TRANS ' + telTrans);
    const corTrans = $('#corTrans').val();
    console.log('CORREO TRANS ' + corTrans);
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;
    const codTran = $('#codTran').val();
    console.log('codTran ' + codTran);

    if (nombreTrans === '' && rutTrans === ''
      && dirTrans === '' && telTrans === '' && corTrans === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (nombreTrans === '' || rutTrans === ''
      || dirTrans === '' || telTrans === '' || corTrans === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (!EMAIL_REGEXP.test(corTrans)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else {
        this._mantenedorTrans.getAgregarTransportista(nombreTrans, rutTrans, dirTrans, telTrans, corTrans, codTran)
          .subscribe((data: HttpResponse<ResponseMantTransportista>) => {
            this.spinnerService.hide();
            this.selectTransRol = false;
            this.obtenerDatosTransportista();
            this.abrilModalOkAccion();
          //   const tokenfull = data.headers.get('Authorization');
          //   this._token.actualizaToken(tokenfull);
          // }, (error: ResponseError) => {
          //   if (error.error.code === 401) {
          //     localStorage.clear();
          //     this._router.navigate(['/']);
          //   }
          });
      }
  }

  modificarTransportista(crrTrans: any) {
    this.spinnerService.show();
    const nombreTrans = this.transCompleto.nomTransportista;
    // const nombreTrans = $('#nombreTrans').val();
    // console.log('NOMBRE TRANS' + nombreTrans);
    const rutTrans = $('#rutTrans').val();
    console.log('RUT TRANS ' + rutTrans);
    const dirTrans = $('#dirTrans').val();
    console.log('DIR TRANS ' + dirTrans);
    const telTrans = $('#telTrans').val();
    console.log('TEL TRANS ' + telTrans);
    const corTrans = $('#corTrans').val();
    console.log('CORREO TRANS ' + corTrans);
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

    if (nombreTrans === '' && rutTrans === ''
      && dirTrans === '' && telTrans === '' && corTrans === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (nombreTrans === '' || rutTrans === ''
      || dirTrans === '' || telTrans === '' || corTrans === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (!EMAIL_REGEXP.test(corTrans)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getModificarTransportista(crrTrans, nombreTrans, rutTrans, dirTrans, telTrans, corTrans)
        .subscribe((data: HttpResponse<ResponseMantTransportista>) => {
          this.spinnerService.hide();
          this.obtenerDatosTransportista();
          this.abrilModalOkAccion();
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  guardarCamion() {
    this.spinnerService.show();
    const patenteCamion = $('#patenteCamion').val();
    console.log('PATENTE CAMION ' + patenteCamion);
    const marcaCamion = $('#marcaCamion').val();
    console.log('MARCA CAMION ' + marcaCamion);
    const modeloCamion = $('#modeloCamion').val();
    console.log('MODELO CAMION ' + modeloCamion);
    const taraCamion = $('#taraCamion').val();
    console.log('TARA CAMION ' + taraCamion);
    const tipoCamion = $('#tipoCamion').val();
    console.log('tipoCamion ' + tipoCamion);
    const selectTransCamion = $('#selectTransCamion').val();
    console.log('SELECT TRANS CAMION ' + selectTransCamion);


    if (patenteCamion === '' && marcaCamion === ''
      && modeloCamion === '' && tipoCamion === '0' && selectTransCamion === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (patenteCamion === '' || marcaCamion === ''
      || modeloCamion === '' || tipoCamion === '0' || selectTransCamion === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getAgregarCamion(patenteCamion, marcaCamion, modeloCamion, '', selectTransCamion, tipoCamion)
        .subscribe((data: HttpResponse<ResponseMantCamiones>) => {
          this.spinnerService.hide();
          this.obtenerDatosCamiones();
          this.abrilModalOkAccion();
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  modificarCamion() {
    this.spinnerService.show();
    const patenteCamion = $('#patenteCamion').val();
    console.log('PATENTE CAMION ' + patenteCamion);
    const marcaCamion = $('#marcaCamion').val();
    console.log('MARCA CAMION ' + marcaCamion);
    const modeloCamion = $('#modeloCamion').val();
    console.log('MODELO CAMION ' + modeloCamion);
    const taraCamion = $('#taraCamion').val();
    console.log('TARA CAMION ' + taraCamion);
    const tipoCamion = $('#tipoCamion').val();
    console.log('tipoCamion ' + tipoCamion);
    const selectTransCamion = $('#selectTransCamion').val();
    console.log('SELECT TRANS CAMION ' + selectTransCamion);

    if (patenteCamion === '' && marcaCamion === ''
      && modeloCamion === '' && tipoCamion === '0' && selectTransCamion === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (patenteCamion === '' || marcaCamion === ''
      || modeloCamion === '' || tipoCamion === '0' || selectTransCamion === '0') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getMofificarCamion(patenteCamion, marcaCamion, modeloCamion, '', selectTransCamion, tipoCamion)
        .subscribe((data: HttpResponse<ResponseMantCamiones>) => {
          this.spinnerService.hide();
          this.obtenerDatosCamiones();
          this.abrilModalOkAccion();
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  guardarRuta() {
    this.spinnerService.show();
    const selectBodegaOrigen = $('#selectBodegaOrigen').val();
    console.log('selectBodegaOrigen ' + selectBodegaOrigen);
    const selectBodegaOrigenGlosa = $('select[name="selectBodegaOrigen"] option:selected').text();
    console.log('selectBodegaOrigenGlosa ' + selectBodegaOrigenGlosa);
    const selectBodegaDestino = $('#selectBodegaDestino').val();
    console.log('selectBodegaDestino ' + selectBodegaDestino);
    const selectBodegaDestinoGlosa = $('select[name="selectBodegaDestino"] option:selected').text();
    console.log('selectBodegaDestinoGlosa ' + selectBodegaDestinoGlosa);
    const valorViajeRampla = $('#valorViajeRampla').val();
    console.log('valorViajeRampla ' + valorViajeRampla);
    const valorViajeCamion = $('#valorViajeCamion').val();
    console.log('valorViajeCamion ' + valorViajeCamion);
    const valorViajeAcoplado = $('#valorViajeAcoplado').val();
    console.log('valorViajeAcoplado ' + valorViajeAcoplado);

    if (selectBodegaOrigen === '0' && selectBodegaDestino === '0' && valorViajeRampla === '' && valorViajeCamion === '' && valorViajeAcoplado === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (selectBodegaOrigen === '0' || selectBodegaDestino === '0' || valorViajeRampla === '' || valorViajeCamion === '' || valorViajeAcoplado === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getAgregarRuta(selectBodegaOrigen, selectBodegaOrigenGlosa, selectBodegaDestino, selectBodegaDestinoGlosa, valorViajeRampla,
        valorViajeCamion, valorViajeAcoplado)
        .subscribe((data: HttpResponse<ResponseMantRutas>) => {
          this.spinnerService.hide();
          this.obtenerDatosRutas();
          this.abrilModalOkAccion();
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  modificarRuta(crrRuta: number) {
    this.spinnerService.show();
    const selectBodegaOrigen = $('#selectBodegaOrigen').val();
    console.log('selectBodegaOrigen ' + selectBodegaOrigen);
    const selectBodegaOrigenGlosa = $('select[name="selectBodegaOrigen"] option:selected').text();
    console.log('selectBodegaOrigenGlosa ' + selectBodegaOrigenGlosa);
    const selectBodegaDestino = $('#selectBodegaDestino').val();
    console.log('selectBodegaDestino ' + selectBodegaDestino);
    const selectBodegaDestinoGlosa = $('select[name="selectBodegaDestino"] option:selected').text();
    console.log('selectBodegaDestinoGlosa ' + selectBodegaDestinoGlosa);
    const valorViajeRampla = $('#valorViajeRampla').val();
    console.log('valorViajeRampla ' + valorViajeRampla);
    const valorViajeCamion = $('#valorViajeCamion').val();
    console.log('valorViajeCamion ' + valorViajeCamion);
    const valorViajeAcoplado = $('#valorViajeAcoplado').val();
    console.log('valorViajeAcoplado ' + valorViajeAcoplado);

    if (selectBodegaOrigen === '0' && selectBodegaDestino === '0' && valorViajeRampla === '' && valorViajeCamion === '' && valorViajeAcoplado === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (selectBodegaOrigen === '0' || selectBodegaDestino === '0' || valorViajeRampla === '' || valorViajeCamion === '' || valorViajeAcoplado === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getModificarRuta(crrRuta, selectBodegaOrigen, selectBodegaOrigenGlosa, selectBodegaDestino, selectBodegaDestinoGlosa, valorViajeRampla,
        valorViajeCamion, valorViajeAcoplado )
        .subscribe((data: HttpResponse<ResponseMantRutas>) => {
          this.spinnerService.hide();
          this.obtenerDatosRutas();
          this.abrilModalOkAccion();
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  obtenerTransNuevo() {
    this._mantenedorTrans.getTrans('')
      .subscribe((data: HttpResponse<any>) => {
        this.transportistas = data.body;
        this.transportistaSelectVOS = this.transportistas.transportistaSelectVOS;
        this.transportistaSelectVOS.forEach( item => {
          this.nombresTrans.push(item.nombre);
          console.log('THIS NOMBRE: ');
          console.log(this.nombres);
          console.log('ITEM.NOMBRE OBTENER TRANS NUEVO');
          console.log(item.nombre);
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
      });
  }

  // selectTransNuevo2() {
  //   this.responseTransManConfig.dataPage.forEach(item => {
  //     // this.transportistas.transportistaSelectVOS.forEach(item => {
  //     if (item.nomTransportista === this.nombreTransportista.toString()) {
  //       this.responseTransSelect2 = item;
  //     }
  //     console.log('item.nomTransportista');
  //     console.log(item.nomTransportista);
  //   });
  // }

  selectTransNuevo() {
      this.transportistas.transportistaSelectVOS.forEach(item => {
      if (item.nombre == this.nombreTransportista) {
        this.responseTransSelect = item;
      }
      console.log('RESPONSE TRANS SELECT: ');
      console.log(this.responseTransSelect);
        console.log('rut: ');
        console.log(this.responseTransSelect.rut);
        console.log('direccion: ');
        console.log(this.responseTransSelect.direccion);
        console.log('telefono: ');
        console.log(this.responseTransSelect.telefono);
        console.log('nombre: ');
        console.log(this.responseTransSelect.nombre);
      });
  }

  obtenerTransCamion() {
    this.nombreTransportistaP = '';
    this._mantenedorTrans.getMantTransportistas(this.page, 99999999, this.nombreTransportistaP)
      .subscribe((data: HttpResponse<any>) => {
        this.responseMant = data.body;
        this.responseTransManConfig = this.responseMant.transportistaPaginador;
        this.detalleTransManConfig = this.responseTransManConfig.dataPage;
        console.log('detalleTransManConfig obtenertranscamion');
        console.log(this.detalleTransManConfig);
      //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerBodegas() {
    this._mantenedorTrans.getBodegaOrigen()
      .subscribe((data: HttpResponse<any>) => {
        this.responseBodegasOrigen = data.body;
        this.responseListadoBodega = this.responseBodegasOrigen.bodegaVOS;
      //   const tokenfull = data.headers.get('Authorization');
      //   this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  filtrarTransportista() {
    this.spinnerService.show();
    if (!this.filtroActivo) {
          this.page = 1;
        }
        this.filtroActivo = true;
    const nombreTrans = $('#nombreTrans').val();
    this.nombreTransportistaP = nombreTrans;
    if (this.nombreTransportistaP === '') {
      this.abrirModalError('Debe ingresar un nombre de transportista!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._mantenedorTrans.getMantTransportistas(this.page, this.pageSize, this.nombreTransportistaP)
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseMantTransportistaCompleto = data.body;
          this.responseTransMan = this.responseMantTransportistaCompleto.transportistaPaginador;
          this.detalleTransMan = this.responseTransMan.dataPage;
          console.log('resultado' + JSON.stringify(data));
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  filtrarCamiones() {
    if (!this.filtroActivo) {
      this.page = 1;
    }
    this.filtroActivo = true;
    const patenteBusque = $('#patenteBusque').val();
    this.patenteTrans = patenteBusque;

    if (this.patenteTrans === '') {
      this.abrirModalError('Debe ingresar una patente!', 'Error.');
    } else {
      this.spinnerService.show();
      this._mantenedorTrans.getMantCamiones(this.page, this.pageSize, this.patenteTrans)
        .subscribe((data: HttpResponse<ResponseMantCamionesCompleto>) => {
          this.spinnerService.hide();
          this.responseMantCamionesCompleto = data.body;
          this.responseCamionMan = this.responseMantCamionesCompleto.camionesPaginador;
          this.detalleCamionMan = this.responseCamionMan.dataPage;
          console.log('PATENTE CAMION: ');
          console.log(this.detalleCamionMan);
          //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  filtrarRutas() {
    if (!this.filtroActivo) {
      this.page = 1;
    }
    this.filtroActivo = true;
    const ciudadOrigen = $('#ciudadOrigen').val();

    if (ciudadOrigen === '') {
      this.abrirModalError('Debe ingresar una ciudad de origen!', 'Error.');
    } else {
      this.spinnerService.show();
      this._mantenedorTrans.getMantRutas(this.page, this.pageSize, ciudadOrigen)
        .subscribe((data: HttpResponse<ResponseMantRutasComplento>) => {
          console.log('resultado' + JSON.stringify(data));
          this.spinnerService.hide();
          this.responseMantRutasComplento = data.body;
          this.responseRutaMan = this.responseMantRutasComplento.rutasPaginador;
          this.detalleRutaMan = this.responseRutaMan.dataPage;
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  filtrarUsuarios() {
    this.spinnerService.show();
    if (!this.filtroActivo) {
      this.page = 1;
    }
    this.filtroActivo = true;
    const nombreUsuario = $('#nombreUsuario').val();
    this.nombreUsuario = nombreUsuario;
    if (this.nombreUsuario === '') {
      this.abrirModalError('Debes rellenar filtro nombre usuario!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.getUsuario(this.page, this.nombreUsuario, 'LIQ')
        .subscribe((data: HttpResponse<any>) => {
          this.spinnerService.hide();
          this.responseUsuarioCompleto = data.body;
          this.usuarioPag = this.responseUsuarioCompleto.usuariosPaginador;
          this.responseUsuario = this.usuarioPag.dataPage;
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  recargarTransportista() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosTransportista();
    } else {
      this.obtenerDatosTransportista();
    }
  }

  recargarCamiones() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosCamiones();
    } else {
      this.obtenerDatosCamiones();
    }
  }

  recargarRutas() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosRutas();
    } else {
      this.obtenerDatosRutas();
    }
  }

  selectChecked(selectInfo: string, agregar?: boolean) {
    if (agregar) {
    } else {
      this.selectRolAux = this.selectRol;
      this.rolAux = this.usuarioCompleto.rol;
      this.usuarioCompleto.rol = selectInfo;
    }
    console.log('AGREGAR: ' + agregar);

    if (selectInfo === 'LIQ' || selectInfo === '5') {
      this.selectRol = true;
    } else {
      this.selectRol = false;
    }
    console.log('SELECT INFO: ' + selectInfo);

    console.log('PRUEBA: ' + this.selectRol + ' ' + selectInfo);
  }

  cierreAgregar() {
    this.selectRol = false;
    this.modalRefAgregar.hide();
  }

  obtenerProveedoresConfig(nombre: string) {
    this._tableConfiguracion.getProveedoresConfig('')
      .subscribe((data: HttpResponse<any>) => {
        console.log('Proveedores Config: ' + JSON.stringify(data.body));
          this.proveedoresConfig = data.body;
          this.obtenerNombres();
      //     const tokenfull = data.headers.get('Authorization');
      //     this._token.actualizaToken(tokenfull);
      // }, (error: ResponseError) => {
      //   if (error.error.code === 401) {
      //     localStorage.clear();
      //     this._router.navigate(['/']);
      //   }
      });
  }

  obtenerNombres() {
    this.proveedoresConfig.responseProveedorConfigs.forEach(item => {
      let re = new RegExp('\"', 'g');
      let re2 = item.nombre.replace(re, '');
      re = new RegExp('\\+', 'g');
      re2 = re2.replace(re, '');
      re = new RegExp('\\*', 'g');
      re2 = re2.replace(re, '');
      this.nombres.push(re2);
      // const nombre = item.nombre.split(' ');
    });
  }

  guardarUsuario() {
    this.spinnerService.show();
    const nombre = $('#nombre').val();
    console.log('NOMBRE ' + nombre);
    const selectAgregarUsuario = $('#selectAgregarUsuario').val();
    console.log('SELECT USUARIO' + selectAgregarUsuario);
    const usuario = $('#usuario').val();
    console.log('USUARIO ' + usuario);
    const pass = $('#pass').val();
    console.log('PASS ' + pass);
    const pass2 = $('#pass2').val();
    console.log('PASS2 ' + pass2);
    const rut = $('#rut').val();
    console.log('RUT ' + rut);
    const email = $('#email').val();
    console.log('email ' + email);
    const selectTransporGuardar = $('#selectTransporGuardar').val();
    console.log('SELECT TRANSPORTISTA' + selectTransporGuardar);
    const tipoUsuario = $('#selectAgregarUsuario option:selected').text();
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

    if (nombre === ''  && selectAgregarUsuario === '0' && usuario === '' && pass === ''
      && pass2 === '' && rut === '' && email === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (nombre === ''  || selectAgregarUsuario === '0' || usuario === '' || pass === ''
      || pass2 === '' || rut === '' || email === '') {
      this.abrirModalError('Debes llenar todos los campos obligatorios!', 'Error.');
      this.spinnerService.hide();
    } else if (selectAgregarUsuario === '4' && selectTransporGuardar === '0' || selectTransporGuardar === 'undefined') {
      this.abrirModalError('Debes seleccionar el tipo de transportista!', 'Error.');
      this.spinnerService.hide();
    } else if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else if (pass !== pass2) {
      this.abrirModalError('Debe introducir las mismas contraseñas!', 'Error.');
      this.spinnerService.hide();
    } else if (selectAgregarUsuario === '5' && selectTransporGuardar !== '0') {
      this._tableConfiguracion.getAgregarUsuario(nombre, usuario, pass, rut, email, selectAgregarUsuario, '', selectTransporGuardar)
        .subscribe((data: HttpResponse<ResponseAdministrarUsuarioVO>) => {
          this.responseAdministrarUsuarioVO = JSON.parse(JSON.stringify(data));
          this.spinnerService.hide();
          this.obtenerDatosUsuario();
          this.abrilModalOkAccion();
          this.selectRol = false;
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    } else if (selectAgregarUsuario === '4') {
      this._tableConfiguracion.getAgregarUsuario(nombre, usuario, '', rut, email, selectAgregarUsuario, '', '')
          .subscribe((data: HttpResponse<any>) => {
            this.spinnerService.hide();
            this.obtenerDatosUsuario();
            this.modalRefAgregar.hide();
            this.selectRol = false;
          //   const tokenfull = data.headers.get('Authorization');
          //   this._token.actualizaToken(tokenfull);
          // }, (error: ResponseError) => {
          //   if (error.error.code === 401) {
          //     localStorage.clear();
          //     this._router.navigate(['/']);
          //   }
          });
      }
    }

  cierreUsuarioModificar() {
    console.log('SELECT_ROL' + this.selectRol);
    this.usuarioCompleto.rol = this.rolAux;
    this.selectRol = this.selectRolAux;
    this.modalRefModificar.hide();
  }

  modificarUsuario(crrUsuario: any) {
    this.spinnerService.show();
    const nombre = $('#nombre').val();

    console.log('NOMBRE ' + nombre);
    const selectAgregarUsuario = $('#selectAgregarUsuario').val();
    console.log('SELECT USUARIO' + selectAgregarUsuario);
    const usuario = $('#usuario').val();
    console.log('USUARIO ' + usuario);
    const pass = $('#pass').val();
    console.log('PASS ' + pass);
    const pass2 = $('#pass2').val();
    console.log('PASS2 ' + pass2);
    const rut = $('#rut').val();
    console.log('RUT ' + rut);
    const email = $('#email').val();
    console.log('email ' + email);
    const selectTransporGuardar = $('#selectTransporGuardar').val();
    console.log('SELECT TRANSPORTISTA' + selectTransporGuardar);
    const tipoUsuario = $('#selectAgregarUsuario option:selected').text();
    const EMAIL_REGEXP = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;

    if (!EMAIL_REGEXP.test(email)) {
      this.abrirModalError('Por favor ingrese un correo válido!', 'Error.');
      this.spinnerService.hide();
    } else if (pass !== pass2) {
      this.abrirModalError('Debe introducir las mismas contraseñas!', 'Error.');
      this.spinnerService.hide();
    } else {
      this._tableConfiguracion.getModificarUsuario(crrUsuario, nombre, usuario, pass, rut, email, selectAgregarUsuario, selectTransporGuardar)
        .subscribe((data: HttpResponse<any>) => {
          this._loginService.modificarUsuario(tipoUsuario, nombre, usuario, pass, rut, email, selectTransporGuardar, selectAgregarUsuario)
            .subscribe((data2: HttpResponse<any>) => {
            }, (error: ResponseError) => {
              if (error.error.code === 401) {
                localStorage.clear();
                this._router.navigate(['/']);
              }
            });
          this.spinnerService.hide();
          this.obtenerDatosUsuario();
          this.abrilModalOkAccion();
          this.selectRol = false;
        //   const tokenfull = data.headers.get('Authorization');
        //   this._token.actualizaToken(tokenfull);
        // }, (error: ResponseError) => {
        //   if (error.error.code === 401) {
        //     localStorage.clear();
        //     this._router.navigate(['/']);
        //   }
        });
    }
  }

  recargar() {
    this.filtroActivo = false;
    if (this.isTabPrincipal) {
      this.obtenerDatosUsuario();
    } else {
      this.obtenerDatosUsuario();
    }
  }

  cambiarTipoPass() {
    const pass = $('#pass').attr('type');
    const pass2 = $('#pass2').attr('type');
    const check = $('#checkPass').prop('checked');
    const check2 = $('#checkPass2').prop('checked');
    console.log('PASS1' + pass);
    console.log('PASS2' + pass2);
    console.log('check' + check);
    console.log('check2' + check2);
    if (check) {
      $('#pass').prop('type', 'text');
    } else {
      $('#pass').prop('type', 'password');
    }
    if (check2) {
      $('#pass2').prop('type', 'text');
    } else {
      $('#pass2').prop('type', 'password');
    }
  }

}
